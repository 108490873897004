import { Separator, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { ARRoutePaths } from '@v2/constants/EPaths';
import ReceiptIcon from '@icons/receipt-icon.svg';
import ReceiptActiveIcon from '@icons/receipt-active-icon.svg';
import BuildingBalanceIcon from '@icons/building-balance-icon.svg';
import BuildingBalanceFullIcon from '@ar/assets/icons/building-balance-full-icon.svg';
import CustomersOutlinedIcon from '@ar/assets/icons/user_outlined_icon.svg';
import CustomersFilledIcon from '@ar/assets/icons/user-filled-icon.svg';
import SettingIcon from '@icons/setting-icon.svg';
import SettingActiveIcon from '@icons/setting-active-icon.svg';
import LogOutIcon from '@icons/log-out-icon.svg';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useLocation } from 'react-router-dom';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { TOP_LEVEL_AP_MENU_ITEMS, apFilterByFeatureFlags } from '@ap/layouts/ApSideNavigation';
import { getGpoData } from '@v2/utils/GPOUtils';
import { LDFlagSet, useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { setAnonymousContext } from '@v2/utils/LaunchdarklyUtils';
import { SideNavigationLink } from './SideNavigationLink';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { queryClient } from '@/containers/app/Root';

const settingsSubmenu:string[] = [ARRoutePaths.AR_BANKING, ARRoutePaths.AR_BRANDING, ARRoutePaths.AR_SUPPLIER_NOTIFICATIONS, ARRoutePaths.AR_INTEGRATION];
const customersSubmenu:string[] = [ARRoutePaths.AR_CUSTOMER_NOTIFICATIONS];

type SideMenuNavigationItem = {
    to: ARRoutePaths,
    icon?: React.ReactNode,
    activeIcon?: React.ReactNode,
    label: string,
    featureFlags?: FeatureFlags[],
};

type FeatureFlags = {
    name: string,
    value: boolean,
};

const INVOICES_TOP_LEVEL_MENU_ITEM:SideMenuNavigationItem = {
    label: 'Invoices',
    to: ARRoutePaths.AR_INVOICES,
    icon: <ReceiptIcon />,
    activeIcon: <ReceiptActiveIcon className="text-teal-500" />,
};

const PAYOUTS_TOP_LEVEL_MENU_ITEM:SideMenuNavigationItem = {
    label: 'Payouts',
    to: ARRoutePaths.AR_PAYOUTS,
    icon: <BuildingBalanceIcon />,
    activeIcon: <BuildingBalanceFullIcon className="text-teal-500 fill-teal-500" />,
};

const INVOICES_SUBMENU:SideMenuNavigationItem[] = [
    {
        label: 'Drafts',
        to: ARRoutePaths.AR_DRAFT_INVOICES,
        featureFlags: [{ name: 'arInvoiceEditor', value: true }],
    }
];

const CUSTOMERS_SUBMENU:SideMenuNavigationItem[] = [
    {
        label: 'Notifications',
        to: ARRoutePaths.AR_CUSTOMER_NOTIFICATIONS,
    },
];

const SETTINGS_SUBMENU:SideMenuNavigationItem[] = [
    {
        label: 'Banking',
        to: ARRoutePaths.AR_BANKING,
    },
    {
        label: 'Branding',
        to: ARRoutePaths.AR_BRANDING,
    },
    {
        label: 'Notifications',
        to: ARRoutePaths.AR_SUPPLIER_NOTIFICATIONS,
    },
    {
        label: 'Integrations',
        to: ARRoutePaths.AR_INTEGRATION,
    },
];
const filterByFeatureFlags = (item:SideMenuNavigationItem, flags:LDFlagSet) => (item.featureFlags ? item.featureFlags.some((flag) => flags[flag.name] === flag.value) : true);
const filterBankingOptionByReceiverID = (item:SideMenuNavigationItem, paymentReceiverID?:string) => !(item.to === ARRoutePaths.AR_BANKING && !paymentReceiverID);

export const SideNavigation : React.FC = () => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const location = useLocation();
    const ldClient = useLDClient();
    const flags = useFlags();
    const pathname = location.pathname.split('/')[1];
    const isCustomerAndSupplier = supplierLoginData?.isCustomerAndSupplier;
    const isGPO = supplierLoginData.gpo;
    const showSettingsSubmenu = (pathname.includes(ARRoutePaths.AR_SETTINGS) || settingsSubmenu.includes(pathname)) && !isGPO;
    const gpoData = getGpoData(supplierLoginData.gpoMemberships);

    // now in supplier stored last response to supplier route and it is not necessary our auth supplier
    // that is way deleted supplier?.name
    // this side navigation do not use for customers. For customers only used ApSideNavigation
    const supplierName = gpoData?.name ?? supplierLoginData?.supplier_name ?? supplier?.name;
    const showCustomersSubmenu = (pathname.includes(ARRoutePaths.AR_CUSTOMERS) || customersSubmenu.includes(pathname)) && !isGPO;
    const showInvoicesSubmenu = pathname.includes(ARRoutePaths.AR_DRAFT_INVOICES) || pathname.includes(ARRoutePaths.AR_INVOICES);
    const filteredSettingsSubMenu = SETTINGS_SUBMENU
        .filter((item) => filterByFeatureFlags(item, flags))
        .filter((item) => filterBankingOptionByReceiverID(item, supplier?.payment_receiver_id));

    const filteredCustomersSubMenu = CUSTOMERS_SUBMENU
        .filter((item) => filterByFeatureFlags(item, flags));
    const filteredInvoicesSubMenu = INVOICES_SUBMENU.filter((item) => filterByFeatureFlags(item, flags));
    const filteredTopLevelApMenuItems = TOP_LEVEL_AP_MENU_ITEMS.filter((item) => apFilterByFeatureFlags(item, flags));
    const trackEvent = (label:string) => {
        sendTrackingEvent({
            ...TrackingEvents.navigationMenuClicked,
            label,
        });
    };
    /**
     * Sends true if navigation formatting should be for sub menu items
     */
    function subMenuFormatting(item):boolean {
        if (item === ARRoutePaths.AR_SETTINGS) {
            return settingsSubmenu.includes(pathname);
        }
        if (item === ARRoutePaths.AR_CUSTOMERS) {
            return customersSubmenu.includes(pathname);
        }
        return false;
    }
    return <nav className="lg:w-[260px] w-[260px] h-full bg-white border-r border-r-gray-200 flex flex-col">
        <div className="px-5 py-5 ">
            <img src={ gpoData?.logo ?? supplier?.logo_url ?? supplierLoginData?.supplier_logo}
                className="w-30 h-10 object-contain mb-3.5 rounded-md"/>
            <Typography weight="font-medium truncate">{supplierName}</Typography>
        </div>
        <Separator/>
        <div className="flex flex-col gap-y-px px-4 mt-4">
            {isCustomerAndSupplier && filteredTopLevelApMenuItems.map((item) => <SideNavigationLink
                to={item.to}
                onClick={() => trackEvent(item.label)}
                activeIcon={item.activeIcon}
                icon={item.icon}
                isSubmenuActive={item.to === ARRoutePaths.AR_SETTINGS ? settingsSubmenu.includes(pathname) : false}
                key={item.label}>
                {item.label}
            </SideNavigationLink>)}
            <SideNavigationLink
                to={INVOICES_TOP_LEVEL_MENU_ITEM.to}
                onClick={() => trackEvent(INVOICES_TOP_LEVEL_MENU_ITEM.label)}
                activeIcon={INVOICES_TOP_LEVEL_MENU_ITEM.activeIcon}
                icon={INVOICES_TOP_LEVEL_MENU_ITEM.icon}
                isSubmenuActive={showInvoicesSubmenu || pathname === INVOICES_TOP_LEVEL_MENU_ITEM.to}
                key={INVOICES_TOP_LEVEL_MENU_ITEM.label}>
                {INVOICES_TOP_LEVEL_MENU_ITEM.label}
            </SideNavigationLink>
            {showInvoicesSubmenu && <>
                {filteredInvoicesSubMenu.map(
                    (item) => <SideNavigationLink
                        to={item.to}
                        onClick={() => trackEvent(item.label)}
                        key={item.label}>
                        {item.label}
                    </SideNavigationLink>,
                )}
            </>}

            <SideNavigationLink
                to={PAYOUTS_TOP_LEVEL_MENU_ITEM.to}
                onClick={() => trackEvent(PAYOUTS_TOP_LEVEL_MENU_ITEM.label)}
                activeIcon={PAYOUTS_TOP_LEVEL_MENU_ITEM.activeIcon}
                icon={PAYOUTS_TOP_LEVEL_MENU_ITEM.icon}
                isSubmenuActive={PAYOUTS_TOP_LEVEL_MENU_ITEM.to === ARRoutePaths.AR_SETTINGS ? settingsSubmenu.includes(pathname) : false}
                key={PAYOUTS_TOP_LEVEL_MENU_ITEM.label}>
                {PAYOUTS_TOP_LEVEL_MENU_ITEM.label}
            </SideNavigationLink>

            <SideNavigationLink
                to={ARRoutePaths.AR_CUSTOMERS}
                onClick={() => trackEvent('Customers')}
                activeIcon={<CustomersFilledIcon className="text-teal-500 fill-teal-500"/>}
                icon={<CustomersOutlinedIcon />}
                isSubmenuActive={subMenuFormatting(ARRoutePaths.AR_CUSTOMERS)}
                key={'Customers'}>
                {'Customers'}
            </SideNavigationLink>
            {showCustomersSubmenu && <>
                {filteredCustomersSubMenu.map(
                    (item) => <SideNavigationLink
                        to={item.to}
                        onClick={() => trackEvent(item.label)}
                        key={item.label}>
                        {item.label}
                    </SideNavigationLink>,
                )}
            </>}
            {!isGPO && <SideNavigationLink
                to={ARRoutePaths.AR_SETTINGS}
                onClick={() => trackEvent('Settings')}
                activeIcon={<SettingActiveIcon className="text-teal-500 fill-teal-500" />}
                icon={<SettingIcon />}
                isSubmenuActive={subMenuFormatting(ARRoutePaths.AR_SETTINGS)}
                key={'Settings'}>
                {'Settings'}
            </SideNavigationLink>}
            {showSettingsSubmenu && <>
                {filteredSettingsSubMenu.map(
                    (item) => <SideNavigationLink
                        to={item.to}
                        onClick={() => trackEvent(item.label)}
                        key={item.label}>
                        {item.label}
                    </SideNavigationLink>,
                )}

            </>}
        </div>
        <div className="mt-auto px-4 mb-4">
            <SideNavigationLink
                to={'/'}
                activeIcon={<LogOutIcon className="text-teal-500 fill-teal-500" />}
                onClick={async () => {
                    trackEvent('Account');
                    await queryClient.invalidateQueries();
                    await setAnonymousContext(ldClient);
                    UserAuthenticationProvider.logOut();
                }}
                icon={<LogOutIcon />}
                key={'Logout'}>
                    Logout
            </SideNavigationLink>

        </div>
    </nav>;
};
