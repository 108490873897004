import { PDFDocument } from 'pdf-lib';

/**

 Converts a Blob object to a base64-encoded string.
 *
 @param {Blob} blob - The Blob object to convert to base64.
 @returns {Promise<unknown>} - A Promise that resolves with the base64-encoded string.
 */
export async function blobToBase64(blob): Promise<unknown> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = ():void => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export const PDF_CONTENT_TYPE = 'application/pdf';

export async function getPdfFromUrl(url: string): Promise<{ pdfUrlObject:string, pdfBytes?: Uint8Array }> {
    try {
        const pdfResponse = await fetch(url);
        if (pdfResponse.ok) {
            const pdfUrlBytes = await pdfResponse.arrayBuffer();
            const pdfDoc = await PDFDocument.load(pdfUrlBytes);
            const pdfBytes = await pdfDoc.save();
            const blobPdf = new Blob([pdfBytes], { type: PDF_CONTENT_TYPE });
            return {
                pdfUrlObject: URL.createObjectURL(blobPdf),
                pdfBytes
            };
        }
        return {
            pdfUrlObject: '',
        };
    } catch (e) {
        return {
            pdfUrlObject: '',
        };
    }
}
