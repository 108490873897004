import { push } from 'react-router-redux';
import React from 'react';
import { toastr } from 'react-redux-toastr';

import * as types from '../constants/ActionTypes';
import * as DataAPI from '../api/DataAPI';
import * as buyerActions from './buyerActions';
import * as accountActions from './accountActions';
import * as orderActions from './orderActions';
import * as orderInvoicesActions from './orderInvoicesActions';
import * as errorsActions from './errorsActions';
import * as routerActions from './routerActions';
import { logException } from '../domains/shared/logger';

/*
 ** This is the loader for specific settings pages that we don't necessarily load initally.
 ** So far this is things like completedOrders(processed), invoicedOrders and locations.
 ** This is because we don't need load the locations for example, until we are on that page.
 */
export function showLoaderSpecificSettings(loadingSpecificSettings) {
  return {
    type: types.SHOW_LOADER_SPECIFIC_SETTINGS,
    loadingSpecificSettings,
  };
}

export function showLoaderSettingsModal(loadingSettingsModal) {
  return {
    type: types.SHOW_LOADER_MODAL_SETTINGS,
    loadingSettingsModal,
  };
}

export function showLoaderPaymentsTabs(loadingPaymentsTabs) {
  return {
    type: types.SHOW_LOADER_PAYMENTS_TABS,
    loadingPaymentsTabs,
  };
}

export function toggleEditBuyerModal(showEditBuyerModal) {
  return {
    type: types.TOGGLE_EDIT_BUYER_MODAL,
    showEditBuyerModal: !showEditBuyerModal,
  };
}

export function toggleAccountSettingsModal(showAccountSettingsModal) {
  return {
    type: types.TOGGLE_ACCOUNT_SETTINGS_MODAL,
    showAccountSettingsModal: !showAccountSettingsModal,
  };
}

export function toggleLocationsModal(showLocationsModal) {
  return {
    type: types.TOGGLE_LOCATIONS_MODAL,
    showLocationsModal: !showLocationsModal,
  };
}

export function updateLocationsModalIndex(locationsModalIndex) {
  return {
    type: types.UPDATE_LOCATIONS_MODAL_INDEX,
    locationsModalIndex,
  };
}

export function toggleMembersModal(showMembersModal) {
  return {
    type: types.TOGGLE_MEMBERS_MODAL,
    showMembersModal: !showMembersModal,
  };
}
export function toggleInvoicePaymentModal(showInvoicePaymentModal) {
  return {
    type: types.TOGGLE_INVOICE_PAYMENT_MODAL,
    showInvoicePaymentModal: !showInvoicePaymentModal,
  };
}

export function updateMembersModalIndex(membersModalIndex) {
  return {
    type: types.UPDATE_MEMBERS_MODAL_INDEX,
    membersModalIndex,
  };
}

export function toggleDeleteMembersModal(showDeleteMembersModal) {
  return {
    type: types.TOGGLE_DELETE_MEMBER_MODAL,
    showDeleteMembersModal: !showDeleteMembersModal,
  };
}

export function storePreviousPathname(pathname) {
  return {
    type: types.STORE_PREVIOUS_PATH_SETTINGS,
    prevPathname: pathname,
  };
}

export function receiveConfigParams(data) {
  const {
    application,
    orderConfirmCountdown,
    overdueTermsAPR,
    overdueTermsDuration,
    overdueTermsUnitPercentage,
    stripePubKey,
    version,
  } = data;

  return {
    type: types.RECEIVE_CONFIG_PARAMS,
    application,
    orderConfirmCountdown,
    overdueTermsAPR,
    overdueTermsDuration,
    overdueTermsUnitPercentage,
    stripePubKey,
    version,
  };
}

export function openStripeModal(isStripeModalOpen) {
  return {
    type: types.OPEN_STRIPE_MODAL,
    isStripeModalOpen,
  };
}

// Thunks

export function InitInvoicesPayments() {
  return async (dispatch) => {
    dispatch(showLoaderSpecificSettings(true));

    try {
      // 1) Get all invoices
      // 2) Get buyer balance
      const responses = await Promise.all([
        dispatch(orderInvoicesActions.loadOrderInvoices()),
      ]);

      // throw an exception in this function if any responses are not received
      if (responses.every((response) => response) === false) {
        throw new Error('One endpoint failed in loading in Promise.all');
      }

      dispatch(showLoaderSpecificSettings(false));
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      console.error('An Error occured with InitInvoicesPayments');
      console.error(error);
      logException(error);
      toastr(errorMessage);
    }
  };
}

export function loadStatementsSettings() {
  return (dispatch) => {
    dispatch(showLoaderPaymentsTabs(true));

    return dispatch(orderInvoicesActions.loadOrderInvoices()).then(() => {
      dispatch(showLoaderPaymentsTabs(false));
    });
  };
}

export function loadAPIConfig() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_API_CONFIG_REQUEST });

      const response = await DataAPI.fetchAPIConfig();
      dispatch({ type: types.FETCH_API_CONFIG_SUCCESS });
      dispatch(receiveConfigParams(response.data));

      return response.data;
    } catch (error) {
      dispatch({ type: types.FETCH_API_CONFIG_FAILED });
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadAPIConfig');
      console.error(error);
      logException(error);
    }
  };
}

export function updateBuyerSettings(data) {
  return (dispatch) => {
    dispatch(showLoaderSettingsModal(true));

    return dispatch(buyerActions.updateBuyer(data)).then(
      () => {
        dispatch(showLoaderSettingsModal(false));
        dispatch(toggleEditBuyerModal(true));
      },
      (error) => {
        // Promise rejected, server call error
        dispatch(showLoaderSettingsModal(false));
      }
    );
  };
}

export function updateAccountSettings(accountUrlsafe, data) {
  return (dispatch) => {
    dispatch(showLoaderSettingsModal(true));

    return dispatch(
      accountActions.updateAccountSettings(accountUrlsafe, data)
    ).then(
      (data) => {
        if (data && data.error === true) {
          dispatch(errorsActions.showError([`Error: ${data.error_msg}`]));
          dispatch(showLoaderSettingsModal(false));
        } else {
          dispatch(showLoaderSettingsModal(false));
          dispatch(toggleAccountSettingsModal(true));
        }
      },
      (error) => {
        // Promise rejected, server call error
        dispatch(showLoaderSettingsModal(false));
      }
    );
  };
}

export function loadBuyerMembers() {
  return async (dispatch) => {
    try {
      dispatch(showLoaderSettingsModal(true));
      await dispatch(buyerActions.loadBuyerMembers());
      dispatch(showLoaderSettingsModal(false));
    } catch (error) {
      dispatch({ type: types.FETCH_API_CONFIG_FAILED });
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadBuyerMembers');
      console.error(error);
      logException(error);
    }
  };
}

export function createMemberSettings(data) {
  return (dispatch) => {
    dispatch(showLoaderSettingsModal(true));

    return dispatch(buyerActions.createMember(data)).then(() => {
      dispatch(showLoaderSettingsModal(false));
      dispatch(toggleMembersModal(true));
    });
  };
}

export function updateMemberSettings(memberUrlsafe, data) {
  return (dispatch) => {
    dispatch(showLoaderSettingsModal(true));

    return dispatch(buyerActions.updateMember(memberUrlsafe, data)).then(() => {
      dispatch(showLoaderSettingsModal(false));
      dispatch(toggleMembersModal(true));
    });
  };
}

export function deleteMemberSettings(selectedMemberUrlsafes) {
  return (dispatch) => {
    for (let i = 0; i < selectedMemberUrlsafes.length; i++) {
      dispatch(buyerActions.deleteMember(selectedMemberUrlsafes[i]));
    }
  };
}
