import { batch } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import React from 'react'; // Needed for errorMessage
import * as orderActions from './orderActions';
import * as analyticsActions from './analyticsActions';
import * as modalsActions from './modalsActions';

import * as types from '../constants/ActionTypes';
import Utils from '../utils';
import * as DataAPI from '../api/DataAPI';
import * as checkoutSelectors from '../selectors/checkoutSelectors';
import { changeRoute } from './routerActions';
import { AnalyticsEventSources } from '../constants/AnalyticsEventSources';
import { logException } from '../domains/shared/logger';
import { EPaths } from '@v2/constants/EPaths';

//
// Exported Actions
//
export function clearData() {
  return {
    type: types.CLEAR_DATA_CHECKOUT,
  };
}

export function showError(errorType, message) {
  return (dispatch, getState) => {
    dispatch(showErrorFinal(errorType, message));

    setTimeout(() => {
      dispatch(removeError());
    }, 6000);
  };
}

export function changeOrderDeliveryRequestsView(orderDeliveryRequestsView) {
  return {
    type: types.CHANGE_ORDER_DELIVERY_REQUESTS_VIEW,
    orderDeliveryRequestsView,
  };
}

export function changeCheckoutView(
  checkoutView,
  checkoutStep1Complete,
  placeOrderCallback,
  deliveryInstructions
) {
  return async (dispatch, getState) => {
    try {
      const { isPendingOrders, paymentMethod, orderToPlaceUrlsafe } =
        getState().checkoutReducer;
      const { buyer } = getState().buyerReducer;
      const order = getState().ordersReducer.orders.find(
        (o) => o.urlsafe === orderToPlaceUrlsafe
      );

      // Do some validationg here
      let error = false;
      let errorMessage = [];

      if (checkoutView === 'reviewView') {
        if (order.items.length > 0) {
          dispatch(collapseOrder([order], order.urlsafe, false));
        }

        // Makes sure if checkout Step1 is complete.
        if (!checkoutStep1Complete.result) {
          error = true;
          errorMessage = [checkoutStep1Complete.errorMessage];
        }
      } else if (checkoutView === 'pendingView') {
        // Validate all orders before proceeding to checkout
        dispatch({ type: types.CHECKOUT_VALIDATE_BEFORE_PLACING_ORDER });

        ({ error, errorMessage } = validateBeforePlacingOrder(
          [order],
          isPendingOrders
        ));

        if (buyer.isSuspended) {
          dispatch(modalsActions.toggleBuyerSuspendedModal());
          return;
        } else if (
          !buyer.stripeCustomerID &&
          paymentMethod === 'payByCreditCard'
        ) {
          dispatch(modalsActions.toggleBuyerCreditCardModal());
          return;
        } else if (isPendingOrders) {
          // Make sure we go to this link after checking out, as opposed to the order specific pending link
          dispatch(changeRoute('/checkout/pending/'));
        } else if (!isPendingOrders && !error) {
          const result = await dispatch(placeOrders([order], undefined, 0, deliveryInstructions));

          if (result) {
            if (result === 'ORDER_IS_CHECKED_OUT') {
              dispatch(modalsActions.showOrderIsAlreadyCheckedOutModal());
              return;
            }
            if (placeOrderCallback) {
              placeOrderCallback();
            } else {
              dispatch({ type: 'CHECKOUT_ORDERS_PLACED', orders: result.data });
              dispatch(showLoaderCheckout(false));
            }
          } else {
            // This will prevent going to the next step
            error = true;
            // Keep this message empty because any error in placeOrder will be handled by it
            errorMessage = [];
          }
        }
      }

      if (error) {
        dispatch(showError('checkoutError', errorMessage));
      } else {
        dispatch(changeCheckoutViewStateOnly(checkoutView));
      }
    } catch (error) {
      console.error(error);
      logException(error);

      // If we have an issue with the changeView then we need to take them back
      // to the previous step. except deliveryView, keep them on the same step.
      if (checkoutView === 'pendingView') {
        dispatch(changeCheckoutViewStateOnly('reviewView'));
      } else {
        dispatch(changeCheckoutViewStateOnly('deliveryView'));
      }
    }
  };
}

export const initCheckoutTokenState = () => ({
  type: 'INIT_CHECKOUT_TOKEN_STATE',
});

export function onSaveDeliveryDayFromCheckout(
  orders,
  order_urlsafe,
  deliveryDay
) {
  return async (dispatch, getState) => {
    const { isPendingOrders } = getState().checkoutReducer;
    const order = orders.find((o) => o.urlsafe === order_urlsafe);

    if (isPendingOrders && deliveryDay === null) {
      // Pending order deliveryDays should NEVER be reset to null.
      // Don't continue with updating the date.
      return;
    } else if (Utils.checkIfPreOrder(order, deliveryDay)) {
      // Check if any of the the items of this order are preOrders and are being
      // delivered today or tomorrow. In that case, we show them the preOrder restricted dialogue.
      dispatch(
        orderActions.showPreOrderRestriction(orders, order_urlsafe, true)
      );

      // Don't continue with updating the date.
      return;
    } else {
      // Finally if everything is working, then save the deliveryDay
      // await is meaningless but only for test cases
      await dispatch(
        orderActions.onSaveDeliveryDay(
          orders,
          order_urlsafe,
          deliveryDay,
          isPendingOrders
        )
      );
    }
  };
}

export function toggleCollapse(orders, order_urlsafe) {
  const index = orders.findIndex((order) => order.urlsafe === order_urlsafe);

  return {
    type: types.TOGGLE_COLLAPSE_CHECKOUT,
    index,
    collapse: !orders[index].collapse,
  };
}

export function toggleChangeUnitModalCheckout(
  order_urlSafe,
  product,
  showChangeUnitModal
) {
  return {
    type: types.TOGGLE_CHANGE_UNIT_MODAL_CHECKOUT,
    showChangeUnitModal: !showChangeUnitModal,
    changeUnitProduct: product,
    currentOrder_urlSafe: order_urlSafe,
  };
}

export function collapseOrder(orders, order_urlsafe, collapse) {
  const index = orders.findIndex((order) => order.urlsafe === order_urlsafe);

  return {
    type: types.COLLAPSE_ORDER,
    collapse,
    index,
  };
}

export function changePendingOrderState(isPendingOrders) {
  return {
    type: types.TOGGLE_PENDING_ORDER_STATE,
    isPendingOrders,
  };
}

export function changePhoneNumber(accountPhoneNumber) {
  return {
    type: types.CHANGE_BUYER_PHONE_NUMBER,
    accountPhoneNumber,
  };
}

export function changeChosenLocation(
  chosenLocation,
  deliveryInstructions,
  deliveryContactName,
  deliveryContactNumber
) {
  return {
    type: types.CHANGE_CHOSEN_LOCATION,
    chosenLocation,
    deliveryInstructions,
    deliveryContactName,
    deliveryContactNumber,
  };
}

export function exitCheckout() {
  return (dispatch) => {
    dispatch(push(EPaths.HOME));
  };
}

export function redirectToConfirmation(orderUrlsafe) {
  return (dispatch) => {
    dispatch(push(`/checkout/${orderUrlsafe}/confirmation`));
  };
}

export function toggleCancelOrderModal(showCancelOrderModal, order_urlsafe) {
  return {
    type: types.TOGGLE_CANCEL_ORDER_MODAL,
    showCancelOrderModal: !showCancelOrderModal,
    cancelOrderUrlsafe: order_urlsafe,
  };
}

export function showLoaderCancelOrderModal(loadingCancelOrderModal) {
  return {
    type: types.SHOW_LOADER_CANCEL_ORDER_MODAL,
    loadingCancelOrderModal: loadingCancelOrderModal,
  };
}

export function selectNextAvailableDate(orders, orderUrlsafe, vendors) {
  let selectedItemIndex = -1;

  // Get order
  const order = orders.find((o) => o.urlsafe === orderUrlsafe);

  const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

  // Get Vendor's nextAvailableDeliveryDays for order
  const nextAvailableDeliveryDays = vendor.region.nextAvailableDeliveryDays;

  // Find the next available date for 48 Hour notice items in at least 2 days
  if (nextAvailableDeliveryDays && nextAvailableDeliveryDays.length > 0) {
    selectedItemIndex = nextAvailableDeliveryDays.findIndex(
      (d) => moment(d.date).diff(moment(), 'days') >= 2
    );

    if (selectedItemIndex > 2) {
      selectedItemIndex = 3;
    }

    const dateIn2Days = Utils.formatDate(
      nextAvailableDeliveryDays[selectedItemIndex].date
    );

    return async (dispatch) => {
      // Change index of deliveryDayItems to be at least 2 days from now.
      dispatch(
        orderActions.changeSelectedDeliveryDayOptionIndex(
          orders,
          orderUrlsafe,
          selectedItemIndex
        )
      );

      // Save delivery day. This method will also toggle off preOrderRestricted flag.
      // await is meaningless, it's for testing purposes only
      if (dateIn2Days) {
        await dispatch(
          orderActions.onSaveDeliveryDay(orders, orderUrlsafe, dateIn2Days)
        );
      }
    };
  }
}

export function deletePreOrderItemsFromCheckout(orders, orderUrlsafe) {
  return async (dispatch) => {
    dispatch(showLoaderCheckout(true));

    // Delete pre-order items for this order
    await dispatch(
      orderActions.deletePreOrderItemsForOrder(orders, orderUrlsafe)
    );

    dispatch(showLoaderCheckout(false));
  };
}

export function showLoaderCheckoutSidebar(loading) {
  return {
    type: types.SHOW_LOADER_CHECKOUT_SIDEBAR,
    loading,
  };
}

//
// Internal Actions
//
function showLoaderCheckout(loading) {
  return {
    type: types.SHOW_LOADER_CHECKOUT,
    loading,
  };
}

function initDeliveryAddressInfo() {
  return (dispatch, getState) => {
    let orderDeliveryRequestsView = '';
    let chosenLocation = '';
    let deliveryInstructions = '';
    let deliveryContactName = '';
    let deliveryContactNumber = '';

    const { orders } = getState().ordersReducer;
    const { buyer = {} } = getState().buyerReducer;
    const { shippingAddresses } = buyer;

    // If the user has already selected a default location for any vendor (we choose the first one here), then
    // we set the view to be the default view diplaying that chosen address.
    if (orders.length > 0) {
      chosenLocation = shippingAddresses[0].formatted;
      deliveryInstructions = shippingAddresses[0].driveInstructions;
      deliveryContactName = shippingAddresses[0].deliveryContactName;
      deliveryContactNumber = shippingAddresses[0].deliveryContactNumber;
      orderDeliveryRequestsView = 'ShowDeliveryRequestsView';
    } else {
      /* If there are more than one locations or no locations we now go
      to EditDeliveryRequestsView. */
      orderDeliveryRequestsView = 'EditDeliveryRequestsView';
    }

    dispatch(changeOrderDeliveryRequestsView(orderDeliveryRequestsView));
    dispatch(
      changeChosenLocation(
        chosenLocation,
        deliveryInstructions,
        deliveryContactName,
        deliveryContactNumber
      )
    );
  };
}

function changeCheckoutViewStateOnly(checkoutView) {
  return {
    type: types.CHANGE_CHECKOUT_VIEW,
    checkoutView,
  };
}

function showErrorFinal(errorType, errorMessage) {
  return {
    type: types.SHOW_ERROR_CHECKOUT,
    loading: false,
    error: true,
    errorType,
    errorMessage,
  };
}

function removeError() {
  return {
    type: types.REMOVE_ERROR_CHECKOUT,
    errorType: '',
    error: false,
    errorMessage: [],
  };
}

function removeOrderCheckout(orders, cancelOrderUrlsafe) {
  return (dispatch) => {
    dispatch(orderActions.removeOrderFromCart(orders, cancelOrderUrlsafe));
  };
}

//
// Async Thunks
//

// Loads initial locations and orders when checkout mounts
export const initCheckout = (isPendingOrders) => {
  return async (dispatch, getState) => {
    try {
      // show the loader while we retrieve the orders and locations from the server
      dispatch(showLoaderCheckout(true));

      // set this variable that let's us know if we should load the current or pending orders
      // for the the checkout.
      dispatch(changePendingOrderState(isPendingOrders));

      // Change the default view to pendingView if we are loading the /checkout/pending/
      // route.
      if (isPendingOrders) {
        dispatch(changeCheckoutViewStateOnly('pendingView'));
      }

      // Get all previous orders (in cart) to use later in mismatch functions below.
      // Need to get the value here before the loadOrdersCheckout and preCheck calls change it.
      const { orders: previousOrders } = getState().ordersReducer;
      const { buyer } = getState().buyerReducer;

      await dispatch(loadOrdersCheckout(isPendingOrders));

      // Add a phone number if we have one in our account
      const { account } = getState().accountReducer;
      dispatch(changePhoneNumber(account.mobile));

      // Perform a pre-checkout call on all non-pending orders
      if (!isPendingOrders) {
        const { orders } = getState().ordersReducer;
        const shippingAddressID = buyer.shippingAddresses[0].id;

        // Await to patch the orders with the tax so that checkout components will only render once
        await dispatch(
          checkoutUpdateTaxesFeesAndShippingAddress(orders, shippingAddressID)
        );
      }

      // If there's any mistmatch between the orders in the cart and in the checkout, send an exception
      // 1) Ignore pending orders (only for orders in the cart)
      // 2) Must have had orders in the cart (before checkout)
      // 2) Must have had items in the cart (before checkout)
      if (
        !isPendingOrders &&
        previousOrders &&
        previousOrders.length > 0 &&
        previousOrders.some((order) => order.items && order.items.length > 0)
      ) {
        dispatch(checkForCartAndCheckoutOrderItemsMismatch(previousOrders));
        dispatch(checkForCartAndCheckoutOrderTotalsMismatch(previousOrders));
      }

      // Remove loader
      dispatch(showLoaderCheckout(false));

      // Initialize delivery address information
      dispatch(initDeliveryAddressInfo());
    } catch (error) {
      console.error(error);
      console.error('error occurred in initCheckout');
      logException(error);
    }
  };
};

export const loadOrdersCheckout = (isPendingOrdersFromArg) => {
  return async (dispatch, getState) => {
    let response;

    const { isPendingOrders: isPendingOrdersFromProps } =
      getState().checkoutReducer;

    // If isPendingOrders status is not provided from args (i.e undefined) use isPendingOrders value from the props directly
    const isPendingOrders =
      isPendingOrdersFromArg === undefined
        ? isPendingOrdersFromProps
        : isPendingOrdersFromArg;

    if (isPendingOrders) {
      response = await dispatch(
        orderActions.loadPendingOrders({
          showLoadingPage: false,
          includeAddOnOrders: true,
        })
      );
    } else {
      response = await dispatch(orderActions.loadCartOrders());

      // Exit checkout if there are no inital orders for the checkout
      if (
        getState().ordersReducer.orders.length === 0
      ) {
        dispatch(exitCheckout());
      }
    }

    return response;
  };
};

export const placeOrders = (
  orders,
  ordersPlacedCallback,
  creditApplied = 0,
  deliveryInstructions
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoaderCheckout(true));
      dispatch({ type: types.PLACE_ORDER_REQUEST });

      const { paymentMethod, orderToPlaceUrlsafe } = getState().checkoutReducer;

      // Filter out orders with no items and not ready for checkout.
      // Change deliveryDay to server expected format
      const checkoutOrder = orders.find(
        (o) =>
          o.items.length > 0 &&
          o.isCheckout &&
          o.urlsafe === orderToPlaceUrlsafe
      );

      const checkoutOrdersContent = {
        [checkoutOrder.urlsafe]: {
          items: checkoutOrder.items.map((i) => ({
            sourceUrlsafe: i.sourceUrlsafe,
            productName: i.name,
            price: i.price,
            quantity: i.quantity,
            notes: i.notes,
            orderProductUrlsafe: i.urlsafe,
            addedByReorder: false,
          })),
          creditApplied,
          deliveryInstructions,
        },
      };

      const response = await DataAPI.checkoutOrders({
        orderUrlsafes: [checkoutOrder.urlsafe],
        orders: checkoutOrdersContent,
      });

      dispatch(orderActions.updateRecentPendingOrders([checkoutOrder]));
      dispatch(orderActions.loadCartOrders());
      dispatch({ type: types.PLACE_ORDER_SUCCESS });
      if (response && response.data) {
        if (ordersPlacedCallback) {
          ordersPlacedCallback();
        }

        batch(() => {
          dispatch(
            analyticsActions.checkOutConfirmScreenLoaded(response.data.data)
          );

          // Change the route to the checkout/pending/ if everything was sucessful
          dispatch(changePendingOrderState(true));
        });
        dispatch(initCheckoutTokenState());
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in placeOrder, response is ${response}`
        );
      }
    } catch (error) {
      // If failure on endpoint occurs
      // 1) Disable loader
      // 2) Go back to review step
      // 3) Uncollapse all orders with items
      // 4) Cancel Balance Payment
      // 5) Show Errors

      dispatch({ type: types.PLACE_ORDER_FAILED });
      dispatch(showLoaderCheckout(false));
      await dispatch(orderActions.loadCartOrders());

      orders.forEach((o) => {
        if (o.items.length > 0) {
          dispatch(collapseOrder(getState().ordersReducer.orders, o.urlsafe));
        }
      });
      const { checkoutId } = getState().checkoutReducer;
      dispatch(initCheckoutTokenState());

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      logException(error);
      // Adding timeout: 0 will disable automatic closing to see longer messages.
      toastr.error(`Error: ${errorMessage}`, { timeOut: 0 });
      console.error('An Error occured with placeOrders');
      console.error(error);
    }
  };
};

export const savePhoneNumber = (account, buyer) => {
  return async (dispatch, getState) => {
    try {
      const buyerMembers = buyer.buyerMembers;

      // Find the memberUrlsafe from the member that matches the account email
      const member = buyerMembers.find(
        (member) => member.account.urlsafe === account.urlsafe
      );

      if (!member) {
        dispatch(
          showError('checkoutError', [
            `Error: Please contact support. Error Code 524, member not found`,
          ])
        );
        console.error(`Error: member with email ${account.email} not found`);
        return;
      }

      const { accountPhoneNumber } = getState().checkoutReducer;

      // Validate the phone number format using this regex
      const valid =
        /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

      // Only send out the request to change the number if phone number is valid
      if (valid.test(accountPhoneNumber) && member) {
        dispatch({ type: types.UPDATE_PHONE_NUMBER_REQUEST });

        const data = {
          firstName: account.firstName,
          lastName: account.lastName,
          mobile: accountPhoneNumber,
          roleName: member.roles.length > 0 ? member.roles[0].name : '',
          receiveNotification: !member.receiveNotification,
        };

        await DataAPI.partialUpdateMember(member.urlsafe, data);
        dispatch({ type: types.UPDATE_PHONE_NUMBER_SUCCESS });
      } else {
        dispatch(changePhoneNumber(account.mobile));
        dispatch(
          showError('checkoutError', ['Please Enter a Valid Phone Number'])
        );
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_PHONE_NUMBER_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with savePhoneNumber');
      console.error(error);
      logException(error);
    }
  };
};

export const cancelOrderCheckout = (cancelOrderUrlsafe) => {
  return async (dispatch, getState) => {
    const { showCancelOrderModal } = getState().checkoutReducer;
    const { orders } = getState().ordersReducer;

    try {
      dispatch(showLoaderCancelOrderModal(true));
      dispatch(removeOrderCheckout(orders, cancelOrderUrlsafe));

      await dispatch(orderActions.cancelOrder(orders, cancelOrderUrlsafe));

      // close modal
      dispatch(toggleCancelOrderModal(showCancelOrderModal, ''));
    } catch (response) {
      toastr.error('An error occured cancelling order');
      console.error(response);
    } finally {
      dispatch(showLoaderCancelOrderModal(false));
    }
  };
};

export const reverseOrderCheckout = (cancelOrderUrlsafe) => {
  return async (dispatch, getState) => {
    const { showCancelOrderModal } = getState().checkoutReducer;
    const { orders } = getState().ordersReducer;

    try {
      dispatch(showLoaderCancelOrderModal(true));
      dispatch(removeOrderCheckout(orders, cancelOrderUrlsafe));

      await dispatch(orderActions.reverseOrder(orders, cancelOrderUrlsafe));

      // close modal
      dispatch(toggleCancelOrderModal(showCancelOrderModal, ''));
    } catch (response) {
      toastr.error('An error occured reversing order');
      console.error(response);
    } finally {
      dispatch(showLoaderCancelOrderModal(false));
    }
  };
};

export const checkoutUpdateTaxesFeesAndShippingAddress = (
  orders,
  shippingAddressID,
  isPendingOrders = false
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.CHECKOUT_PRECHECK_REQUEST });

      const { orders: prevOrders } = getState().ordersReducer;
      const { orderToPlaceUrlsafe } = getState().checkoutReducer;

      const checkoutOrder = orders.find(
        (o) =>
          o.items.length > 0 &&
          o.isCheckout &&
          o.urlsafe === orderToPlaceUrlsafe
      );
      const responses = await Promise.all(
        [checkoutOrder].map(async (o) =>
          DataAPI.patchOrder(
            o.urlsafe,
            {
              updateTaxes: true,
              updateFees: true,
              shippingAddressID,
            },
            isPendingOrders
          )
        )
      );

      if (responses.every((response) => response) === false) {
        throw new Error(
          'One endpoint failed in loading in Promise.all in checkoutUpdateTaxesAndFees'
        );
      }

      dispatch({ type: types.CHECKOUT_PRECHECK_SUCCESS });

      // Load the orders in the orders reducer.
      // We need to add prevOrders as the 2nd argument.
      // This is because preProcessOrders needs them to preserve added fields like 'isCheckout'
      const patchedOrders = responses.map((r) => r.data);

      // Filter the orders which were not patched
      const patchOrdersUrlsafe = patchedOrders.map((o) => o.urlsafe);
      const nonPatchedOrders = prevOrders.filter(
        (o) => !patchOrdersUrlsafe.includes(o.urlsafe)
      );

      // Process and include both the patchedOrders and nonPatchedOrders back in the store
      dispatch(
        orderActions.preProcessOrders(
          [...patchedOrders, ...nonPatchedOrders],
          prevOrders,
          false
        )
      );
    } catch (error) {
      dispatch({ type: types.CHECKOUT_PRECHECK_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      logException(error);
      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with checkoutUpdateTaxesAndFees');
      console.error(error);
    }
  };
};

export const checkForCartAndCheckoutOrderItemsMismatch = (previousOrders) => {
  return async (dispatch, getState) => {
    dispatch({ type: types.CHECK_CART_AND_CHECKOUT_QUANTITY_MISMATCH });

    const { orders: currentOrders } = getState().ordersReducer;
    const checkoutOrdersByUrlsafe = currentOrders
      .filter((order) => order.items && order.items.length > 0)
      .reduce((prev, curr) => ({ ...prev, [curr.urlsafe]: curr }), {});

    const cartOrders = previousOrders.filter(
      (order) => order.items && order.items.length > 0
    );

    const mismatchedItems = [];
    for (const cartOrder of cartOrders) {
      const checkoutItemsByUrlsafe = checkoutOrdersByUrlsafe[
        cartOrder.urlsafe
      ].items.reduce((prev, curr) => ({ ...prev, [curr.urlsafe]: curr }), {});

      for (const cartItem of cartOrder.items) {
        // Case I: Item exists in Cart, but not Checkout
        // If not found by urlsafe(because we migh not have it yet)
        // Attempt to compare sourceUrlsafe
        const checkoutItem = checkoutItemsByUrlsafe[cartItem.urlsafe]
          ? checkoutItemsByUrlsafe[cartItem.urlsafe]
          : checkoutOrdersByUrlsafe[cartOrder.urlsafe].items.find(
              (checkoutItem) =>
                checkoutItem.sourceUrlsafe === cartItem.sourceUrlsafe
            );

        if (!checkoutItem) {
          mismatchedItems.push({
            order: cartOrder,
            type: 'I',
            cartItem,
            checkoutItem: null,
            status: `Item missing from checkout that existed in cart ${cartItem.name}. Item urlsafe is ${cartItem.urlsafe}`,
          });
        } else if (checkoutItem.quantity !== cartItem.quantity) {
          // Case II: Item exists in both Cart and Checkout, but quantity is not correct
          mismatchedItems.push({
            order: cartOrder,
            type: 'II',
            cartItem,
            checkoutItem,
            status: `quantities don't match for checkout item ${checkoutItem.name}. In checkout quantity is ${checkoutItem.quantity} and in cart it was ${cartItem.quantity}. urlsafe is ${cartItem.urlsafe}`,
          });
        }
      }

      const checkoutItems = checkoutOrdersByUrlsafe[cartOrder.urlsafe].items;
      // Case II: RARE case where Item exists in Checkout, but not Cart
      if (
        cartOrder.items &&
        checkoutItems &&
        cartOrder.items.length < checkoutItems.length
      ) {
        const cartItemsByUrlSafe = cartOrder.items.reduce(
          (prev, curr) => ({ ...prev, [curr.urlsafe]: curr }),
          {}
        );
        for (const checkoutItem of checkoutItems) {
          const cartItem = cartItemsByUrlSafe[checkoutItem.urlsafe];
          if (!cartItem) {
            mismatchedItems.push({
              order: cartOrder,
              type: 'III',
              cartItem: null,
              checkoutItem,
              status: `RARE ERROR: Item missing from cart that exists in checkout ${checkoutItem.name}. Item urlsafe is ${checkoutItem.urlsafe}`,
            });
          }
        }
      }
    }

    // If we detect any mismatched then we attempt to fix them
    if (mismatchedItems.length > 0) {
      const indexesToRemove = [];
      const isFromCart = false,
        isDebounceActive = false;

      console.log(`ATTEMPTING TO FIX ${mismatchedItems.length} MISMATCH(ES)`);
      for (const [index, mismatchedItem] of mismatchedItems.entries()) {
        const { order, cartItem, checkoutItem, status } = mismatchedItem;

        // Log the mismatch through the console so it can clearly be seen on on the error reporter.
        console.log(status);

        // Fix Case I
        if (cartItem && cartItem.quantity && !checkoutItem) {
          // For the case when an item is missing from the checkout that was in the cart.
          // we make a call to add that missing item to the checkout.
          const product = {
            genericItem: {
              name: cartItem.genericItem.name,
              description: cartItem.genericItem.description,
              imageURL: cartItem.genericItem.imageURL,
              urlsafe: cartItem.genericItem.urlsafe,
            },
            id: cartItem.sourceID,
            productName: cartItem.name,
            unit: cartItem.unitName,
            unitDescription: cartItem.unitDescription,
            variantDescription: cartItem.variantDescription,
            price: cartItem.price,
            taxable: cartItem.isTaxable,
            productCode: cartItem.productCode,
            vendorID: cartItem.vendorID,
            urlsafe: cartItem.sourceUrlsafe,
          };
          console.log(
            `Adding ${cartItem.productName} manually to Checkout items`
          );
          dispatch(
            orderActions.changeQuantityWithoutID(
              order.urlsafe,
              null,
              cartItem.quantity,
              'CUSTOM',
              AnalyticsEventSources.CHECKOUT,
              product,
              isFromCart,
              isDebounceActive
            )
          );

          indexesToRemove.push(index);
        } else if (
          cartItem &&
          checkoutItem &&
          cartItem.quantity &&
          checkoutItem.quantity
        ) {
          // Fix Case II
          // For the case when quantities mismatch (i.e the item is not missing, but the qty is different),
          // we make a call to change the quantity to the one that was in the cart.
          console.log(
            `Changing quantity manually from ${checkoutItem.quantity} to ${cartItem.quantity} for ${checkoutItem.productName}`
          );
          dispatch(
            orderActions.changeQuantityWithID(
              order.urlsafe,
              checkoutItem,
              cartItem.quantity,
              'CUSTOM',
              AnalyticsEventSources.CHECKOUT,
              isFromCart,
              isDebounceActive
            )
          );

          indexesToRemove.push(index);
        } else {
          // Case III and others, just report.
          console.log(
            `Unable to solve mismatch case ${mismatchedItem.type}. Most likely checkout has an item that did not exist in the cart`
          );
        }
      }

      const remainingMismatchedItem = mismatchedItems.filter(
        (x, idx) => !indexesToRemove.includes(idx)
      );

      let reporterMessage = '';
      if (remainingMismatchedItem.length > 0) {
        reporterMessage =
          'Mismatched Quantities between Cart and Checkout detected. Outstanding Items NOT Fixed';
        for (const remaining of remainingMismatchedItem) {
          console.log(remaining.status);
        }
      } else {
        reporterMessage =
          'Mismatched Quantities between Cart and Checkout detected. All Items Fixed';
      }
      console.log(remainingMismatchedItem);

      // Trigger the error reporter to send an exception to inform us that this happend.
      try {
        throw new Error(reporterMessage);
      } catch (error) {
        logException(error);
      }
    }
  };
};

export const checkForCartAndCheckoutOrderTotalsMismatch = (previousOrders) => {
  return async (dispatch, getState) => {
    dispatch({ type: types.CHECK_CART_AND_CHECKOUT_TOTALS_MISMATCH });

    // Uses previousOrders embedded in the state object format (under ordersReducer) to get previous total
    const { ordersTotalMinusTaxes: totalInCart } =
      checkoutSelectors.getAdditionalOrderTotals(
        { ...getState(), ordersReducer: { orders: previousOrders } },
        {}
      );

    // Use getState() passed into the selector to get latest orders total
    const { ordersTotalMinusTaxes: totalInCheckout } =
      checkoutSelectors.getAdditionalOrderTotals(getState(), {});

    if (
      parseFloat(totalInCart).toFixed(2) ===
      parseFloat(totalInCheckout).toFixed(2)
    ) {
      return;
    }

    try {
      throw new Error(
        `Mismatched totals detected between cart ($${parseFloat(
          totalInCart
        ).toFixed(2)}) and checkout ($${parseFloat(totalInCheckout).toFixed(
          2
        )})`
      );
    } catch (error) {
      const { orders: currentOrders } = getState().ordersReducer;
      const checkoutOrdersByUrlsafe = currentOrders
        .filter((order) => order.items && order.items.length > 0)
        .reduce((prev, curr) => ({ ...prev, [curr.urlsafe]: curr }), {});

      const cartOrders = previousOrders.filter(
        (order) => order.items && order.items.length > 0
      );

      for (const cartOrder of cartOrders) {
        const checkoutItemsByUrlsafe = checkoutOrdersByUrlsafe[
          cartOrder.urlsafe
        ].items.reduce((prev, curr) => ({ ...prev, [curr.urlsafe]: curr }), {});

        for (const cartItem of cartOrder.items) {
          const checkoutItem = checkoutItemsByUrlsafe[cartItem.urlsafe];
          if (!checkoutItem) {
            console.log(
              `Item ${cartItem.name} is missing and accounts for ${parseFloat(
                cartItem.quantity * cartItem.price
              ).toFixed(2)} total mismatch`
            );
            continue;
          }
          if (cartItem.quantity !== checkoutItem.quantity) {
            console.log(
              `Item ${cartItem.name} quantites mismatch. ${cartItem.quantity} and checkout ${checkoutItem.quantity}`
            );
            console.log(
              `total mismatch in cart ${parseFloat(
                cartItem.quantity * cartItem.price
              ).toFixed(2)} and checkout ${parseFloat(
                checkoutItem.quantity * checkoutItem.price
              ).toFixed(2)}`
            );
            continue;
          }
          if (cartItem.price !== checkoutItem.price) {
            console.log(
              `Item ${cartItem.name} price mismatch. ${cartItem.price} and checkout ${checkoutItem.price}`
            );
            console.log(
              `totals mismatch in cart ${parseFloat(
                cartItem.quantity * cartItem.price
              ).toFixed(2)} and checkout ${parseFloat(
                checkoutItem.quantity * checkoutItem.price
              ).toFixed(2)}`
            );
            continue;
          }
        }
      }
      logException(error);
    }
  };
};

export const saveChangesToAddOnOrder = (orderUrlsafe) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_REQUEST });
      dispatch(showLoaderCheckout(true));
      const { account } = getState().accountReducer;
      const { orders, singleOrder: orderBeforeEditing } =
        getState().ordersReducer;

      const orderIndex = orders.findIndex((o) => o.urlsafe === orderUrlsafe);

      if (orderIndex === -1) {
        throw new Error(`order ID ${orderUrlsafe} not found in orders list`);
      }

      const order = orders[orderIndex];

      const postData = {
        items: order.items.map((item) => ({
          itemID: item.sourceUrlsafe,
          quantity: item.quantity,
        })),
        createdBy: account.email,
      };

      const response = await DataAPI.saveChangesToAddOnOrder(
        orderUrlsafe,
        postData
      );

      dispatch({ type: types.CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_SUCCESS });

      const parentOrderUrlSafe = response.data.order.urlsafe;

      dispatch(
        push({
          pathname: `/checkout/${parentOrderUrlSafe}/confirmation`,
          state: {
            isAddOnOrder: true,
          },
        })
      );

      // Update the original quantity of items so that you cannot decrease the quantity
      for (const item of order.items) {
        const itemBeforeChange = (orderBeforeEditing.items || []).find(
          (i) => i.urlsafe === item.urlsafe
        );

        if (itemBeforeChange && item.quantity !== itemBeforeChange.quantity) {
          dispatch(
            orderActions.updateOriginalQuantity(orders, orderUrlsafe, item)
          );
        } else if (
          response &&
          response.data &&
          !itemBeforeChange &&
          !item.urlsafe
        ) {
          // When adding a new item. We need to give it the urlsafe so the (delete) button will be removed
          const newAddOnOrder = response.data.order || response.data;
          const newUrlsafe = newAddOnOrder.items.find(
            (i) => i.productCode === item.productCode
          );
          dispatch(
            orderActions.addURLSafeToCartItem(
              orders,
              orderUrlsafe,
              item,
              newUrlsafe
            )
          );
          dispatch(
            orderActions.updateOriginalQuantity(orders, orderUrlsafe, item)
          );
        }
      }

      dispatch(showLoaderCheckout(false));
      toastr.success('Changes saved');
    } catch (error) {
      dispatch({ type: types.CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_FAILED });
      dispatch(showLoaderCheckout(false));
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        const apiError = ((error || {}).response || {}).data || {};
        errorMessage =
          apiError.errorMessage || apiError.message || 'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with saveChangesToAddOnOrder');
      console.error(error);
      logException(error);
    }
  };
};

export const cancelChangeToAddOnOrder = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_REQUEST });
      dispatch(showLoaderCheckout(true));

      const isPendingOrders = true;
      await dispatch(loadOrdersCheckout(isPendingOrders));
      dispatch({ type: types.CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_SUCCESS });
      dispatch(showLoaderCheckout(false));
      toastr.success('Changes cancelled');
    } catch (error) {
      dispatch({ type: types.CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_FAILED });
      dispatch(showLoaderCheckout(false));

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with cancelChangeToAddOnOrder');
      console.error(error);
      logException(error);
    }
  };
};

//
// Helpers
//
export function validateBeforePlacingOrder(orders, isPendingOrders) {
  let errorMessage = '';

  if (orders.length === 0) {
    throw new Error('No orders found while trying to place order');
  }

  // Next, we need to make sure that we actually
  // have at least one order with an item to place an order. Otherwise, show an error
  const ordersWithItems = orders.filter((order) => order.items.length > 0);

  if (!isPendingOrders) {
    // If there are no orders with items, don't allow user to place an order.
    // Note that they should be redirected anyways, but this is a failsafe.
    if (ordersWithItems.length === 0) {
      errorMessage = [
        'Error: You must have at least 1 order with items to place an order.',
      ];
    }

    for (const order of ordersWithItems) {
      // If we have any preOrder items that are being deliveryed today or tomorrow then we need to
      // stop checkout and give the user a modal to deal with it.
      if (Utils.checkIfPreOrder(order, order.deliveryDay)) {
        errorMessage = [
          `Error: Please Select Another Delivery Day For ${order.vendorName} Because You Have Items That Require 48 Hours Notice.`,
        ];
        break;
      }
    }
  }

  // Let the caller know if there was an error to prevent further actions.
  return { error: errorMessage ? true : false, errorMessage };
}

export const setOrderToPlaceUrlsafe = (urlsafe) => ({
  type: 'SET_ORDER_TO_PLACE_URLSAFE',
  payload: urlsafe,
});
