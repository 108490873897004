import moment from 'moment';

import * as types from '../constants/ActionTypes';
import * as AnalyticsAPI from '../api/AnalyticsAPI';
import Utils from '../utils';
import { logException } from '../domains/shared/logger';

export function requestAnItem(searchText) {
  return (dispatch) => {
    try {
      const properties = {
        searchQuery: searchText,
      };

      dispatch({ type: types.ANALYTICS_REQUEST_AN_ITEM, properties });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_REQUEST_AN_ITEM,
        properties,
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

/*
 ** Order Events
 */
export function changeNotes(orderProduct, vendorName, vendorID) {
  return (dispatch, getState) => {
    const { isPendingOrders } = getState().checkoutReducer;

    const productName = orderProduct.name || orderProduct.productName || '';
    const {
      productCode,
      unitName = '',
      unitDescription = '',
      sourceID = '',
    } = orderProduct;

    const properties = {
      'Vendor name': vendorName,
      'Vendor id': vendorID,
      'Product name': productName,
      'Product id': productCode,
      'Variant name': `${unitName} - ${unitDescription}`,
      'Variant id': sourceID,
      isPending: isPendingOrders,
    };

    dispatch({ type: types.ANALYTICS_CHANGE_NOTES, properties: properties });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_CHANGE_NOTES, properties);
  };
}

export function reorder(order, fromScreen) {
  return (dispatch, getState) => {
    try {
      const { vendors } = getState().vendorsReducer;
      const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

      const properties = {
        screen: fromScreen,
        source: '',
        vendorID: vendor.id,
        vendorName: vendor.name,
      };

      dispatch({ type: types.ANALYTICS_REORDER, properties: properties });

      // API call to log Analytics Event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_REORDER,
        properties,
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

export function removeItemsFromCart(
  orders = [],
  orderUrlSafe = '',
  orderProduct = {}
) {
  return (dispatch, getState) => {
    const { isPendingOrders } = getState().checkoutReducer;
    const order = orders.find((o) => o.urlsafe === orderUrlSafe);
    if (!order) {
      return;
    }

    const productName = orderProduct.name || orderProduct.productName || '';
    const vendorName = order.vendorName;
    const vendorID = order.vendorID;
    const {
      sourceID = '',
      unitName = '',
      unitDescription = '',
      productCode = '',
    } = orderProduct;

    const properties = {
      'Number of items': 0,
      'Vendor name': vendorName,
      'Vendor id': vendorID,
      'Product name': productName,
      'Product id': productCode,
      'Variant name': `${unitName} - ${unitDescription}`,
      'Variant id': sourceID,
      isPending: isPendingOrders,
    };

    dispatch({
      type: types.ANALYTICS_REMOVE_ITEM_FROM_CART,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_REMOVE_ITEM_FROM_CART, properties);

    //
    // Check if we should call another event if this is the last item in the cart for
    // this vendor.
    //
    // If this order has only one item left, then this vendor is being removed from the cart.
    if (order.items && order.items.length === 1) {
      dispatch(removeVendorFromCart(order));
    }
  };
}

export function removeVendorFromCart(order = {}) {
  return (dispatch, getState) => {
    const { isPendingOrders } = getState().checkoutReducer;

    const { vendorName = '', vendorID = '' } = order;

    const properties = {
      'Vendor name': vendorName,
      'Vendor id': vendorID,
      isPending: isPendingOrders,
    };

    dispatch({
      type: types.ANALYTICS_REMOVE_VENDOR_FROM_CART,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_REMOVE_VENDOR_FROM_CART,
      properties
    );
  };
}

export function changeOrderInstructions(orders, order_urlsafe) {
  return (dispatch, getState) => {
    const { isPendingOrders } = getState().checkoutReducer;
    const orderIndex = orders.findIndex(
      (order) => order.urlsafe === order_urlsafe
    );

    if (orderIndex === -1) {
      return;
    }

    const order = orders[orderIndex];

    const { vendorName = '', vendorID = '' } = order;

    const properties = {
      'Vendor name': vendorName,
      'Vendor id': vendorID,
      isPending: isPendingOrders,
    };

    dispatch({
      type: types.ANALYTICS_CHANGE_ORDER_INSTRUCTIONS,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_CHANGE_ORDER_INSTRUCTIONS,
      properties
    );
  };
}

export function changeDeliveryDay(orders, order_urlsafe, deliveryDay) {
  return (dispatch, getState) => {
    const { isPendingOrders } = getState().checkoutReducer;
    const orderIndex = orders.findIndex(
      (order) => order.urlsafe === order_urlsafe
    );

    if (orderIndex === -1) {
      return;
    }

    const order = orders[orderIndex];

    const { vendorName = '', vendorID = '' } = order;

    const properties = {
      'Vendor name': vendorName,
      'Vendor id': vendorID,
      'Delivery Day': Utils.formatDate(deliveryDay, 'MM/DD/YYYY'),
      isPending: isPendingOrders,
    };

    dispatch({
      type: types.ANALYTICS_CHANGE_DELIVERY_DAY,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_CHANGE_DELIVERY_DAY, properties);
  };
}

export function deleteOrder(orders, order_urlsafe) {
  return (dispatch, getState) => {
    const { isPendingOrders } = getState().checkoutReducer;
    const orderIndex = orders.findIndex(
      (order) => order.urlsafe === order_urlsafe
    );

    if (orderIndex === -1) {
      return;
    }

    const order = orders[orderIndex];

    // If the order is still in the cart, then we are not deleting the order,
    // we are only removing all the items from the cart.
    if (!isPendingOrders) {
      dispatch(removeVendorFromCart(order));
      return;
    }

    const { vendorName = '', vendorID = '' } = order;

    const properties = {
      'Vendor name': vendorName,
      'Vendor id': vendorID,
    };

    dispatch({ type: types.ANALYTICS_DELETE_ORDER, properties: properties });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_DELETE_ORDER, properties);
  };
}

export function reverseOrder(orders, order_urlsafe) {
  return (dispatch) => {
    const orderIndex = orders.findIndex(
      (order) => order.urlsafe === order_urlsafe
    );

    if (orderIndex === -1) {
      return;
    }

    const order = orders[orderIndex];

    const { vendorName = '', vendorID = '' } = order;

    const properties = {
      'Vendor name': vendorName,
      'Vendor id': vendorID,
    };

    dispatch({ type: types.ANALYTICS_REVERSE_ORDER, properties: properties });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_REVERSE_ORDER, properties);
  };
}

export function orderDetailsScreenLoaded(order, orderStatus) {
  return (dispatch, getState) => {
    try {
      const { vendors } = getState().vendorsReducer;
      const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

      const { deliveryDay = '', total = '' } = order;

      const properties = {
        orderTotal: total,
        orderStatus,
        deliveryDay,
        vendorID: vendor.id,
        vendorName: vendor.name,
      };

      dispatch({ type: types.ANALYTICS_ORDER_DETAILS_LOADED, properties });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_ORDER_DETAILS_LOADED,
        properties,
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

export function downloadInvoice() {
  return (dispatch) => {
    const properties = {};

    dispatch({
      type: types.ANALYTICS_DOWNLOAD_INVOICE,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_DOWNLOAD_INVOICE, properties);
  };
}

export function filterPaymentHistory(statementURLParams) {
  return (dispatch) => {
    const params = statementURLParams.split('&');

    const type = params[0] ? params[0].split('statementType=')[1] : '';

    const startDate = params[1] ? params[1].split('startDate=')[1] : '';

    const endDate = params[2] ? params[2].split('endDate=')[1] : '';

    const properties = {
      'Start Date': startDate,
      'End Date': endDate,
      Type: type,
    };

    dispatch({
      type: types.ANALYTICS_FILTER_PAYMENT_HISTORY,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_FILTER_PAYMENT_HISTORY, properties);
  };
}

export function singleOrderPayNow(order) {
  return (dispatch) => {
    const properties = {
      'Invoice Number': order.invoiceNumber,
      'Order Number': order.id,
      'Amount Paid': order.outstandingAmount,
    };

    dispatch({
      type: types.ANALYTICS_SINGLE_ORDER_PAY_NOW,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_SINGLE_ORDER_PAY_NOW, properties);
  };
}

//
// OrderGuide
//
export function addOrderGuide(vendorID) {
  return (dispatch) => {
    const properties = {
      'Vendor name': vendorID,
      'Vendor id': vendorID,
    };

    dispatch({ type: types.ANALYTICS_ADD_ORDERGUIDE, properties: properties });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_ADD_ORDERGUIDE, properties);
  };
}

export function removeOrderGuide() {
  return (dispatch, getState) => {
    const { singleVendor } = getState().vendorsReducer;

    const properties = {
      'Vendor name': singleVendor.name,
      'Vendor id': singleVendor.id,
    };

    dispatch({
      type: types.ANALYTICS_REMOVE_ORDERGUIDE,
      properties: properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_REMOVE_ORDERGUIDE, properties);
  };
}

export function sortOrderGuide(sortType) {
  return (dispatch) => {
    const properties = {
      'Sort type': sortType,
    };

    dispatch({ type: types.ANALYTICS_SORT_ORDERGUIDE, properties: properties });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_SORT_ORDERGUIDE, properties);
  };
}

export function changeUnitOrderGuidePopup(isCurVariantInStock, variants) {
  return (dispatch) => {
    const numVariantsInStock = variants.reduce(
      (prev, curr) => (prev = curr.inStock ? prev + 1 : prev),
      0
    );

    const properties = {
      'Number of Variants In Stock': numVariantsInStock,
      'Is Current Variant In Stock': isCurVariantInStock,
    };

    dispatch({
      type: types.ANALYTICS_CHANGE_UNIT_ORDERGUIDE_POPUP,
      properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_CHANGE_UNIT_ORDERGUIDE_POPUP,
      properties
    );
  };
}

export function changeUnitOrderGuideConfirmed(selectedVariants) {
  return (dispatch) => {
    const properties = {
      'Item IDs Added': selectedVariants,
    };

    dispatch({
      type: types.ANALYTICS_CHANGE_UNIT_ORDERGUIDE_CONFIRMED,
      properties,
    });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_CHANGE_UNIT_ORDERGUIDE_CONFIRMED,
      properties
    );
  };
}

export function viewSuggestions(showMore, product) {
  return (dispatch) => {
    try {
      const {
        productName = '',
        productCode = '',
        unit = '',
        unitDescription = '',
        variantCode = '',
        vendorName = '',
        vendorID = '',
      } = product;

      const properties = {
        productID: productCode,
        variantName: `${unit} - ${unitDescription}`,
        variantID: variantCode,
        showMore,
        productName,
        vendorName,
        vendorID,
      };

      dispatch({ type: types.ANALYTICS_VIEW_SUGGESTIONS, properties });

      // API Call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_VIEW_SUGGESTIONS,
        properties,
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

//
// View Page
//
export function viewPage(screenName, properties = {}) {
  return (dispatch) => {
    properties['Screen Name'] = screenName;

    dispatch({ type: types.ANALYTICS_VIEW_PAGE, properties: properties });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_VIEW_PAGE, properties);
  };
}

//
// View Page with Segment Migration
//
export function viewPageWithProperties(screenName, properties = {}) {
  return (dispatch) => {
    try {
      dispatch({ type: screenName, properties });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(screenName, properties, segmentMigration);
    } catch (error) {
      logException(error);
    }
  };
}

export function viewPageSegment(fromScreen, fromSource) {
  return (dispatch) => {
    try {
      const properties = {
        screen: fromScreen,
        source: fromSource,
      };

      dispatch({ type: types.ANALYTICS_VIEW_PAGE_SEGMENT, properties });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_VIEW_PAGE_SEGMENT,
        properties,
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

export function productDetailViewLoaded(variants) {
  return (dispatch) => {
    try {
      const {
        productCode = '',
        productName = '',
        featured = false,
      } = variants[0];

      const properties = {
        productID: productCode,
        numberOfVariants: variants.length,
        isLimitedTimeItem: featured,
        productName,
      };

      dispatch({ type: types.ANALYTICS_PRODUCT_DETAIL_LOADED, properties });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_PRODUCT_DETAIL_LOADED,
        properties,
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

export function share(properties = {}) {
  return (dispatch) => {
    dispatch({ type: types.ANALYTICS_SHARE, properties: properties });

    // API call to log Analytics event
    AnalyticsAPI.trackEvent(types.ANALYTICS_SHARE, properties);
  };
}

export function supplierAdded({
  supplierDefaultType,
  supplierAddedAs,
  communicationMethod,
  minOrder,
  deliveryFee,
  deliveryDays,
  cuttOff,
}) {
  return (dispatch) => {
    try {
      const properties = {
        supplierDefaultType,
        supplierAddedAs,
        communicationMethod,
        minOrder,
        deliveryFee,
        deliveryDays,
        cuttOff,
      };

      dispatch({ type: types.ANALYTICS_VIEW_SUPPLIER_ADDED, properties });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_VIEW_SUPPLIER_ADDED,
        properties,
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

export function appStoreClicked() {
  return (dispatch) => {
    try {
      dispatch({ type: types.ANALYTICS_APP_STORE_CLICKED });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_APP_STORE_CLICKED,
        {},
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

export function googlePlayClicked() {
  return (dispatch) => {
    try {
      dispatch({ type: types.ANALYTICS_GOOGLE_PLAY_CLICKED });

      // API call to log Analytics event
      const segmentMigration = true;
      AnalyticsAPI.trackEvent(
        types.ANALYTICS_GOOGLE_PLAY_CLICKED,
        {},
        segmentMigration
      );
    } catch (error) {
      logException(error);
    }
  };
}

//#region SIGNIN/SIGNUP ANALYTICS
export function signinScreenLoaded() {
  return (dispatch) => {
    const properties = {
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_SIGNIN_SCREEN_LOADED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_SIGNIN_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}

export function signupScreenLoaded() {
  return (dispatch) => {
    const properties = {
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_SIGNUP_SCREEN_LOADED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_SIGNUP_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}

export function signedup({ firstName, lastName } = {}) {
  return (dispatch) => {
    const properties = {
      firstName,
      lastName,
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_SIGNEDUP, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_SIGNEDUP,
      properties,
      segmentMigration
    );
  };
}

export function signedupConfirmed() {
  return (dispatch) => {
    const properties = {
      // firstName,
      // lastName,
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_SIGNEDUP_CONFIRMED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_SIGNEDUP_CONFIRMED,
      properties,
      segmentMigration
    );
  };
}

export function buyerCreated({ address, restaurantName } = {}) {
  return (dispatch) => {
    const properties = {
      address,
      restaurantName,
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_BUYER_CREATED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_BUYER_CREATED,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region ONBOARDING ANALYTICS
export function existingBuyersEntrytoAddSupplierFlow() {
  return (dispatch) => {
    const properties = {
      timeTriggered: timeTriggered(),
    };

    dispatch({
      type: types.ANALYTICS_EXISTING_BUYERS_ENTRY_TO_ADD_SUPPLIER_FLOW,
      properties,
    });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_EXISTING_BUYERS_ENTRY_TO_ADD_SUPPLIER_FLOW,
      properties,
      segmentMigration
    );
  };
}

export function onboardingSupplierListScreenLoaded({
  numOfSuppliersListed,
  numOfBYOSSuppliersListed,
  numOfMarketSuppliersListed,
} = {}) {
  return (dispatch) => {
    const properties = {
      numOfSuppliersListed,
      numOfBYOSSuppliersListed,
      numOfMarketSuppliersListed,
      timeTriggered: timeTriggered(),
    };

    dispatch({
      type: types.ANALYTICS_ONBOARDING_SUPPLIER_LIST_SCREEN_LOADED,
      properties,
    });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_ONBOARDING_SUPPLIER_LIST_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}

export function onboardingSupplierConfirmationScreenLoaded({
  suppliersName,
  numOfSuppliersListed,
  numOfBYOSSuppliersListed,
  numOfMarketSuppliersListed,
  numOfSuppliersSelected,
  numOfBYOSSuppliersSelected,
  numOfMarketSuppliersSelected,
  existingOrNewBuyer,
  timeSpentOnPage,
} = {}) {
  return (dispatch) => {
    const properties = {
      numOfSuppliersListed,
      numOfBYOSSuppliersListed,
      numOfMarketSuppliersListed,
      numOfSuppliersSelected,
      numOfBYOSSuppliersSelected,
      numOfMarketSuppliersSelected,
      existingOrNewBuyer,
      suppliersName,
      timeSpentOnPage,
      timeTriggered: timeTriggered(),
    };

    dispatch({
      type: types.ANALYTICS_ONBOARDING_SUPPLIER_CONFIRMATION_SCREEN_LOADED,
      properties,
    });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_ONBOARDING_SUPPLIER_CONFIRMATION_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}

export function addNewBYOSSuppliers(source) {
  return (dispatch) => {
    const properties = {
      source,
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_ADD_NEW_BYOS_SUPPLIERS, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_ADD_NEW_BYOS_SUPPLIERS,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region ADD TO CART ANALYTICS
export function addItemsToCart(order, orderProduct = {}, source) {
  return (dispatch) => {
    const properties = {
      timeTriggered: timeTriggered(),
      productName: orderProduct.name || orderProduct.productName || '',
      supplierName: order.vendorName,
      source,
    };

    dispatch({ type: types.ANALYTICS_ITEM_ADDED_TO_CART, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_ITEM_ADDED_TO_CART,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region CHECKOUT ANALYTICS
function getCheckoutPropertiesFromOrders(orders) {
  let numOfBYOSSuppliers = 0;
  let numOfMarketSuppliers = 0;
  let numOfBYOSItems = 0;
  let numOfMarketItems = 0;
  const supplierNames = [];
  let totalCheckoutAmount = 0;

  for (const order of orders) {
    if (order.isBYOS) {
      numOfBYOSSuppliers++;
    } else {
      numOfMarketSuppliers++;
    }

    for (const item of order.items) {
      if (item.isBYOS) {
        numOfBYOSItems++;
      } else {
        numOfMarketItems++;
      }
    }
    supplierNames.push(order.vendorName);
    totalCheckoutAmount += order.grandTotal;
  }

  return {
    numOfBYOSSuppliers,
    numOfMarketSuppliers,
    numOfBYOSItems,
    numOfMarketItems,
    supplierNames,
    totalCheckoutAmount,
    timeTriggered: timeTriggered(),
  };
}

export function checkOutDeliveryScreenLoaded(orders) {
  return (dispatch) => {
    const properties = getCheckoutPropertiesFromOrders(orders);

    dispatch({
      type: types.ANALYTICS_CHECKOUT_DELIVERY_SCREEN_LOADED,
      properties,
    });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_CHECKOUT_DELIVERY_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}

export function checkOutPaymentScreenLoaded(orders) {
  return (dispatch) => {
    const properties = getCheckoutPropertiesFromOrders(orders);

    dispatch({
      type: types.ANALYTICS_CHECKOUT_PAYMENT_SCREEN_LOADED,
      properties,
    });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_CHECKOUT_PAYMENT_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}

export function checkOutConfirmScreenLoaded(orders) {
  return (dispatch) => {
    const properties = getCheckoutPropertiesFromOrders(orders);

    dispatch({
      type: types.ANALYTICS_CHECKOUT_CONFIRM_SCREEN_LOADED,
      properties,
    });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_CHECKOUT_CONFIRM_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region CHAT ANALYTICS
export function inboxScreenLoaded(numOfChatChannels, source) {
  return (dispatch) => {
    const properties = {
      timeTriggered: timeTriggered(),
      numOfChatChannels: numOfChatChannels,
      source,
    };

    dispatch({ type: types.ANALYTICS_INBOX_SCREEN_LOADED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_INBOX_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region ORDER DESK ANALYTICS
export function orderDeskScreenLoaded() {
  return (dispatch) => {
    const properties = {
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_ORDER_DESK_SCREEN_LOADED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_ORDER_DESK_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region MY ORDERS ANALYTICS
export function myOrdersScreenLoaded() {
  return (dispatch) => {
    const properties = {
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_MY_ORDERS_SCREEN_LOADED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_MY_ORDERS_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region ORDER GUIDE ANALYTICS
export function orderGuideScreenLoaded({
  supplierName,
  supplierDefaultType,
  supplierAddedAs,
  nameOfOG,
  numOfItemsInOG,
} = {}) {
  return (dispatch) => {
    const properties = {
      supplierName,
      supplierDefaultType,
      supplierAddedAs,
      nameOfOG,
      numOfItemsInOG,
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_ORDER_GUIDE_SCREEN_LOADED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_ORDER_GUIDE_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}

export function addItemToOrderGuide({
  productName,
  supplierName,
  nameOfOG,
  newItemOrInInventory,
  source,
} = {}) {
  return (dispatch) => {
    const properties = {
      productName,
      supplierName,
      nameOfOG,
      newItemOrInInventory,
      source,
    };

    dispatch({ type: types.ANALYTICS_ORDER_GUIDE_ITEM_ADDED, properties });

    // API call to log Analytics event
    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_ORDER_GUIDE_ITEM_ADDED,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region MARKET ANALYTICS
export function marketScreenLoaded({
  selectedVendors = [],
  selectedCategories = [],
} = {}) {
  return (dispatch) => {
    let filtersOn = 'else';

    if (selectedVendors.length === 0 && selectedCategories.length === 0) {
      filtersOn = 'unfiltered';
    } else if (selectedVendors.length === 1) {
      filtersOn = 'oneSupplierSelected';
    } else if (selectedCategories.length > 0) {
      filtersOn = 'catagorySelected';
    }

    const properties = {
      filtersOn,
      timeTriggered: timeTriggered(),
    };

    dispatch({ type: types.ANALYTICS_MARKET_SCREEN_LOADED, properties });

    const segmentMigration = true;
    AnalyticsAPI.trackEvent(
      types.ANALYTICS_MARKET_SCREEN_LOADED,
      properties,
      segmentMigration
    );
  };
}
//#endregion

//#region HELPERS ANALYTHICS
const timeTriggered = () => moment.utc().format();
//#endregion
