import { useEffect } from 'react';

/**
 * Custom hook that listens for a specific key combination and triggers a callback function when the combination is pressed.
 *
 * @param {string[]} keys - An array of keys that need to be pressed in combination with the control key (Ctrl on Windows/Linux, Command on macOS).
 * @param {() => void} callback - The callback function to be executed when the key combination is pressed.
 * @example
 * useKeyCombination(['c'], () => {
 *   console.log('Ctrl+C or Command+C was pressed');
 * });
 * @returns {void}
 */
export const useKeyCombination = (keys: string[], callback: () => void): void => {
    const handleKeyDown = (event: KeyboardEvent) => {
        // Check if the correct keys are pressed
        const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
        const ctrlKey = isMac ? event.metaKey : event.ctrlKey;
        const keyPressed = keys.includes(event.key);

        if (ctrlKey && keyPressed) {
            // Prevent the default action (e.g., preventing copying)
            event.preventDefault();
            // Call the callback function
            callback();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};
