import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { GalleryImageOptions, ImageOptions } from '@capacitor/camera/dist/esm/definitions';
import { convertPhotoUriToFileObject } from '@v2/utils/Photos';

type TUsePhotoGallery = {
    takePhoto: (options? : ImageOptions) => Promise<File>,
    pickImages: (options? : GalleryImageOptions) => Promise<File[]>,
};

const DEFAULT_CAMERA_OPTIONS:ImageOptions = {
    quality: 90,
    source: CameraSource.Camera,
    resultType: CameraResultType.Uri,
};

const DEFAULT_GALLERY_OPTIONS:GalleryImageOptions = {
    quality: 90,
};

export function usePhotoGallery(): TUsePhotoGallery {
    /**
     * Take a photo with the camera
     *
     * @param options
     */
    const takePhoto = async (options = DEFAULT_CAMERA_OPTIONS):Promise<File> => {
        const photo = await Camera.getPhoto({ ...DEFAULT_CAMERA_OPTIONS, ...options });
        return convertPhotoUriToFileObject(photo);
    };

    /**
     * Pick images from the gallery
     *
     * @param options
     */
    const pickImages = async (options = DEFAULT_GALLERY_OPTIONS):Promise<File[]> => {
        const files = [];
        const imagesPicked = await Camera.pickImages({
            ...DEFAULT_GALLERY_OPTIONS,
            ...options,
        });
        await Promise.all(imagesPicked.photos.map(async (photo) => {
            const file = await convertPhotoUriToFileObject(photo);
            files.push(file);
        }));
        return files;
    };

    return {
        takePhoto,
        pickImages
    };
}
