import moment from 'moment';
import {toastr} from 'react-redux-toastr';

import * as types from '../constants/ActionTypes';
import * as DataAPI from '../api/DataAPI';
import {logException} from '../domains/shared/logger';
import {loadSuppliers} from "@/actions/suppliersActions";

export function receiveVendors(vendors) {
  // Convert all cutOffTimes to local time from UTC
  for (const vendor of vendors) {
    if (vendor.region && !!vendor.region.cutOffTime) {
      vendor.region.cutOffTime = moment
        .utc(vendor.region.cutOffTime, 'hh:mm:ss')
        .local()
        .format('h:mm a');
    }
  }

  return {
    type: types.RECEIVE_VENDORS,
    vendors,
  };
}

export function receiveSingleVendor(vendor, isConvertToLocalTime = true) {
  // Convert all cutOffTimes to local time from UTC
  if (isConvertToLocalTime) {
    if (vendor.region && !!vendor.region.cutOffTime) {
      vendor.region.cutOffTime = moment
        .utc(vendor.region.cutOffTime, 'hh:mm:ss')
        .local()
        .format('h:mm a');
    }
  }

  return {
    type: types.RECEIVE_SINGLE_VENDOR,
    singleVendor: vendor,
  };
}

export function toggleProductDetailModal(isProductDetailModalOpen) {
  return {
    type: types.TOGGLE_PRODUCT_DETAIL_MODAL,
    isProductDetailModalOpen,
  };
}

// Async Thunks
export const loadVendors = (useCache = false, specificVendor) => {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_VENDORS });


    try {
      // now that there may be frequest changes to supplier settings
      // we don't want to use cache for loading the vendor
      const response = await DataAPI.fetchVendors(false, specificVendor);

      if (response && response.data) {
        const { data: vendors } = response.data;
        dispatch(receiveVendors(specificVendor ? [vendors] : vendors));
        dispatch(loadSuppliers());

        return vendors;
      } else {
        throw new Error(
          `Error, response from server has issues in loadVendors, response is ${response}`
        );
      }
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadVendors');
      console.error(error);
      logException(error);
    }
  };
};

export const deleteVendor = (vendorUrlsafe) => {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_VENDOR });

    try {
      const response = await DataAPI.deleteVendor(vendorUrlsafe);

      if (response && response.data) {
        return;
      } else {
        throw new Error(
          `Error, response from server has issues in deleteVendor, response is ${response}`
        );
      }
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with deleteVendor');
      console.error(error);
      logException(error);
    }
  };
};

export const reportIncorrectItem = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.POST_REPORT_INCORRENT_ITEM });

    try {
      const response = await DataAPI.reportIncorrectItem(data);
      toastr.success('Item Reported Sucessfully');
      return response;
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with reportIncorrectItem');
      console.error(error);
    }
  };
};

