import { App as CapacitorApp } from '@capacitor/app';
import {
  CAPACITOR_BACK_BUTTON_LISTENER_ID,
  CAPACITOR_STATUS_TAP_LISTENER_ID,
  EIonicPlatforms,
} from '../../constants/Mobile';
import { isPlatform } from '@ionic/react';

export const MAIN_ION_CONTENT_ID = `app-content__main`;
export const PAGE_WRAPPER_CONTENT_ID = `page-wrapper__`;

export const getIonContent = (id = MAIN_ION_CONTENT_ID) =>
  document.querySelector(`ion-content#${id}`) ?? null;

export const scrollToTop = (element = MAIN_ION_CONTENT_ID, speed = 500) => {
  const ionContent = getIonContent(element);
  if (ionContent) {
    ionContent.scrollToTop(speed);
  }
};

/**
 * This method will add a listener for the physical back button for android only
 */
export const addBackButtonAndroidListener = () => {
  if (isPlatform(EIonicPlatforms.ANDROID)) {
    CapacitorApp.addListener(
      CAPACITOR_BACK_BUTTON_LISTENER_ID,
      ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      }
    );
  }
};

export const addStatusBarTapEventListener = () => {
  if (isPlatform(EIonicPlatforms.IOS) && isPlatform(EIonicPlatforms.CAPACITOR)) {
    window.addEventListener(CAPACITOR_STATUS_TAP_LISTENER_ID, function () {
      scrollToTop();
    });
  }
};
