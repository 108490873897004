import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import Utils from '../../../../utils';

import './style.scss';
import { Typography } from '@notch-ordering/ui-components';

export default class DeliveryFeeStatus extends Component {
  render() {
    const {
      className,
      minimumOrderAmount,
      orderTotalMinusTaxandFees,
      orderQualifiesForFreeDel,
    } = this.props;

    const progressBarPercentage =
      (orderTotalMinusTaxandFees / minimumOrderAmount) * 100;
    const progressBarWidthStyle = {
      width: `${Utils.getProgressBarFillWidth(progressBarPercentage)}%`
    };

    return (
      <div className={classNames('delivery-fee-status mt-2', className)}>
        {orderQualifiesForFreeDel ? (
          <Typography className='top-text free'>
            <span className=''>Free </span> delivery reached!
          </Typography>
        ) : (
          <div>
            <div className='progress-bar-table'>
              <div className='progress-bar-col'>
                <div className='outer-bar'>
                  <div
                    className='inner-bar'
                    style={progressBarWidthStyle}
                  />
                </div>
              </div>
            </div>

            <Typography className='top-text'>
              <span className='amount'>
                {Utils.formatAsCurrency(
                  minimumOrderAmount - orderTotalMinusTaxandFees
                )}
              </span>{' '}
              away from free delivery.
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

DeliveryFeeStatus.propTypes = {
  className: PropTypes.string,
  minimumOrderAmount: PropTypes.number.isRequired,
  vendor: PropTypes.object.isRequired,
  orderTotalMinusTaxandFees: PropTypes.number.isRequired,
};
