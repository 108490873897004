/* eslint-disable @typescript-eslint/no-explicit-any */
import { Buyer } from '@v2/types/Buyer';
import { deleteVendor, fetchVendors, searchOrders, fetchSingleOrder } from '@/api/DataAPI';
import { AddressData, LegacyInvoiceData, SupplierData } from './CoreAPI';

export type InvoicesResponse = {
    total_count: number,
    invoices: LegacyInvoiceData[],
    limit: number,
    offset: number,
};

export type Order = {
    grandTotal: number,
    orderBuyerStatus: string,
    shippingAddress: AddressData,
    billingAddress: AddressData,
    isConfirmed: boolean,
    isVendorEmailSent: boolean,
    isAutoPayByCard: boolean,
    tax: number,
    addOnOrderFor: string,
    addOnOrders: [],
    deliveryConfirmedBySupplierAt: string,
    deliveryRequest: string,
    statementEndAt: string,
    debitTotalAmount: number,
    paymentProvider: string,
    isTestOrder: boolean,
    images: { image_serving_url: string, image_key:string, image_url: string }[],
    skippedAt: string,
    customerUrlsafe: string,
    paidAmount: number,
    id: number,
    paidAt: string,
    creditApplied: number,
    total: number,
    invoiceURL: string,
    urlsafe: string,
    grandSubtotal: number,
    invoices: [],
    isConnect: true,
    grandPaidAmount: number,
    deliveryDay: string,
    vendorUrlsafe: string,
    isOnHold: boolean,
    vendorName: string,
    isShortedOrder: boolean,
    placedAt: string,
    isInShoppingCart: boolean,
    buyer: Buyer,
    invoiceNumber: string,
    subtotal: number,
    dueAt: string,
    isPaid: boolean,
    isAddOnOrder: boolean,
    isReplacementOrder: boolean,
    canEditReceivingTool: boolean,
    isDeliveryConfirmed: boolean,
    items: OrderItem[],
    notes: string,
    sentAt: string,
    isOrderReviewSubmitted: boolean,
    paymentTerms: number,
};

export type OrderItem = {
    original_unit_quantity: number,
    originalQuantity: number,
    expected_delivery_quantity: number,
    total: number,
    isTaxable: boolean,
    taxPercentage: number,
    pricePerUnit: number,
    salesUnit: string,
    unit_price: number,
    externalId: string,
    unitName: string,
    packaging_price: number,
    unit_quantity: number,
    categories: string[],
    taxAmount: number,
    name: string,
    packaging_quantity: number,
    isAddonItem: boolean,
    productCode: string,
    salesQuantity: number,
    urlsafe: string,
    description: string,
    price: number,
    unitDescription: string,
    priceTotal: number,
    quantity: number,
};

/**
 * This is a intermediary layer for the old DataAPI.js network requests that interact with suppliers.
 */

/**
 * Gets the supplier data from be-core
 *
 * @param supplierUrlsafeKey - Urlsafe Key of the supplier
 */
export async function getSupplier(supplierUrlsafeKey: string): Promise<SupplierData> {
    const response = await fetchVendors(false, supplierUrlsafeKey);
    return new Promise((resolve, reject) => {
        if (response?.data?.data) {
            resolve(response.data.data);
        } else {
            reject(response);
        }
    });
}

export type GetOrdersParams = {
    limit?: number,
    offset?: number,
    deliveryDayStart?: string,
    deliveryDayEnd?: string,
    vendorUrlsafe?: string,
    sortBy?: string,
    id?: string, // Order number
    vendorID?: string,
};

export type OrdersResponse = {
    totalCount: number,
    limit: number,
    offset: number,
    data: Order[],
};

export async function getOrders(params: GetOrdersParams): Promise<OrdersResponse> {
    const response = await searchOrders({
        isInShoppingCart: false,
        ...params as any
    });
    return new Promise((resolve, reject) => {
        if (response?.data) {
            resolve(response.data);
        } else {
            reject(response);
        }
    });
}

/**
 * Removes a supplier from a buyer on be-core
 *
 * @param supplierUUID - The uuid of the supplier to remove from the current buyer
 */
export async function removeSupplier(supplierUUID: string): Promise<void> {
    const response = await deleteVendor(supplierUUID);
    if (response.status !== 200) {
        throw new Error('Failed to remove supplier catalog from buyer');
    }
}

export async function getOrder(orderUrlsafeKey: string): Promise<{ data: Order }> {
    const order = await fetchSingleOrder(orderUrlsafeKey);
    return order;
}
