import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';

import * as types from '../constants/ActionTypes';
import * as signInActions from './signInActions';
import * as buyerActions from './buyerActions';
import * as intercom from '../third-party/Intercom';
import * as DataAPI from '../api/DataAPI';
import * as analyticsActions from './analyticsActions';
import { USER_SIGNING_UP } from '../constants/GlobalConstants';
import { changeRoute } from './routerActions';
import * as Segment from '../third-party/Segment';
import { logException, logMessage } from '../domains/shared/logger';
import { ROLES } from '../constants/Roles';
import { isPlatform } from '@ionic/react';
import { EIonicPlatforms } from '@/constants/Mobile';
import { EPaths } from '@v2/constants/EPaths';
import { useHomeStore } from '@v2/stores/HomeStore';

function updateAccountStateOnly(firstName, lastName) {
  return {
    type: types.UPDATE_ACCOUNT_SETTINGS,
    firstName,
    lastName,
  };
}

export function showLocationSwitcher(showLocationSwitcher) {
  return {
    type: types.SET_LOCATION_SWITCHER,
    showLocationSwitcher,
  };
}

function getSessionData(data) {
  const defaultBuyer = !_.isEmpty(window.localStorage.getItem('chefhero_defb'))
    ? JSON.parse(window.localStorage.getItem('chefhero_defb'))
    : null;

  if (!defaultBuyer || !defaultBuyer.buyerMemberID) {
    logMessage(
      `default buyer from storage does not have a buyerMemberID: ${JSON.stringify(
        defaultBuyer
      )}`
    );
  }

  const accountWithRoles = {
    ...data.account,
    memberID: defaultBuyer ? defaultBuyer.buyerMemberID : 0,
    roles: defaultBuyer ? defaultBuyer.roles : [],
  };

  return {
    type: types.GET_SESSION_DATA,
    account: accountWithRoles,
    org: data.org,
    impersonator: data.impersonator || null,
  };
}

// Async Thunks
export const updateAccountSettings = (accountUrlsafe, data) => {
  return async (dispatch) => {
    try {
      dispatch(updateAccountStateOnly(data.firstName, data.lastName));
      dispatch({ type: types.UPDATE_ACCOUNT_REQUEST });

      await DataAPI.updateAccountSettings(accountUrlsafe, data);
      dispatch({ type: types.UPDATE_ACCOUNT_SUCCESS });
    } catch (error) {
      handleCatch(
        types.UPDATE_ACCOUNT_FAILED,
        'updateAccountSettings',
        error,
        dispatch
      );
    }
  };
};

/**
 * Fetch the user session
 */
export const fetchSession = () => {
  return async (dispatch) => {
    try {
      const defaultBuyer = !_.isEmpty(
        window.localStorage.getItem('chefhero_defb')
      )
        ? JSON.parse(window.localStorage.getItem('chefhero_defb'))
        : null;
      dispatch({ type: types.FETCH_SESSION_REQUEST });

      const response = await DataAPI.fetchSession();

      if (response && response.data) {
        dispatch({ type: types.FETCH_SESSION_SUCCESS });

        // Check whether the default buyer is not set in local storage, then set the default buyer to the
        // first buyer in the org.  Otherwise keep the same default buyer already set in local storage

        if (defaultBuyer) {
          // Get the key of the saved default buyer in localStorage, match it with the one from response
          // and replace the saved buyer in localStorage with the data from the response
          const defaultBuyerKey = defaultBuyer ? defaultBuyer.buyerKey : '';
          const defaultBuyerOrg = response.data.org.find(
            (b) => b.buyerKey === defaultBuyerKey
          );

          // If the buyer is not available in the session response, force a logout
          if (!defaultBuyerOrg) {
            throw new Error(`Selected buyer is not currently available`);
          }

          dispatch(buyerActions.selectDefaultBuyer(defaultBuyerOrg));
        } else {
          const buyerOrgs = response.data.org.filter((buyer) => buyer.buyerKey);
          const urlSearchParams = new URLSearchParams(window.location.search);
          const buyer = buyerOrgs.find(
            (b) => b.buyerKey === urlSearchParams.get('buyerKey')
          );
          if (buyer) {
            dispatch(buyerActions.selectDefaultBuyer(buyer));
          } else {
            dispatch(buyerActions.selectDefaultBuyer(buyerOrgs[0]));
          }
        }

        // We require the buyerMemberID for third party application registration and we need to know which buyer
        // we are first. So we need to set the data in the reducer after figuring out the buyer.
        dispatch(getSessionData(response.data));

        const { org = [], account = {} } = response.data;
        const selectedBuyerKey = defaultBuyer ? defaultBuyer.buyerKey : '';

        // If don't have a default buyer or buyer doesn't exist in list of orgs, then
        // redirect/throw according to conditions.
        if (
          !selectedBuyerKey ||
          !org.find((buyer) => buyer.buyerKey === selectedBuyerKey)
        ) {
          // Only use org with buyerKey
          const buyerOrgs = org.filter((o) => o.buyerKey);

          // To pipe the search params (when available) to the route
          const searchParams = window.location
            ? window.location.search || ''
            : '';

          if (buyerOrgs?.length < 1) {
            throw new Error(`No buyer found for account ${account.email}`);
          } else {
            // Make first buyer the default buyer.
            const buyer = buyerOrgs[0];
            window.localStorage.setItem('chefhero_defb', JSON.stringify(buyer));

            // If accountholder role is a bookkeeper, deny access with an error message,
            // otherwise route other accountholders to the store
            if (
              buyerOrgs[0].roles &&
              buyerOrgs[0].roles[0].name === ROLES.BOOK_KEEPER
            ) {
              throw new Error(
                'This role receives payment notifications via email. For access, please contact the owner of this account.'
              );
            }

            // Otherwise, load route via push method. This will allow us to continue accepting react router requests.
            // One case is when new selfonboard buyer account is created, we would like to open the Add Supplier dialog for non-referral accounts.

            // Check if the sessionKey is present in search params.
            // If true, then it is coming from Admin3 impersonation, in that case, we don't want to pass search params.
            // Because this avoids the again and again loading of webapp.

            if (!searchParams) {
              return dispatch(push(EPaths.HOME));
            }

            if (!searchParams.includes('sessionKey=')) {
              dispatch(push(`${EPaths.HOME}/${searchParams}`));
            }
          }
        }

        return response.data;
      } else {
        // Tracking if response is empty
        throw new Error('Session info not received for account.');
      }
    } catch (error) {
      // If error has occurred fetching the session logout
      logout();

      handleCatch(types.FETCH_SESSION_FAILED, 'fetchSession', error, dispatch);
    }
  };
};

/**
 * Resends Activation Email for inactive User Account
 *
 * @param {string} email - Email of the user account
 * @param {string} firstName - First name of user
 * @param {string} lastName - Last name of user
 * @returns {object} {ok, errorMessage}
 */
export const resendSignupActivationEmail = (
  email,
  firstName,
  lastName,
  fromSubdomain = ''
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.RESEND_ACTIVATION_EMAIL_REQUEST,
      });
      const response = await DataAPI.resendSignupActivationEmail(
        email,
        firstName,
        lastName,
        fromSubdomain
      );

      if (response && response.data) {
        dispatch({
          type: types.RESEND_ACTIVATION_EMAIL_SUCCESS,
        });
        toastr.success('Account activation email sent successfully');
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in resendSignupActivationEmail, response is ${response}`
        );
      }
    } catch (error) {
      handleCatch(
        types.RESEND_ACTIVATION_EMAIL_FAILED,
        'resendSignupActivationEmail',
        error,
        dispatch
      );
    }
  };
};

/**
 * Verifies Activation Email Code for inactive User Account
 *
 * @param {string} email - Email of the user account
 * @param {string} code - Activation code of the user account
 * @returns {object} {ok, errorMessage}
 */
export const verifySignupAccountCode = (email, code) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.VERIFY_ACTIVATION_EMAIL_CODE_REQUEST,
      });
      const response = await DataAPI.verifySignupAccountCode(email, code);

      if (response && response.data) {
        dispatch({
          type: types.VERIFY_ACTIVATION_EMAIL_CODE_SUCCESS,
        });
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in verifySignupAccountCode, response is ${response}`
        );
      }
    } catch (error) {
      handleCatch(
        types.VERIFY_ACTIVATION_EMAIL_CODE_FAILED,
        'verifySignupAccountCode',
        error,
        dispatch
      );
      dispatch(changeRoute('/account/signin/'));
    }
  };
};

/**
 * Creates a self onboard user buyer account
 * @param {string} email user email
 * @param {string} code activation code of the user
 * @param {object} data object with restaurant name (optional), shipping address, drive instructions and supplier referral (optional) info
 */
export const createSelfOnboardBuyerAccount = (email, code, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.CREATE_SELF_ONBOARD_BUYER_ACCOUNT_REQUEST,
      });
      const response = await DataAPI.createSelfOnboardBuyerAccount(
        email,
        code,
        data
      );

      if (response && response.data) {
        dispatch({
          type: types.CREATE_SELF_ONBOARD_BUYER_ACCOUNT_SUCCESS,
        });

        /*
         * We need this to make sure we can make buyer specific calls between onboarding the buyer
         * and sigingin them in. In this case we want to load all the suggested buyers right after,
         * so we need to set all the jwt authenticaion information that comes back from this call.
         */
        // Clear the default buyer
        window.localStorage.removeItem('chefhero_defb');
        window.localStorage.setItem('chefhero_sk', response.data.sessionKey);
        window.localStorage.setItem('chefhero_rt', response.data.refreshToken);
        window.localStorage.setItem('chefhero_e', response.data.expiresAt);
        window.__accessToken__ = response.data.accessToken;

        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in createSelfOnboardBuyerAccount, response is ${response}`
        );
      }
    } catch (error) {
      handleCatch(
        types.CREATE_SELF_ONBOARD_BUYER_ACCOUNT_FAILED,
        'createSelfOnboardBuyerAccount',
        error,
        dispatch
      );
    }
  };
};

const handleCatch = (
  type,
  methodName,
  error,
  dispatch,
  errorsNotToLogArray
) => {
  dispatch({
    type,
  });
  let errorMessage = '';

  if (!error.response) {
    errorMessage = 'Detected a connection problem, please refresh this page';
  } else {
    errorMessage =
      (((error || {}).response || {}).data || {}).message || 'Please try again';
  }

  toastr.error(`Error: ${errorMessage}`);

  if (errorsNotToLogArray && errorsNotToLogArray.includes(errorMessage)) {
    return;
  }

  console.error(`An Error occurred with ${methodName}`);
  console.error(error);
  logException(error);
};

/**
 * Logs the user in without using password
 *
 * @param {string} sessionKey user session key
 * @param {string} refreshToken user refresh token
 * @param {int} expiresAt token expiry time
 * @param {string} accessToken user access token
 */
export const loginUserWithSessionInfo = (
  sessionKey,
  refreshToken,
  expiresAt,
  accessToken
) => {
  return async (dispatch) => {
    // Clear the default buyer
    window.localStorage.removeItem('chefhero_defb');

    // Set the user session info
    window.localStorage.setItem('chefhero_sk', sessionKey);
    window.localStorage.setItem('chefhero_rt', refreshToken);
    window.localStorage.setItem('chefhero_e', expiresAt);
    window.__accessToken__ = accessToken;

    // Clear the Intercom session
    intercom.clearSession();

    // Load User
    await dispatch(fetchSession());

    await dispatch(signInActions.postSignin());
  };
};

export const aliasUserWithMemberId = () => {
  return async () => {
    const response = await DataAPI.fetchSession();

    if (response && response.data) {
      const buyerMemberID =
        response.data.org && response.data.org.length === 1
          ? response.data.org[0].buyerMemberID
          : '';
      const accountEmail =
        response.data.account && response.data.account.email
          ? response.data.account.email
          : '';

      if (buyerMemberID !== '' && accountEmail !== '') {
        Segment.alias(buyerMemberID, accountEmail);
      }
    }
  };
};

/**
 * Initiates the manual process of deleting their account. Sends an internal email request
 * which is processed weekly by CS. Also emails the user to confirm that the process has started.
 */
 export const deleteAccount = (email, firstName, lastName) => {
  return async () => {
    try {
      const response = await DataAPI.sendDeleteAccountEmail(
        email,
        firstName,
        lastName,
      );

      if (response && response.data) {
        toastr.success('Account delete request successful.');
        logout();
      } else {
        toastr.error('Something went wrong, please try again.');
      }
    } catch (error) {
      toastr.error('Something went wrong, please try again.');
    }
  };
};

/**
 * Remove all session info from local storage
 * and redirect back to signin page
 * 
 * NOTE: This appears to not be in use. See signInActions.signout() instead.
 */
export const logout = () => {
    window.localStorage.removeItem('chefhero_sk');
    window.localStorage.removeItem('chefhero_rt');
    window.localStorage.removeItem('chefhero_e');
    window.localStorage.removeItem('chefhero_defb');
    window.location.reload();
}
