import AlertIcon from '@icons/alert-icon.svg';
import { toast } from '@notch-ordering/ui-components';
import { tCommon } from '@v2/i18n';
import React from 'react';
import { logException } from '@/domains/shared/logger';

/**
 * Logs an error in the browser console, LogRocket and shows a toaster to the user.
 *
 * @param msg - User facing error message
 * @param error - Error data object
 * @param showToaster - When true, shows the message in a red alert toaster in the top-right
 */
export function logError(msg, error, showToaster = true): void {
    // eslint-disable-next-line no-console
    console.error(msg, error);
    logException(error);

    if (showToaster) {
        toast.show({
            icon: <AlertIcon className="text-red-300"/>,
            message: `${tCommon('somethingWentWrong')} ${tCommon('tryAgain')}`,
            showClose: true
        });
    }
}
