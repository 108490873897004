// Error codes with their assosiated messages, currently related with payment methods removal
export const ErrMessages = {
    "400001": 'Payment method not found',
    "400003": 'Future payment is scheduled on this payment method',
};

export const getErrorMessage = (code, defaultErr="An error occurred") => {

    let errorMessage = defaultErr;

    // Checking for the code and associated error message with the code
    if (code && ErrMessages[code] && ErrMessages[code] != "") {
        errorMessage = ErrMessages[code]
    }

    return errorMessage
  };