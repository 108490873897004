import { createSelector } from 'reselect';
import moment from 'moment';

import Utils from '../utils';
import * as orderSelector from './ordersSelectors';
import * as checkoutHelpers from '../helpers/checkoutHelpers';
import { ADDITIONAL_FEE_TYPES } from '../constants/AdditionalFeeTypes';

const getOrders = (state) => state.ordersReducer.orders;
const getCancelOrderUrlsafe = (state) =>
  state.checkoutReducer.cancelOrderUrlsafe;
const getVendors = (state) => state.vendorsReducer.vendors;
const getCheckoutReducer = (state) => state.checkoutReducer;

const getVendor = (props) => props.vendor;
const getOrder = (props) => props.order;
const getIsEditingSingleOrder = (state, props) => props.isEditingSingleOrder;
const getCurrentlyEditingorderUrlsafe = (state, props) =>
  props.currentlyEditingOrderUrlsafe;
const getIsPendingOrdersURLParamProp = (state, props) =>
  props.isPendingOrdersURLParamProp;
const getProps = (props) => props;
export const getCredit = (state) => state.checkoutReducer.credit;
const getTotals = (state, props) => props.additionalOrderTotals;

// Selector that gives you the vendor for the order you want to cancel.
export const getCanceledOrderVendor = createSelector(
  [getOrders, getCancelOrderUrlsafe],
  (orders, cancelOrderUrlsafe) => {
    // Find the order index
    let orderIndex = orders.findIndex(
      (order) => order.urlsafe === cancelOrderUrlsafe
    );

    // Return the vendor for that order, empty if not found
    let cancelOrderVendor =
      orderIndex > -1 ? orders[orderIndex].vendorName : '';

    return cancelOrderVendor;
  }
);

// Selector takes array of vendors and returns nested vendors object organized by urlsafe
export const getVendorsByUrlsafe = createSelector([getVendors], (vendors) =>
  _.indexBy(vendors, 'urlsafe')
);

// Selector that tells you if you're after the cutofftime for a vendor
export const IsAfterCutOffTime = createSelector([getVendor], (vendor) => {
  // Convert the cutoff time hh:mm am/pm format to moment object for comparison
  const cutOffTime = moment(vendor.region.cutOffTime, 'h:mm a');

  const beforeTime = moment('12:00 AM', 'h:mm a');
  const afterTime = moment('3:00 AM', 'h:mm a');

  // Ignore cutOffTime (i.e make it false) if cutOffTime is between 12:00 AM and 3:00 AM
  if (cutOffTime.isBetween(beforeTime, afterTime)) {
    // Return false value and exit function
    return false;
  }

  // Compare to see if the current time is past vendor cutoff date
  const currentTime = moment();
  const afterCutOffTime = currentTime.isAfter(cutOffTime);
  return afterCutOffTime;
});

// Selector that uses the vendor and order objects to determine if we are after the vendor delivery day
// cutoff time for addOn orders
export const isAfterDeliveryDayCutOffTime = createSelector(
  [getVendor, getOrder],
  (vendor, order) => {
    return Utils.isAfterDeliveryDayCutOffTime(vendor, order);
  }
);

// Selector that takes the orders and credits applied to them and calculated the total ordersReducer
export const getCheckoutOrdersGrandTotal = createSelector(
  [
    getOrders,
    getVendors,
    getIsPendingOrdersURLParamProp,
    getIsEditingSingleOrder,
    getCurrentlyEditingorderUrlsafe,
  ],
  (
    orders,
    vendors,
    isPendingOrdersURLParamProp,
    isEditingSingleOrder,
    currentlyEditingOrderUrlsafe
  ) => {
    let ordersGrandTotal = 0;

    // Filter out orders based on the following conditions:
    // 1. Order is Checkout
    // 2. Are we editing a single order, in which case we skip adding other orders to the total
    // 3. Are we past cutoff time - meaning orders cannot be edited anyway
    const filteredOrders = orders.filter((order) => {
      if (
        !order.isBYOS &&
        order.isCheckout &&
        !(
          isEditingSingleOrder && order.urlsafe !== currentlyEditingOrderUrlsafe
        )
      ) {
        const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

        if (vendor) {
          return !Utils.isAfterDeliveryDayCutOffTime(vendor, order);
        }

        return true;
      }

      return false;
    });

    if (filteredOrders.length > 0) {
      ordersGrandTotal = filteredOrders.reduce((prev, order) => {
        // Use existing selector to get grandTotal
        return (
          prev +
          orderSelector.getSingleOrderGrandTotal(
            {
              vendorsReducer: { vendors },
              checkoutReducer: { isPendingOrders: isPendingOrdersURLParamProp },
            },
            { order }
          )
        );
      }, 0);
    }

    // force to 0 if the final total is below 0
    ordersGrandTotal = ordersGrandTotal > 0 ? ordersGrandTotal : 0;

    return ordersGrandTotal;
  }
);

export const getItemsTotal = createSelector([getOrder], (order) => {
  return order.items.reduce((acc, item) => {
    if (item.price <= 0) {
      return acc;
    }

    return acc + item.price * item.quantity;
  }, 0);
});

// Selector that takes the orders and calculates both the ordersTotal without taxes and total taxes.
export const getAdditionalOrderTotals = createSelector(
  [
    getOrders,
    getVendors,
    getIsEditingSingleOrder,
    getCurrentlyEditingorderUrlsafe,
  ],
  (orders, vendors, isEditingSingleOrder, currentlyEditingOrderUrlsafe) => {
    let ordersTotalMinusTaxes = 0;
    let ordersTotalTaxes = 0;
    let ordersTotalMinusTaxesAndFees = 0;

    // Filter out orders that are BYOS and not isCheckout
    // If we are editing a single order, then we need skip adding other orders to the total
    const filteredOrders = orders.filter(
      (order) =>
        (order.isInShoppingCart || isEditingSingleOrder) &&
        order.items?.length > 0 &&
        order.isCheckout &&
        !(
          isEditingSingleOrder && order.urlsafe !== currentlyEditingOrderUrlsafe
        )
    );

    ordersTotalMinusTaxes = filteredOrders.reduce((prevValue, order) => {
      const total = order.items.reduce(
        (prev, item) => prev + item.price * item.quantity,
        0
      );

      let otherFees = 0;
      if (order.additionalFees) {
        otherFees = order.additionalFees.reduce((prev, fee) => {
          return (
            prev +
            (fee.displayName.toLowerCase() !== 'delivery fee' &&
            fee.feeType.toLowerCase() !== ADDITIONAL_FEE_TYPES.DELIVERY &&
            !fee.isWaived
              ? fee.amount
              : 0)
          );
        }, 0);
      }

      let customDeliveryFee = 0;
      if (order.additionalFees) {
        customDeliveryFee = order.additionalFees.reduce((prev, fee) => {
          return (
            prev +
            ((fee.displayName.toLowerCase() === 'delivery fee' ||
              fee.feeType.toLowerCase() === ADDITIONAL_FEE_TYPES.DELIVERY) &&
            !fee.isWaived
              ? fee.amount
              : 0)
          );
        }, 0);
      }

      const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);
      const isBelowMinimumOrder = checkIfBelowMinimumOrder({ order, vendor });
      const deliveryFee =
        customDeliveryFee || Utils.getDeliveryMinusTax(order, vendor);

      // subtotal is a sum of all the items and delivery fee if the order has items (excluding taxes)
      const isAddDeliveryFee =
        isBelowMinimumOrder && order.isCheckout && order.items?.length > 0;
      return isAddDeliveryFee
        ? prevValue + total + deliveryFee + otherFees
        : prevValue + total + otherFees;
    }, 0);

    ordersTotalTaxes = filteredOrders.reduce((prevValue, order) => {
      const reduceFunction = (prevValueItem, orderItem) => {
        // If the item is taxable simply multiply price by quantity by percentage
        const orderItemTotalTaxAmount = orderItem.isTaxable
          ? orderItem.price * orderItem.quantity * orderItem.taxPercentage
          : 0;
        return prevValueItem + orderItemTotalTaxAmount;
      };

      const total = order.items.reduce(reduceFunction, 0);
      const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);
      const isBelowMinimumOrder = checkIfBelowMinimumOrder({ order, vendor });

      // subtotal is a sum of all the items and delivery fee if the order has items (excluding taxes)
      const isAddDeliveryFee =
        isBelowMinimumOrder && order.isCheckout && order.items?.length > 0;
      let additionalFeesTax = 0;
      if (order.additionalFees && order.additionalFees.length > 0) {
        additionalFeesTax = order.additionalFees
          .filter((fee) => {
            return (
              !fee.isWaived &&
              (fee.displayName.toLowerCase() !== 'delivery fee' ||
                fee.isAddedBySupplier ||
                isAddDeliveryFee)
            );
          })
          .reduce((prevValue, fee) => prevValue + fee.tax, 0);
      } else if (
        isAddDeliveryFee &&
        (!order.additionalFees ||
          !order.additionalFees.length ||
          !order.additionalFees.every(
            (fee) => fee.displayName.toLowerCase() === 'delivery fee'
          ))
      ) {
        const ordersTotalTaxesOriginal = order.originalOrderItems?.reduce(
          reduceFunction,
          0
        );

        additionalFeesTax = order.tax - ordersTotalTaxesOriginal;
      }

      return prevValue + total + additionalFeesTax;
    }, 0);

    ordersTotalMinusTaxesAndFees = filteredOrders.reduce((prevValue, order) => {
      const total = order.items.reduce(
        (prev, item) => prev + item.price * item.quantity,
        0
      );

      let customDeliveryFee = 0;
      if (order.additionalFees) {
        customDeliveryFee = order.additionalFees.reduce((prev, fee) => {
          return (
            prev +
            ((fee.displayName.toLowerCase() === 'delivery fee' ||
              fee.feeType.toLowerCase() === ADDITIONAL_FEE_TYPES.DELIVERY) &&
            !fee.isWaived
              ? fee.amount
              : 0)
          );
        }, 0);
      }

      const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);
      const isBelowMinimumOrder = checkIfBelowMinimumOrder({ order, vendor });
      const deliveryFee =
        customDeliveryFee || Utils.getDeliveryMinusTax(order, vendor);

      // subtotal is a sum of all the items and delivery fee if the order has items (excluding taxes)
      const isAddDeliveryFee =
        isBelowMinimumOrder && order.isCheckout && order.items?.length > 0;
      return isAddDeliveryFee
        ? prevValue + total + deliveryFee
        : prevValue + total;
    }, 0);

    // force to 0 if the final total is below 0
    ordersTotalMinusTaxes =
      ordersTotalMinusTaxes > 0 ? ordersTotalMinusTaxes : 0;
    ordersTotalTaxes = ordersTotalTaxes > 0 ? ordersTotalTaxes : 0;
    ordersTotalMinusTaxesAndFees =
      ordersTotalMinusTaxesAndFees > 0 ? ordersTotalMinusTaxesAndFees : 0;

    return {
      ordersTotalMinusTaxes,
      ordersTotalTaxes,
      ordersTotalMinusTaxesAndFees,
    };
  }
);

// Check if a day we have previously selected to be the order delivery day is now disabled.
// This only applied to Non-Pending orders because you should not disable a pending order (that is really bad).
export const checkIfDisabledDayWasAlreadyChosen = createSelector(
  [getProps],
  (props) => {
    let result = false;
    const { isPendingOrders, order, vendor, afterSameDayCutOffTime } = props;

    // Edge case
    // If we are after the cutoff time and the deliveryDay is already selected as today or tomorrow,
    // then reset the deliveryDay to null to make the user reselect it again. But ONLY for non-pending state.
    // This is because they should not be able to proceed under these condition to the next step. but they
    // have selected them in the past before the cutofftime or the vendor indicated they were closed.
    //
    // We need to reset the deliveryDay to null and make the user select it again, if the following
    // conditions occur in Non-Pending state only.
    //
    // 1) If the vendor is closed that day.
    // 2) If deliveryDay is today and if afterSameDayCutOffTime (but only if we have a sameDayCutOff)
    // 3) If deliveryDay is today and we don't have a sameDayCutOff (should not be able to select today)
    // 4) If delivery day is before vendor's first nextAvailableDeliveryDays
    if (!isPendingOrders && order.deliveryDay) {
      if (
        isVendorClosedToday(
          checkoutHelpers.getVendorClosedDays(vendor),
          order.deliveryDay
        )
      ) {
        // Condition 1
        result = true;
      } else if (Utils.isDateToday(order.deliveryDay)) {
        // Conditions 2 and 3
        if (vendor.region.cutOffTime && afterSameDayCutOffTime) {
          result = true;
        } else if (!vendor.region.cutOffTime) {
          result = true;
        }
      } else if (
        vendor &&
        vendor.region &&
        vendor.region.nextAvailableDeliveryDays
      ) {
        // Condition 4
        result = moment(order.deliveryDay).isBefore(
          vendor.region.nextAvailableDeliveryDays[0].date
        );
      }
    }

    return result;
  }
);

// Check if Checkout Step1 is complete
export const checkIfCheckoutStep1Complete = createSelector(
  [getOrders, getCheckoutReducer, getVendors, getCurrentlyEditingorderUrlsafe],
  (orders, checkoutReducer, vendors, currentlyEditingorderUrlsafe) => {
    let result = true;
    let errorMessage = '';

    const { isPendingOrders, orderDeliveryRequestsView } = checkoutReducer;

    /*
     ** First step in validating step1 of checkout.
     ** Validate:
     ** 1) We have chosen a shipping Address.
     ** 2) We are in the correct orderDeliveryRequestsView (i.e ShowDeliveryRequestsView)
     */
    if (orderDeliveryRequestsView !== 'ShowDeliveryRequestsView') {
      result = false;
      errorMessage = 'Please specify a shipping address for your order';
    }

    /*
     ** Second step in validating step1 of checkout.
     ** Validate For Non-pending orders only, every order has a:
     ** 1) A deliveryDay
     ** 2) The deliveryDay is not before today's date.
     ** 3) Not be preOrder restricted meaning, they can't be delivered for today or tomorrow and have preOrder items.
     ** 4) The deliveryTimeWindowIndex exits for orders that are buying group and are not being delivered today.
     ** If those conditions are met we return true that the checkout step1 is complete.
     */

    // If we are pending mode, this check is irrelevant and the result is always true
    if (!isPendingOrders) {
      const today = moment();

      for (const order of orders) {
        // Skip orders that have no items from this check
        // Skip orders that isCheckout is false
        if (
          order.items?.length === 0 ||
          !order.isCheckout ||
          currentlyEditingorderUrlsafe !== order.urlsafe
        ) {
          continue;
        }

        // Make sure deliveryDay is in moment format to use diff
        const deliveryDay = moment(order.deliveryDay);

        if (!order.deliveryDay || order.selectedDeliveryDayOptionIndex < 0) {
          result = false;
          errorMessage = `Please Select a Delivery Date For ${order.vendorName}`;
          break;
        } else if (today.diff(deliveryDay, 'days') > 0) {
          result = false;
          errorMessage = `The Delivery Day For ${order.vendorName} Has Already Passed, Please Select Another Date`;
          break;
        } else if (order.preOrderRestricted) {
          result = false;
          errorMessage = `Please Select Another Delivery Day For ${order.vendorName} Because You Have Items That Require 48 Hours Notice`;
          break;
        } else if (!Utils.isDateToday(order.deliveryDay)) {
          let vendorIndex = -1;
          for (const [index, vendor] of vendors.entries()) {
            if (vendor.id === order.vendorID) {
              vendorIndex = index;
              break;
            }
          }
        } else {
          // The result is true (it's true by default)
        }
      }
    }

    return {
      result,
      errorMessage,
    };
  }
);

// Check if Order is below minimum order amount
export const checkIfBelowMinimumOrder = createSelector([getProps], (props) => {
  const { order, vendor } = props;
  // if the delivery fee was added by the supplier
  // we will always want to display it
  if (order.additionalFees && order.additionalFees.length > 0) {
    if (
      order.additionalFees.some(
        (fee) =>
          fee.displayName.toLowerCase() === 'delivery fee' &&
          !fee.isWaived &&
          fee.isAddedBySupplier
      )
    ) {
      return true;
    } else if (
      order.additionalFees.every(
        (fee) =>
          fee.displayName.toLowerCase() !== 'delivery fee' || fee.isWaived
      )
    ) {
      return false;
    }
  }

  // Get the vendor from the buyer becuase in the future, we will be getting it from here exclusively
  const vendorMinimum = vendor ? vendor.region.minimumOrderAmount : 0;

  // Use a computed order total instead of order.subtotal which might not be updated
  const orderTotal = order.items.reduce(
    (prev, item) => prev + item.price * item.quantity,
    0
  );

  // Check minimum order amount for orders that are
  // 1) NOT $0 orders
  // 2) NOT SASS orders
  // 3) isCheckout is true
  return vendorMinimum !== 0 && order.isCheckout && orderTotal < vendorMinimum;
});

// Check if Order is below minimum order amount
export const getSupplierCustomDeliveryFee = createSelector(
  [getProps],
  (props) => {
    const { order } = props;
    if (order.additionalFees) {
      const fee = order.additionalFees.find(
        (fee) =>
          !fee.isWaived && fee.displayName.toLowerCase() === 'delivery fee'
      );
      if (fee) {
        return fee.amount;
      }
    }

    return 0;
  }
);

// Helper to see if vendor is closed
function isVendorClosedToday(vendorClosedDays, daytoCheck) {
  if (!daytoCheck) {
    return false;
  }

  // All days will be represented in Number format, Sunday = 0 to Saturday = 6
  const daytoCheckNumberFormat = moment(daytoCheck).day();
  const vendorClosedDaysInNumberFormat = vendorClosedDays.map(
    (day) => day.dayOfWeekNumber
  );

  // If the day we are checking is included in the array of closed days, then the vendor is closed
  if (vendorClosedDaysInNumberFormat.includes(daytoCheckNumberFormat)) {
    return true;
  } else {
    return false;
  }
}

// same fn as getCreditToUse but does not calculate additionalOrderTotals
export const getCreditToUseFromOrder = createSelector(
  [getCredit, (state, total) => total],
  (credit, total) => {
    if (credit.isLoading || !credit.amount) {
      return 0;
    }

    return credit.amount > total ? total : credit.amount;
  }
);

// same fn as getTotalsWithCredit but does not calculate additionalOrderTotals
export const getTotalsWithCreditFromOrder = createSelector(
  [getCredit, (state, total) => total],
  (credit, total) => {
    if (credit.isLoading) {
      return total;
    }

    return credit.amount >= total ? 0 : total - credit.amount;
  }
);

export const getCreditToUse = createSelector(
  [getCredit, getTotals],
  (credit, additionalOrderTotals) => {
    const total =
      additionalOrderTotals.ordersTotalMinusTaxes +
      additionalOrderTotals.ordersTotalTaxes;
    if (credit.isLoading || !credit.amount) {
      return 0;
    }

    return credit.amount > total ? total : credit.amount;
  }
);

export const getTotalsWithCredit = createSelector(
  [getCredit, getTotals],
  (credit, additionalOrderTotals) => {
    const total =
      additionalOrderTotals.ordersTotalMinusTaxes +
      additionalOrderTotals.ordersTotalTaxes;
    if (credit.isLoading) {
      return total;
    }

    return credit.amount >= total ? 0 : total - credit.amount;
  }
);
