import * as types from '../constants/ActionTypes';
import { AnalyticsAddSupplierSources } from '../constants/AnalyticsEventSources';

export function toggleBuyerSuspendedModal() {
  return {
    type: types.TOGGLE_BUYER_SUSPENDED_MODAL,
  };
}

export function toggleBuyerCreditCardModal() {
  return {
    type: types.TOGGLE_BUYER_CREDIT_CARD_MODAL,
  };
}

export const showAddSingleSupplierModal = (
  show,
  source = AnalyticsAddSupplierSources.ORDER_DESK
) => ({
  type: types.SHOW_ADD_SUPPLIER_MODAL,
  show,
  addSupplierOpenSource: source,
});

export const showAddMultipleSuppliersModal = (show) => ({
  type: types.SHOW_ADD_SUPPLIERS_MODAL,
  show,
});

export function hideOrderIsAlreadyCheckedOutModal() {
  return { type: types.HIDE_ORDER_IS_ALREADY_CHECKED_OUT_MODAL };
}

export function showOrderIsAlreadyCheckedOutModal() {
  return { type: types.SHOW_ORDER_IS_ALREADY_CHECKED_OUT_MODAL };
}
