import React from 'react';
import WarningIcon from '@icons/warning-icon.svg';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { Link } from 'react-router-dom';
import { Button, Loading, Modal, Separator, Typography, toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import InboxIcon from '@ar/assets/icons/inbox-icon.svg';
import { DraftInvoiceModals, useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { useDraftInvoiceUpdateMutation } from '@/ar/hooks/mutations/DraftInvoiceMutations';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { DraftInvoiceStatus } from '@/ar/network/AccountsReceivable.network';

export type DraftInvoiceDuplicateInvoiceNumberConfirmModalProps = {
    onConfirmSubmit: () => void,
    draftInvoiceId: string,
};

export const DraftInvoiceDuplicateInvoiceNumberConfirmModal : React.FC<DraftInvoiceDuplicateInvoiceNumberConfirmModalProps> = ({ onConfirmSubmit, draftInvoiceId }) => {
    const { supplierLoginData } = useSupplierStore();
    const { modals, closeModal } = useDraftInvoiceStore();
    const draftInvoiceUpdateMutation = useDraftInvoiceUpdateMutation();

    const { invoice } = modals;

    const handleConfirmButtonClick = () => {
        onConfirmSubmit();
        closeModal(DraftInvoiceModals.DuplicateInvoiceNumberConfirmModal);
    };

    const handleOnClose = () => {
        closeModal(DraftInvoiceModals.DuplicateInvoiceNumberConfirmModal);
    };

    const handleArchiveDraftButtonClick = async () => {
        draftInvoiceUpdateMutation.mutate({
            draftInvoiceID: draftInvoiceId,
            supplierID: supplierLoginData?.supplier_id,
            body: {
                status: DraftInvoiceStatus.Archived,
            },
        }, {
            onSuccess: async () => {
                toast.show({
                    message: 'Draft Invoice has been archived',
                    icon: <CheckIcon/>,
                });
                handleOnClose();
            },
            onError: () => {
                toast.show({
                    message: 'Failed to archive draft invoice',
                });
            }
        });
    };

    if (!invoice) return null;

    return <Modal isOpen={modals[DraftInvoiceModals.DuplicateInvoiceNumberConfirmModal]}
        onClose={handleOnClose}
        title={<div className="flex items-center gap-4">
            <WarningIcon className="w-8 h-8 text-orange-400 bg-orange-100 p-2 rounded" />
            <Typography className="m-0 font-medium" variant="LG-2">
                Existing invoice detected
            </Typography>
        </div>}
        headerPadding="mt-0 mb-5"
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[400px]">
        <Typography as="div" className="px-5 pb-10 text-gray-700">
            We&apos;ve noticed that an invoice with{' '}
            <Link target="_blank" to={`/${ARRoutePaths.AR_INVOICES}/${invoice.id}`} className="text-blue-500">this number</Link>{' '}
            already exists in our system. You can save & publish this draft to push an update to the existing invoice or archive this draft.
        </Typography>
        <Separator className="my-4"/>

        <div className="flex items-center gap-4 ml-auto w-full px-5">
            <Button
                disabled={draftInvoiceUpdateMutation.isLoading}
                onClick={handleArchiveDraftButtonClick}
                variant="TERTIARY_FILLED"
                size="SMALL"
                className="ml-auto"
                minWidth="w-auto">
                <div className="flex items-center gap-2">
                    {draftInvoiceUpdateMutation.isLoading ? <Loading className="ml-auto" isDark/> : <InboxIcon className="w-4 h-4 mt-0.5 "/>} Archive Draft
                </div>
            </Button>
            <Button
                disabled={draftInvoiceUpdateMutation.isLoading}
                onClick={handleConfirmButtonClick}
                variant="SECONDARY"
                size="SMALL"
                minWidth="w-auto">
                Save & update invoice
            </Button>
        </div>
    </Modal>;
};
