import moment from 'moment';

import * as GlobalConstants from './constants/GlobalConstants';
import { logException } from './domains/shared/logger';

function Utils() {}


Utils.QUANTITY_TYPES = {
    INCREASE: 'INCREASE',
    DECREASE: 'DECREASE',
    CUSTOM: 'CUSTOM',
}

Utils.changeQuantityHelper = (oldQuantity, type) => {
  let newQuantity = 0;

  switch (type) {
    case Utils.QUANTITY_TYPES.INCREASE:
      newQuantity = Math.floor(oldQuantity) + 1;
      break;

    case Utils.QUANTITY_TYPES.DECREASE:
      newQuantity = Math.floor(oldQuantity) - 1;
      break;

    case Utils.QUANTITY_TYPES.CUSTOM:
      // Remove non-numeric characters
      let quantity = oldQuantity.toString().replace(/[^0-9\-]/g, '');

      if (!quantity) {
        quantity = '0';
      }
      // convert quantity from string to integer
      newQuantity = parseInt(quantity, 10);
      break;
  }

  // If the quantity ends up being negative, then change it to 0 immediately
  // so it can be deleted
  if (newQuantity < 0) {
    newQuantity = 0;
  }

  return newQuantity;
};

/*
 ** Check If any of the items are have isPreOrder flag and are being delivered Today or Tomorrow.
 */
Utils.checkIfPreOrder = (order, deliveryDay) => {
  if (!order) {
    return false;
  }

  return Utils.isDateToday(deliveryDay) || Utils.isDateTomorrow(deliveryDay)
    ? order.items.some((i) => i.isPreOrder)
    : false;
};

/*
 ** Sleep for ms
 */
Utils.sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Return the Order and OrderProduct given orders, vendorUrlsafe and OrderProduct's sourceUrlsafe.
 */
Utils.getOrderAndOrderProductForCode = (
  orders,
  vendorUrlsafe,
  sourceUrlsafe
) => {
  let foundOrderProduct = null;
  let foundOrder = null;

  for (const order of orders) {
    if (order && order.vendorUrlsafe === vendorUrlsafe) {
      foundOrder = order;

      for (const orderProduct of order.items) {
        if (orderProduct.sourceUrlsafe === sourceUrlsafe) {
          foundOrderProduct = orderProduct;
          break;
        }
      }
    }
  }

  return { order: foundOrder, orderProduct: foundOrderProduct };
};

/**
 * Helper to return the Order that contains the OrderProduct with specifid sourceUrlsafe
 * @param {*} orders
 * @param {*} orderProductSourceUrlsafe
 */
Utils.getOrderContainingOrderProductUrlsafe = (
  orders,
  orderProductSourceUrlsafe
) => {
  return orders.find((o) =>
    o.items.find((i) => i.sourceUrlsafe === orderProductSourceUrlsafe)
  );
};

/*
 ** Formats inputDate (of type String or moment object) using the provided format string pattern
 */
Utils.formatDate = (inputDate, format = 'YYYY-MM-DD') => {
  /**
   * Fix: PLAT-593
   * For delivery_date in invoice data, the timestamp includes HH:MM:SS as always 00:00:00.
   * moment formatting evaluates this as the day before due to timezone conversion.
   * (e.g. 2021-02-06T00:00:00Z => Feb 5, 2021)
   * In order data delivery_date, HH:MM:SS are not included.
   * Removing the end Z in these cases formats to the expected date
   * as it prevents moment from doing timezone conversion from UTC
   */
  if (_.isString(inputDate) && inputDate.includes('00:00:00Z')) {
    inputDate = inputDate.slice(0, inputDate.length-1);
  }

  if (_.isString(inputDate) || inputDate instanceof Date) {
    return moment(inputDate).format(format);
  } else if (inputDate instanceof moment) {
    return inputDate.format(format);
  } else {
    return '';
  }
};

/*
 ** a Custom groupBy function, that maintain the original order of the array
 */
Utils.groupByKeepOrder = (arr = [], prop) => {
  const newArr = []; // array to return, keeps track of order
  const wrapObj = {}; // temporary object used for grouping

  arr.forEach((item) => {
    if (item) {
      // gets property name to group by and converts it to a string for grouping purposes
      let propName = item[prop];
      if (propName) {
        propName = propName.toString();

        // checks if group exists already and creates a new group if not,
        // pushing it into the array to return ('newArr')
        if (!wrapObj[propName]) {
          wrapObj[propName] = [];
          newArr.push(wrapObj[propName]);
        }

        // adds item to the group
        wrapObj[propName].push(item);
      } else {
        console.warn(
          'utils -> groupByKeepOrder',
          "Property '" +
            prop +
            "' not found within object. It will be ignored from the output.",
          item
        );
      }
    }
  });

  return newArr;
};

/*
 ** custom groupBy function, return a dictionary with prop as the keys
 */
Utils.dictByKeepOrder = (arr = [], prop) => {
  const wrapObj = {}; // temporary object used for grouping

  arr.forEach((item) => {
    if (item) {
      // gets property name to group by and converts it to a string for grouping purposes
      let propName = item[prop];
      if (propName) {
        propName = propName.toString();

        // checks if group exists already and creates a new group if not,
        // pushing it into the array to return ('newArr')
        if (!wrapObj[propName]) {
          wrapObj[propName] = [];
        }

        // adds item to the group
        wrapObj[propName].push(item);
      } else {
        console.warn(
          'utils -> dictByKeepOrder',
          "Property '" +
            prop +
            "' not found within object. It will be ignored from the output.",
          item
        );
      }
    }
  });

  return wrapObj;
};

/*
 ** return how overdue an invoice is in days or weeks
 */
Utils.getOverdueDuration = (date) => {
  const dueDate = moment.utc(date);
  const today = moment.utc();
  const daysDiff = today.diff(dueDate, 'days');

  if (daysDiff > 7) {
    return daysDiff < 14
      ? today.diff(dueDate, 'weeks') + ' Week'
      : today.diff(dueDate, 'weeks') + ' Weeks';
  } else {
    return daysDiff === 1 ? daysDiff + ' Day' : daysDiff + ' Days';
  }
};

/*
 ** Validate a phone number
 */
Utils.isPhoneNumberValid = (phoneNumber) => {
  const phoneNumberRegex =
    /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  return phoneNumberRegex.test(phoneNumber);
};

/**
 * Return the minimum order amount for the specified Vendor
 */
Utils.getMinimumOrderAmount = (order, vendor) =>
  vendor ? parseInt(vendor.region.minimumOrderAmount) : 0;

/*
 ** Determined if user is logged in
 */
Utils.isAuthed = () => {
  const sessionKey = window.localStorage.getItem('chefhero_sk');
  const refreshToken = window.localStorage.getItem('chefhero_rt');
  const expiresAt = window.localStorage.getItem('chefhero_e');

  if (!sessionKey || !refreshToken || !expiresAt) {
    return false;
  }

  if (moment.unix(expiresAt - 60).isBefore(moment())) {
    return false;
  }

  return true;
};

/*
 ** Catch rare exception cases that we want to track
 */
Utils.trackException = (condition, errorMessage) => {
  try {
    if (condition) {
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error(error);
    logException(error);
  }
};

/*
 ** Get URL Params from url and return them as an object of keys
 */
Utils.getParams = (url) => {
  if (!url || !url.split('?')[1]) {
    return {};
  }

  const query = url.split('?')[1];

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

/*
 ** Validate a zip/postal code based on which country it's from
 */
Utils.validatePostalCodeOrZip = (country, zip) => {
  const reZip = /(^\d{5}(-\d{4})?$)/;
  const rePostalCode =
    /(^[ABCEGHJKLMNPRSTVXY-abceghjklmnprstvxy]\d[ABCEGHJKLMNPRSTVWXYZ-abceghjklmnprstvxy]( )?\d[ABCEGHJKLMNPRSTVWXYZ-abceghjklmnprstvxy]\d$)/;
  const isValid =
    country === 'Canada'
      ? rePostalCode.test(zip.trim())
      : reZip.test(zip.trim());
  return isValid;
};
/*
 ** Get productCode and return a backup image (only works for old system)
 */
Utils.getBackupImage = (productCode) => {
  return `http://storage.googleapis.com/chefhero-storage/products/images/${productCode}.png`;
};

/*
 ** Check if date is in the future
 */
Utils.isDateInFuture = (dateString) => {
  return moment(dateString).isAfter();
};

/*
 ** Check if date is in the past
 */
Utils.isDateInPast = (dateString) => {
  return moment(dateString).isBefore();
};

/*
 **  Check if date is today
 */
Utils.isDateToday = (dateString) => {
  return moment().isSame(dateString, 'day');
};

/*
 **  Check if date is tomorrow
 */
Utils.isDateTomorrow = (dateString) => {
  return moment().add(1, 'days').isSame(dateString, 'day');
};

/*
 * Get Delivery Fee for an Order.
 * Use an order's additionalFeesTotal if it exists and we are a peding order.
 * Otherwise, use vendor deliveryFee or fallback defaultDeliverFee
 */
Utils.getDeliveryMinusTax = (order, vendor, isPendingOrder = false) => {
  if (vendor) {
    if (order.additionalFeesTotal > 0 && isPendingOrder) {
      return order.additionalFeesTotal;
    } else {
      return vendor.region.deliveryFee ? vendor.region.deliveryFee : 0;
    }
  }
};

/*
 *  get Delivery Fee Tax for an Order using additionalFeesTaxTotal
 */
// TODO Remove order.additionalFeesTaxTotal when we move Add-on Ordes to V3
Utils.getDeliveryFeeTax = (order) =>
  order.tax || order.additionalFeesTaxTotal || 0;

/*
 **  Validate phone numbers
 */
Utils.validPhoneNumber = (phone) => {
  const phoneRegex =
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

  return phoneRegex.test(phone);
};

Utils.getProgressBarFillWidth = (progressPercentage) => {
  let progressFillWidth = 0;

  if (progressPercentage < 11) {
    progressFillWidth = 5;
  } else if (progressPercentage > 90 && progressPercentage < 100) {
    progressFillWidth = 90;
  } else {
    const roundingValue = 5;
    const multiplier = Math.floor(progressPercentage / roundingValue);
    progressFillWidth = multiplier * roundingValue;
  }

  return progressFillWidth;
};

// Returns the current index of the nextAvailableDeliveryDays based on the order deliveryDay
Utils.findSelectedDeliveryDayOptionIndex = (
  deliveryDay,
  nextAvailableDeliveryDays
) => {
  let selectedItemIndex = -1;

  if (!deliveryDay) {
    return -1;
  }

  if (Utils.isDateInPast(deliveryDay)) {
    return -1;
  } else if (nextAvailableDeliveryDays.length > 0) {
    // Find the index from nextAvailableDeliveryDays looking at the first 3 dates in the array
    selectedItemIndex = nextAvailableDeliveryDays
      .slice(0, 3)
      .map((d) => Utils.formatDate(d.date))
      .findIndex((date) => date === deliveryDay);

    // Otherwise, a custom date was selected
    if (selectedItemIndex === -1) {
      selectedItemIndex = 3;
    }
  }

  return selectedItemIndex;
};

// Checks if object is empty (no keys) or all values are falsy (incliuding 0 value)
Utils.isObjectEmptyOrValuesFalsy = (inputObject) => {
  if (
    _.isEmpty(inputObject) ||
    Object.values(inputObject).every(
      (val) =>
        !val ||
        _.isEmpty(val) ||
        (val.constructor === Array && val.length === 0)
    )
  ) {
    return true;
  }
  return false;
};

// Returns class name based on whether selected or not for filters
Utils.getVendorFilterSelectionClassName = (
  vendorId,
  vendorName,
  selectedVendors = []
) => {
  const isSelected = selectedVendors.some((v) => v.vendorID === vendorId);

  if (isSelected || (vendorId === 0 && selectedVendors.length === 0)) {
    return 'selected';
  }

  return null;
};

Utils.getCategoryFilterSelectionClassName = (
  category,
  selectedCategories = [],
  filters
) => {
  const isSelected = selectedCategories.some((c) => category === c);
  const isEnabled =
    filters.enabledCategories.length > 0
      ? filters.enabledCategories.find((c) => c.key === category)
      : true;

  if (isSelected || (category === 0 && selectedCategories.length === 0)) {
    return 'selected';
  }

  if (!isEnabled && category !== 0) {
    return 'disabled';
  }

  return null;
};

// Temporarily map new orderguide product data to old order product data.
// Once we move to the new data fully we should remove this conversion method
Utils.convertToOldProductData = (product, buyerProductVariant) => {
  let oldProduct = {};

  if (!_.isEmpty(product) && !_.isEmpty(buyerProductVariant)) {
    const vendorProductVariant =
      buyerProductVariant.productVariantUnit.vendorProductVariant;

    oldProduct = {
      category: product.categories ? product.categories : [],
      fromNewSystem: true,
      genericItem: {
        categories: product.categories ? product.categories : [],
        description: product.description ? product.description : '',
        id: vendorProductVariant.id,
        imageURL: product.images ? product.images : [],
        name: product.name ? product.name : '',
        urlsafe: product.id,
      },
      genericItemKey: product.id,
      id: vendorProductVariant.legacyId,
      inStock: vendorProductVariant.availability,
      name: product.name ? product.name : '',
      preOrder: vendorProductVariant.preOrderIn > 0 ? true : false,
      price: vendorProductVariant.price,
      pricePerStandardUnit: vendorProductVariant.pricePerStandardUnit
        ? vendorProductVariant.pricePerStandardUnit
        : 0,
      pricelistName: 'New System',
      productCode: product.id,
      productDescription: product.description ? product.description : '',
      productName: product.name ? product.name : '',
      productVariantUnitId: buyerProductVariant.productVariantUnit.id,
      split: vendorProductVariant.split,
      standardUnit: vendorProductVariant.salesUnitId
        ? vendorProductVariant.salesUnitId
        : '',
      taxable: product.taxCode,
      unit: buyerProductVariant.productVariantUnit.packaging,
      unitDescription: buyerProductVariant.productVariantUnit.description,
      urlsafe: vendorProductVariant.id,
      variantCode: buyerProductVariant.productVariantUnit.id,
      variantExternalCode: vendorProductVariant.externalId,
      vendorID: vendorProductVariant.vendorId,
      vendorKey: vendorProductVariant.vendorKeyUrlsafe,
      vendorName: vendorProductVariant.vendorName,
    };
  }

  return oldProduct;
};

Utils.getOrderGuideForVariant = (variant, orderGuideItems) => {
  for (const item of orderGuideItems) {
    for (const buyerProduct of item.buyerProducts) {
      if (variant.productVariantUnitId === buyerProduct.productVariantUnitId) {
        return buyerProduct.buyerProductOrderGuides[0];
      }
    }
  }
  return null;
};

// Converts a standard unit quantity to Kg quantity
Utils.convertStandQtyToKg = (quantity, vendorProductVariant) => {
  const { price, pricePerStandardUnit, standardUnit } = vendorProductVariant;

  if (standardUnit.includes('lb') && quantity) {
    const qtyPerStandardUnit = price / pricePerStandardUnit;
    return Math.round(
      quantity * qtyPerStandardUnit * GlobalConstants.LBS_TO_KGS
    );
  } else if (standardUnit.includes('kg') && quantity) {
    const qtyPerStandardUnit = price / pricePerStandardUnit;
    return Math.round(quantity * qtyPerStandardUnit);
  }
  return quantity;
};

// Converts a Kg quantity to the standard unit quantity
Utils.convertKgQtyToStandUnit = (
  quantity,
  vendorProductVariant,
  roundingFunc = Math.ceil
) => {
  const { standardUnit, pricePerStandardUnit, price } = vendorProductVariant;

  if (standardUnit.includes('lb')) {
    const qtyPerStandardUnit = price / pricePerStandardUnit;
    return roundingFunc(
      (quantity / qtyPerStandardUnit) * GlobalConstants.KG_TO_LBS
    );
  } else if (standardUnit.includes('kg')) {
    const qtyPerStandardUnit = price / pricePerStandardUnit;
    return roundingFunc(quantity / qtyPerStandardUnit);
  }
  return quantity;
};

/*
    Calculating original price using formula:
    originalPrice = currentPrice/((100 - percentOff)/100)
    which simplifies to:
    originalPrice = (currentPrice*100)/(100 - percentOff)
    (This formula is required because percentOff is returned as a whole number, not decimal)
*/
Utils.calculateOriginalPrice = (variant) => {
  return parseFloat((variant.price * 100) / (100 - variant.percentOff));
};

// Determines if it's after cutofftime (-1 one hour) for a specific delivery day (used for AddOn order editing)
Utils.isAfterDeliveryDayCutOffTime = (vendor = {}, order = {}) => {
  if (
    !vendor ||
    _.isEmpty(vendor) ||
    !order ||
    _.isEmpty(order) ||
    !order.deliveryDay ||
    order.isInShoppingCart
  ) {
    return null;
  }

  // We form the cutOff Date time based on the day before the delivery day and vendor cutoff time
  const cutOffDateTime = Utils.getDeliveryDayCutOffDateTime(vendor, order);

  // Compare to see if we are after the cutOff datetime
  return moment().isAfter(cutOffDateTime);
};

// get cutofftime for a specific delivery day (used for AddOn order editing)
Utils.getDeliveryDayCutOffDateTime = (vendor = {}, order = {}) => {
  return moment(order.cutoffDatetime);
};

// Correctly return the URI component in a more usable format
Utils.formatURIComponent = (uriComponent, shouldReturnBool = false) => {
  if (!uriComponent) {
    if (shouldReturnBool) {
      return false;
    }
    return '';
  }

  const stringURIComponent = decodeURIComponent(uriComponent);

  switch (stringURIComponent) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return stringURIComponent;
  }
};

Utils.getFormattedPaymentType = (paymentType, paymentMethodType) => {
  switch ((paymentType || '').toUpperCase()) {
    case 'BALANCE':
      switch ((paymentMethodType || '').toUpperCase()) {
        case 'ACCOUNT_CREDITS':
          return 'Account Credit';
        case 'CARD':
          return 'Credit';
        case 'EFT':
        case 'ACH':
        case 'BANK':
        case 'PADS':
          return 'Bank';
        default:
          return 'Payment';
      }
    case 'ACCOUNT_CREDIT':
      return 'Account Credit';
    case 'CASH':
      return 'Cash';
    case 'CHEQUE':
      return 'Cheque';
    case 'EFT':
    case 'ACH':
      return 'Bank';
    default:
      return 'Credit';
  }
};

// Used to show animation when an item is added to the sidebar
Utils.animateCart = (element) => {
  const backgroundColor = '#8963B0';
  // This scroll to the particular orderItem
  element.scrollIntoView({ behavior: 'smooth' });

  // Here we change the background color immediately, we then slowly return back
  // to the original colors over 2 seconds. We use .animate() because we need a callback method
  // before we run the transition back to the original colors
  $(element).animate({ backgroundColor, color: 'white' }, 0, () => {
    $(element).animate(
      { backgroundColor: '#FFFFFF', backgroundColor: 'black' },
      1000
    );
  });

  // This is needed becaues the product name is nested deeper into the DOM, and we need it
  // to change the color of its font.
  $(element)
    .children('.name')
    .children('.product-name')
    .animate({ color: 'white' }, 0, () => {
      $(element)
        .children('.name')
        .children('.product-name')
        .animate({ color: '#676a6c' }, 1000);
    });

  // Prevent scrollbar from orderguide from showing
  // after adding an item.
  $('body').css('overflow', 'hidden');
};

/**
 * Returns true if vendor has a set region.
 * @param vendor
 * @returns {boolean}
 */
Utils.hasVendorRegion = (vendor) => vendor && vendor.region;

/**
 * Returns true if vendor has a minimum order amount set.
 * @param vendor
 * @returns {*|boolean}
 */
Utils.hasVendorMinimumOrderAmount = (vendor) =>
  Utils.hasVendorRegion(vendor) && vendor.region.minimumOrderAmount >= 0;

/**
 * Returns true if vendor has a delivery fee amount set.
 * @param vendor
 * @returns {boolean}
 */
Utils.hasVendorDeliveryFee = (vendor) =>
  Utils.hasVendorRegion(vendor) && vendor.region.deliveryFee > 0;

/**
 * Returns true if the provided vendor has a region with a minimumOrderAmount and a deliveryFee
 */
Utils.hasVendorMinimumOrderAmountAndDeliveryFee = (vendor) =>
  Utils.hasVendorMinimumOrderAmount(vendor) &&
  Utils.hasVendorDeliveryFee(vendor);

/**
 * Returns a list of all valid emails from the input string
 */
Utils.getAllEmails = (input) =>
  (input || '').match(/(?:[\w.%+-]+)@(?:[\w-]+\.)+(?:[\w]{2,})/gi) || [];

/**
 * Matches an e-mail
 */
Utils.isEmailValid = (input) =>
  (input || '').match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/**
 * Matches an e-mail or a separator (space, comma or semicolon)
 */
Utils.isEmailOrSeparatorValid = (input) =>
  (input || '').match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})|[ ,;]$/i);

/**
 * Returns the CSS name class for the orderStatus
 */
Utils.getOrderStatusClass = (orderStatus) =>
  orderStatus.replace(/\s|'/g, '').toLowerCase();

/**
 * Returns true if orderStatus is in processing status
 */
Utils.isOrderStatusProcessing = (orderStatus) =>
  orderStatus.toLowerCase() === 'processing';

Utils.getMomentDateFromJsDate = (datetime, keepLocalTime = true) => {
  if (!datetime) return '';

  const year = datetime.getFullYear();
  const month = datetime.getMonth();
  const day = datetime.getDate();
  const hours = datetime.getHours();
  const minutes = datetime.getMinutes();
  const seconds = datetime.getSeconds();
  const ms = datetime.getMilliseconds();

  return moment(new Date(year, month, day, hours, minutes, seconds, ms)).utc(
    keepLocalTime
  );
};

/**
 * Trims the start of the string of spaces, commas or semicolons
 */
Utils.trimStartSeparators = (str) => (str || '').replace(/^[ ,;]*/, '');

/**
 * Return the position of the first space, comma or semicolon separator
 */
Utils.firstSeparatorPosition = (str) => (str || '').search(/[ ,;]/);

/**
 * Return the formatted input as a currency string with {minimumFractionDigits} fraction digits
 * @param {*} num Input number
 * @param {*} options Option to set minimumFractionDigits and maximumFractionDigits (default = 2)
 */
Utils.formatAsCurrency = (
  num,
  { minimumFractionDigits = 2, maximumFractionDigits = 2 } = {}
) => {
  if (isNaN(num)) {
    return num;
  }

  const currencySymbol = '$';
  const localeName = 'en-US';

  return `${currencySymbol}${parseFloat(num).toLocaleString(localeName, {
    minimumFractionDigits,
    maximumFractionDigits,
  })}`;
};

/** Function is used when ordersTotalMinusTaxes < Credits aka (unusedAmount)
 * for example:
 *   Buyer’s credit = 100$
 *   Apple = 10$
 *   Tax=2$
 *   ________
 *   (credit=10$)
 *   ________
 *   Total for user to pay = 2$ , Buyer’s credit after = 90$
 */
Utils.getExactCredit = (orderSubTotal, totalCredit) => {
  const remainingCredit = Math.abs(orderSubTotal - totalCredit);
  return totalCredit - remainingCredit;
};
/**
 * Returns TRUE if percentChance has truthy value, otherwise returns FALSE
 * @param {*} percent - number that sets the percentage of button showing up
 */
Utils.showByChance = (percent) => {
  const percentChance = Math.random() <= percent / 100;
  return percentChance;
};

/**
 *  Returns TRUE if the supplied impersonator object has email or id with the value that includes '@chefhero', otherwise FALSE
 * @param {*} impersonator - an object that contains email and id properties
 */
Utils.checkIfChefHeroImpersonator = ({ email = '', id = '' }) => {
  return email.includes('@chefhero') || id.includes('@chefhero');
};

Utils.lowercaseSentence = (sentence) => {
  if (!sentence) {
    return '';
  }

  const words = sentence.split(' ').map((word, i) => {
    if (i > 0) {
      return word.toLowerCase();
    }

    return word;
  });

  return words.join(' ');
};

Utils.getTagTypePerStatus = (status) => {
  if (!status) {
    return 'rejected';
  }

  switch (status.toLowerCase()) {
    case 'processing':
    case 'order sent':
    case 'order viewed':
    case 'in shopping cart':
      return 'placed';

    case "today's delivery":
      return 'updated';

    case 'delivered':
    case 'invoiced':
    case 'paid':
      return 'fulfilled';

    case 'unpaid':
      return 'unpaid';

    default:
      return 'rejected';
  }
};

Utils.roundOff2 = (value) => {
  return (Math.round((Number(value) + Number.EPSILON) * 100) / 100).toFixed(2);
};

Utils.debounce = (callback, timeout) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), timeout);
  };
};

Utils.debounceWithCancel = (callback, timeout) => {
  let timer;
  const debouncedCallBack = (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
  const cancel = () => {
    clearTimeout(timer);
  };
  return [debouncedCallBack, cancel];
};

Utils.getInputQuantityOptions = (length = 100) =>
  new Array(length).fill(0).map((val, idx) => {
    return {
      value: (idx + 1).toString(),
      label: (idx + 1).toString(),
    };
  });

Utils.orderInvoiceToOrderPayment = (order) => {
  return {
    orderId: order.order_id,
    payments: order.external_id ? [{
      id: order.external_id,
      status: order.invoice_status,
      paymentMethod: order.payment_method_type,
      paymentMethodBrand: order.payment_method_brand
    }] : []
  };
};

export default Utils;
