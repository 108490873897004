import * as types from '../constants/ActionTypes';

const emptySingleVendor = {
  customer: {
    allowOrderingWeekendForMonday: false,
    deliveryFee: '',
    vendorStatus: 2,
    sendPOBy: '',
    salesRepPhone: '',
    minimumOrderAmount: '',
    isBYOS: false,
    externalRef: '',
    salesRepEmail: '',
    createdAt: '',
    operationalDays: '',
    vendorType: '',
  },
  isBYOS: false,
  name: '',
  region: {
    deliveryTimeWindows: [],
    deliveryFee: 0.0,
    cutOffTime: '00:00:00',
    minimumOrderAmount: 0.0,
    operationalDays: {
      monday: { isActive: false },
      tuesday: { isActive: false },
      friday: { isActive: false },
      wednesday: { isActive: false },
      thursday: { isActive: false },
      sunday: { isActive: false },
      saturday: { isActive: false },
    },
    nextAvailableDeliveryDays: [],
    id: '',
  },
  urlsafe: '',
  type: '',
  id: '',
  isTesting: false,
};

const initialState = {
  vendors: [],
  singleVendor: emptySingleVendor,
  singleVendorStore: {},
  singleVendorCategory: [],
  isStorefrontLoading: false,
  isCategoryLoading: false,
  isProductDetailModalOpen: false,
  paymentMethods: [],
  isLoadingPaymentMethods: false,
};

export default function vendorsReducer(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_VENDORS':
      return Object.assign({}, state, {
        vendors: action.vendors,
      });

    case 'RECEIVE_SINGLE_VENDOR':
      return Object.assign({}, state, {
        singleVendor: action.singleVendor,
      });

    case 'TOGGLE_PRODUCT_DETAIL_MODAL':
      return {
        ...state,
        isProductDetailModalOpen: action.isProductDetailModalOpen,
      };

    case types.FETCH_VENDOR_PAYMENT_METHODS_PENDING:
      return {
        ...state,
        isLoadingPaymentMethods: true,
        paymentMethods: [],
      };

    case types.FETCH_VENDOR_PAYMENT_METHODS_FULFILLED:
      const { paymentMethods } = action.payload;
      return {
        ...state,
        isLoadingPaymentMethods: false,
        paymentMethods,
      };

    case types.FETCH_VENDOR_PAYMENT_METHODS_REJECTED:
      return {
        ...state,
        isLoadingPaymentMethods: false,
        paymentMethods: [],
      };

    default:
      return state;
  }
}
