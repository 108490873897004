import { useEffect, useState } from 'react';
import Utils from '../../../utils';

export const getIsMobileWidth: () => boolean = () => window.innerWidth <= 1024;

export default function useIsMobileWidth(): boolean {
    const [isMobileWidth, setIsMobileWidth] = useState(getIsMobileWidth());

    useEffect(() => {
        const onResize = (): void => {
            setIsMobileWidth(getIsMobileWidth());
        };

        window.addEventListener('resize', Utils.debounce(onResize, 100));

        return (): void => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return isMobileWidth;
}
