import { Filesystem } from '@capacitor/filesystem';
import { GalleryPhoto, Photo } from '@capacitor/camera/dist/esm/definitions';

type TPhotoGallery = GalleryPhoto | Photo;

/**
 *
 * Convert a TPhotoGallery type from capacitor object to blob
 *
 * @param photo  TPhotoGallery type
 */
export const convertPhotoUriToBlob = async (photo: TPhotoGallery): Promise<Blob> => {
    const contents = await Filesystem.readFile({
        path: photo.path,
    });
    const base64Data = contents.data;
    return fetch(`data:image/jpeg;base64,${base64Data}`).then((r) => r.blob());
};

/**
 *
 * Convert a TPhotoGallery type from capacitor object to file
 *
 * @param photo TPhotoGallery type
 */
export const convertPhotoUriToFileObject = async (photo: TPhotoGallery, type = 'image/jpeg'): Promise<File> => {
    const blob = await convertPhotoUriToBlob(photo);
    return new File([blob], photo.path, { type });
};
