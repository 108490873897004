import LogRocket from 'logrocket';

export const identifyUserForLogging = ({ user, buyer }) => {
  LogRocket.identify(user.id, {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    restaurant: buyer.name,
    buyerID: buyer.id,
  });
};

export const logException = (exception) => {
  LogRocket.captureException(exception);
};

export const logMessage = (message) => {
  LogRocket.captureMessage(message);
};

export const startNewSession = () => {
  LogRocket.startNewSession();
};
