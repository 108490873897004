export const formatAsCurrency = (
    num,
    { minimumFractionDigits = 2, maximumFractionDigits = 2 } = {},
): string => {
    if (Number.isNaN(num)) {
        return num;
    }

    const currencySymbol = '$';
    const localeName = 'en-US';

    const formattedNum = parseFloat(num).toLocaleString(localeName, {
        minimumFractionDigits,
        maximumFractionDigits,
    });

    if (num < 0) {
        return `-${currencySymbol}${formattedNum.slice(1)}`;
    }

    return `${currencySymbol}${formattedNum}`;
};

/**
 *
 * Converts cents to dollars
 *
 * @param cents - Amount in cents to be converted
 */
export const centsToDollars = (cents: number | null): number => {
    if (!cents || Number.isNaN(cents) || typeof cents !== 'number') {
        return 0;
    }
    return cents / 100;
};

/**
 * Converts dollars to cents
 *
 * @param dollars - Amount in dollars to be converted
 */

export const dollarsToCents = (dollars: number): number => {
    if (!dollars || Number.isNaN(dollars) || typeof dollars !== 'number') {
        return 0;
    }
    return Number((dollars * 100).toFixed(2));
};

export const formatPhoneNumber = (phoneNumber: string): string => {
    // Filter only numbers from the input
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');

    // Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        // Remove the matched extension code
        // Change this to format for any country code.
        const intCode = (match[1] ? '+1 ' : '');
        return `${intCode}(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return phoneNumber ?? '';
};
