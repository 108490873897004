import { SupplierLoginData } from '@ar/stores/SupplierStore';
import { GPOMembership } from '@/ar/network/AccountsReceivable.network';

export function getGpoData(gpoMemberships: GPOMembership[]): GPOMembership {
    const gpo = gpoMemberships[0];
    if (!gpo) {
        return undefined;
    }
    return { ...gpo, name: `${gpo.name} Admin` };
}

export function getGpoId(supplierLoginData: SupplierLoginData) {
    return supplierLoginData?.gpoMemberships?.[0]?.id;
}
