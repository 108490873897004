/* eslint-disable class-methods-use-this */
import { createSupplier } from '@v2/network/CoreAPI';
import { createProduct, getLinkedSupplierCatalogs, getSupplierCatalogs, updateSupplierCatalog } from '@v2/network/SecretShopAPI';
import chalk from 'chalk';
import { removeSupplier } from '@v2/network/LegacyAPI';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { getBuyerKey, getState } from '@/api/DataAPI';

/**
 * Easy functions to cheat things
 */
export class Debug {
    public supplierCatalogs = {
        markCatalogsAsDiscoverable: this.markCatalogsAsDiscoverable.bind(this),
        createNewSupplierCatalog: this.createNewSupplier.bind(this),
        removeAllSuppliers: this.removeAllSuppliers.bind(this),
        createProducts: this.createProducts.bind(this),
    };

    public constructor() {
        window.Debug = {
            supplierCatalogs: this.supplierCatalogs,
            clearOnboarding: this.clearOnboarding.bind(this),
            clearCreditCard: this.clearCreditCard.bind(this),
        };
    }

    private log(...args): void {
        console.log(chalk.green.bgBlack(...args));
    }

    private clearOnboarding(): void {
        useOnboardingStore.persist.clearStorage();
        window.location.reload();
    }

    private clearCreditCard(): void {
        const { buyer } = getState().buyerReducer;
        delete buyer.stripeCustomerID;
        this.log('Credit Card info has been removed for this session.');
        this.removeAllSuppliers();
    }

    private markCatalogsAsDiscoverable(limit = 100): void {
        getSupplierCatalogs({ limit }).then((data) => {
            const ids = data.results.map((catalog) => catalog.id);
            for (let i = 0; i < ids.length; i++) {
                updateSupplierCatalog(ids[i])
                    .then((res) => {
                        this.log(`Catalog for ${res.supplierName} is now discoverable`, res.id);
                    });
            }
        });
    }

    private createNewSupplier(name: string, email: string, phone?: string): void {
        createSupplier(getBuyerKey(), { name, email, phone })
            .then((res) => {
                this.log('Created supplier: ', JSON.stringify(res));
            });
    }

    private removeAllSuppliers(): void {
        getLinkedSupplierCatalogs(getBuyerKey())
            .then(({ supplierCatalogs }) => {
                supplierCatalogs.forEach((catalog) => {
                    removeSupplier(catalog.supplierUrlsafeKey)
                        .then(() => {
                            this.log('Removed ', catalog.supplierName);
                        });
                });
            });
    }

    private async createProducts(count: number, catalogID: string): Promise<void> {
        for (let i = 0; i < count; i++) {
            const product = await createProduct({
                name: `Product ${i}`,
                category: 'Packaging',
                packages: [{
                    sku: `sku${Math.random() * 1000}`,
                    unit: 'each',
                    prices: [{
                        pricePerUnit: i
                    }]
                }],
            }, catalogID);
            this.log('Create product: ', product);
        }
    }
}
