const initialState = {
  error: false,
  errorMessage: [],
};

export default function errorsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_ERROR':
      return Object.assign({}, state, {
        error: action.error,
        errorMessage: action.errorMessage,
      });

    case 'REMOVE_ERROR':
      return Object.assign({}, state, {
        error: action.error,
        errorMessage: action.errorMessage,
      });

    default:
      return state;
  }
}
