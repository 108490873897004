import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import orderguideEditorStore from './orderguideEditorStore';
import orderguideEditorSidebar from './orderguideEditorSidebar';
import changeUnitReducer from './changeUnitReducer';
import checkoutReducer from './checkoutReducer';
import ordersReducer from './ordersReducer';
import orderDeskReducer from './orderDeskReducer';
import orderHistoryReducer from './orderHistoryReducer';
import settingsReducer from './settingsReducer';
import buyerReducer from './buyerReducer';
import accountReducer from './accountReducer';
import vendorsReducer from './vendorsReducer';
import searchReducer from './searchReducer';
import orderInvoicesReducer from './orderInvoicesReducer';
import singleOrderGuideReducer from './singleOrderGuideReducer';
import cartReducer from './cartReducer';
import modalsReducer from './modalsReducer';
import configReducer from './configReducer';
import errorsReducer from './errorsReducer';
import chatReducer from '../domains/Chat/reducers';
import receivingReducer from '../domains/ReceivingTool/reducers';
import feature from './featureReducer';
import orderDetailsReducer from '../domains/OrderDetails/reducers';
import suppliersReducer from "@/reducers/suppliersReducer";

const AppReducer = combineReducers({
  orderguideEditorStore,
  orderguideEditorSidebar,
  changeUnitReducer,
  checkoutReducer,
  ordersReducer,
  orderDeskReducer,
  orderHistoryReducer,
  settingsReducer,
  buyerReducer,
  accountReducer,
  vendorsReducer,
  suppliersReducer,
  searchReducer,
  orderInvoicesReducer,
  singleOrderGuideReducer,
  cartReducer,
  errorsReducer,
  modalsReducer,
  configReducer,
  routing: routerReducer,
  form: formReducer,
  toastr: toastrReducer,
  chat: chatReducer,
  feature,
  receiving: receivingReducer,
  orderDetails: orderDetailsReducer,
});

export default AppReducer;
