/*
 ** Segment for Analytics
 */
export const init = () => {
  const segmentSnippet = `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
    analytics.load('${process.env.SEGMENT_ID}');
    analytics.page();
    }}();`;

  /* eslint-disable */
  (function () {
    var e = document.createElement('script'),
      t = document.getElementsByTagName('head')[0];
    (e.innerHTML = `${segmentSnippet}`), t.parentNode.appendChild(e);
  })();
  /* eslint-enable */
};

export const identify = (id, data) => {
  if (window.analytics) {
    analytics.identify(id, data);
  }
};

export const track = (eventName, properties) => {
  if (window.analytics) {
    analytics.track(eventName, properties);
  }
};

export const alias = (userId, previousId) => {
  if (window.analytics) {
    analytics.alias(userId, previousId);
  }
};

export const reset = () => {
  if (window.analytics) {
    analytics.reset();
  }

  if (window.mixpanel && window.mixpanel.cookie) {
    window.mixpanel.cookie.clear();
  }
};
