export const KG_CONVERSION_BUYER_IDS = [
  'ahVzfnByb2R1Y2VoZXJvLXByb2plY3RyEgsSBUJ1eWVyGICAwO7C3YAJDA',
  'ahJkfmNoZWZoZXJvLXJlbGVhc2VyEgsSBUJ1eWVyGICAwO7C3YAJDA',
];

export const KG_TO_LBS = 2.20462;

export const LBS_TO_KGS = 0.453592;

export const MY_ORDERS_QUERY_LIMIT = 20;

export const DAYS_OF_THE_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const BYOS = 'byos';

export const MARKETPLACE = 'marketplace';

export const ADD_A_NEW_PRODUCT = 'Add a new product';

export const SEARCH_ADD_ITEM_AUTO_SUGGESTIONS_LIMIT = 10;

export const USER_SIGNING_UP = 'USER_SIGNING_UP';

export const CHEFHERO_APP_LINK_IOS =
  'https://apps.apple.com/us/app/chefhero/id1067130489';
export const CHEFHERO_APP_LINK_ANDROID1 =
  'https://play.google.com/store/apps/details?id=com.vendorhero.storefront';
export const CHEFHERO_APP_LINK_ANDROID2 =
  'intent://scan/#Intent;scheme=chefhero://open;package=com.vendorhero.storefront;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.vendorhero.storefront;end';

// How many chat messages we should load by default per chat channel
export const CHAT_MESSAGES_HISTORY_LIMIT = 50;

// How many mseconds we should wait before we trigger the remote message loading request
// This wait is required as we don't want to fire the event on every scroll movement
export const CHAT_MESSAGES_HISTORY_PAGE_LOADING_IN_MSECONDS = 1000;

// How many mseconds we should wait before we try to update the last message in channel list body
// This wait is required as batch the dispatch for channel and its messages
export const CHAT_CHANNEL_LAST_MESSAGE_LOADING_IN_MSECONDS = 1000;

// How many mseconds we should wait before we select the top message on initial load.
// This wait is required as channels are coming one by one from twilios "channelAdded" event
export const CHAT_CHANNEL_SELECTED_MESSAGE_LOADING_IN_MSECONDS = 3000;

// How many mseconds we should wait before we return after creating the new chat channel.
// This wait is required as "channelAdded" event gets fired from twilio and we receive the channel to proceed with rest of the operations, like sending a message
export const CHAT_DELAY_AFTER_NEW_CHANNEL_CREATION_IN_MSECONDS = 3000;

// How many mseconds we should wait before we make the chat channels or empty chat visible on UI, if any.
export const CHAT_MAIN_LOADING_DELAY_IN_MSECONDS = 1000;

// How many mseconds we should wait before we update the unread messages count on UI, if any.
// This wait is required, as Twilio updates unread counter for the message sent by user who sent it.
// So, before we mark it as consumed this wait allows us not to show that unread counter increment on the UI.
export const CHAT_UNREAD_COUNT_UPDATE_DELAY_IN_MSECONDS = 1000;

export const CHAT_MEDIA_MESSAGE_TEXT = '[Media Message]';
export const CHAT_CHANNEL_TEMP_NAME_STARTS_WITH = 'LOCAL_CH';
export const CHAT_COUNTRY_CODE = '+1';
export const CHAT_SUPPLIER_CONTACT_TIMELINE_GENERIC_MESSAGE =
  'The supplier typically replies in few hours. If urgent, please contact the supplier directly.';
export const FIRST_MESSAGE = 'first-message';
