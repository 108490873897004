export const ogEditorView = {
  HOME_VIEW: 'HOME_VIEW',
  SEARCH_RESULTS_VIEW: 'SEARCH_RESULTS_VIEW',
  NO_RESULTS_VIEW: 'NO_RESULTS_VIEW',
  CUSTOM_PRODUCT_FORM_VIEW: 'CUSTOM_PRODUCT_FORM_VIEW',
};

export const preOrderModalOption = {
  NONE: 'NONE',
  DELETE_PREORDER_ITEMS: 'DELETE_PREORDER_ITEMS',
  SELECT_NEW_DELIVERY_DAY: 'SELECT_NEW_DELIVERY_DAY',
};

export const orderInvoicesRangeTypes = {
  CURRENT: 'CURRENT',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  THREE_MONTHS: 'THREE_MONTHS',
  CUSTOM: 'CUSTOM',
  ALL: 'ALL',
};
