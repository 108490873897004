import { toastr } from 'react-redux-toastr';

import { initOrderHistory } from '../../../actions/orderHistoryActions';
import {
  createChatChannel,
  createGeneralChatChannel,
} from './chatChannelsListActions';
import { setMessagesConsumed } from '../helpers/chatClientHelper';
import {
  CHAT_CHANNEL_TEMP_NAME_STARTS_WITH,
  FIRST_MESSAGE,
} from '../../../constants/GlobalConstants';
import * as DataAPI from '../../../api/DataAPI';
import { logException } from '../../../domains/shared/logger';

// #region Action Types
export const GET_CHAT_TOKEN = 'GET CHAT TOKEN';
export const GET_CHAT_TOKEN_SUCCESS = 'GET CHAT TOKEN SUCCESS';
export const GET_CHAT_TOKEN_FAILED = 'GET CHAT TOKEN FAILED';

export const REFRESH_CHAT_TOKEN = 'REFRESH CHAT TOKEN';
export const REFRESH_CHAT_TOKEN_SUCCESS = 'REFRESH CHAT TOKEN SUCCESS';
export const REFRESH_CHAT_TOKEN_FAILED = 'REFRESH CHAT TOKEN FAILED';

export const SEND_CHAT_MESSAGE = 'SEND CHAT MESSAGE';
export const SEND_CHAT_FIRST_MESSAGE_SUCCESS =
  'SEND CHAT FIRST MESSAGE SUCCESS';
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND CHAT MESSAGE SUCCESS';
export const SEND_CHAT_MESSAGE_FAILED = 'SEND CHAT MESSAGE FAILED';

export const UPDATE_CHAT_STATUS = 'UPDATE CHAT STATUS';
export const SHOW_CHAT_UI = 'SHOW THE CHAT UI';

export const SET_OPENING_SOURCE = 'SET OPENING SOURCE';
export const SET_MP_CHAT_ACCESS_LIST = 'SET MP CHAT ACCESS LIST';
// #endregion Action Types

// #region Actions
/**
 * Fetch the Chat token for user by passing user identity
 *
 * This method fetches the token and sets the token into chat auth reducer state.
 * The 'useEffect' method inside useChatClient.js file uses it to
 * create the Twilio Chat instance.
 */
export const getChatToken =
  (identity, firstName = '', lastName = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_CHAT_TOKEN });

      const {
        vendorsReducer: { vendors },
        buyerReducer: {
          defaultBuyer: { buyerName: name, buyerKey },
        },
        orderHistoryReducer: { myOrders: orders = [] },
      } = getState();

      if (vendors.length === 0) await waitForVendors(getState);
      // load order history only if the user is not in my orders page to avoid duplicate api calls
      if (orders.length === 0 && window.location.pathname !== '/orders/')
        await dispatch(initOrderHistory());

      const response = await DataAPI.fetchTwilioChatToken(identity); // Retrieves Chat token
      if (response && response.data) {
        const accessTokenChat = response.data.access_token;
        dispatch({
          type: GET_CHAT_TOKEN_SUCCESS,
          identity,
          displayName: `${firstName} ${lastName}`,
          name,
          buyerKey,
          accessTokenChat,
        });
      } else {
        dispatch({ type: GET_CHAT_TOKEN_FAILED });
      }
    } catch (error) {
      handleCatch(GET_CHAT_TOKEN_FAILED, 'getChatToken', error, dispatch);
    }
  };

/**
 * Refresh the Twilio Chat instance by reading the user identity from reducer Chat state.
 *
 * This method fetches the token and sets the token into chat auth reducer state.
 * The 'useEffect' method inside useChatClient.js file uses it to
 * update the Twilio Chat instance.
 */
export const refreshChatToken = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REFRESH_CHAT_TOKEN });

    const {
      auth: { identity },
    } = getState().chat; // Gets users identity
    const response = await DataAPI.fetchTwilioChatToken(identity);

    if (response && response.data) {
      const accessTokenChat = response.data.access_token;
      dispatch({ type: REFRESH_CHAT_TOKEN_SUCCESS, identity, accessTokenChat });
    } else {
      dispatch({ type: REFRESH_CHAT_TOKEN_FAILED });
    }
  } catch (error) {
    handleCatch(REFRESH_CHAT_TOKEN_FAILED, 'refreshChatToken', error, dispatch);
  }
};

/**
 * A method to send a user Chat message.
 *
 * This method internally handles by prefixing extra info to the message in case it is the
 * first message from user for the selected channel "sid" in chatSelected reducer.
 *
 * @param {string | FormData | {  contentType: string; media: string | Buffer}} message A message to be sent
 */
export const sendChatMessage = (message) => async (dispatch, getState) => {
  try {
    if (!message || (typeof message === 'string' && !message.trim())) return; // Bails out if message is invalid or empty (only for "string" type)

    dispatch({ type: SEND_CHAT_MESSAGE });

    const {
      chat: {
        auth: { name },
        channels,
        messages,
        chatSelected: { sid },
      },
      feature: { isOrderChat },
    } = getState();
    const { channel } = channels.list[sid];

    let channelSidToUse = sid;
    let channelToUse = channel;
    let messagesToUse = messages;

    // If the sid is local sid then we should create a chat channel before sending a message
    if (sid.toUpperCase().startsWith(CHAT_CHANNEL_TEMP_NAME_STARTS_WITH)) {
      // Creates a new chat channel
      if (!isOrderChat || channelToUse.state.attributes.channel_id) {
        channelSidToUse = await dispatch(createGeneralChatChannel(sid));
      } else {
        channelSidToUse = await dispatch(createChatChannel(sid));
      }

      // Retrieves latest data from store
      const {
        channels: { list },
        messages,
      } = getState().chat;
      // Assign latest data to the variables
      channelToUse = list[channelSidToUse].channel;
      messagesToUse = messages;
    }

    // First message has be of 1 length due to inbuilt supplier reply message notification
    if (messagesToUse[channelSidToUse].messages.length < 2) {
      // If "message" is going to be first message of the user, then add some extra info into the message being sent
      await channelToUse.sendMessage(FIRST_MESSAGE, {
        isFirstMessage: 'true',
        buyerName: name,
      });
      await channelToUse.sendMessage(message);
    } else {
      // Else, just send the message as is
      await channelToUse.sendMessage(message);
    }

    await setMessagesConsumed(channelToUse); // Sets all the messages in Chat channel as read by user
    dispatch({ type: SEND_CHAT_MESSAGE_SUCCESS });
  } catch (error) {
    dispatch(refreshChatToken);
    handleCatch(SEND_CHAT_MESSAGE_FAILED, 'sendChatMessage', error, dispatch);
  }
};

/**
 * Sets the opening source for the chat ui
 *
 * @param {string} source
 */
export const setOpeningSource = (openingSource) => (dispatch) =>
  dispatch({ type: SET_OPENING_SOURCE, openingSource });

/**
 * Sets the supplier urlsafe's for allowed MP chat
 *
 * @param {Array} accessListMPChat an array of supplier urlsafe's for allowed MP chat
 */
export const setMPChatAccessList = (accessListMPChat) => (dispatch) =>
  dispatch({ type: SET_MP_CHAT_ACCESS_LIST, accessListMPChat });
// #endregion Actions

// #region Helper methods
/**
 * A method to add wait until vendors are loaded.
 *
 * @param {Function} state a redux getState function
 * @param {Function} attempts current attempt number
 * @param {Function} max max number of attempts
 */
const waitForVendors = async (state, attempts = 0, max = 5) => {
  if (attempts === max) return;
  if (!state || !state().vendorsReducer) return;

  const { vendors } = state().vendorsReducer;

  if (vendors.length === 0) {
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve();
      }, 1000 * (attempts || 1))
    );
    await waitForVendors(state, attempts + 1, max);
  }
};

/**
 * Handle Catch method in case Action is failed to execute.
 *
 * @param {string} type Action Type
 * @param {string} methodname Error occured in method name
 * @param {object} error Error object
 * @param {Function} dispatch Redux dispatch function
 */

const handleCatch = (type, methodname, error, dispatch) => {
  dispatch({ type });

  let errorMessage = '';
  if (!error.response)
    errorMessage = 'Detected a connection problem, please refresh this page';
  else
    errorMessage =
      (((error || {}).response || {}).data || {}).message || 'Please try again';

  toastr.error(`Error: ${errorMessage}`);
  // eslint-disable-next-line no-console
  console.error(`An Error occured with ${methodname} ${error}`);
  logException(error);
};
// #endregion Helper methods
