import Mixpanel from 'mixpanel-browser';

function getQueryParam(url, parameters) {
  // Expects a raw URL
  const param = parameters.replace(/[[]/, '[').replace(/[\]]/, ']');
  const regexS = '[?&]' + param + '=([^&#]*)';
  const regex = new RegExp(regexS);
  const results = regex.exec(url);

  if (
    results === null ||
    (results && typeof results[1] !== 'string' && results[1].length)
  ) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
}

export function getCampaignParams() {
  const campaign_keywords =
    'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let kw = '';
  const params = {};
  const firstParams = {};
  let index;

  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      firstParams[campaign_keywords[index] + ' [first touch]'] = kw;
    }
  }

  return { params, firstParams };
}
