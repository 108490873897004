import { Badge, GridColDef, Typography } from '@notch-ordering/ui-components';
import { GetTransactionItem, TransactionCharge } from '@ar/network/AccountsReceivable.network';
import { commonGridDef } from '@ar/pages/InvoicesPage/InvoicePageConstants';
import React from 'react';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
    flex: 1,
};

export const INVOICE_ITEMS_COLUMNS = {
    name: 'description',
    quantity: 'quantity',
    unitPrice: 'price',
    tax: 'tax_amount',
    total: 'total',
} as const;
export const getInvoiceItemColumns = (): GridColDef<GetTransactionItem>[] => [{
    ...gridColDef,
    field: INVOICE_ITEMS_COLUMNS.name,
    headerName: 'Name',
},
{
    ...gridColDef,
    field: INVOICE_ITEMS_COLUMNS.quantity,
    headerName: 'Quantity',
},
{
    ...gridColDef,
    field: INVOICE_ITEMS_COLUMNS.unitPrice,
    headerName: 'Unit Price',
    renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
},
{
    ...gridColDef,
    field: INVOICE_ITEMS_COLUMNS.tax,
    headerName: 'Tax',
    renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
},
{
    ...gridColDef,
    field: INVOICE_ITEMS_COLUMNS.total,
    headerName: 'Total',
    renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
},
];

export const INVOICE_CREDITS_COLUMNS = {
    amount: 'amount',
    reason: 'description',
    updatedAt: 'updated_at',
} as const;

export const getInvoiceCreditColumns = (): GridColDef<GetTransactionItem>[] => [
    {
        ...gridColDef,
        field: INVOICE_CREDITS_COLUMNS.amount,
        headerName: 'Amount',
        renderCell: ({ value }) => <div className="flex gap-4 items-center"><span>{formatAsCurrency(centsToDollars(value))}</span><Badge variant="GRAY">Used</Badge> </div>,
    },
    {
        ...gridColDef,
        field: INVOICE_CREDITS_COLUMNS.updatedAt,
        headerName: 'Issue date',
        renderCell: ({ value }) => (value ? formatIsoStringToUtcDate(new Date(value).toISOString(), 'MMM d, yyyy') : '--'),
    },
];
export const INVOICE_PAYMENTS_COLUMNS = {
    amount: 'amount',
    paymentMethod: 'paymentMethod',
    date: 'created_at',
    status: 'status',
    surchargeAmount: 'surcharge_amount'
} as const;

export const getInvoicePaymentColumns = (): GridColDef<TransactionCharge>[] => [
    {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.amount,
        headerName: 'Amount',
        renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
    },
    {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.surchargeAmount,
        headerName: 'Surcharge',
        renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
    }, {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.paymentMethod,
        headerName: 'Method',
        renderCell: ({ row }) => (row?.payment_method ? <PaymentMethodBadge brand={row.payment_method.brand} last4={row.payment_method.last4}/> : '--'),
    },
    {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.date,
        headerName: 'Date',
        renderCell: ({ value }) => (value ? formatIsoStringToUtcDate(new Date(value).toISOString(), 'MMM d, yyyy') : '--'),
    },
    {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.status,
        headerName: 'Status',
        renderCell: ({ value }) => (value === 'canceled' ? <Badge variant="RED" className="truncate font-medium">failed</Badge> : <Badge variant="GREEN" className="truncate font-medium">{value}</Badge>),
    },

];

export type CustomerBlockProps = {
    title: React.ReactNode,
    value: React.ReactNode,
};

export const PanelBlock : React.FC<CustomerBlockProps> = ({ title, value }) => <div className="flex flex-col gap-2">
    <Typography as="div" className="text-gray-600 font-medium">
        {title}
    </Typography>
    <Typography as="div">
        {value}
    </Typography>
</div>;

export const gridSX = {
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
        py: '20px',
        alignItems: 'flex-start',
    },
    '& .MuiDataGrid-virtualScroller': {
        minHeight: 'auto',
    },
};

export const EmptyState : React.FC<{ text:string }> = ({ text }) => <Typography as="div" className="text-gray-600">
    {text}
</Typography>;
