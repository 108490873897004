import Mixpanel from 'mixpanel-browser';
import * as Segment from '../third-party/Segment';

import Utils from '../utils';
import { logException } from '../domains/shared/logger';

let retries = 0;

export async function trackEvent(
  eventName,
  properties,
  segmentMigration = false
) {
  // Only allow event tracking in staging/production
  if (__PROD__) {
    try {
      callEventTrackingPlatforms(eventName, properties, segmentMigration);
    } catch (error) {
      // If Mixpanel is not initialized yet, then
      // we try to call the event again 3 times (with 500ms wait), then we stop and report error.
      if (retries < 3) {
        await Utils.sleep(500);
        retries++;
        trackEvent(eventName, properties, segmentMigration);
      } else {
        console.error(error);
        console.error('An error occurred with trackEvent');
        logException(error);
      }
    }
  }
}

function callEventTrackingPlatforms(
  eventName,
  properties,
  segmentMigration = false
) {
  // TODO: Clean up segment migration once everything is moved over to segment
  if (segmentMigration && window.analytics) {
    Segment.track(eventName, properties);
  } else {
    // Make sure Mixpanel is ready or you will get an error
    // because _flags has attributes Mixpanel needs to access.
    if (Mixpanel._flags) {
      // Log event using Mixpanel
      Mixpanel.track(eventName, properties);
    }
  }
}
