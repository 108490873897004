import { StatusCodes } from 'http-status-codes';
import axios, { AxiosResponse } from 'axios';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { SESSION_EXPIRED_KEY } from '@v2/components/SignIn/LoginForm/constants';
import { EStrategyName } from './EStrategyName';
import { SerializeStrategy, TokenData } from './TokenData';
import { STORAGE_KEY, UserAuthenticationProvider } from '../UserAuthenticationProvider';
import { Currency,
    GPOMembership,
    refreshSessionToken,
    SupplierMembership } from '@/ar/network/AccountsReceivable.network';

export type ArTokenResponse = {
    api_token: string,
    expires_at?: string | null,
    last_synced_at?: string | null,
    stripe_account_id:string,
    supplier_id: string,
    supplier_logo: string,
    supplier_name: string,
    supplier_country: string,
    email?: string,
    isNotchEmail?: boolean,
    customers?: {
        id: string,
        businessName: string,
        currency: Currency,
        country: string,
    }[],
    supplier_memberships?: SupplierMembership[],
    gpo?: boolean,
    isCustomer?: boolean,
    isSupplier?: boolean,
    isCustomerAndSupplier?: boolean,
    customer_id?: string,
    customer_business_name?: string,
    customer_country?: string,
    currency?: Currency,
    gpoMemberships: GPOMembership[],
    refresh_token: string,
};

// If we want to logout all the ar sessions we should update this version number
export const AR_SESSION_KEY = '0.0.2';
/**
 * Base class for bearer token authorization strategies
 */
export abstract class ArTokenStrategyBase {
    protected tokenData: TokenData;

    private _strategyName: EStrategyName;

    /**
     *
     */
    public constructor(tokenData: TokenData, strategyName: EStrategyName) {
        this.tokenData = tokenData;
        this._strategyName = strategyName;
    }

    /**
     *
     */
    public serialize(): SerializeStrategy {
        if (!this.tokenData) {
            throw new Error('Attempted to serialize invalid token');
        }

        return {
            name: this._strategyName,
            token: this.tokenData,
        };
    }

    /**
     * Checks if the token is expired.
     * Consider if the session key has been updated.
     * Consider a 15-minute buffer time before the actual expiration time.
     *
     * @returns {boolean} True if expired, false otherwise.
     */
    public isExpired(): boolean {
        const bufferTime = 15;
        const currentTime = new Date();
        const expiresAt = new Date(this.tokenData.expiresAt);
        currentTime.setMinutes(currentTime.getMinutes() - bufferTime);
        return (this.tokenData.sessionKey !== AR_SESSION_KEY) || (this.tokenData.expiresAt ? expiresAt < currentTime : false);
    }

    /**
     *
     */
    public async authenticateRequest(request: RequestInit): Promise<void> {
        const accessToken: string = await this.getAccessToken();

        request.headers = {
            ...request.headers,
            Authorization: accessToken,
        };
    }

    /**
     *
     */
    public async getAccessToken(): Promise<string> {
        if (this.isExpired()) {
            if (!await this.refreshToken()) {
                UserAuthenticationProvider.logOut();
                window.localStorage.setItem(SESSION_EXPIRED_KEY, '1');
                return '';
            }
        }
        return `${this.tokenData.accessToken}`;
    }

    public async refreshToken(): Promise<boolean> {
        try {
            const response = await refreshSessionToken(this.tokenData.refreshToken);
            if (response.status !== StatusCodes.OK) {
                return false;
            }
            this.tokenData = {
                ...this.tokenData,
                accessToken: response.data.api_token,
                expiresAt: response.data.expires_at,
            };
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.serialize()));
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    /**
     *
     */
    public getStrategyName(): EStrategyName {
        return this._strategyName;
    }

    /**
     *
     */
    public static async getToken(url: string, payload: { email: string, password: string }): Promise<TokenData | null> {
        const loginResponse:AxiosResponse<ArTokenResponse> = await axios
            .post(url, payload)
            .catch(() => { throw new Error('An error has occurred during login!'); });
        if (loginResponse.status !== StatusCodes.OK) {
            return null;
        }
        const token = loginResponse.data;
        const { api_token: accessToken, expires_at: expiresAt, refresh_token: refreshToken, ...loginData } = token;
        const isNotchEmail = ['notchordering.com', 'notch.financial'].includes(payload?.email?.split('@')[1]);
        const isCustomer = !!loginData?.customer_id && !loginData?.supplier_id;
        const isSupplier = !!loginData.supplier_id && !loginData?.customer_id;
        const isCustomerAndSupplier = !!loginData.supplier_id && !!loginData?.customer_id;
        // saving supplier data in store
        useSupplierStore.getState().setSupplierLoginData({ ...loginData,
            api_token: accessToken,
            email: payload.email,
            isNotchEmail,
            isCustomer,
            isSupplier,
            isCustomerAndSupplier });

        return {
            accessToken,
            refreshToken,
            expiresAt: (expiresAt === null || !expiresAt) ? '' : expiresAt,
            sessionKey: AR_SESSION_KEY,
        };
    }
}
