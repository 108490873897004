import React, { useState } from 'react';
import { Badge, Loading, Tooltip, Typography } from '@notch-ordering/ui-components';
import VisaIcon from '@icons/visa-icon.svg';
import MastercardIcon from '@icons/mastercard-icon.svg';
import AmexIcon from '@icons/amex-icon.svg';
import PadIcon from '@icons/pad-icon.svg';
import { mergeClassNames } from '@v2/utils/Helpers';
import { BadgeProps } from '@notch-ordering/ui-components/src/components/Badge/BadgeConstants';
import { normalizeYear } from '@/utils/DateUtils';

export interface PaymentMethodBadgeProps {
    brand: string,
    last4: string,
    autoCollection?: boolean,
    className?: string,
    badgeProps?: Omit<BadgeProps, 'children'>,
    isDefault?: boolean,
    exp_month?: number,
    exp_year?: number,
    isAutoPay?: boolean,
}

export const getCCIcon = (brand: string, iconClassName?: string): JSX.Element => {
    const brandLower = brand.toLowerCase().trim();
    const defaultIconClassName = 'h-4';
    const mergedClassName = mergeClassNames(defaultIconClassName, iconClassName);

    switch (brandLower) {
        case 'american express':
        case 'amex':
            return <AmexIcon className={mergedClassName}/>;
        case 'mastercard':
        case 'mc':
            return <MastercardIcon className={mergedClassName}/>;
        case 'visa':
            return <VisaIcon className={mergedClassName}/>;
        default:
            return <PadIcon className={mergedClassName}/>;
    }
};

const PaymentMethodBadge : React.FC<PaymentMethodBadgeProps> = ({
    brand,
    last4,
    autoCollection,
    className,
    badgeProps,
    isDefault,
    exp_month,
    exp_year,
    isAutoPay
}) => {
    const [shouldShowTooltip, setShouldShowTooltip] = useState<boolean>(false);
    const [shouldShowExpTooltip, setShouldShowExpTooltip] = useState<boolean>(false);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const hasExpiryDate = exp_year > 0 && exp_month > 0;
    const normalizedExpYear = normalizeYear(exp_year);
    const isExpired = normalizedExpYear < currentYear || (normalizedExpYear === currentYear && exp_month <= currentMonth);

    return (<div className="flex flex-row justify-between my-2">
        <div className="flex">{getCCIcon(brand)}</div>
        <div className="flex m-0 ml-2 mr-1.5 text-gray-800 font-normal text-base leading-5 font-inter whitespace-nowrap">•••• {last4 === undefined ? <Loading isDark /> : last4}</div>
        <div className="flex "> {hasExpiryDate
            && <Tooltip
                show={shouldShowExpTooltip && isExpired}
                placement="top"
                onShow ={(): void => { setShouldShowExpTooltip(true); }}
                onHide ={(): void => { setShouldShowExpTooltip(false); }}
                trigger={
                    <div>
                        <Typography className={mergeClassNames('truncate flex-none items-center m-0 mr-1.5', className)}>
                            <Badge fontSize="text-1" variant={isExpired ? 'RED' : 'GRAY'}>
                                Exp: {exp_month}/{exp_year}
                            </Badge>
                        </Typography>
                    </div>}>
                    Your payment method has expired. Please remove it and notify your customer to avoid any interruptions.
            </Tooltip>}
        </div>
        {autoCollection && (isDefault || isAutoPay) && (
            <div className="flex ">
                <Tooltip
                    show={shouldShowTooltip}
                    placement="top"
                    onShow ={(): void => { setShouldShowTooltip(true); }}
                    onHide ={(): void => { setShouldShowTooltip(false); }}
                    trigger={
                        <div>
                            <Typography className={mergeClassNames('truncate flex-none items-center m-0 mr-1.5', className)}>
                                <Badge fontSize="text-1" variant="PURPLE" {...badgeProps}>Autopay</Badge>
                            </Typography>
                        </div>}>
                        The payment method that gets automatically charged for upcoming bills.
                </Tooltip>
            </div>)}
        {!autoCollection && isDefault && (
            <div className="flex ">
                <Tooltip
                    show={shouldShowTooltip}
                    placement="top"
                    onShow ={(): void => { setShouldShowTooltip(true); }}
                    onHide ={(): void => { setShouldShowTooltip(false); }}
                    trigger={
                        <div>
                            <Typography className={mergeClassNames('truncate flex-none items-center', className)}>
                                <Badge fontSize="text-1" variant="GREEN" {...badgeProps}>
                                    Default
                                </Badge>
                            </Typography>
                        </div>}>
                        The payment method your customer prefers for bill payments.
                </Tooltip></div>)}
    </div>);
};

export default PaymentMethodBadge;
