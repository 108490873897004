import { ogEditorView } from '../constants/EnumTypes';

const initialState = {
  selectedOrderGuide: { name: 'untitled', id: null, items: [] },
  selectedVendor: { vendorID: '', name: '', categories: [] },
  isOrderGuideEditorModalOpen: false,
  buyerID: 0,
  vendorID: '',
  vendorName: '',
  items: [], // The search results
  categories: [], // The categories that are extracted from search results
  chosenCategories: [], // categories we have chosen to filter by (multipe).
  sortBy: '', // What to sort the search results by.
  searchText: '', // The text inside the search bar in the store
  currentCell: -1, // Keeps track of which cell is clicked into another view
  loading: false, // Responsible for showing loader
  loadingAll: false, // Responsible for showing the loader on the entire store
  closeModalBool: false,
  disableFilterSort: true, // Disables the sort/filter on the StoreBar component
  view: ogEditorView.HOME_VIEW, // The different views for ogEditor store
};

export default function orderguideEditorStore(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_INITIAL':
      return Object.assign({}, state, {
        vendorID: action.vendorID,
        buyerID: action.buyerID,
        vendorName: action.vendorName,
      });

    case 'SHOW_LOADER':
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case 'SHOW_LOADER_ALL':
      return Object.assign({}, state, {
        loadingAll: action.loadingAll,
      });

    case 'TOGGLE_ORDERGUIDE_EDITOR_MODAL':
      return Object.assign({}, state, {
        isOrderGuideEditorModalOpen: !state.isOrderGuideEditorModalOpen,
        selectedOrderGuide: action.selectedOrderGuide,
        selectedVendor: action.selectedVendor,
      });

    case 'RECIEVE_SEARCHRESULTS':
      return Object.assign({}, state, {
        items: action.items,
        loading: action.loading,
        currentCell: action.currentCell, // reset currentCell on every search
        disableFilterSort: action.disableFilterSort,
      });

    case 'RECEIVE_CATEGORIES':
      return Object.assign({}, state, {
        categories: action.categories,
      });

    case 'UPDATE_SEARCHTEXT':
      return Object.assign({}, state, {
        searchText: action.searchText,
      });

    case 'ADD_ITEM_TO_ORDERGUIDE_EDITOR':
      return Object.assign({}, state, {
        items: state.items
          ? state.items.map((item) =>
              item.map((variant) =>
                variant.productVariantUnitId ===
                action.item.productVariantUnitId
                  ? Object.assign({}, variant, { itemAdded: true })
                  : variant
              )
            )
          : undefined,
      });

    case 'REMOVE_ITEM_FROM_ORDERGUIDE_EDITOR':
      return Object.assign({}, state, {
        items: state.items
          ? state.items.map((item) =>
              item.map((variant) =>
                variant.productVariantUnitId ===
                action.item.productVariantUnitId
                  ? Object.assign({}, variant, { itemAdded: false })
                  : variant
              )
            )
          : undefined,
      });

    case 'UPDATE_ORDERGUIDE':
      // Resets to the initial store view (with no search results)
      // should do this via a store variable though
      return Object.assign({}, state, {
        items: [],
      });

    case 'RESET_CURRENT_CELL':
      // Resets any open cell to DEFAULT view, when another
      // cell is opened
      return Object.assign({}, state, {
        currentCell: action.index,
      });

    case 'FILTER_BY_CATEGORY':
      return Object.assign({}, state, {
        chosenCategories: action.chosenCategories,
      });

    case 'SORT_RESULTS':
      return Object.assign({}, state, {
        sortBy: action.sortBy,
      });

    case 'CHANGE_OG_EDITOR_VIEW':
      return Object.assign({}, state, {
        view: action.view,
      });

    case 'UPDATE_SELECTED_ORDERGUIDE':
      return Object.assign({}, state, {
        selectedOrderGuide: action.selectedOrderGuide,
      });

    case 'CLEAR_FILTERS':
      return Object.assign({}, state, {
        chosenCategories: action.chosenCategories,
        sortBy: action.sortBy,
      });

    case 'CLEAR_DATA':
      return initialState;

    default:
      return state;
  }
}
