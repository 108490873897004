import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import SpinnerIcon from '@icons/spinner-icon.svg';

export const DraftInvoiceProcessingModal: React.FC = () => <div className="absolute -left-10 top-0 right-0 bottom-0  flex items-center justify-center bg-gray-700 bg-opacity-50 z-50 w-[calc(100%+5rem)]">
    <div className="bg-white rounded-lg shadow-lg p-8 text-center flex flex-col gap-4 w-full mx-4 lg:mx-0 lg:w-[320px]">
        <SpinnerIcon className="animate-spin h-6 w-6 mx-auto"/>
        <Typography className="font-medium m-0">Parsing invoice data</Typography>
        <Typography className="m-0 mb-3 text-gray-600">Please wait a little while we take your invoice up a notch!</Typography>
    </div>
</div>;
