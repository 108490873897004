import create from 'zustand';
import { isEqual, omit, omitBy } from 'lodash';
import { DraftInvoice, DraftInvoiceStatus, GetDraftInvoicesParams } from '../network/AccountsReceivable.network';
import { getUrlSearchParamsAsObject, updateUrlSearchParams } from '@/utils/UrlUtils';

export type SelectedInvoiceMap = Record<string, DraftInvoice>;
type SearchParams = GetDraftInvoicesParams & { page?: number };
export type DraftInvoiceStoreState = {
    searchParams: SearchParams,
};

type DraftInvoiceStoreActions = {
    updateSearchParams: (params: Partial<SearchParams>) => void,
    haveSearchParamsChanged?: () => boolean,
    resetPagination: () => void,
    reset: () => void,

};

export type DraftInvoiceStore = DraftInvoiceStoreState & DraftInvoiceStoreActions;
const searchParamsFromURL = (): Partial<SearchParams> => {
    const searchParamsEntries = getUrlSearchParamsAsObject<Partial<SearchParams>>();
    return {
        ...searchParamsEntries,
        // Split the customer_id and status query params into arrays, this values are coming from the URL as comma separated strings
        customer_id: typeof searchParamsEntries?.customer_id === 'string' && searchParamsEntries?.customer_id !== '' ? (searchParamsEntries.customer_id as string)?.split(',') : [],
        status: typeof searchParamsEntries?.status === 'string' && searchParamsEntries?.status !== '' ? (searchParamsEntries.status as string)?.split(',') as DraftInvoiceStatus[] : [],
    };
};

const DRAFT_INVOICE_STORE_INITIAL_STATE: DraftInvoiceStoreState = {
    searchParams: {
        limit: 20,
        offset: 0,
        page: 0,
        supplier_id: '',
        ...searchParamsFromURL(), // Merge the URL search params with the default search params
    },
};

export const draftInvoicesStoreDefinition = (set, getState): DraftInvoiceStore => ({
    ...DRAFT_INVOICE_STORE_INITIAL_STATE,
    reset: () => set(DRAFT_INVOICE_STORE_INITIAL_STATE),
    resetPagination: () => set((state) => ({
        searchParams: {
            ...state.searchParams,
            offset: 0,
        },
    })),
    updateSearchParams: (params: Partial<SearchParams>) => set((state) => {
        const newSearchParams = {
            ...state.searchParams,
            ...params,
        };
        updateUrlSearchParams<SearchParams>(newSearchParams);
        return {
            searchParams: newSearchParams,
        };
    }),
    haveSearchParamsChanged: (): boolean => {
        const { searchParams } = getState();
        const ignoredKeys: (keyof SearchParams)[] = ['page', 'offset', 'supplier_id'];
        // removing from searchParams the ignored keys and undefined values for an accurate comparison
        const currentSearchParams = omitBy(omit(searchParams, ignoredKeys), (value) => value === undefined);
        const initialSearchParams = omit(DRAFT_INVOICE_STORE_INITIAL_STATE.searchParams, ignoredKeys);
        return !isEqual(currentSearchParams, initialSearchParams);
    },

});

export const useDraftInvoicesStore = create<DraftInvoiceStore>(draftInvoicesStoreDefinition);
