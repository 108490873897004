import React from 'react';
import DangerIcon from '@icons/danger-icon.svg';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { ConfirmModal } from '../shared/ConfirmModal';
import { DraftInvoiceModals, useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';

export type DraftInvoiceItemDeleteModalProps = {
    onConfirmDelete: (id: string) => void,
};

export const DraftInvoiceItemDeleteModal : React.FC<DraftInvoiceItemDeleteModalProps> = ({ onConfirmDelete }) => {
    const { modals, closeModal } = useDraftInvoiceStore();
    const { selectedItem, deleteItemModal } = modals;

    const onConfirm = () => {
        onConfirmDelete(selectedItem?.id);
        closeModal(DraftInvoiceModals.DeleteItem);
        toast.show({
            message: 'Product deleted',
            icon: <CheckIcon />,
            showClose: false,
        });
    };
    const onClose = () => {
        closeModal(DraftInvoiceModals.DeleteItem);
    };
    if (!selectedItem) return null;
    return <ConfirmModal isOpen={deleteItemModal}
        title={<div className="flex flex-col gap-4">
            <DangerIcon className="w-8 h-8 text-red-500" />
            <div>Are you sure you want to delete {selectedItem?.description} product?</div>
        </div>}
        confirmVariant={'DESTRUCTIVE'}
        confirmLabel={'Delete'}
        description={'This action cannot be undone after saving the draft invoice.'}
        onConfirm={onConfirm}
        onClose={onClose} />;
};
