import { toastr } from 'react-redux-toastr';
import { batch } from 'react-redux';

import { removeChatMessage } from './chatMessagesActions';
import { setChatSelected } from './chatSelectedActions';
import { initOrderHistory } from '../../../actions/orderHistoryActions';
import {
  setMessagesConsumed,
  calculateUnreadChatMessages,
} from '../helpers/chatClientHelper';
import {
  CHAT_MEDIA_MESSAGE_TEXT,
  CHAT_DELAY_AFTER_NEW_CHANNEL_CREATION_IN_MSECONDS,
  CHAT_UNREAD_COUNT_UPDATE_DELAY_IN_MSECONDS,
  FIRST_MESSAGE,
} from '../../../constants/GlobalConstants';
import Utils from '../../../utils';
import * as DataAPI from '../../../api/DataAPI';
import { logException } from '../../../domains/shared/logger';
import { SEND_CHAT_FIRST_MESSAGE_SUCCESS } from './chatAuthActions';

// #region Action Types
export const ADD_CHAT_CHANNELS = 'ADD CHAT CHANNELS';
export const ADD_CHAT_CHANNELS_SUCCESS = 'ADD CHAT CHANNELS SUCCESS';
export const ADD_CHAT_CHANNELS_FAILED = 'ADD CHAT CHANNELS FAILED';

export const UPDATE_CHAT_CHANNEL = 'UPDATE CHAT CHANNEL';
export const UPDATE_CHAT_CHANNEL_SUCCESS = 'UPDATE CHAT CHANNEL SUCCESS';
export const UPDATE_CHAT_CHANNEL_FAILED = 'UPDATE CHAT CHANNEL FAILED';

export const CREATE_CHAT_CHANNEL = 'CREATE CHAT CHANNEL';
export const CREATE_CHAT_CHANNEL_SUCCESS = 'CREATE CHAT CHANNEL SUCCESS';
export const CREATE_CHAT_CHANNEL_FAILED = 'CREATE CHAT CHANNEL FAILED';

export const REMOVE_CHAT_CHANNEL = 'REMOVE CHAT CHANNEL';
export const SET_CHAT_CHANNEL_LAST_MESSAGE = 'SET CHAT CHANNEL LAST MESSAGE';
export const SET_CHAT_CHANNEL_MESSAGES_AS_READ =
  'SET CHAT CHANNEL MESSAGES AS READ';
export const SET_CHAT_CHANNEL_UNREAD_MESSAGE_COUNT =
  'SET CHAT CHANNEL UNREAD MESSAGE COUNT';
export const SET_CHAT_CHANNEL_UNREAD_MESSAGE_COUNT_FAILED =
  'SET CHAT CHANNEL UNREAD MESSAGE COUNT FAILED';
// #endregion Action Types

// #region Actions
/**
 * Add Chat channels
 *
 * @param {Array} channels channels array to add
 */
export const addChatChannels =
  (channels = []) =>
  async (dispatch, getState) => {
    try {
      if (!channels || channels.length === 0) {
        // Bail out if no channel data
        dispatch({ type: ADD_CHAT_CHANNELS_FAILED });
        return;
      }

      dispatch({ type: ADD_CHAT_CHANNELS });

      const {
        vendorsReducer: { vendors = [] } = {},
        orderHistoryReducer: { myOrders: orders = [] } = {},
        feature: { isOrderChat },
      } = getState();
      const updatedChannels = {};

      // Read metadata from each Chat channel and prepare channelAttributes object by taking care of all edge cases.
      channels.forEach(async (channel) => {
        const {
          state: {
            attributes: {
              channel_id: channelID,
              vendor_id: vendorUrlsafe,
              order_number: orderNumber,
              order_status: orderStatus,
            } = {},
          } = {},
        } = channel;
        const {
          state: {
            lastMessage: { timestamp = '', index = 0 } = {},
            lastConsumedMessageIndex = 0,
          } = {},
        } = channel;

        const vendor = vendors.find((v) => v.urlsafe === vendorUrlsafe) || {};
        let order =
          orders.find((o) => o.id.toString() === orderNumber?.toString()) || {};

        if (!channel.sid) return; // if no sid, just return.

        if (isOrderChat) {
          if (Object.keys(order).length === 0) {
            // If order is not found, it could be due to the reason that it might have just placed by the user, and it is not yet available in the store.
            await dispatch(initOrderHistory()); // Load order History
            // Retrieves latest data from store
            const {
              orderHistoryReducer: { myOrders: latestOrders = [] } = {},
            } = getState();
            // Assign latest data to the variables
            order =
              latestOrders.find(
                (o) => o.id.toString() === orderNumber?.toString()
              ) || {};
          }
        }

        let channelAttributes;
        if (isOrderChat) {
          channelAttributes = {
            channelID,
            vendorUrlsafe,
            vendorName: vendor.name,
            orderNumber,
            orderStatus,
            orderDate: new Date(order.placedAt),
            deliveryDate: new Date(Utils.formatDate(order.deliveryDay)),
            timestamp,
            lastMessageIndex: index,
            lastConsumedMessageIndex,
          };
        } else {
          channelAttributes = {
            channelID,
            vendorUrlsafe,
            vendorName: vendor.name,
            timestamp,
            lastMessageIndex: index,
            lastConsumedMessageIndex,
          };
        }

        updatedChannels[channel.sid] = {
          channel,
          channelAttributes,
        };
      });

      if (Object.keys(updatedChannels).length === 0) {
        // bails out if nothing to add
        dispatch({ type: ADD_CHAT_CHANNELS_FAILED });
        return;
      }

      dispatch({ type: ADD_CHAT_CHANNELS_SUCCESS, channels: updatedChannels });
      dispatch(countUnreadChatMessages()); // update the unread messages counter
    } catch (error) {
      handleCatch(ADD_CHAT_CHANNELS_FAILED, 'addChatChannels', error, dispatch);
    }
  };

/**
 * Update Chat channel
 * @param {Object} chatChannel Chat channel object
 */
export const updateChatChannel =
  (chatChannel, channelMembers) => (dispatch) => {
    try {
      if (!chatChannel) return;

      dispatch({ type: UPDATE_CHAT_CHANNEL });

      const { channel } = chatChannel; // You can destructure "updateReasons" as well

      // Read metadata from Chat channel and prepare channelAttributes object by taking care of all edge cases.
      const { state: { attributes: { order_status: orderStatus } = {} } = {} } =
        channel;
      const {
        state: {
          lastMessage: { timestamp = '', index = 0 } = {},
          lastConsumedMessageIndex = 0,
        } = {},
      } = channel;

      const updatedChannel = {
        channel,
        channelAttributes: {
          orderStatus,
          timestamp,
          lastMessageIndex: index,
          lastConsumedMessageIndex,
        },
        channelMembers,
      };

      dispatch({
        type: UPDATE_CHAT_CHANNEL_SUCCESS,
        sid: channel.sid,
        updatedChannel,
      });
      setTimeout(
        () => dispatch(countUnreadChatMessages()),
        CHAT_UNREAD_COUNT_UPDATE_DELAY_IN_MSECONDS
      ); // update the unread messages counter
    } catch (error) {
      handleCatch(
        UPDATE_CHAT_CHANNEL_FAILED,
        'updateChatChannel',
        error,
        dispatch
      );
    }
  };

/**
 * Create a new Chat channel
 *
 * @param {string} sid Channel unique sid
 */
export const createChatChannel = (sid) => async (dispatch, getState) => {
  if (!sid) {
    dispatch({ type: CREATE_CHAT_CHANNEL_FAILED });
    return;
  }

  dispatch({ type: CREATE_CHAT_CHANNEL });

  // Prepare data
  const {
    orderHistoryReducer: { myOrders: orders = [] } = {},
    vendorsReducer: { vendors = [] } = {},
    buyerReducer: { buyerMembers = [] } = {},
    chat: { channels: { list = {} } } = {},
    feature: { isChatEnabled } = {},
  } = getState();

  const oldChannel = list[sid] || {};

  setTimeout(() => {
    batch(() => {
      // Removes the old (Local) Chat channel
      dispatch(removeChatChannel(sid));
      // Removes the old (Local) Chat messages
      dispatch(removeChatMessage(sid));
    });
  }, 3000);

  try {
    const {
      channelAttributes: { orderNumber = '' },
    } = oldChannel;
    const order = orders.find((o) => o.id === orderNumber) || {};

    const vendorInChat = vendors.find(
      (v) => v.urlsafe === order.vendorUrlsafe
    ) || { email: '' };

    const {
      customer: {
        salesRepEmail: orderEmail = '',
        salesRepPhone: orderPhone = '',
        sendPOBy: orderPOSendBy = '',
      } = {},
      enableConnect,
      name: vendorName,
    } = vendorInChat;

    const buyerAccounts = [];
    buyerMembers.forEach((bm) => {
      buyerAccounts.push({
        account_id: bm.urlsafe,
        email: bm.account ? bm.account.email : '',
        first_name: bm.account ? bm.account.firstName : '',
        last_name: bm.account ? bm.account.lastName : '',
        notification_token: '',
      });
    });

    const isMarket = !order.isBYOS;
    const email = isMarket ? vendorInChat.email : orderEmail;

    let chatChannelObj = {
      isMarket,
      orderUrlsafe: order.urlsafe,
      orderNumber: order.id.toString(),
      orderStatus: order.orderBuyerStatus,
      orderPlacedDay: new Date(order.placedAt),
      orderDeliveryDay: new Date(
        Utils.formatDate(order.deliveryDay, 'YYYY-MM-DD')
      ),
      orderEmail: email,
      orderPhone,
      orderPOSendBy,
      vendorUrlsafe: order.vendorUrlsafe,
      buyerUrlsafe: order.buyer.urlsafe,
      buyerName: order.buyer.name,
      buyerAccounts,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      vendorName,
    };

    if (isChatEnabled) {
      chatChannelObj = { ...chatChannelObj, isEnableConnect: !!enableConnect };
    }

    // Create channel
    const response = await DataAPI.createTwilioChatChannel({
      ...chatChannelObj,
    });
    if (response && response.data) {
      const channelSid = response.data.channel_sid;

      batch(() => {
        // Mark the received sid as selected
        dispatch(setChatSelected(channelSid));
        // Dispatch success
        dispatch({ type: CREATE_CHAT_CHANNEL_SUCCESS });
      });
      // This wait is required as "channelAdded" event gets fired from twilio and we receive the channel to proceed with rest of the operations, like sending a message
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
        }, CHAT_DELAY_AFTER_NEW_CHANNEL_CREATION_IN_MSECONDS)
      );
      return channelSid;
    } else {
      dispatch({ type: CREATE_CHAT_CHANNEL_FAILED });
    }
  } catch (error) {
    handleCatch(
      CREATE_CHAT_CHANNEL_FAILED,
      'createChatChannel',
      error,
      dispatch
    );
  }
};

export const createGeneralChatChannelIfDoesNotExist =
  (vendorUrlsafe) => async (dispatch, getState) => {
    dispatch({ type: CREATE_CHAT_CHANNEL });

    // Prepare data
    const {
      vendorsReducer: { vendors = [] } = {},
      buyerReducer: { buyer, buyerMembers = [] } = {},
      feature: { isChatEnabled } = {},
    } = getState();

    try {
      const vendorInChat = vendors.find((v) => v.urlsafe === vendorUrlsafe) || {
        email: '',
      };

      const {
        customer: {
          id: customerId,
          sendPOBy: orderPOSendBy = '',
          salesRepEmail: orderEmail = '',
          salesRepPhone: orderPhone = '',
        } = {},
        enableConnect,
        name: vendorName,
        email = '',
        primaryVendorId,
      } = vendorInChat;

      const buyerAccounts = [];
      buyerMembers.forEach((bm) => {
        buyerAccounts.push({
          account_id: bm.urlsafe,
          email: bm.account ? bm.account.email : '',
          first_name: bm.account ? bm.account.firstName : '',
          last_name: bm.account ? bm.account.lastName : '',
          notification_token: '',
        });
      });

      let chatChannelObj = {
        customerId,
        orderPOSendBy,
        vendorUrlsafe,
        primaryVendorId,
        buyerAccounts,
        buyerUrlsafe: buyer.urlsafe,
        buyerName: buyer.name,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        orderEmail: email || orderEmail,
        orderPhone,
        vendorName,
      };

      if (isChatEnabled) {
        chatChannelObj = {
          ...chatChannelObj,
          isEnableConnect: !!enableConnect,
        };
      }

      // Create channel
      const response = await DataAPI.createTwilioGeneralChatChannel({
        ...chatChannelObj,
      });
      if (response && response.data) {
        const channelSid = response.data.channel_sid;

        batch(() => {
          // Mark the received sid as selected
          dispatch(setChatSelected(channelSid));
          // Dispatch success
          dispatch({ type: CREATE_CHAT_CHANNEL_SUCCESS });
        });
        // This wait is required as "channelAdded" event gets fired from twilio and we receive the channel to proceed with rest of the operations, like sending a message
        await new Promise((resolve) =>
          setTimeout(() => {
            resolve();
          }, CHAT_DELAY_AFTER_NEW_CHANNEL_CREATION_IN_MSECONDS)
        );

        // Retrieves latest data from store
        const {
          channels: { list },
          messages,
        } = getState().chat;
        // Assign latest data to the variables
        const channelToUse = list[channelSid].channel;

        // First message has be of 1 length due to inbuilt supplier reply message notification
        if (messages[channelSid].messages.length < 2) {
          // If "message" is going to be first message of the user, then add some extra info into the message being sent
          await channelToUse.sendMessage(FIRST_MESSAGE, {
            isFirstMessage: 'true',
          });
          dispatch({ type: SEND_CHAT_FIRST_MESSAGE_SUCCESS });
        }

        return channelSid;
      } else {
        dispatch({ type: CREATE_CHAT_CHANNEL_FAILED });
      }
    } catch (error) {
      handleCatch(
        CREATE_CHAT_CHANNEL_FAILED,
        'createChatChannel',
        error,
        dispatch
      );
    }
  };

/**
 * Creates a general chat channel between buyer and supplier
 * @param {*} sid
 * @returns
 */
export const createGeneralChatChannel = (sid) => async (dispatch, getState) => {
  dispatch({ type: CREATE_CHAT_CHANNEL });

  // Prepare data
  const { chat: { channels: { list = {} } } = {} } = getState();

  const oldChannel = list[sid] || {};
  const {
    channelAttributes: { vendorUrlsafe },
  } = oldChannel;

  setTimeout(() => {
    batch(() => {
      // Removes the old (Local) Chat channel
      dispatch(removeChatChannel(sid));
      // Removes the old (Local) Chat messages
      dispatch(removeChatMessage(sid));
    });
  }, 3000);

  const channelToUse = await dispatch(
    createGeneralChatChannelIfDoesNotExist(vendorUrlsafe)
  );
  return channelToUse;
};

/**
 * Update the Chat channel last message
 *
 * @param {string} sid Channel unique sid
 * @param {string} lastMessageBody The body of last message sent by user in the Chat channel
 * @param {string} lastMessageType The type of last message sent by user in the Chat channel
 */
export const updateChatChannelLastMessage =
  (sid, lastMessageBody, lastMessageType = '') =>
  (dispatch) => {
    if (!sid) return;

    try {
      // Updates the channel list message body text
      dispatch({
        type: SET_CHAT_CHANNEL_LAST_MESSAGE,
        sid,
        lastMessage:
          lastMessageType === 'media'
            ? CHAT_MEDIA_MESSAGE_TEXT
            : lastMessageBody,
      });
    } catch (error) {
      handleCatch(null, 'updateChatChannelLastMessage', error);
    }
  };

/**
 * Set all Channel messages as read
 *
 * @param {string} sid Channel unique sid
 */
export const setChannelMessagesAsRead = (sid) => async (dispatch, getState) => {
  if (!sid) return;

  try {
    const {
      channels: { list },
    } = getState().chat;

    if (list[sid].channel) setMessagesConsumed(list[sid].channel); // Marks all Chat channel messages as read
    dispatch({ type: SET_CHAT_CHANNEL_MESSAGES_AS_READ });
  } catch (error) {
    handleCatch(null, 'setChannelMessagesAsRead', error);
  }
};

/**
 * Counts unread chat messages
 */
export const countUnreadChatMessages = () => (dispatch, getState) => {
  try {
    const {
      channels: { list },
    } = getState().chat;
    const unreadMessagesCount = calculateUnreadChatMessages(list); // Call to calculate unread Chat messages
    dispatch({
      type: SET_CHAT_CHANNEL_UNREAD_MESSAGE_COUNT,
      unreadMessagesCount,
    });
  } catch (error) {
    handleCatch(
      SET_CHAT_CHANNEL_UNREAD_MESSAGE_COUNT_FAILED,
      'countUnreadChatMessages',
      error,
      dispatch
    );
  }
};

/**
 * Removes the Chat channel from store
 *
 * @param {string} sid Channel unique sid
 */
export const removeChatChannel = (sid) => (dispatch) => {
  if (!sid) return;

  try {
    dispatch({ type: REMOVE_CHAT_CHANNEL, sid });
  } catch (error) {
    handleCatch(null, 'removeChatChannel', error);
  }
};
// #endregion Actions

// #region Helper methods
/**
 * Handle Catch method in case Action is failed to execute.
 *
 * @param {string} type Action Type
 * @param {string} methodname Error occured in method name
 * @param {object} error Error object
 * @param {Function} dispatch Redux dispatch function
 */
const handleCatch = (type, methodname, error, dispatch) => {
  if (type) dispatch({ type });

  let errorMessage = '';
  if (!error.response)
    errorMessage = 'Detected a connection problem, please refresh this page';
  else
    errorMessage =
      (((error || {}).response || {}).data || {}).message || 'Please try again';

  toastr.error(`Error: ${errorMessage}`);
  // eslint-disable-next-line no-console
  console.error(`An Error occured with ${methodname} ${error}`);
  logException(error);
};
// #endregion Helper methods
