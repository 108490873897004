import firebase from 'firebase/app';
import 'firebase/remote-config';
import chalk from "chalk";

let _isActivated = false;

export const isFeatureFlagActivated = () => {
    return _isActivated;
}

export const activateFeatureFlags = async () => {
    if (_isActivated) {
        return;
    }

    await remoteConfig.fetchAndActivate().then(() => {
        console.log(chalk.black.bgGrey.bold('===Firebase Remote Config initialized==='));
        _isActivated = true;
    });
}

/**
 * Init and activate feature flags.
 */
firebase.initializeApp(process.env.FIREBASE);
const remoteConfig = firebase.remoteConfig();
remoteConfig.defaultConfig = {
    buyer_bushwhack_enabled: true,
    buyer_mark_as_paid: true,
    buyer_order_details: true,
    buyer_shackleshot_enabled: false,
    buyer_integrations_enabled: false,
    chat_allow_all_market_vendors: true,
    chat_enabled: true,
    chat_order_specific_enabled: false,
    create_default_vendor_financial_setting_flag: false,
    receiving_tool_enabled: true,
    send_ims_delivery_day_excluded_buyers: []
};
remoteConfig.settings.minimumFetchIntervalMillis = 180000;
remoteConfig.setLogLevel(process.env.DEPLOY_ENV !== 'production' ? 'debug' : 'silent');
activateFeatureFlags();

/**
 * Get the firebase remote config to check feature-flag values.
 * 
 * @param {*} fetch - Refetch data. When false, will use the last cached values
 * @returns 
 */
export const getRemoteConfig = async (fetch = false) => {
    if (!_isActivated) {
        await activateFeatureFlags();
    } else if (fetch) {
        await remoteConfig.fetch();
    }

    return remoteConfig;
};

export const featureFlag = async (key) => {
    const remoteConfig = await getRemoteConfig();

    return remoteConfig.getString(key);
};

export const featureFlagBoolean = async (key) => {
    const remoteConfig = await getRemoteConfig();

    return remoteConfig.getBoolean(key);
};
