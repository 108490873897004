/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError } from '@v2/utils/logError';
import { isValid } from '@v2/utils/isValid';
import { logException } from '@/domains/shared/logger';
import { getAccessToken, getAPIConfig } from '@/api/APIClient';

/**
 * APIClient
 *
 * @deprecated (please use axios)
 */

/**
 * Handles a response from an API, passing the data on to the success
 * or error callback
 *
 * @param response - Endpoint response object
 * @param showErrorToaster - Should any errors received be displayed in a toaster
 */
function handleResponse(response: Response, showErrorToaster?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
        switch (response.status) {
            case 204:
                resolve({});
                break;
            default:
                response.json().then((data) => {
                    if (data?.success === false || data.error) {
                        logError(data.error, data, showErrorToaster);
                        reject(data);
                    } else {
                        resolve(data);
                    }
                })
                    .catch((error) => {
                        reject(error);
                    });
        }
    });
}

/**
 * Handles an error that occurs when attempting to make a request to an API
 *
 * @param error - Error object
 * @param onError - Error callback
 */
function handleError(error): void {
    // TODO [PLAT-702]: Add retry logic

    logException(error);
}

/**
 * Makes an HTTP request
 *
 * @param url - The endpoint url
 * @param config - Request config containing method, body, headers, etc.
 * @param auth - Adds the bearer token for authentication
 * @param showErrorToaster - Should any errors received be displayed in a toaster
 */
export async function sendRequest(url: string, config?: RequestInit, auth?: boolean, showErrorToaster?: boolean): Promise<any> {
    let requestInit: RequestInit = config;
    if (auth) {
        const apiConfig = await getAPIConfig();
        const accessToken: string = await getAccessToken(apiConfig);

        if (!isValid(requestInit)) {
            requestInit = { headers: {} };
        }

        requestInit.headers = {
            ...config?.headers,
            Authorization: `Bearer ${accessToken}`
        };
    }

    return new Promise((resolve, reject) => {
        fetch(url, requestInit)
            .then((response: Response) => handleResponse(response, showErrorToaster))
            .then((data) => resolve(data))
            .catch((error) => {
                handleError(error);
                reject(error);
            });
    });
}
