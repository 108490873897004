import { useSelector } from 'react-redux';
import { RootState } from '..';

const useBuyerHook = ():RootState => {
    const { defaultBuyer, buyer, buyerMembers } = useSelector((store: RootState) => store.buyerReducer);

    return {
        buyer,
        defaultBuyer,
        buyerMembers
    };
};

export default useBuyerHook;
