import { GridColDef, Typography } from '@notch-ordering/ui-components';
import React, { ReactNode } from 'react';
import { GetTransactionStatus, Transaction, TransactionStatus } from '@ar/network/AccountsReceivable.network';

import { InvoicePaymentMethodColumn } from '@ar/components/Invoices/InvoicePaymentMethodColumn/InvoicePaymentMethodColumn';
import CheckIcon from '@icons/check-icon.svg';
import { InvoiceAmountColumn } from '@ar/components/Invoices/InvoiceAmountColumn/InvoiceAmountColumn';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { BillsActionButtonColumn } from '@/ap/components/Bills/BillsActionButtonColumn/BillsActionButtonColumn';

export const headerClassName = 'text-gray-600 font-body text-med';
export const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
};

export const INVOICE_COLUMNS = {
    total: 'total',
    invoiceDate: 'invoice_date',
    dueDate: 'due_date',
    customer: 'business_name',
    supplier: 'supplier_name',
    remainingAmount: 'outstanding_amount',
    paymentMethod: 'paymentMethod',
    invoiceNumber: 'invoice_number',
    synced: 'sync',
    actions: 'actions',
    surchargeAmount: 'surcharge_amount'
} as const;

export const getColumns = (): GridColDef<Transaction>[] => [{
    ...gridColDef,
    field: INVOICE_COLUMNS.total,
    headerName: 'Total Amount',
    minWidth: 300,
    cellClassName: 'overflow-visible', // This must be set for the popover to be visible
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => <InvoiceAmountColumn isBill={true} invoice={row}/>,
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.remainingAmount,
    headerName: 'Remaining Amount',
    minWidth: 160,
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>,
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.surchargeAmount,
    headerName: 'Surcharge',
    minWidth: 160,
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography>,
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.invoiceDate,
    headerName: 'Issue date',
    minWidth: 120,
    flex: 1,
    sortable: true,
    // row.invoice_date is stored in UTC format but is actually local time midnight in the database
    // e.g. If I am in Toronto (UTC -4) and its June 18th 5pm then if the issue date is set to today it will be stored as June 18th midnight in the database
    // e.g. if I am in Dubai (UTC +4) and its June 19th 1am then if the issue date is set to today it will be stored as June 19th midnight in the database
    // The issue date is received from the database without HH/mm/ss and is presented as UTC
    renderCell: ({ row }) => (row.invoice_date ? <div>{formatIsoStringToUtcDate(new Date(row.invoice_date).toISOString(), 'MMM d, yyyy')}</div> : '--'),
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.dueDate,
    headerName: 'Due date',
    minWidth: 120,
    flex: 1,
    sortable: true,
    // row.due_date is stored in UTC format but is actually local time midnight in the database
    // e.g. If I am in Toronto (UTC -4) and its June 18th 5pm then if the due date is set to today it will be stored as June 18th midnight in the database
    // e.g. if I am in Dubai (UTC +4) and its June 19th 1am then if the due date is set to today it will be stored as June 19th midnight in the database
    // The due date is received from the database without HH/mm/ss and is presented as UTC
    renderCell: ({ row }) => (row.due_date ? <div>{formatIsoStringToUtcDate(new Date(row.due_date).toISOString(), 'MMM d, yyyy')}</div> : '--'),
}, {
    ...gridColDef,
    field: INVOICE_COLUMNS.customer,
    headerName: 'Customer',
    minWidth: 180,
    flex: 0.5,
    sortable: true,
    renderCell: ({ value }) => <Typography className="truncate">{value}</Typography>,
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.supplier,
    headerName: 'Supplier',
    minWidth: 180,
    flex: 0.5,
    sortable: true,
    renderCell: ({ value }) => <Typography className="truncate">{value}</Typography>,
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.paymentMethod,
    headerName: 'Payment Method',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <InvoicePaymentMethodColumn transaction={row}/>,
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.invoiceNumber,
    headerName: 'Invoice #',
    flex: 1,
    sortable: true,
    minWidth: 140,
},
{
    ...gridColDef,
    field: INVOICE_COLUMNS.synced,
    headerName: 'Synced',
    align: 'center',
    flex: 1,
    width: 54,
    maxWidth: 54,
    renderCell: ({ row }) => row.sync && <CheckIcon className="text-green-300 w-4 h-4" />,
},
{
    ...gridColDef,
    headerName: '',
    flex: 1,
    field: INVOICE_COLUMNS.actions,
    cellClassName: 'overflow-visible', // This must be set for the popover to be visible
    headerClassName: 'hidden',
    renderCell: ({ row }): ReactNode => <BillsActionButtonColumn invoice={row}/>,
},
];

export const getBillStatus = (status: GetTransactionStatus): GetTransactionStatus => (status === TransactionStatus.DoNotCharge ? TransactionStatus.Disputed : status);
