import { createStore, applyMiddleware, compose } from 'redux';
import createDebounce from 'redux-debounced';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';
import createSagaMiddleware from 'redux-saga';
import { offline } from '@redux-offline/redux-offline';
import defaultConfig from '@redux-offline/redux-offline/lib/defaults';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import AppReducer from '../reducers';
import sagas from '../sagas';
import enhancedReduxThunk from '../redux-middleware/enhancedReduxThunk';
import packageInfo from '../../package.json';

export default function configureStore(preloadedState) {
  const middleware = routerMiddleware(browserHistory);

  // Initialize LogRocket
  if (process.env.LOGROCKET_APP_ID) {
    LogRocket.init(process.env.LOGROCKET_APP_ID, {
      release: packageInfo.version,
    });
    setupLogRocketReact(LogRocket);
  }

  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();

  // Don't persist any reducers for now.
  const offlineConfig = {
    ...defaultConfig,
    persist: (any) => !any,
  };

  const store = createStore(
    AppReducer,
    preloadedState,
    compose(
      applyMiddleware(
        middleware,
        createDebounce(),
        enhancedReduxThunk,
        sagaMiddleware,
        LogRocket.reduxMiddleware()
      ),
      offline(offlineConfig)
    )
  );

  // Run the saga
  sagaMiddleware.run(sagas);

  return store;
}
