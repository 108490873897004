import { push } from 'react-router-redux';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

import * as types from '../constants/ActionTypes';
import * as checkoutActions from './checkoutActions';
import * as checkoutSelectors from '../selectors/checkoutSelectors';
import * as analyticsActions from './analyticsActions';
import * as cartActions from './cartActions';
import * as DataAPI from '../api/DataAPI';
import Utils from '../utils';
import { logException, logMessage } from '../domains/shared/logger';
import { getProductFromOrderItems } from '../helpers/product';
import * as vendorsActions from "@/actions/vendorsActions";
import { createCustomProduct, deleteCustomProduct, listCustomProductsMap, updateCustomProduct } from "@v2/network/SecretShopAPI";

// Temp global variables used for keeping track of products added to the cart to avoid race conditions
const newOrderProductAPICalls = {};
const editOrderProductAPICalls = {};

//
// Exported Actions
//
export function preProcessOrders(
  ordersServerFormat,
  prevOrders,
  isPendingOrders = false
) {
  return (dispatch, getState) => {
    const { vendors } = getState().vendorsReducer;

    dispatch({ type: types.PREPROCESS_ORDERS });

    let showOrdersTotal = true;

    const processedOrders = preProcessOrdersHelper(
      ordersServerFormat,
      prevOrders,
      isPendingOrders,
      vendors
    );

    // TODO: This is best suited to be a selector
    // If any orders has a total of 0, then we don't show the total
    for (const order of processedOrders) {
      if (order.items.length > 0 && order.total === 0) {
        showOrdersTotal = false;
        break;
      }
    }

    // Add Orders to store.
    if (isPendingOrders) {
      // We add the received orders to the pendingOrders property in the OrdersReducer
      dispatch(receivePendingOrders(processedOrders));

      // If we are in checkout we add received orders to the 'orders' property too in the
      // ordersReducer. Because the views are identical for both
      if (
        window.location.href.includes('checkout') &&
        !window.location.href.includes('confirmation')
      ) {
        dispatch(receiveOrders(processedOrders, showOrdersTotal));
      }
    } else {
      // We add the orders received to the orders property in the OrdersReducer
      dispatch(receiveOrders(processedOrders, showOrdersTotal));
    }
  };
}

// Usually just cart orders, but in the checkout view it could be pending orders.
function receiveOrders(processedOrders, showOrdersTotal) {
  return (dispatch) => {
    if (processedOrders.length) {
      const safeUrls = processedOrders.reduce((acc, order) => {
        if (order.isInShoppingCart && order.items.length > 0) {
          acc.push(order.urlsafe);
        }
        return acc;
      }, []);

      dispatch(fetchCartOrderCustomItems(safeUrls));
    }

    dispatch({
      type: types.RECEIVE_ORDERS,
      orders: processedOrders,
      showOrdersTotal,
    });
  };
}

function receivePendingOrders(processedOrders) {
  return {
    type: types.RECEIVE_PENDING_ORDERS,
    pendingOrders: processedOrders,
  };
}

function receiveCompletedOrdersToday(completedOrdersToday) {
  return {
    type: types.RECEIVE_COMPLETED_ORDERS_TODAY,
    completedOrdersToday,
  };
}

export function receiveSingleOrders(singleOrder) {
  return {
    type: types.RECEIVE_SINGLE_ORDER,
    singleOrder,
  };
}

export function onClickEdit(orders, order_urlsafe, orderProduct) {
  const { orderIndex, orderProductIndex } = findOrderAndOrderProductIndex(
    orders,
    order_urlsafe,
    orderProduct
  );

  return {
    type: types.TOGGLE_EDIT_MODE_CART,
    orderIndex,
    orderProductIndex,
    editMode: !orderProduct.editMode,
  };
}

export function updateNoteState(orders, order_urlsafe, notes) {
  const index = orders.findIndex((order) => order.urlsafe === order_urlsafe);

  return {
    type: types.SAVE_NOTE_CHECKOUT,
    index,
    notes,
  };
}

export function updateDeliveryDayState(orderUrlsafe, deliveryDay) {
  return {
    type: types.SAVE_DELIVERY_DAY,
    orderUrlsafe,
    deliveryDay,
  };
}

export function removeOrderFromCart(orders, order_urlSafe) {
  const orderIndex = orders.findIndex(
    (order) => order.urlsafe === order_urlSafe
  );

  return {
    type: types.REMOVE_ORDER_FROM_CART,
    orderIndex,
  };
}

export function changeSelectedDeliveryDayOptionIndex(
  orders,
  order_urlsafe,
  selectedDeliveryDayOptionIndex
) {
  const index = orders.findIndex((order) => order.urlsafe === order_urlsafe);

  return {
    type: types.CHANGE_SELECTED_DELIVERY_DAY_OPTION_INDEX,
    selectedDeliveryDayOptionIndex,
    index,
  };
}

export function showPreOrderRestriction(
  orders,
  order_urlsafe,
  preOrderRestricted
) {
  const index = orders.findIndex((order) => order.urlsafe === order_urlsafe);

  return {
    type: types.SHOW_PRE_ORDER_RESTRICTION,
    index,
    preOrderRestricted,
  };
}

export function deletePreOrderItemsForOrder(orders, orderUrlsafe) {
  return async (dispatch) => {
    const order = orders.find((o) => o.urlsafe === orderUrlsafe);

    // async/await here to execute each delete in sequence (not concurrently)
    for (const orderItem of order.items) {
      if (orderItem.isPreOrder) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(deleteOrderProduct(order.urlsafe, orderItem));
      }
    }
  };
}

export function toggleIsCheckoutOrder(orders, order_urlsafe, isCheckout) {
  const index = orders.findIndex((order) => order.urlsafe === order_urlsafe);

  return {
    type: types.TOGGLE_IS_CHECKOUT_ORDER,
    isCheckout: !isCheckout, // Toggle true/false
    index,
  };
}

//
// Internal Actions
//
function showLoader(loading) {
  return {
    type: types.SHOW_LOADER_ORDERS,
    loading,
  };
}

function updateQuantityCart(
  orders,
  order_urlSafe,
  orderProduct,
  quantity,
  type
) {
  const { orderIndex, orderProductIndex } = findOrderAndOrderProductIndex(
    orders,
    order_urlSafe,
    orderProduct
  );

  switch (type) {
    case Utils.QUANTITY_TYPES.INCREASE:
      return {
        type: types.INCREASE_QUANTITY_CART,
        quantity,
        orderIndex,
        orderProductIndex,
      };

    case Utils.QUANTITY_TYPES.DECREASE:
      return {
        type: types.DECREASE_QUANTITY_CART,
        quantity,
        orderIndex,
        orderProductIndex,
      };

    case Utils.QUANTITY_TYPES.CUSTOM:
      return {
        type: types.CUSTOM_QUANTITY_CART,
        quantity,
        orderIndex,
        orderProductIndex,
      };
  }
}

function addProductToCartAfterError(orderIndex, previousItems) {
  return {
    type: types.ADD_PRODUCT_TO_CART_ERROR,
    orderIndex,
    previousItems,
  };
}

function removeProductFromCart(orders, orderUrlSafe, orderProduct) {
  const { orderIndex, orderProductIndex } = findOrderAndOrderProductIndex(
    orders,
    orderUrlSafe,
    orderProduct
  );

  return {
    type: types.REMOVE_PRODUCT_FROM_CART,
    orderIndex,
    orderProductIndex,
  };
}

function updateNoteCart(orders, orderUrlSafe, orderProduct, note) {
  const { orderIndex, orderProductIndex } = findOrderAndOrderProductIndex(
    orders,
    orderUrlSafe,
    orderProduct
  );

  return {
    type: types.SAVE_NOTE_CART,
    orderIndex,
    orderProductIndex,
    note,
    editMode: false,
  };
}

function checkToShowOrdersTotal(orders) {
  const index = orders
    .filter((order) => order.items.length > 0)
    .findIndex((order) => order.total === 0);

  // If we find any orders totalling zero, then we don't show orders total
  const showOrdersTotal = index > -1 ? false : true;

  return {
    type: types.SHOW_ORDERS_TOTAL_CHECK,
    showOrdersTotal,
  };
}

function addNewOrderProduct(
  orders,
  order_urlSafe,
  newOrderProduct,
  quantity,
  price
) {
  const { orderIndex } = findOrderAndOrderProductIndex(
    orders,
    order_urlSafe,
    newOrderProduct
  );

  return {
    type: types.ADD_NEW_PRODUCT_CART,
    newOrderProduct,
    orderIndex,
    quantity,
    price,
  };
}

export function addURLSafeToCartItem(
  orders,
  orderUrlsafe,
  orderProduct,
  urlsafe
) {
  if (!orderUrlsafe || !orderProduct || !urlsafe) {
    logException(
      `addURLSafeToCartItem: Missing params ${orderUrlsafe} ${orderProduct} ${urlsafe}`
    );
    return;
  }
  const { orderIndex, orderProductIndex } = findOrderAndOrderProductIndex(
    orders,
    orderUrlsafe,
    orderProduct
  );

  return (dispatch) => {
    // IMPORTANT IF STATEMENT
    // There is a chance orderProductIndex will be -1 (not found).
    // This happens if we add a new item and then we delete it before this method gets called.
    if (orderProductIndex > -1) {
      dispatch({
        type: types.ADD_URLSAFE_TO_CART_ITEM,
        orderIndex,
        orderProductIndex,
        urlsafe,
      });
    }
  };
}

function fetchTaxedItem() {
  return {
    type: 'FETCHING_TAXED_ITEM',
  };
}

function fetchTaxedItemSuccess() {
  return {
    type: 'FETCHING_TAXED_ITEM_SUCCESS',
  };
}

function changeOrderPosition(orders, order_urlSafe, newPositionIndex) {
  const orderIndex = orders.findIndex(
    (order) => order.urlsafe === order_urlSafe
  );

  // Make sure the newPositionIndex is smaller or equal than the length of orders - 1
  newPositionIndex =
    newPositionIndex <= orders.length - 1 ? newPositionIndex : 0;

  return {
    type: types.CHANGE_ORDER_POSITION,
    orderIndex,
    newPositionIndex,
  };
}

export function updateOriginalQuantity(orders, orderUrlsafe, orderProduct) {
  const { orderIndex, orderProductIndex } = findOrderAndOrderProductIndex(
    orders,
    orderUrlsafe,
    orderProduct
  );

  return {
    type: types.UPDATE_ORIGINAL_QUANTITY,
    orderIndex,
    orderProductIndex,
    originalQuantity: orderProduct.quantity,
  };
}

//
// Async Thunk Actions
//
export const loadCartOrders = (loadVendors = false) => {
  return async (dispatch, getState) => {
    const { prevOrders } = getState().ordersReducer?.orders ?? [];
    try {
      dispatch({ type: types.FETCH_CART_ORDERS_REQUEST });
      if(loadVendors){
        await dispatch(vendorsActions.loadVendors());
      }

      dispatch(cartActions.showCartLoader(true));

      const response = await DataAPI.fetchCartOrders();

      dispatch({ type: types.FETCH_CART_ORDERS_SUCCESS });

      if (!response.data || !response.data.data) {
        throw new Error(
          `Endpoint did not return expected order data in the response`
        );
      }

      dispatch(preProcessOrders(response.data.data, prevOrders, false));
      dispatch(cartActions.showCartLoader(false));

      return response.data.data;
    } catch (error) {
      dispatch({ type: types.FETCH_CART_ORDERS_FAILED });
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).error_msg ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadCartOrders');
      console.error(error);
      logException(error);
    }
  };
};

/** Fetches and saves into the Redux store the pending orders */
export const loadPendingOrders = ({
  showLoadingPage,
  includeAddOnOrders = false,
} = {}) => {
  return async (dispatch, getState) => {
    const { orders } = getState().ordersReducer;

    try {
    dispatch({ type: types.FETCH_PENDING_ORDERS_REQUEST });

      if (showLoadingPage) {
        dispatch(showLoader(true));
      }

      const deliveryDayStart = Utils.formatDate(moment());
      const response = await DataAPI.searchOrders({
        deliveryDayStart,
        isInShoppingCart: false,
        limit: 100,
        includeAddOnOrders,
      });

      if (response && response.data) {
        dispatch({ type: types.FETCH_PENDING_ORDERS_SUCCESS });

        const pendingOrders = response.data.data || [];

        dispatch(preProcessOrders(pendingOrders, orders, true));
        return pendingOrders;
      } else {
        throw new Error(
          `Error, response from server has issues in loadPendingOrders, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.FETCH_PENDING_ORDERS_FAILED });
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).error_msg ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadPendingOrders');
      console.error(error);
      logException(error);
    }
  };
};

/** Fetches and saves into the Redux store the orders that will be delivered today */
export const loadOrdersForDeliveryToday = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_ORDERS_FOR_DELIVERY_TODAY_REQUEST });

      const today = Utils.formatDate(moment());
      const response = await DataAPI.searchOrders({
        isInShoppingCart: false,
        deliveryDayStart: today,
        deliveryDayEnd: today,
        limit: 100,
      });

      if (response && response.data) {
        const ordersForToday = response.data.data || [];
        const filteredOrdersForToday = ordersForToday.filter(
          (o) => !o.isAddOnOrder
        );

        dispatch({ type: types.FETCH_ORDERS_FOR_DELIVERY_TODAY_SUCCESS });
        dispatch(receiveCompletedOrdersToday(filteredOrdersForToday));

        return filteredOrdersForToday;
      } else {
        throw new Error(
          `Error, response from server has issues in loadOrdersForDeliveryToday, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.FETCH_ORDERS_FOR_DELIVERY_TODAY_FAILED });
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).error_msg ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadOrdersForDeliveryToday');
      console.error(error);
      logException(error);
    }
  };
};

export const loadSingleOrder = (orderUrlsafe) => {
    return async (dispatch) => {
      try {
        dispatch({ type: types.FETCH_SINGLE_ORDER_REQUEST });
  
        const response = await DataAPI.fetchSingleOrder(orderUrlsafe);
        let receiveSingleOrder = {};
  
        if (response && response.data) {
          receiveSingleOrder = {
            ...response.data,
          };
        }
  
        if (receiveSingleOrder) {
          dispatch(
            receiveSingleOrders({
              ...receiveSingleOrder,
            })
          );
  
          dispatch(fetchCartOrderCustomItems([receiveSingleOrder.urlsafe]));
          dispatch({ type: types.FETCH_SINGLE_ORDER_SUCCESS });
        } else {
          throw new Error(
            `Error, response from server has issues in loadSingleOrder, response is ${response}`
          );
        }
      } catch (error) {
        dispatch({ type: types.FETCH_SINGLE_ORDER_FAILED });
  
        let errorMessage = '';
  
        if (!error.response) {
          errorMessage =
            'Detected a connection problem, please refresh this page';
        } else {
          errorMessage =
            (((error || {}).response || {}).data || {}).errorMessage ||
            'Please try again';
          // 403 error status indicates fetchSingleOrder tried to retrieve an order that does not belong to the current buyer
          // Redirect to all invoices page to avoid showing the user a blank invoice page
          if (((error || {}).response || {}).status === 403) {
            dispatch(push('/invoices/'));
          }
        }
  
        toastr.error(`Error: ${errorMessage}`);
        console.error('An Error occured with loadSingleOrder');
        console.error(error);
        logException(error);
      }
    };
  };

export const changeQuantityWithID = (
  order_urlSafe,
  orderProduct,
  oldQuantity,
  type,
  source = '',
  isFromCart = false,
  isDebounceActive = true,
  notes = ''
) => {
  const thunk = async (dispatch, getState) => {
    dispatch({ type: types.CHANGE_QUANTITY_CART_WITH_ID });

    const { orders } = getState().ordersReducer;
    const { vendors } = getState().vendorsReducer;
    const { isPendingOrders } = getState().checkoutReducer;
    const { buyer } = getState().buyerReducer;

    if (!orderProduct) {
      toastr.error(
        'Error: We Could Not Find Your Item, Please Contact Support'
      );
      return;
    }

    dispatch(checkToShowOrdersTotal(orders));

    // First let the helper determine the future value of the quantity
    const quantity = Utils.changeQuantityHelper(oldQuantity, type);

    const orderProduct_urlsafe = orderProduct.urlsafe;

    // If the new quantity has not changed, don't continue with request or changing any data.
    if (quantity === orderProduct.quantity) {
      return;
    }

    const data = {
      orders,
      isPendingOrders,
      order_urlSafe,
      vendors,
      orderProduct,
      newQuantity: quantity,
    };

    // THIS APPLIES TO CHECKOUT PENDING ORDERS ONLY
    // Determine when the user is adding a new Qty if their change is below the minimumOrderAmount
    // but for pending orders and buying group vendors only.
    const { result: isBelowMinimum, vendorMinOrderAmount: minimumOrderAmount } =
      belowMinPendingOrderAmnt(data);
    if (isBelowMinimum && !_.isEmpty(orderProduct)) {
      // EDGE CASE: For when you are updating quantites using the text field and not
      // using the + or - button.
      // This if statement determines if it is in fact updated using the textbox and if so,
      // will Revert the quantity to the previous qty it was before it got changed.
      // However the previous qty is not really known so we Revert back to last known qty from server.
      // We have to make sure to send a server call as well to make sure the local/remote data are in sync.
      if (type === Utils.QUANTITY_TYPES.CUSTOM && typeof oldQuantity === 'string') {
        // Figure out the old quantity
        let calculatedOldQty = orderProduct.total / orderProduct.price;
        calculatedOldQty = parseInt(calculatedOldQty);

        dispatch(
          changeQuantityWithID(
            order_urlSafe,
            orderProduct,
            calculatedOldQty,
              Utils.QUANTITY_TYPES.CUSTOM
          )
        );
      }
      toastr.error(
        `Error: Your changes to this order will make it below the minimum order amount of ${minimumOrderAmount}`
      );
      return;
    }

    if (quantity !== null || quantity !== undefined) {
      // If the quantity is 0 then we delete the item and send a DELETE
      // request to the server to remove the item from the order
      if (quantity === 0) {
        const { orderIndex } = findOrderAndOrderProductIndex(
          orders,
          order_urlSafe,
          orderProduct
        );
        const previousItems = orders[orderIndex].items;
        // validates if this is the last item in the order
        if (previousItems?.length === 1) {
          // find the vendor for this order
          const vendor = vendors.find(
            (v) => v.urlsafe === orders[orderIndex].vendorUrlsafe
          );

          // PLAT-157 - Remove ALL custom items if ALL the products in an order got removed
          let customItems = getState().ordersReducer.customItems.orders[order_urlSafe];
          if (customItems != null) {
            for (const item of customItems) {
              dispatch(deleteCustomItem(order_urlSafe, item.id));
            }
          }

          // set the checkout order delivery day as the next available delivery day
          dispatch(
            checkoutActions.onSaveDeliveryDayFromCheckout(
              [orders[orderIndex]],
              orders[orderIndex].urlsafe,
              vendor?.region?.nextAvailableDeliveryDays?.[0]?.date || null
            )
          );
        }

        dispatch(removeProductFromCart(orders, order_urlSafe, orderProduct));

        dispatch(
          cartActions.showCartItemNotification({
            ...orderProduct,
            orderUrlSafe: order_urlSafe,
            quantity,
          })
        );

        try {
          editOrderProductAPICalls[orderProduct_urlsafe] = _.debounce(
            async (orderProduct, quantity, isPendingOrders) => {
              // Wrap in try catch since the debouncing call gets called at a differnt point than the entire Thunk.
              // This causes the entire Thunk to catch any errors wrapping inside debounce
              try {
                dispatch({ type: types.DELETE_ORDER_PRODUCT_REQUEST });

                await DataAPI.deleteOrderProduct(
                  order_urlSafe,
                  orderProduct.urlsafe,
                  isPendingOrders
                );

                // Update taxes after adding a new OrderProduct only when on Checkout screen
                if (window.location.href.includes('/checkout/')) {
                  if (orderProduct.isTaxable) {
                    const shippingAddressID = buyer.shippingAddresses[0].id;
                    await dispatch(
                      checkoutActions.checkoutUpdateTaxesFeesAndShippingAddress(
                        orders,
                        shippingAddressID,
                        isPendingOrders
                      )
                    );
                  }
                }

                if (isPendingOrders) {
                  toastr.success('Changes saved');
                }

                dispatch({ type: types.DELETE_ORDER_PRODUCT_SUCCESS });
                dispatch(
                  analyticsActions.removeItemsFromCart(
                    orders,
                    order_urlSafe,
                    orderProduct
                  )
                );
              } catch (error) {
                loadCartOrPendingOrders(dispatch);

                let errorMessage = '';
                if (!error.response) {
                  errorMessage =
                    'Detected a connection problem, please refresh this page';
                } else {
                  errorMessage =
                    (((error || {}).response || {}).data || {}).error_msg ||
                    'Please try again';
                }
                if (
                  errorMessage.includes('has already been sent to the vendor')
                ) {
                  errorMessage =
                    'This order has already been sent to your vendor, reloading page...';
                  setTimeout(() => window.location.reload(), 3000);
                }

                dispatch({ type: types.DELETE_ORDER_PRODUCT_FAILED });
                dispatch(addProductToCartAfterError(orderIndex, previousItems));
                toastr.error(errorMessage);
                console.error(error);
                logException(error);
              }
              //The call to hide the sidebar loader must be placed here
              //Because the call is debounced, the order is fully updated only here
              dispatch(checkoutActions.showLoaderCheckoutSidebar(false));
            },
            1000
          );
          editOrderProductAPICalls[orderProduct_urlsafe](
            orderProduct,
            0,
            isPendingOrders
          );
        } catch (error) {
          dispatch({ type: types.DELETE_ORDER_PRODUCT_FAILED });
          dispatch(addProductToCartAfterError(orderIndex, previousItems));
          toastr.error('Error Occurred, Please Try Again');
          console.error(error);
          logException(error);
        }
      } else {
        // Else, we update the quantity for the orderProduct id which is the orderProduct_urlsafe
        await dispatch(
          updateQuantityCart(
            orders,
            order_urlSafe,
            orderProduct,
            quantity,
            type
          )
        );

        dispatch(
          cartActions.showCartItemNotification({
            ...orderProduct,
            orderUrlSafe: order_urlSafe,
            quantity,
          })
        );

        // Don't proceed with data calls if we are editing an addOn orders.
        // We have to save the changes explicitly.
        const order = (orders || []).find((o) => o.urlsafe === order_urlSafe);
        if (order && order.isEditingAddOnOrder) {
          return;
        }

        if (
          !window.location.href.includes('/checkout/') &&
          isFromCart === false
        ) {
          // Re-position order to bottom of the cart
          await dispatch(
            changeOrderPosition(orders, order_urlSafe, orders.length - 1)
          );
        }

        try {
          if (editOrderProductAPICalls[orderProduct_urlsafe]) {
            editOrderProductAPICalls[orderProduct_urlsafe].cancel();
          }
          editOrderProductAPICalls[orderProduct_urlsafe] = _.debounce(
            async (orderProduct_urlsafe, quantity, isPendingOrders) => {
              // Wrap in try catch since the debouncing call gets called at a differnt point than the entire Thunk.
              // This causes the entire Thunk to catch any errors wrapping inside debounce
              try {
                const response = await DataAPI.editOrderProduct(
                  order_urlSafe,
                  orderProduct_urlsafe,
                  { quantity,
                    ...(!!notes && { notes }) || {}
                  },
                  isPendingOrders
                );

                if (response && response.data && !_.isEmpty(response)) {
                  const { orders } = getState().ordersReducer;

                  if (window.location.href.includes('/checkout/')) {
                    const order = (orders || []).find(
                      (o) => o.urlsafe === order_urlSafe
                    );

                    const vendor = vendors.find(
                      (v) => v.urlsafe === order.vendorUrlsafe
                    );

                    if (
                      order &&
                      vendor &&
                      order.isCheckout &&
                      !isPendingOrders &&
                      checkoutSelectors.checkIfBelowMinimumOrder({
                        order,
                        vendor,
                      })
                    ) {
                      const shippingAddressID = buyer.shippingAddresses[0].id;
                      await dispatch(
                        checkoutActions.checkoutUpdateTaxesFeesAndShippingAddress(
                          orders,
                          shippingAddressID
                        )
                      );
                    }
                  }

                  if (isPendingOrders) {
                    const { pendingOrders } = getState().ordersReducer;
                    const updatedOrder = response.data;
                    // Replace the updated pending order
                    const updatedOrders = pendingOrders.map((o) =>
                      o.urlsafe === updatedOrder.urlsafe ? updatedOrder : o
                    );

                    dispatch(
                      preProcessOrders(
                        updatedOrders,
                        pendingOrders,
                        isPendingOrders
                      )
                    );

                    if (window.location.href.includes('/checkout/')) {
                      toastr.success('Changes saved');
                    }
                  }
                }
              } catch (error) {
                loadCartOrPendingOrders(dispatch);

                let errorMessage = '';
                if (!error.response) {
                  errorMessage =
                    'Detected a connection problem, please refresh this page';
                } else {
                  errorMessage =
                    (((error || {}).response || {}).data || {}).message ||
                    `Error occurred updating quantities to ${orderProduct.name}. Please try again`;
                }

                if (
                  errorMessage.includes('has already been sent to the vendor')
                ) {
                  errorMessage =
                    'This order has already been sent to your vendor, reloading page...';
                  setTimeout(() => window.location.reload(), 3000);
                }
                toastr.error(errorMessage);
                logException(error);
              }

              dispatch({ type: types.EDIT_ORDER_PRODUCT_COMPLETE });
            },
            1500
          );
          editOrderProductAPICalls[orderProduct_urlsafe](
            orderProduct_urlsafe,
            quantity,
            isPendingOrders
          );

          dispatch(
            analyticsActions.addItemsToCart(order, orderProduct, source)
          );
        } catch (error) {
          // Change back to the old quantity if there was any server error
          dispatch(
            updateQuantityCart(
              orders,
              order_urlSafe,
              orderProduct,
              oldQuantity,
                Utils.QUANTITY_TYPES.CUSTOM
            )
          );
          toastr.error('Error Occurred changing quantities, Please Try Again');
          console.error(error);
          logException(error);
        }
      }
    } else {
      console.error(
        'There is NO orderProdcut_urlsafe when changing quantites with changeQuantityWithID function'
      );
    }
  };

  // Allow debouncing using redux-debounced middleware addon
  if (isDebounceActive) {
    thunk.meta = {
      debounce: {
        time: 100,
        key: `change-quantity-withID-action-for-${orderProduct.urlsafe}`,
      },
    };
  }

  return thunk;
};

export const changeQuantityWithoutID = (
  order_urlSafe,
  orderProduct,
  oldQuantity,
  type,
  source,
  product = { price: 0, id: '', vendorID: '', urlsafe: '' },
  isFromCart = false,
  isDebounceActive = true,
  notes = ''
) => {
  const thunk = async (dispatch, getState) => {
    dispatch({ type: types.CHANGE_QUANTITY_CART_NO_ID });

    const { orders } = getState().ordersReducer;
    const { isPendingOrders } = getState().checkoutReducer;
    const { buyer } = getState().buyerReducer;

    const sourceUrlsafe = orderProduct
      ? orderProduct.sourceUrlsafe
      : product.urlsafe;

    const order = (orders || []).find((o) => o.urlsafe === order_urlSafe);

    if (!order || !orders || orders.length === 0) {
      logException(
        `Order ${order_urlSafe} was not in store orders: (${(orders || []).map(
          (o) => o.urlsafe
        )}.`
      );
    }

    dispatch(checkToShowOrdersTotal(orders));

    // First let the helper determine the future value of the quantity
    const quantity = Utils.changeQuantityHelper(oldQuantity, type);

    /* When adding a new item to the cart during Checkout check if the orderProduct needs 48-hours.
    Disallow adding that item when the selected delivery date it today or tomorrow. */
    if (window.location.href.includes('/checkout/')) {
      if (
        order &&
        order.deliveryDay &&
        (Utils.isDateToday(order.deliveryDay) ||
          Utils.isDateTomorrow(order.deliveryDay)) &&
        orderProduct &&
        orderProduct.isPreOrder
      ) {
        toastr.error(
          `${
            orderProduct ? orderProduct.productName : product.productName
          } cannot be added to this order since it requires 48 hour notice. Please change the delivery day to add this item.`
        );
        return;
      }
    }

    // If the orderProduct exists and it has no ID, then we just update the existing orderProduct
    if (orderProduct) {
      // If the quantity is 0, remove this orderProduct from cart.
      if (quantity === 0) {
        dispatch(removeProductFromCart(orders, order_urlSafe, orderProduct));
      } else {
        // Else, update it's quantity only.
        dispatch(
          updateQuantityCart(
            orders,
            order_urlSafe,
            orderProduct,
            quantity,
            type
          )
        );

        if (
          !window.location.href.includes('/checkout/') &&
          isFromCart === false
        ) {
          // Re-position order to bottom of the cart
          dispatch(
            changeOrderPosition(orders, order_urlSafe, orders.length - 1)
          );
        }
      }

      dispatch(
        cartActions.showCartItemNotification({
          ...orderProduct,
          orderUrlSafe: order_urlSafe,
          quantity,
        })
      );
    } else {
      // If there is no orderProduct and product exists, then we need to create a NEW orderProduct
      // Exit this function if we have no orderProduct and the quantity is 0.
      // This means the user is just adding a new orderProduct
      if (quantity === 0) {
        return;
      }

      orderProduct = product;

      // Add new orderProduct to store
      dispatch(
        addNewOrderProduct(
          orders,
          order_urlSafe,
          orderProduct,
          quantity,
          product.price
        )
      );

      dispatch(
        cartActions.showCartItemNotification({
          ...orderProduct,
          orderUrlSafe: order_urlSafe,
          quantity,
        })
      );

      if (
        !window.location.href.includes('/checkout/') &&
        isFromCart === false
      ) {
        // Re-position order to bottom of the cart
        dispatch(changeOrderPosition(orders, order_urlSafe, orders.length - 1));
      }
    }

    if (quantity !== null || quantity !== undefined) {
      try {
        if (!isPendingOrders) {
          const data = {
            orderUrlsafe: order_urlSafe,
            sourceUrlsafe,
            quantity,
            ...(!!notes && { notes }) || {}
          };

          if (newOrderProductAPICalls[product.urlsafe]) {
            newOrderProductAPICalls[product.urlsafe].cancel();
          }

          newOrderProductAPICalls[product.urlsafe] = _.debounce(
            async (data, isPendingOrders) => {
              // Wrap in try catch since the debouncing call gets called at a differnt point than the entire Thunk.
              // This causes the entire Thunk to catch any errors wrapping inside debounce
              try {
                let response;

                // Don't proceed with data calls if we are editing an addOn orders.
                // We have to save the changes explicitly.
                if (order && !order.isEditingAddOnOrder) {
                  response = await DataAPI.addOrderProduct(
                    data.orderUrlsafe,
                    data
                  );
                }

                if (response && response.data && !_.isEmpty(response.data)) {
                  const newOrder = response.data;
                  const newOrderProduct = getProductFromOrderItems(
                    newOrder.items,
                    data.sourceUrlsafe
                  );

                  if (newOrderProduct) {
                    const { orders } = getState().ordersReducer;
                    dispatch(
                      addURLSafeToCartItem(
                        orders,
                        order_urlSafe,
                        orderProduct,
                        newOrderProduct.urlsafe
                      )
                    );
                  }

                  // Update taxes after adding a new taxable OrderProduct on Checkout screen
                  if (
                    window.location.href.includes('/checkout/') &&
                    !isPendingOrders
                  ) {
                    if (newOrderProduct.isTaxable) {
                      const shippingAddressID = buyer.shippingAddresses[0].id;
                      dispatch(
                        checkoutActions.checkoutUpdateTaxesFeesAndShippingAddress(
                          orders,
                          shippingAddressID
                        )
                      );
                    }
                  }

                  if (isPendingOrders) {
                    toastr.success('Changes saved');
                  }
                }
              } catch (error) {
                // Refresh cart orders to refresh results from server.
                // This is better than optimistically changing it back which might not be accurate.
                // We also wait 2 seconds to allow any items being added after the failure to make it to the cart.
                setTimeout(() => loadCartOrPendingOrders(dispatch), 2000);

                let errorMessage = '';
                if (!error.response) {
                  errorMessage =
                    'Detected a connection problem, please refresh this page';
                } else {
                  errorMessage =
                    (((error || {}).response || {}).data || {}).error_msg ||
                    `Error occurred updating quantities to ${orderProduct.productName}. Please try again`;
                }
                if (
                  errorMessage.includes('has already been sent to the vendor')
                ) {
                  errorMessage =
                    'This order has already been sent to your vendor, reloading page...';
                  setTimeout(() => window.location.reload(), 3000);
                }
                toastr.error(errorMessage);
                logException(error);
              }
            },
            1000
          );
          newOrderProductAPICalls[product.urlsafe](data, isPendingOrders);

          dispatch(
            analyticsActions.addItemsToCart(order, orderProduct, source)
          );
        } else {
          const { quantity } = orderProduct;

          const data = {
            orderUrlsafe: order_urlSafe,
            sourceUrlsafe,
            quantity,
          ...(!!notes && { notes }) || {}
          };

          // Don't proceed with data calls if we are editing an addOn orders.
          // We have to save the changes explicitly.
          if (order && order.isEditingAddOnOrder) {
            return;
          }

          const response = await DataAPI.addOrderProduct(
            data.orderUrlsafe,
            data,
            isPendingOrders
          );

          if (response && response.data) {
            const { orders } = getState().ordersReducer;
            const { urlsafe: productUrlsafe } = getProductFromOrderItems(
              response.data.items,
              orderProduct.sourceUrlsafe
            );
            dispatch(
              addURLSafeToCartItem(
                orders,
                order_urlSafe,
                orderProduct,
                productUrlsafe
              )
            );
          }

          if (window.location.href.includes('/checkout/')) {
            const { orders } = getState().ordersReducer;
            const { orderIndex, orderProductIndex } =
              findOrderAndOrderProductIndex(
                orders,
                order_urlSafe,
                orderProduct
              );
            if (orderProductIndex >= 0) {
              const op = orders[orderIndex].items[orderProductIndex];

              if (op.isTaxable) {
                const shippingAddressID = buyer.shippingAddresses[0].id;
                dispatch(
                  checkoutActions.checkoutUpdateTaxesFeesAndShippingAddress(
                    [orders[orderIndex]],
                    shippingAddressID,
                    isPendingOrders
                  )
                );
              } else {
                const response = await DataAPI.fetchSingleOrder(order_urlSafe);

                if (response && response.data && !_.isEmpty(response)) {
                  const { orders } = getState().ordersReducer;
                  dispatch(
                    preProcessOrders([response.data], orders, isPendingOrders)
                  );
                }
              }
            }
          }

          toastr.success('Changes saved');
        }
      } catch (error) {
        toastr.error('Error Occurred changing quantities, Please Try Again');
        console.error(error, 'error with request');
        logException(error);
        dispatch(removeProductFromCart(orders, order_urlSafe, orderProduct));
      }
    } else {
      console.error(
        'There is NO orderProdcut_urlsafe when changing quantites with changeQuantityWithID function'
      );
    }
  };

  // Allow debouncing using redux-debounced middleware addon
  if (isDebounceActive) {
    thunk.meta = {
      debounce: {
        time: 100,
        key: `change-quantity-withoutID-action-for-${product.urlsafe}`,
      },
    };
  }

  return thunk;
};

export const deleteOrderProduct = (orderUrlSafe, orderProduct) => {
  return async (dispatch, getState) => {
    try {
      const { orders } = getState().ordersReducer;
      const { isPendingOrders } = getState().checkoutReducer;

      dispatch(checkToShowOrdersTotal(orders));

      const orderProductUrlsafe = orderProduct.urlsafe;
      if (orderProductUrlsafe) {
        dispatch(removeProductFromCart(orders, orderUrlSafe, orderProduct));
        dispatch({ type: types.DELETE_ORDER_PRODUCT_REQUEST });

        await DataAPI.deleteOrderProduct(
          orderUrlSafe,
          orderProductUrlsafe,
          isPendingOrders
        );

        dispatch({ type: types.DELETE_ORDER_PRODUCT_SUCCESS });
        dispatch(
          analyticsActions.removeItemsFromCart(
            orders,
            orderUrlSafe,
            orderProduct
          )
        );
      }
    } catch (error) {
      dispatch({ type: types.DELETE_ORDER_PRODUCT_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).error_msg ||
          'Please try again';
      }
      if (errorMessage.includes('has already been sent to the vendor')) {
        errorMessage =
          'This order has already been sent to your vendor, reloading page...';
        setTimeout(() => window.location.reload(), 3000);
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with deleteOrderProduct');
      console.error(error);
      logException(error);
    }
  };
};

export const saveNoteCart = (orderUrlsafe, orderProduct, note) => {
  return async (dispatch, getState) => {
    try {
      const { orders } = getState().ordersReducer;
      const { isPendingOrders } = getState().checkoutReducer;

      dispatch(updateNoteCart(orders, orderUrlsafe, orderProduct, note));

      if (orderProduct.urlsafe) {
        const data = {
          notes: note,
          // WARNING: Need to send this or endpoint will wipe out qty
          quantity: orderProduct.quantity,
        };

        await DataAPI.editOrderProduct(
          orderUrlsafe,
          orderProduct.urlsafe,
          data,
          isPendingOrders
        );

        const order = orders.find((o) => o.urlsafe === orderUrlsafe);

        dispatch(
          analyticsActions.changeNotes(
            orderProduct,
            order.vendorName,
            order.vendorID
          )
        );
      } else {
        const { quantity, sourceUrlsafe } = orderProduct;

        const data = {
          notes: note,
          sourceUrlsafe,
          quantity,
        };

        const response = await DataAPI.addOrderProduct(orderUrlsafe, data);
        // Replace the empty urlsafe for this new orderProduct with the urlsafe,
        // we received from the serverif (response && response.data) {
        const newOrder = response.data || {};
        const newOrderProduct = getProductFromOrderItems(
          newOrder?.items,
          data.sourceUrlsafe
        );

        if (newOrderProduct) {
          const { orders } = getState().ordersReducer;
          dispatch(
            addURLSafeToCartItem(
              orders,
              orderUrlsafe,
              orderProduct,
              newOrderProduct.urlsafe
            )
          );
        }
      }
    } catch (error) {
      let errorMessage = '';
      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).error_msg ||
          `Error occurred adding your note. Please try again`;
      }
      if (errorMessage.includes('has already been sent to the vendor')) {
        errorMessage =
          'This order has already been sent to your vendor, reloading page...';
        setTimeout(() => window.location.reload(), 3000);
      }
      toastr.error(errorMessage);
      logException(error);
      console.error(error);
      console.error('Error occured with saveNoteCart');
    }
  };
};

export const saveNoteCartWithoutID = () => {
  // TODO not yet implemented, will be put to use when using these actions for the cart as well.
};

export const onSaveDeliveryDay = (
  orders,
  orderUrlsafe,
  deliveryDay,
  isPendingOrder = false
) => {
  return async (dispatch) => {
    try {
      const order = orders.find((order) => order.urlsafe === orderUrlsafe);

      if (order.preOrderRestricted) {
        dispatch(showPreOrderRestriction(orders, orderUrlsafe, false));
      }

      dispatch(updateDeliveryDayState(orderUrlsafe, deliveryDay));

      dispatch({ type: types.UPDATE_DELIVERY_DAY_REQUEST });

      const response = await DataAPI.patchOrder(
        orderUrlsafe,
        {
          deliveryDay: Utils.formatDate(deliveryDay),
        },
        isPendingOrder
      );

      dispatch({ type: types.UPDATE_DELIVERY_DAY_SUCCESS });

      if (response && response.data) {
        if (isPendingOrder) {
          toastr.success('Changes saved');
        }

        dispatch(
          analyticsActions.changeDeliveryDay(orders, orderUrlsafe, deliveryDay)
        );
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in onSaveDeliveryDay, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_DELIVERY_DAY_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }
      if (errorMessage.includes('has already been sent to the vendor')) {
        errorMessage =
          'This order has already been sent to your vendor, reloading page...';
        setTimeout(() => window.location.reload(), 3000);
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with onSaveDeliveryDay');
      console.error(error);
      logException(error);
    }
  };
};

export const resetOrder = (orderUrlsafe) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.RESET_ORDER_REQUEST });

      await DataAPI.resetOrder(orderUrlsafe);

      dispatch({ type: types.RESET_ORDER_SUCCESS });
    } catch (error) {
      dispatch({ type: types.RESET_ORDER_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with resetOrder');
      console.error(error);
      logException(error);
    }
  };
};

export const cancelOrder = (orders, orderUrlsafe) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CANCEL_ORDER_REQUEST });

      await DataAPI.cancelOrder(orderUrlsafe);

      dispatch({ type: types.CANCEL_ORDER_SUCCESS });
      dispatch(analyticsActions.deleteOrder(orders, orderUrlsafe));
    } catch (error) {
      dispatch({ type: types.CANCEL_ORDER_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }
      if (errorMessage.includes('has already been sent to the vendor')) {
        errorMessage =
          'This order has already been sent to your vendor, reloading page...';
        setTimeout(() => window.location.reload(), 3000);
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with cancelOrder');
      console.error(error);
      logException(error);
    }
  };
};

export const loadCartOrPendingOrders = (dispatch) => {
  // Load cart orders if we are not changing anything in a pending state.
  // load pending orders if we are in the pending state in checkout.
  if (window.location.href.includes('/checkout/pending/')) {
    const isPendingOrders = true;
    dispatch(checkoutActions.loadOrdersCheckout(isPendingOrders));

    return;
  }

  dispatch(loadCartOrders());
};

export const reverseOrder = (orders, orderUrlsafe) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.REVERSE_ORDER_REQUEST });

      await DataAPI.resetOrder(orderUrlsafe);

      dispatch({ type: types.REVERSE_ORDER_SUCCESS });
      dispatch(analyticsActions.reverseOrder(orders, orderUrlsafe));
    } catch (error) {
      dispatch({ type: types.REVERSE_ORDER_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }
      if (errorMessage.includes('has already been sent to the vendor')) {
        errorMessage =
          'This order has already been sent to your vendor, reloading page...';
        setTimeout(() => window.location.reload(), 3000);
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with reverseOrder');
      console.error(error);
      logException(error);
    }
  };
};

export const reorder = (order, fromScreen) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.REORDER_REQUEST });

      const response = await DataAPI.reorder(order.urlsafe);

      dispatch({ type: types.REORDER_SUCCESS });

      if (response && response.data) {
        // open and refresh the cart to load the new products added from reorder
        dispatch(loadCartOrders(true));
        dispatch(cartActions.showCart(true));
        toastr.success(
          'Items successfully added to your cart!',
          '*Prices and availabilities may vary',
          { timeOut: 6000 }
        );
        dispatch(analyticsActions.reorder(order, fromScreen));
      }
    } catch (error) {
      let errorMessage = '';
      dispatch({ type: types.REORDER_FAILED });

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else if (
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.includes('items are unavailable')
      ) {
        errorMessage = 'Item out of stock';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with reorder');
      console.error(error);
      logException(error);
    }
  };
};

export const updateOrdersDeliveryRequests = (
  deliveryContactName,
  deliveryContactNumber,
  deliveryRequests,
  orderId
) => {
  return async (dispatch, getState) => {
    const { orders } = getState().ordersReducer;

    // Combine special changes into one string
    const specialDeliveryRequests = `deliveryRequests: ${deliveryRequests}; deliveryContactName: ${deliveryContactName}; deliveryContactNumber: ${deliveryContactNumber}`;
    // Can use first order since all the orders (which are being checked out) have the same delivery requests
    const previousDeliveryRequests = orders[0]
      ? orders[0].deliveryRequests
      : '';

    try {
      const orderUpdateRequests = [];

      // Order locations are updated optimistically in the reducer first
      for (const [index, order] of orders.entries()) {
        // Here we need to do two things:
        // 1) We need to update every order's deliveryRequests in the reducer
        // 2) We need to prepare server calls to update the order's deliveryRequests in an array to later be called in parallel

        if (orderId && orderId !== order.urlsafe) {
          continue;
        }

        dispatch({
          type: types.UPDATE_ORDER_DELIVERY_REQUESTS_STATE_ONLY,
          index,
          specialDeliveryRequests,
        });

        orderUpdateRequests.push(
          DataAPI.patchOrder(order.urlsafe, {
            deliveryRequests: specialDeliveryRequests,
          })
        );
      }

      dispatch({ type: types.UPDATE_ORDER_DELIVERY_REQUESTS_REQUEST });

      await Promise.all(orderUpdateRequests);

      dispatch({ type: types.UPDATE_ORDER_DELIVERY_REQUESTS_SUCCESS });
      dispatch(
        checkoutActions.changeOrderDeliveryRequestsView(
          'ShowDeliveryRequestsView'
        )
      );
    } catch (error) {
      dispatch({ type: types.UPDATE_ORDER_DELIVERY_REQUESTS_FAILED });

      // Revert to previous location if optimisic update fails
      for (const [index] of orders.entries()) {
        dispatch({
          type: types.UPDATE_ORDER_DELIVERY_REQUESTS_STATE_ONLY,
          index,
          specialDeliveryRequests: previousDeliveryRequests,
        });
      }
      dispatch(
        checkoutActions.changeOrderDeliveryRequestsView(
          'ShowDeliveryRequestsView'
        )
      );

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Error updating location and special delivery requests, please try again';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).error_msg ||
          'Please try again';
      }

      logException(error);
      toastr.error(errorMessage);
      console.error('An Error occured with updateOrdersDeliveryRequests');
      console.error(error);
    }
  };
};

export const updateDeliveryInstructions = (
  orderUrlsafe,
  deliveryInstructions
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.UPDATE_ORDER_DELIVERY_REQUESTS_REQUEST });
      await DataAPI.patchOrder(orderUrlsafe, {
        deliveryInstructions,
      });

      dispatch({ type: types.UPDATE_ORDER_DELIVERY_REQUESTS_SUCCESS });
      dispatch(
        checkoutActions.changeOrderDeliveryRequestsView(
          'ShowDeliveryRequestsView'
        )
      );

      const orderIdx = getState().ordersReducer.orders.findIndex(
        (order) => order.urlsafe === orderUrlsafe
      );
      if (orderIdx > -1) {
        dispatch({
          type: types.UPDATE_ORDER_DELIVERY_REQUESTS_STATE_ONLY,
          index: orderIdx,
          specialDeliveryRequests: deliveryInstructions,
        });
      }
    } catch (error) {
      let errorMessage = '';
      if (!error.response) {
        errorMessage =
          'Error updating location and special delivery requests, please try again';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).error_msg ||
          'Please try again';
      }

      toastr.error(errorMessage);
    }
  };
};

export const updateRecentPendingOrders = (recentOrders) => {
  return async (dispatch) => {
    const compareOrders = async () => {
      try {
        const pendingOrders = (
          await dispatch(loadPendingOrders({ showLoadingPage: false }))
        ).map((po) => po.urlsafe);
        // make sure all of the recent orders are part of the pending orders
        const hasFoundAllOrders = recentOrders.every(({ urlsafe }) =>
          pendingOrders.includes(urlsafe)
        );
        if (!hasFoundAllOrders && retries > 0) {
          // some of the orders are missing, let's retry
          retries--;
          setTimeout(compareOrders, 3000);
        }
      } catch (e) {
        // continue
        retries--;
      }
    };
    let retries = 4;
    setTimeout(compareOrders, 3000);
  };
};

//
// Helpers
//

// Helper to find the orderIndex and OrderProductIndex to help us know which orderProduct to change
export const findOrderAndOrderProductIndex = (
  orders,
  orderUrlsafe,
  orderProduct
) => {
  const orderIndex = orders.findIndex(
    (order) => order.urlsafe === orderUrlsafe
  );

  let orderProductIndex = -1;

  if (orderProduct && orderIndex > -1) {
    if (orderProduct.urlsafe) {
      orderProductIndex = orders[orderIndex].items.findIndex(
        (_orderProduct) => _orderProduct.urlsafe === orderProduct.urlsafe
      );
    } else if (orderProduct.productCode && orderProduct.sourceUrlsafe) {
      // If the orderProduct doesn't have a urlsafe, then we try to look up the index using productCode and sourceUrlsafe
      orderProductIndex = orders[orderIndex].items.findIndex(
        (_orderProduct) => {
          return (
            _orderProduct.productCode === orderProduct.productCode &&
            _orderProduct.sourceUrlsafe === orderProduct.sourceUrlsafe
          );
        }
      );
    }
  }

  return { orderIndex, orderProductIndex };
};

// Helper functon to PREVENT users from adding/removing items to the cart if it will make
// the order total below the minimumOrderAmount for Pending Orders Only and for Buying Group Vendors Only.
function belowMinPendingOrderAmnt(data) {
  const {
    isPendingOrders,
    orders,
    orderProduct,
    order_urlSafe,
    vendors,
    newQuantity,
  } = data;

  let result = false;
  let vendorMinOrderAmount = 0;
  let vendorDeliveryFee = 0;

  // If this Not a pending order, then ignore this.
  // Allow the user to change quantities freely.
  if (!isPendingOrders) {
    result = false;
  } else {
    // Find the vendor we're looking for and determine the minimumOrderAmount and if they are
    // a buying group vendor.
    if (vendors && orders && order_urlSafe) {
      const order = orders.find((order) => order.urlsafe === order_urlSafe);

      const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

      if (vendor) {
        vendorMinOrderAmount = vendor.region.minimumOrderAmount || 0;
        vendorDeliveryFee = vendor.region.deliveryFee || 0;
      } else {
        // If there's no vendor found return false and exit out of the function.
        return false;
      }
    }

    // Ignore below minimum pending if we have a delivery fee.
    if (vendorDeliveryFee > 0) {
      return false;
    }

    // Check if we are below the minimum order amount for a quantity change on an order item.
    // But only if the following conditions are met:
    // 1) The vendor is a buying group vendor.
    // 2) The vendorMinOrderAmount is above 0, we ignore it if it is 0 (the default).
    // 3) This vendor actually has a list of pending orders.
    if (vendorMinOrderAmount > 0 && orders.length > 0) {
      // Find the order we are editing
      const order = orders.find((order) => order.urlsafe === order_urlSafe);

      if (order) {
        let newOrderSubtotal = 0;

        // Loop through all the order items to calculate the new would be total for the order.
        for (let i = 0; i < order.items.length > 0; i++) {
          const orderItem = order.items[i];

          // If it's the order item being changes, we want to add the new order total
          // using the newQuantity the user selects.
          if (orderItem.urlsafe === orderProduct.urlsafe) {
            newOrderSubtotal =
              newOrderSubtotal + newQuantity * orderProduct.price;
          } else {
            // Otherwise, if this is an order item not currently being changes, we still
            // need to add it to the total using current quantity * price.
            // Note: Do not rely on the orderItem total, because after the user makes one
            // change to the quantity, the local data is innacurate.
            newOrderSubtotal =
              newOrderSubtotal + orderItem.quantity * orderItem.price;
          }
        }

        // If the new quantity or removal of the item will result
        // in the newOrderSubtotal becoming less than the minimumOrderAmount return true.
        if (newOrderSubtotal < vendorMinOrderAmount) {
          result = true;
        }
      } else {
        result = false;
      }
    } else {
      result = false;
    }
  }

  return { result, vendorMinOrderAmount };
}

// Helper to preProcess Orders
function preProcessOrdersHelper(
  ordersServerFormat,
  prevOrders,
  isPendingOrders,
  vendors
) {
  const processedOrders = [];

  const orders = ordersServerFormat.filter(
    (order) => !(order.isReplacementOrder || order.isShortedOrder)
  );

  for (const order of orders) {
    // Determine if this is going to be edited as an addOn Order
    // TODO Remove the first two checks when moving to v3
    const isEditingAddOnOrder =
      order.readyForQc ||
      order.vendorEmailSend ||
      order.isReadyForQC ||
      order.isVendorEmailSent ||
      false;

    // 1) Ignore addon orders from being in the reducer
    // 2) Ignore any corrupted value order.items
    if (order.isAddOnOrder || !order.items) {
      continue;
    }

    // We need to preProcess the order Items in case, we are editing an AddOn order in the checkout
    // Filter out empty items returned by the endpoint
    let processedOrderItems = order.items.filter((i) => !_.isEmpty(i));

    const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

    // Filter out orders for which we don't have the Vendor object.
    // Orders from Inactive vendors are returned, but the vendor objects are not returned by the backend
    if (!vendor) {
      continue;
    }

    // Get Vendor's nextAvailableDeliveryDays for order
    const nextAvailableDeliveryDays = vendor.region.nextAvailableDeliveryDays;

    // If this is an addon order that going to be edited, check for items in the addon orders and add them to the items
    if (isEditingAddOnOrder) {
      if (order.addOnOrders && order.addOnOrders.length > 0) {
        for (const [
          addOnOrderIndex,
          addOnOrder,
        ] of order.addOnOrders.entries()) {
          const previousAddOnOrder =
            addOnOrderIndex > 0 ? order.addOnOrders[addOnOrderIndex - 1] : null;

          if (!addOnOrder.items) {
            continue;
          }

          for (const addOnItem of addOnOrder.items) {
            // If the item already exists on the current order, then just add up the quantities
            let addOnItemInExistingOrderOrAddOn = order.items.find(
              (item) => item.sourceID === addOnItem.sourceID
            );

            // If the item doesn't exist in the current order then just adjust the quantities in the current order.
            if (!addOnItemInExistingOrderOrAddOn && previousAddOnOrder) {
              addOnItemInExistingOrderOrAddOn = previousAddOnOrder.items.find(
                (item) => item.sourceID === addOnItem.sourceID
              );
            }

            // If the addOn item is found either in the order or the previous addOn then just adjust the quantities in the current order.
            if (addOnItemInExistingOrderOrAddOn) {
              const itemIndex = processedOrderItems.findIndex(
                (item) =>
                  item.sourceID === addOnItemInExistingOrderOrAddOn.sourceID
              );
              processedOrderItems = processedOrderItems
                .slice(0, itemIndex)
                .concat([
                  {
                    ...processedOrderItems[itemIndex],
                    quantity:
                      processedOrderItems[itemIndex].quantity +
                      addOnItem.quantity,
                    originalQuantity:
                      processedOrderItems[itemIndex].quantity +
                      addOnItem.quantity,
                  },
                ])
                .concat(processedOrderItems.slice(itemIndex + 1));
            } else {
              // Otherwise, just add it to the list of items
              processedOrderItems.push(addOnItem);
            }
          }
        }
      } else {
        // Make the original quantity the quantity if we don't have any addOn orders yet
        processedOrderItems = processedOrderItems.map((item) => ({
          ...item,
          originalQuantity: item.quantity,
        }));
      }
    }

    let processedOrder = {
      ...order,

      /*
       ** Add new properties to the order on the frontend to extend functionality
       */
      // Collapse to track collapsing of an order in the UI.
      collapse: false,
      selectedDeliveryDayOptionIndex: Utils.findSelectedDeliveryDayOptionIndex(
        order.deliveryDay,
        nextAvailableDeliveryDays
      ),
      // Add preOrderRestricted property to see whether this order is restricted from
      // ordering today or tomorrow because they have preOrder items.
      preOrderRestricted: Utils.checkIfPreOrder(order, order.deliveryDay),
      // Add isCheckout to see if the user wants to checkout this order or not
      isCheckout: true,
      // Editable after checkout until one hour before cutoff cutoff
      isEditingAddOnOrder,

      // Add a new temp property called editMode on each orderProduct
      items: processedOrderItems.map((item) => ({ ...item, editMode: false })),

      originalOrderItems: order.items,

      // Adds vendorID to the order object (used a lot in analytics)
      vendorID: vendor.id,
      // vendorName: vendor.name,
    };

    // If the collapse was open previously make sure it still is
    if (prevOrders && prevOrders.length > 0) {
      const prevOrder = prevOrders.find(
        (order) => order.urlsafe === processedOrder.urlsafe
      );

      if (prevOrder) {
        processedOrder = {
          ...processedOrder,
          collapse: prevOrder.collapse,
          notes: prevOrder.notes,
          deliveryDay: prevOrder.deliveryDay,
          isCheckout: prevOrder.isCheckout,
          isEditingAddOnOrder: prevOrder.isEditingAddOnOrder,
        };
      }
    }
    processedOrders.push(processedOrder);
  }

  // for NON-pending orders, we must find and
  // filter out duplicates that the server could send.
  // TODO: Remove in May 2020 if there are no errors related to DUPLICATE ORDERS RECEIVED triggered
  // on our error handling service
  if (isPendingOrders === false) {
    return removeDuplicateOrders(processedOrders);
  }

  return processedOrders;
}

// Helper to create a new OrderProduct
export function createNewOrderProduct(product, quantity) {
  return {
    genericItem: {
      name: product.genericItem.name,
      description: product.genericItem.description,
      imageURL: product.genericItem.imageURL,
      urlsafe: product.genericItem.urlsafe,
    },
    description: product.productDescription,
    isTaxable: product.taxable,
    isPreOrder: product.preOrder,
    name: product.productName,
    variantDescription: product.variantDescription,
    sourceID: product.id,
    sourceUrlsafe: product.urlsafe,
    productCode: product.productCode,
    price: product.price,
    quantity: quantity,
    taxAmount: product.taxAmount,
    taxPercentage: product.taxPercentage,
    total: (parseFloat(quantity) * parseFloat(product.price)).toFixed(2),
    unitDescription: product.unitDescription,
    unitName: product.unit,
    vendorID: product.vendorID,
  };
}

// removeDuplicateOrders
function removeDuplicateOrders(orders) {
  // Group all the orders by vendorID so that we can identify the vendors with duplicate orders.
  const ordersByVendorID = _.groupBy(orders, 'vendorID');
  const duplicateOrdersToFilter = [];

  // Here we go through all the vendors and build the list of duplicated orders to filter out.
  Object.keys(ordersByVendorID).map((vendorID) => {
    // Only inspect vendors that have multiple orders (i.e duplicates)
    if (ordersByVendorID[vendorID].length > 1) {
      const duplicateOrdersByVendor = ordersByVendorID[vendorID];

      /*
       ** Here we deal with two cases:
       ** 1) If one duplicate orders already has items, keep it and add the rest to duplicate list.
       ** 2) If no duplicate order have items, choose the first one and add the rest to duplicate list.
       */
      if (duplicateOrdersByVendor.some((order) => order.items.length > 0)) {
        // Find the index of the order with items
        // Add all the orders to duplicate array except for orderWithItemIndex
        const orderWithItemIndex = duplicateOrdersByVendor.findIndex(
          (order) => order.items.length > 0
        );
        duplicateOrdersToFilter.push(
          ...duplicateOrdersByVendor
            .filter((order, index) => index !== orderWithItemIndex)
            .map((order) => order.urlsafe)
        );
      } else {
        // Add all orders to duplicate list except the first.
        duplicateOrdersToFilter.push(
          ...duplicateOrdersByVendor
            .filter((order, index) => index !== 0)
            .map((order) => order.urlsafe)
        );
      }
    }
  });

  if (duplicateOrdersToFilter.length > 0) {
    logMessage(`DUPLICATE ORDERS RECEIVED: [${duplicateOrdersToFilter}]`);
  }

  // Filter out all orders that are included in the duplicateOrdersToFilter array
  // .filter() does this by only adding the ones that are not included duplicateOrdersToFilter.
  return orders.filter(
    (order) => !duplicateOrdersToFilter.includes(order.urlsafe)
  );
}

export const fetchCartOrderCustomItems = (orderIds) => {
  return async (dispatch, getState) => {
    try {
      if (!orderIds.length) {
        return;
      }
      const { buyer } = getState().buyerReducer;

      dispatch({ type: types.FETCH_ORDER_CUSTOM_ITEMS_REQUEST });

      const customProducts = await listCustomProductsMap(buyer.urlsafe, orderIds);

      dispatch({
        type: types.FETCH_ORDER_CUSTOM_ITEMS_SUCCESS,
        payload: customProducts,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`fetchCartOrderCustomItems error: ${error}`);
      dispatch({ type: types.FETCH_ORDER_CUSTOM_ITEMS_REQUEST_FAILED });
      toastr.error(`Error retrieving custom items`);
      logException(error);
    }
  };
};

export const updateCustomItemQuantity = (urlsafe, productId, quantity) => {
  return async (dispatch, getState) => {
    let previousQuantity;

    try {
      const currentOrder = getState().ordersReducer.customItems.orders[urlsafe];
      previousQuantity = currentOrder.find(
        (item) => item.id === productId
      ).quantity;
      const { buyer } = getState().buyerReducer;

      dispatch({ type: types.UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST });

      dispatch({
        type: types.UPDATE_ORDER_CUSTOM_ITEM_QUANTITY,
        payload: {
          urlsafe,
          productId,
          quantity,
        },
      });

      await updateCustomProduct(
        buyer.urlsafe,
        productId,
        quantity
      );
      dispatch({
        type: types.UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: types.UPDATE_ORDER_CUSTOM_ITEM_QUANTITY,
        payload: {
          urlsafe,
          productId,
          quantity: previousQuantity,
        },
      });
      dispatch({
        type: types.UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST_FAILED,
      });
      toastr.error('Error on update', `Error updating custom item quantity`);
      logException(error);
    }
  };
};

export const deleteCustomItem = (urlsafe, productId) => {
  return async (dispatch, getState) => {
    let currentOrder;
    let previousIndex;
    try {
      currentOrder = getState().ordersReducer.customItems.orders[urlsafe];
      previousIndex = currentOrder.findIndex((item) => item.id === productId);
      dispatch({
        type: types.DELETE_ORDER_CUSTOM_ITEM_REQUEST,
        payload: {
          urlsafe,
          productId,
        },
      });
      const { buyer } = getState().buyerReducer;

      await deleteCustomProduct(buyer.urlsafe, productId);
      dispatch({
        type: types.DELETE_ORDER_CUSTOM_ITEM_REQUEST_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.DELETE_ORDER_CUSTOM_ITEM_REQUEST_FAILED });
      dispatch({
        type: types.SET_ORDER_CUSTOM_ITEM_WITH_INDEX,
        payload: {
          urlsafe,
          product: currentOrder[previousIndex],
          index: previousIndex,
        },
      });
      toastr.error('Error on delete product', `Error deleting custom item`);
      logException(error);
    }
  };
};

export const createCustomItem = (data, orderId) => {
  return async (dispatch, getState) => {
    try {
      const { buyer } = getState().buyerReducer;
      dispatch({
        type: types.SHOW_ORDER_CUSTOM_ITEM_LOADER,
        isLoading: true,
      });

      const createdProduct = await createCustomProduct(data, buyer.urlsafe, orderId);
      dispatch({
        type: types.ADD_CUSTOM_ITEM,
        payload: {
          urlsafe: orderId,
          product: createdProduct,
        },
      });
      dispatch({
        type: types.SHOW_ORDER_CUSTOM_ITEM_LOADER,
        isLoading: false,
      });

      return createdProduct;
    } catch (error) {
      toastr.error(
        `Error`,
        `An error happened while creating the custom product`
      );
      dispatch({
        type: types.SHOW_ORDER_CUSTOM_ITEM_LOADER,
        isLoading: false,
      });
      logException(error);
    }
  };
};
