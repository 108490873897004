// #recieving tool Action Types
export const GET_ORDER_DETAILS_START = 'RECIEVING TOOL/GET ORDER DETAILS START';
export const GET_ORDER_DETAILS_SUCCESS =
  'RECIEVING TOOL/GET ORDER DETAILS SUCCESS';
export const GET_ORDER_DETAILS_FAILED =
  'RECIEVING TOOL/GET ORDER DETAILS FAILED';

export const SEARCH_START = 'RECIEVING TOOL/SEARCH START';
export const SEARCH_SUCCESS = 'RECIEVING TOOL/SEARCH SUCCESS';
export const SEARCH_FAILED = 'RECIEVING TOOL/SEARCH FAILED';
export const SEARCH_CLEAR = 'RECIEVING TOOL/SEARCH CLEAR';
