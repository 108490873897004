import '../../../styles/dataPage.scss';
import React, { useEffect, useState } from 'react';
import { ActionBar,
    ActionBarButton,
    Banner,
    DataGridCheckbox,
    EBannerType,
    Loading,
    NotchDataGrid,
    Typography } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import { useGetBills } from '@ar/hooks/queries/InvoicesQueries.hook';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { GridSortModel } from '@mui/x-data-grid';

import { getBills, GetTransactionSortBy, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { useBillStore } from '@ar/stores/BillsStore';
import { BillFilters } from '@ap/components/Bills/BillFilters/BillFilters';
import { BillManualChargeModal } from '@ap/components/Bills/Modals/BillManualChargeModal';
import { BillsBulkManualChargeModal } from '@ap/components/Bills/Modals/BillsBulkManualChargeModal/BillsBulkManualChargeModal';
import { BillDoNotChargeModal } from '@ap/components/Bills/Modals/BillDoNotChargeModal';
import { BillBulkDoNotChargeModal } from '@ap/components/Bills/Modals/BillBulkDoNotChargeModal';
import { mapSelectedInvoiceToLoadedInvoice, MAX_INVOICES_LIMIT } from '@ar/stores/InvoicesStore';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { MANUAL_CHARGE_ENABLED_STATUSES } from '@ar/components/Invoices/InvoiceActionButtonColumn/InvoiceActionButtonColumn';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { isSurchargeEnabled } from '@v2/utils/Surcharge';
import { PADModal } from '@ap/components/PADModal';
import { getColumns, INVOICE_COLUMNS } from '@/ap/pages/BillsPage/BillsPageConstants';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

export const PAY_QUERY_PARAM = 'pay';

/**
 * The AR Invoices page
 *
 * @returns JSX Element
 */
export const BillsPage = function (): JSX.Element {
    useDocumentTitle('Bills - Notch');
    const {
        searchParams, updateSearchParams, haveSearchParamsChanged, setModal,
        setSelectedInvoicesModel, setSelectedInvoices, selectedInvoices, selectedInvoicesModel, clearSelectedInvoices,
        getReadyToBeChargedInvoices,
        getDoNotChargeInvoices,
        setAreAllInvoicesSelected,
        areAllInvoicesSelected,
        modals
    } = useBillStore();
    const [urlSearchParams] = useSearchParams();
    // get pay parameters from the url
    const hasPayParam = urlSearchParams.get(PAY_QUERY_PARAM) === 'true';
    const hasInvoiceIDParam = urlSearchParams.get('id');
    const openPayModal = hasPayParam && hasInvoiceIDParam;

    const { supplierLoginData } = useSupplierStore();
    const { data: transactionsResponse, status, isLoading: isLoadingBills } = useGetBills({ searchParams: {
        ...searchParams,
        offset: searchParams.page * searchParams.limit,
        customer_id: supplierLoginData?.customer_id,
        supplier_id: null
    } });
    const navigate = useNavigate();
    const [isLoadingAllBills, setIsLoadingAllBills] = useState(false);
    const isLoading = isLoadingBills;
    const bills = transactionsResponse?.bills || [];
    const hasResults = bills.length > 0;
    const successStatus = status === 'success';
    const showResults = successStatus && hasResults;
    const emptyFilteredResults = successStatus && !hasResults && haveSearchParamsChanged();
    const noResults = successStatus && transactionsResponse?.total === 0 && !haveSearchParamsChanged();
    const invoicesSelectedNumber = selectedInvoicesModel.length;
    const currentTransaction = modals?.transaction;

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined,
        };
        if (!field || !sort) {
            return;
        }
        const direction = sort === 'asc' ? '' : '-';
        const fieldToSortBy:GetTransactionSortBy = `${direction}${field}` as GetTransactionSortBy;
        updateSearchParams({
            sort_by: fieldToSortBy,
        });
    }, []);

    const handleCloseCreatePaymentMethod = (): void => {
        setModal('addPaymentMethod', false, currentTransaction);
    };

    const handleSelectAllClick = async (): Promise<void> => {
        try {
            setIsLoadingAllBills(true);
            const allBillsResponse = await getBills({
                ...searchParams,
                customer_id: supplierLoginData?.customer_id,
                supplier_id: null,
                limit: MAX_INVOICES_LIMIT
            });
            setAreAllInvoicesSelected(true);
            const allBills = allBillsResponse?.bills;
            const allBillsIDs = allBills?.map((invoice) => invoice.id) || [];
            setSelectedInvoices(mapSelectedInvoiceToLoadedInvoice(allBillsIDs, allBills, selectedInvoices));
            setSelectedInvoicesModel(allBillsIDs);
            setIsLoadingAllBills(false);
        } catch (e) {
            console.error(e);
            setIsLoadingAllBills(false);
            clearSelectedInvoices();
        }
    };

    const onSelectionModelChange = (selected): void => {
        setSelectedInvoicesModel(selected);
        // storing the selected invoices in a map to avoid looping through the invoices array
        setSelectedInvoices(mapSelectedInvoiceToLoadedInvoice(selected, bills, selectedInvoices));
        sendTrackingEvent(TrackingEvents.invoiceSelected);
    };

    const getColumnsVisibilityModel = (): Record<string, boolean> => {
        const needColumnSurcharge = isSurchargeEnabled(bills);
        // if keys are not present in the object by default, all the columns are visible.
        if (supplierLoginData.gpo) {
            return {
                [INVOICE_COLUMNS.surchargeAmount]: needColumnSurcharge
            };
        }
        const visibilityModel = {
            [INVOICE_COLUMNS.customer]: !supplierLoginData.isCustomer,
            [INVOICE_COLUMNS.surchargeAmount]: needColumnSurcharge
        };

        return visibilityModel;
    };

    const getActionBarButtons = (): ActionBarButton[] => {
        if (invoicesSelectedNumber === 0) {
            return [];
        }
        return [
            {
                variant: 'TERTIARY_DARK',
                onClick: ():void => {
                    setModal('bulkManualCharge', true);
                    sendTrackingEvent(TrackingEvents.chargeInvoiceClicked);
                },
                hidden: getReadyToBeChargedInvoices().length === 0,
                text: `Pay (${getReadyToBeChargedInvoices().length})`,
            },
            {
                variant: 'TERTIARY_DARK',
                onClick: ():void => {
                    setModal('bulkDoNotChargeInvoice', true);
                    sendTrackingEvent(TrackingEvents.doNotChargeClicked);
                },
                hidden: getDoNotChargeInvoices().length === 0,
                text: `Dispute (${getDoNotChargeInvoices().length})`,
            },
        ];
    };
    const allButtonsAreHidden = getActionBarButtons().every((button) => button.hidden);

    const hasSelectedAllBillsInPage = transactionsResponse?.total > searchParams.limit && selectedInvoicesModel.length === transactionsResponse?.bills?.length;
    const handleClearAllSelection = ():void => {
        setAreAllInvoicesSelected(false);
        setSelectedInvoicesModel([]);
        setSelectedInvoices({});
    };

    useEffect(() => {
        const hasBillResults = transactionsResponse?.bills?.length > 0;
        if (openPayModal && hasBillResults) {
            const [invoice] = transactionsResponse?.bills ?? [];
            const isPayable = MANUAL_CHARGE_ENABLED_STATUSES.includes(invoice?.status as TransactionStatus);
            if (invoice && isPayable) {
                setModal('manualCharge', true, invoice);
            }
        }
    }, [openPayModal, transactionsResponse?.bills?.length]);

    return (
        <>

            <PageTitle>Bills</PageTitle>

            <div className="mt-2">
                <BillFilters/>
                {(haveSearchParamsChanged() && (areAllInvoicesSelected || hasSelectedAllBillsInPage)) && <Banner className="my-2 text-center"
                    alertType={EBannerType.INFO}
                    body={ <div>
                        {!areAllInvoicesSelected && !isLoadingAllBills && <>All {searchParams.limit} bills on this page are selected. <span role="button" onClick={handleSelectAllClick} className="font-medium text-teal-500 cursor-pointer">Select all bills that match your search criteria</span></>}
                        {isLoadingAllBills && <Loading isDark />}
                        {areAllInvoicesSelected && !isLoadingAllBills && <>All bills in this search are selected. <span role="button" onClick={handleClearAllSelection} className="font-medium text-teal-500 cursor-pointer">Clear selection</span></>}
                    </div>}/>}

                {isLoading && <Loading isDark className="mt-10" />}
                {showResults && <>
                    <NotchDataGrid
                        autoHeight
                        loading={isLoading}
                        columnVisibilityModel={getColumnsVisibilityModel()}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableSelectionOnClick
                        disableColumnFilter
                        checkboxSelection
                        keepNonExistentRowsSelected
                        components={{
                            BaseCheckbox: DataGridCheckbox,
                        }}
                        onSelectionModelChange={onSelectionModelChange}
                        selectionModel={selectedInvoicesModel}
                        disableColumnSelector
                        disableColumnMenu
                        columns={getColumns()}
                        page={searchParams.page}
                        rowCount={transactionsResponse?.total ?? 0}
                        rowsPerPageOptions={[searchParams.limit]}
                        pageSize={searchParams.limit}
                        paginationMode="server"
                        rowHeight={61}
                        onPageChange={(newPage): void => {
                            updateSearchParams({ page: newPage });
                            const pageChangeEvent = newPage > searchParams.page ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                            sendTrackingEvent(pageChangeEvent);
                        }}
                        rows={bills}
                        onRowClick={(row) => {
                            if (!row.id) return;
                            navigate(`/${ARRoutePaths.AR_BILLS}/${row.id}`);
                        }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }}/>
                </>
                }
                {emptyFilteredResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">Try searching for something else or adjusting your filters</Typography>
                </div>
                }
                {noResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">No bills found</Typography>
                </div>
                }
            </div>
            <BillManualChargeModal/>
            <BillsBulkManualChargeModal/>
            <BillDoNotChargeModal/>
            <BillBulkDoNotChargeModal/>

            <PADModal isOpen={modals?.addPaymentMethod}
                handleClose={handleCloseCreatePaymentMethod}
                fromSettingsPage/>

            {(invoicesSelectedNumber > 0 && !allButtonsAreHidden) && <div className="flex justify-center items-center">
                <ActionBar
                    text={`${invoicesSelectedNumber} ${invoicesSelectedNumber === 1 ? 'bill' : 'bills'} selected`}
                    isOpen={invoicesSelectedNumber > 0}
                    close={():void => {
                        clearSelectedInvoices();
                        sendTrackingEvent(TrackingEvents.exitActionPanel);
                    }}
                    selection={selectedInvoicesModel}
                    onClose={clearSelectedInvoices}
                    buttons={getActionBarButtons()}/>
            </div>}
        </>

    );
};
