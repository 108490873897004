import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Badge,
    Banner,
    Button,
    EBannerType,
    Input,
    Select,
    Separator,
    toast,
    Typography } from '@notch-ordering/ui-components';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Currency, signUpCustomer } from '@ar/network/AccountsReceivable.network';
import InfoIcon from '@icons/info-icon.svg';
import EyeOffIcon from '@icons/eye-off-icon.svg';
import EyeIcon from '@icons/eye-icon.svg';
import { EPaths } from '@v2/constants/EPaths';
import { AxiosError } from 'axios';
import { useGetGpos } from '@ar/hooks/queries/SupplierQueries.hook';

import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';
import { useSupplierStore } from '@/ar/stores/SupplierStore';

const supplierID = process.env.NOTCH_SUPPLIER_ID;

const gpoSuppliersFieldErrorMessage = 'Please provide a valid group purchasing organization.';
export const gpoSuppliersField = z.array(z.object({
    gpo_id: z.string({
        invalid_type_error: gpoSuppliersFieldErrorMessage,
        required_error: gpoSuppliersFieldErrorMessage,
    }).uuid({ message: gpoSuppliersFieldErrorMessage }),
    gpo_name: z.string().optional(),
})).optional();
export const createCustomerAndAccountSchema = z.object({
    bussines_name: z.string().nonempty({ message: 'Business name is required' }),
    external_id: z.string().optional(),
    first_name: z.string().nonempty({ message: 'First name is required' }),
    last_name: z.string().nonempty({ message: 'Last name is required' }),
    email: z.string().email({ message: 'Invalid email address' }),
    password: z.string().nonempty({ message: 'Password is required' }),
    currency: z.nativeEnum(Currency, {
        errorMap: (issue) => {
            if (issue.code === 'invalid_enum_value') {
                return { message: 'Currency is required' };
            }
            return { message: issue.message ?? '' };
        },
    }).default(Currency.cad),
    gpoSuppliers: gpoSuppliersField,
});
export type CreateCustomerAndAccount = z.infer<typeof createCustomerAndAccountSchema>;

enum ErrorTypes {
    ACCOUNT_EXIST = 'ACCOUNT_EXIST',
    CUSTOMER_CREATION = 'CUSTOMER_CREATION',
}

export const SignUpPage: React.FC = () => {
    // get email address from query parameters
    const { search } = useLocation();
    const [searchParams] = useSearchParams();

    // get the email parameter with regex from the search param without any decoding
    const regex = /email=([^&]+)/;
    const email = regex.exec(search)?.[1] ?? '';
    // get the gpo_ids parameter from the search param and split it by comma
    const gpoIDs = searchParams.get('gpo_ids')?.split(',') ?? [];

    const signUpCustomerMutation = useMutation(signUpCustomer);
    const [formError, setFormError] = React.useState<ErrorTypes>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { supplierLoginData } = useSupplierStore();
    const supplierToken = supplierLoginData?.api_token;
    const { data: gposResponse } = useGetGpos();
    const { setAmplitudeUserId, trackAmplitudeEvent } = useAmplitude();

    const { handleSubmit, control, setValue } = useForm<CreateCustomerAndAccount>({
        resolver: zodResolver(createCustomerAndAccountSchema),
        mode: 'onBlur',
        defaultValues: {
            email: email ?? '',
            bussines_name: '',
            first_name: '',
            last_name: '',
            currency: Currency.cad,
        }
    });
    const { errors } = useFormState({
        control,
    });
    const { fields: fieldsGpo } = useFieldArray({
        control,
        name: 'gpoSuppliers',
    });
    const onSubmit = async (data: CreateCustomerAndAccount) => {
        try {
            signUpCustomerMutation.mutate({
                supplierID,
                body: {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    external_id: uuidv4(),
                    supplier_id: supplierID,
                    business_name: data.bussines_name,
                    password: data.password,
                    account_name: data.bussines_name,
                    currency: data.currency,
                    gpo_ids: data.gpoSuppliers.map((gpoSupplier) => gpoSupplier.gpo_id) ?? undefined,
                },
                token: supplierToken
            }, {
                onSuccess: () => {
                    toast.show({
                        message: 'Account successfully created'
                    });
                    setAmplitudeUserId(data.email);

                    trackAmplitudeEvent(AmplitudeEventActions.signUp, { userId: data.email, page: 'Sign up page' });
                    UserAuthenticationProvider.loginUser(data.email, data.password).then(() => {
                        window.location.reload();
                    });
                },
                onError: (err: AxiosError<{ error?: string }>) => {
                    const message = err.response?.data?.error || err.message;
                    console.log({ err });
                    if (message.includes('exists')) {
                        setFormError(ErrorTypes.ACCOUNT_EXIST);
                        toast.show({
                            message: 'Failed to create customer account'
                        });
                    } else {
                        setFormError(ErrorTypes.CUSTOMER_CREATION);
                        toast.show({
                            message: `Failed to create customer : ${message}`
                        });
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // if the gpos are loaded, then assign it to gpoSuppliers field in the form
        if (gposResponse?.gpos.length > 0) {
            // assign the values only if the gpos are loaded, and they exist in the response to filter invalid gpo_ids
            const gpoSuppliersSelected = gposResponse.gpos
                .filter((gpo) => gpoIDs.includes(gpo.id))
                // map the gpos to the format required by the form field
                ?.map((gpo) => ({
                    gpo_id: gpo.id,
                    gpo_name: gpo.name,
                })) ?? [];
            // set the value of the gpoSuppliers field in the form
            setValue('gpoSuppliers', gpoSuppliersSelected);
        }
    }, [gposResponse?.gpos]);
    return <div className="h-screen bg-plum-500 overflow-auto">
        <div className="bg-gray-700 opacity-75 w-full h-full absolute"/>

        <div className="w-full h-full flex items-center px-2">
            <div
                className="bg-white rounded-xl sm:p-10 sm:pt-5 sm:pb-8 p-6 pt-0 m-auto flex flex-col w-full sm:max-w-[430px] items-center z-10">

                <Typography variant="3XL" desktopSize="m-0 text-7 pb-10">Sign up</Typography>

                <form className="flex flex-col gap-5 w-full" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => <Input
                                label="Email"
                                disabled={true}
                                variant="SMALL"
                                isInvalid={!!errors.email}
                                invalidMessage={errors.email?.message}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div className="relative">
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <Input
                                label="Password"
                                type={showPassword ? 'TEXT' : 'PASSWORD'}
                                variant="SMALL"
                                isInvalid={!!errors.password}
                                invalidMessage={errors.password?.message}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                        {showPassword ? <div
                            className="absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                            onClick={(): void => setShowPassword(false)}
                            role={'button'}>
                            <EyeOffIcon className="text-gray-600 inline-flex w-4 h-4"/>
                        </div> : <div
                            className=" absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                            onClick={(): void => setShowPassword(true)}
                            role={'button'}>
                            <EyeIcon className="text-gray-600 inline-flex w-4 h-4"/>
                        </div>}
                    </div>

                    <div>
                        <Controller
                            name="bussines_name"
                            control={control}
                            render={({ field }) => <Input
                                label="Business Name"
                                variant="SMALL"
                                isInvalid={!!errors.bussines_name}
                                invalidMessage={errors.bussines_name?.message}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>

                    </div>
                    <div>
                        <Controller
                            name="first_name"
                            control={control}
                            render={({ field }) => <Input
                                label="First Name"
                                variant="SMALL"
                                isInvalid={!!errors.first_name}
                                invalidMessage={errors.first_name?.message}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div>
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field }) => <Input
                                label="Last Name"
                                variant="SMALL"
                                isInvalid={!!errors.last_name}
                                invalidMessage={errors.last_name?.message}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div>
                        <Controller
                            name="currency"
                            control={control}
                            render={({ field }) => <Select
                                label="Currency"
                                variant="SMALL"
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange(value);
                                }}
                                isInvalid={!!errors.currency}
                                options={Object.values(Currency).map((currency) => ({
                                    label: currency.toUpperCase(),
                                    value: currency,
                                }))}
                                invalidMessage={errors.currency?.message}
                                required/>}/>
                    </div>
                    {fieldsGpo?.length > 0 && <div className="flex-col gap-2 flex">
                        <Typography desktopSize="lg:text-2 text-2" className="m-0">Group purchasing organization{fieldsGpo.length > 1 ? 's' : ''}
                        </Typography>
                        <div className="flex gap-2">
                            {fieldsGpo.map((field, index) => (
                                <div key={field.id} className="flex gap-2 ">
                                    <Badge className="flex items-center" variant="TEAL" size="MEDIUM">
                                        {field.gpo_name}
                                    </Badge>
                                    {!!errors.gpoSuppliers?.[index]?.gpo_id?.message && <Typography
                                        className="text-red-500">{errors.gpoSuppliers?.[index]?.gpo_id?.message}</Typography>}

                                </div>
                            ))}
                        </div>
                    </div>}

                    <Separator/>
                    {!!formError
                        && <div className="w-full mb-4">
                            <Banner alertType={EBannerType.ERROR}
                                body={<Typography as="div" className="text-gray-600">
                                    {formError === ErrorTypes.ACCOUNT_EXIST ? 'Customer account exists.' : 'Failed to create customer'}
                                    {formError === ErrorTypes.ACCOUNT_EXIST
                                            && <span>&nbsp;Please <Link to={EPaths.SIGN_IN}
                                                className="text-red-500">login</Link></span>}
                                </Typography>}
                                icon={<InfoIcon className="w-5 h-5 text-red-300"/>}
                                isDismissable={false}/>
                        </div>
                    }
                    <div>
                        <Button variant="SECONDARY"
                            className="w-full mb-4"
                            loading={signUpCustomerMutation.isLoading}
                            type="submit">Save</Button>

                    </div>

                </form>

            </div>
        </div>
    </div>;
};
