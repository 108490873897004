export enum EIonicPlatforms {
    IOS = 'ios',
    ANDROID = 'android',
    WEB = 'web',
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILEWEB = 'mobileweb',
    CAPACITOR = 'capacitor',
}

export const CAPACITOR_BACK_BUTTON_LISTENER_ID = 'backButton';
export const CAPACITOR_STATUS_TAP_LISTENER_ID = 'statusTap';
