import { deleteDraftInvoice, patchDraftInvoice, PublishDraftInvoiceParams, PublishDraftInvoiceResponse } from '@ar/network/AccountsReceivable.network';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { AxiosError } from 'axios';
import { DRAFT_INVOICE_QUERY_KEY, FETCH_DRAFT_INVOICES_QUERY_KEY } from '../queries/DraftInvoicesQueries.hook';
import { queryClient } from '@/containers/app/Root';
import { FETCH_TRANSACTION_QUERY_KEY } from '../queries/InvoicesQueries.hook';

export const useDraftInvoiceUpdateMutation = () => useMutation(patchDraftInvoice, {
    onSuccess: async () => {
        await queryClient.invalidateQueries([DRAFT_INVOICE_QUERY_KEY]);
    }
});

interface UseDraftInvoiceMutationParams {
    mutationFn: (params:PublishDraftInvoiceParams) => Promise<PublishDraftInvoiceResponse>,
    successMessage: string,
    onSuccess?: (data: PublishDraftInvoiceResponse) => void,
}

const requiredFieldsLabels = {
    externalId: 'External ID',
    invoiceNumber: 'Invoice Number',
    invoiceDate: 'Invoice Date',
    dueDate: 'Due Date',
    total: 'Total',
    terms: 'Terms',
    customerId: 'Customer',
};

type PublishError = AxiosError<{ error?: string, missing_fields:string[] }>;
const getMissingFieldsMessage = (missingFields: string[]) => missingFields.map((field) => requiredFieldsLabels[field]).join(', ');

const getErrorMessage = (error: PublishError): string => {
    const defaultMessage = 'Failed to publish invoice';
    const response = error.response?.data;

    if (!response) {
        return defaultMessage;
    }

    if (response.missing_fields?.length) {
        return `There are unsaved missing fields: ${getMissingFieldsMessage(response.missing_fields)}`;
    }

    return response.error ?? defaultMessage;
};

export const usePublishOrRepublishDraftInvoiceMutation = ({ mutationFn, successMessage, onSuccess }: UseDraftInvoiceMutationParams) => {
    const navigate = useNavigate();
    return useMutation(mutationFn, {
        onSuccess: async (data) => {
            await queryClient.invalidateQueries([DRAFT_INVOICE_QUERY_KEY]);
            await queryClient.invalidateQueries([FETCH_TRANSACTION_QUERY_KEY]);

            toast.show({
                message: successMessage,
                icon: <CheckIcon />,
            });

            if (onSuccess) {
                onSuccess(data);
            }
            navigate(`/${ARRoutePaths.AR_INVOICES}/${data.id}`);
        },
        onError: (error:PublishError) => {
            const message = getErrorMessage(error);
            toast.show({
                message
            });
        }
    });
};

export const useDraftInvoiceDeleteMutation = () => useMutation(deleteDraftInvoice, {
    onSuccess: async () => {
        await queryClient.invalidateQueries([DRAFT_INVOICE_QUERY_KEY]);
        await queryClient.invalidateQueries([FETCH_DRAFT_INVOICES_QUERY_KEY]);
    }
});
