import { fork } from 'redux-saga/effects';

import searchSaga from './searchSaga';

/**
 * rootSaga
 */
export default function* rootSaga() {
  yield fork(searchSaga);
}
