import React from 'react';
import { Input, Button, Typography } from '@notch-ordering/ui-components';
import { useMutation } from '@tanstack/react-query';
import { setupPayment, SupplierData } from '@v2/network/CoreAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { CC_INPUT_MAX_LENGTH, ccExpiresFormat,
    ccNumberFormat, CVC_INPUT_MAX_LENGTH,
    cvcFormat, DEFAULT_PAYMENT_METHOD_TYPE,
    ERROR_MESSAGE } from '@v2/components/Shared/AddCreditCard/AddCreditCardFormConstants';
import useAccount from '@v2/hooks/useAccount.hook';
import { AccountData } from '@v2/types/AccountData';
import { AxiosResponse } from 'axios';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';

export interface AddCreditCardFormProps {
    onSuccess?: () => void,
    supplier?: SupplierData,
    bills?: InvoiceData[],
}

export interface IAddCreditCardFormState {
    cardNumber: string,
    cardHolderName: string,
    expirationDate: string,
    cvv: string,
}

export const addCreditCardFormInitialState: IAddCreditCardFormState = {
    cardNumber: '',
    cardHolderName: '',
    expirationDate: '',
    cvv: '',
};

export const AddCreditCardForm : React.FC<AddCreditCardFormProps> = ({ onSuccess, supplier, bills }) => {
    const [form, setForm] = React.useState<IAddCreditCardFormState>(addCreditCardFormInitialState);
    const { email }: AccountData = useAccount();
    const { buyer } = useBuyerHook();
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const setupPaymentMutation = useMutation(setupPayment, {
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
            setForm(addCreditCardFormInitialState);
        },
        onError: (response: AxiosResponse) => {
            setErrorMessage(response.data?.error?.message ?? ERROR_MESSAGE);
        }
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>):void => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e):void => {
        e.preventDefault();
        setErrorMessage(null);
        const invoices = bills?.map((b) => ({ invoice_number: b.invoiceNumber, amount: b.invoiceTotal }));
        setupPaymentMutation.mutate({
            buyerData: {
                email: buyer.email || email,
                name: buyer.name,
                id: buyer.id,
            },
            buyerUrlsafeKey: buyer.urlsafe,
            supplierUrlsafeKey: supplier?.urlsafe,
            paymentMethodData: {
                type: DEFAULT_PAYMENT_METHOD_TYPE,
                card: {
                    number: form.cardNumber,
                    exp_month: +form.expirationDate.split('/')[0],
                    exp_year: +form.expirationDate.split('/')[1],
                    cvc: form.cvv,
                },
            },
            invoices
        });
    };
    const { isLoading } = setupPaymentMutation;

    const isDisabled = !form.cardNumber || !form.cardHolderName || !form.expirationDate || !form.cvv;

    return <form onSubmit={handleSubmit} noValidate>

        <div className="flex flex-col gap-6">
            <Input inputProps={{
                maxLength: CC_INPUT_MAX_LENGTH,
            }
            }
            value={form.cardNumber}
            className="w-full"
            onChange={(e):void => {
                const value = ccNumberFormat(e.target.value);
                setForm({
                    ...form,
                    cardNumber: value
                });
            }
            }
            name="cardNumber"
            label="Card Number"
            required/>

            <Input value={form.cardHolderName}
                className="w-full"
                onChange={onChange}
                name="cardHolderName"
                label="Card Holder Name"
                required/>
            <div className="flex gap-6">
                <Input value={form.expirationDate}
                    className="w-full"

                    onChange={ (e):void => {
                        setForm({
                            ...form,
                            expirationDate: ccExpiresFormat(e.target.value)
                        });
                    }}
                    name="expirationDate"
                    label="Expiry date (MM/YY)"
                    required/>
                <Input value={form.cvv}
                    className="w-full"
                    inputProps={{ maxLength: CVC_INPUT_MAX_LENGTH }}
                    onChange={ (e):void => {
                        setForm({
                            ...form,
                            cvv: cvcFormat(e.target.value)
                        });
                    }}
                    name="cvv"
                    label="CVC"
                    required/>
            </div>
            {!!errorMessage && <Typography as="div" variant="SM" desktopSize="lg:text-1" className="text-red-500">{errorMessage}</Typography>}
            <div>
                <Button loading={isLoading}
                    variant="SECONDARY"
                    size="MEDIUM"
                    type="submit"
                    className="w-full"
                    disabled={isDisabled || isLoading}>Continue</Button>
            </div>
        </div>

    </form>;
};
