import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { ApSideNavigation } from '@ap/layouts/ApSideNavigation';

const AR_GTM_TAG_ID = process.env.AR_GTM_TAG_ID ?? '';
/**
 * The AP page layout
 */
export function ApPageLayout() {
    return (
        <div className="flex h-full flex-wrap">
            <Helmet>
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${AR_GTM_TAG_ID}`}/>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', ${JSON.stringify(AR_GTM_TAG_ID)});
                    `}
                </script>
            </Helmet>
            <ApSideNavigation />
            <div className="flex w-full flex-1 flex-col overflow-auto p-10 h-screen">
                <Outlet />
            </div>
        </div>
    );
}
