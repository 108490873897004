export const SET_LOCATION_SWITCHER = 'SET_LOCATION_SWITCHER';
export const FETCHING_TAXED_ITEM_SUCCESS = 'FETCHING_TAXED_ITEM_SUCCESS';
export const FETCHING_TAXED_ITEM = 'FETCHING_TAXED_ITEM';
/*
 ** Errors
 */
export const SHOW_ERROR = 'SHOW_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

/*
 ** Buyer
 */
export const FETCH_BUYER_REQUEST = 'FETCH_BUYER_REQUEST';
export const FETCH_BUYER_SUCCESS = 'FETCH_BUYER_SUCCESS';
export const FETCH_BUYER_FAILED = 'FETCH_BUYER_FAILED';
export const UPDATE_BUYER_REQUEST = 'UPDATE_BUYER_REQUEST';
export const UPDATE_BUYER_SUCCESS = 'UPDATE_BUYER_SUCCESS';
export const UPDATE_BUYER_FAILED = 'UPDATE_BUYER_FAILED';
export const RECEIVE_BUYER = 'RECEIVE_BUYER';
export const RECEIVE_BUYER_MEMBERS = 'RECEIVE_BUYER_MEMBERS';
export const CREATE_BUYER_MEMBER_REQUEST = 'CREATE_BUYER_MEMBER_REQUEST';
export const CREATE_BUYER_MEMBER_SUCCESS = 'CREATE_BUYER_MEMBER_SUCCESS';
export const CREATE_BUYER_MEMBER_FAILED = 'CREATE_BUYER_MEMBER_FAILED';
export const ADD_BUYER_MEMBER = 'ADD_BUYER_MEMBER';
export const VENDORS_ADDED = 'VENDORS_ADDED';
export const UPDATE_BUYER_MEMBER_REQUEST = 'UPDATE_BUYER_MEMBER_REQUEST';
export const UPDATE_BUYER_MEMBER_SUCCESS = 'UPDATE_BUYER_MEMBER_SUCCESS';
export const UPDATE_BUYER_MEMBER_FAILED = 'UPDATE_BUYER_MEMBER_FAILED';
export const UPDATE_BUYER_MEMBER = 'UPDATE_BUYER_MEMBER';
export const DELETE_BUYER_MEMBER_REQUEST = 'DELETE_BUYER_MEMBER_REQUEST';
export const DELETE_BUYER_MEMBER_SUCCESS = 'DELETE_BUYER_MEMBER_SUCCESS';
export const DELETE_BUYER_MEMBER_FAILED = 'DELETE_BUYER_MEMBER_FAILED';
export const DELETE_BUYER_MEMBER = 'DELETE_BUYER_MEMBER';
export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILED = 'VALIDATE_EMAIL_FAILED';
export const UPDATE_PHONE_NUMBER_REQUEST = 'UPDATE_PHONE_NUMBER_REQUEST';
export const UPDATE_PHONE_NUMBER_SUCCESS = 'UPDATE_PHONE_NUMBER_SUCCESS';
export const UPDATE_PHONE_NUMBER_FAILED = 'UPDATE_PHONE_NUMBER_FAILED';
export const CREATE_BUYER_SHIPPING_ADDRESS_REQUEST =
  'CREATE_BUYER_SHIPPING_ADDRESS_REQUEST';
export const CREATE_BUYER_SHIPPING_ADDRESS_SUCCESS =
  'CREATE_BUYER_SHIPPING_ADDRESS_SUCCESS';
export const CREATE_BUYER_SHIPPING_ADDRESS_FAILED =
  'CREATE_BUYER_SHIPPING_ADDRESS_FAILED';
export const ADD_BUYER_SHIPPING_ADDRESS = 'ADD_BUYER_SHIPPING_ADDRESS';
export const UPDATE_BUYER_SHIPPING_ADDRESS_REQUEST =
  'UPDATE_BUYER_SHIPPING_ADDRESS_REQUEST';
export const UPDATE_BUYER_SHIPPING_ADDRESS_SUCCESS =
  'UPDATE_BUYER_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_BUYER_SHIPPING_ADDRESS_FAILED =
  'UPDATE_BUYER_SHIPPING_ADDRESS_FAILED';
export const UPDATE_BUYER_SHIPPING_ADDRESS = 'UPDATE_BUYER_SHIPPING_ADDRESS';
export const DELETE_BUYER_LOCATION_REQUEST = 'DELETE_BUYER_LOCATION_REQUEST';
export const DELETE_BUYER_LOCATION_SUCCESS = 'DELETE_BUYER_LOCATION_SUCCESS';
export const DELETE_BUYER_LOCATION_FAILED = 'DELETE_BUYER_LOCATION_FAILED';
export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAILED = 'SEND_INVITE_FAILED';
export const SELECT_DEFAULT_BUYER = 'SELECT_DEFAULT_BUYER';
export const FETCH_BUYER_MEMBERS_REQUEST = 'FETCH_BUYER_MEMBERS_REQUEST';
export const FETCH_BUYER_MEMBERS_SUCCESS = 'FETCH_BUYER_MEMBERS_SUCCESS';
export const FETCH_BUYER_MEMBERS_FAILED = 'FETCH_BUYER_MEMBERS_FAILED';

/*
 ** Sign In
 */
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILED = 'SIGNOUT_FAILED';

/*
 ** Account
 */
export const GET_ACCESS_TOKEN_ACCOUNT_DATA = 'GET_ACCESS_TOKEN_ACCOUNT_DATA';
export const UPDATE_EMAIL_NOTIFICATION_REQUEST =
  'UPDATE_EMAIL_NOTIFICATION_REQUEST';
export const UPDATE_EMAIL_NOTIFICATION_SUCCESS =
  'UPDATE_EMAIL_NOTIFICATION_SUCCESS';
export const UPDATE_EMAIL_NOTIFICATION_FAILED =
  'UPDATE_EMAIL_NOTIFICATION_FAILED';
export const UPDATE_EMAIL_NOTIFICATION_ACCOUNT =
  'UPDATE_EMAIL_NOTIFICATION_ACCOUNT';
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';
export const FETCH_SESSION_REQUEST = 'FETCH_SESSION_REQUEST';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAILED = 'FETCH_SESSION_FAILED';
export const GET_SESSION_DATA = 'GET_SESSION_DATA';
export const FETCH_ACCOUNT_REQUEST = 'FETCH_ACCOUNT_REQUEST';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAILED = 'FETCH_ACCOUNT_FAILED';
export const CREATE_SIGNUP_ACCOUNT_REQUEST = 'CREATE_SIGNUP_ACCOUNT_REQUEST';
export const CREATE_SIGNUP_ACCOUNT_SUCCESS = 'CREATE_SIGNUP_ACCOUNT_SUCCESS';
export const CREATE_SIGNUP_ACCOUNT_FAILED = 'CREATE_SIGNUP_ACCOUNT_FAILED';
export const RESEND_ACTIVATION_EMAIL_REQUEST =
  'RESEND_ACTIVATION_EMAIL_REQUEST';
export const RESEND_ACTIVATION_EMAIL_SUCCESS =
  'RESEND_ACTIVATION_EMAIL_SUCCESS';
export const RESEND_ACTIVATION_EMAIL_FAILED = 'RESEND_ACTIVATION_EMAIL_FAILED';
export const VERIFY_ACTIVATION_EMAIL_CODE_REQUEST =
  'VERIFY_ACTIVATION_EMAIL_CODE_REQUEST';
export const VERIFY_ACTIVATION_EMAIL_CODE_SUCCESS =
  'VERIFY_ACTIVATION_EMAIL_CODE_SUCCESS';
export const VERIFY_ACTIVATION_EMAIL_CODE_FAILED =
  'VERIFY_ACTIVATION_EMAIL_CODE_FAILED';
export const CREATE_SELF_ONBOARD_BUYER_ACCOUNT_REQUEST =
  'CREATE_SELF_ONBOARD_BUYER_ACCOUNT_REQUEST';
export const CREATE_SELF_ONBOARD_BUYER_ACCOUNT_SUCCESS =
  'CREATE_SELF_ONBOARD_BUYER_ACCOUNT_SUCCESS';
export const CREATE_SELF_ONBOARD_BUYER_ACCOUNT_FAILED =
  'CREATE_SELF_ONBOARD_BUYER_ACCOUNT_FAILED';
export const IS_LOADING_APP_INIT = 'IS_LOADING_APP_INIT';
export const FETCH_BUYER_PAYMENT_METHODS = 'FETCH_BUYER_PAYMENT_METHODS';
export const FETCH_BUYER_PAYMENT_METHODS_SUCCEEDED =
  'FETCH_BUYER_PAYMENT_METHODS_SUCCEEDED';
export const FETCH_BUYER_PAYMENT_METHODS_FAILED =
  'FETCH_BUYER_PAYMENT_METHODS_FAILED';

export const REMOVE_BUYER_PAYMENT_METHODS = 'REMOVE_BUYER_PAYMENT_METHODS';
export const CREATE_PAYMENT_METHOD_TOKEN = 'CREATE_PAYMENT_METHOD_TOKEN';
export const CREATE_PAYMENT_METHOD_TOKEN_SUCCEEDED =
  'CREATE_PAYMENT_METHOD_TOKEN_SUCCEEDED';
export const CREATE_PAYMENT_METHOD_TOKEN_FAILED =
  'CREATE_PAYMENT_METHOD_TOKEN_FAILED';

export const REMOVE_BUYER_PAYMENT_METHODS_SUCCEEDED =
  'REMOVE_BUYER_PAYMENT_METHODS_SUCCEEDED';
export const REMOVE_BUYER_PAYMENT_METHODS_FAILED =
  'REMOVE_BUYER_PAYMENT_METHODS_FAILED';
export const SET_BUYER_PAYMENT_METHODS_WITH_INDEX =
  'SET_BUYER_PAYMENT_METHODS_WITH_INDEX';

/*
 ** Buyer Balance
 */
export const RECEIVE_BUYER_BALANCE = 'RECEIVE_BUYER_BALANCE';
export const SET_CUSTOM_PAYMENT_AMOUNT = 'SET_CUSTOM_PAYMENT_AMOUNT';
export const SET_SELECTED_PAYMENT_BOX = 'SET_SELECTED_PAYMENT_BOX';
export const PAYMENT_COMPLETE = 'PAYMENT_COMPLETE';
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED';
export const RECEIVE_STRIPE_CUSTOMER = 'RECEIVE_STRIPE_CUSTOMER';
export const RECEIVE_STRIPE_CUSTOMER_SOURCES =
  'RECEIVE_STRIPE_CUSTOMER_SOURCES';
export const ADD_STRIPE_CUSTOMER_SOURCE = 'ADD_STRIPE_CUSTOMER_SOURCE';
export const DELETE_STRIPE_CUSTOMER_SOURCE = 'DELETE_STRIPE_CUSTOMER_SOURCE';
export const ADD_CUSTOMER_CARD_REQUEST = 'ADD_CUSTOMER_CARD_REQUEST';
export const ADD_CUSTOMER_CARD_SUCCESS = 'ADD_CUSTOMER_CARD_SUCCESS';
export const ADD_CUSTOMER_CARD_FAILED = 'ADD_CUSTOMER_CARD_FAILED';
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';
export const DELETE_CUSTOMER_CARD_REQUEST = 'DELETE_CUSTOMER_CARD_REQUEST';
export const DELETE_CUSTOMER_CARD_SUCCESS = 'DELETE_CUSTOMER_CARD_SUCCESS';
export const DELETE_CUSTOMER_CARD_FAILED = 'DELETE_CUSTOMER_CARD_FAILED';
export const FETCH_CUSTOMER_CARDS_REQUEST = 'FETCH_CUSTOMER_CARDS_REQUEST';
export const FETCH_CUSTOMER_CARDS_SUCCESS = 'FETCH_CUSTOMER_CARDS_SUCCESS';
export const FETCH_CUSTOMER_CARDS_FAILED = 'FETCH_CUSTOMER_CARDS_FAILED';

/*
 ** Order Guide Editor
 */
export const LOAD_INITIAL = 'LOAD_INITIAL';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_ADD_ITEM = 'OPEN_ADD_ITEM';
export const OPEN_EDIT_ITEM = 'OPEN_EDIT_ITEM';

// Orderguide Editor Store
export const SHOW_LOADER = 'SHOW_LOADER';
export const SHOW_LOADER_ALL = 'SHOW_LOADER_ALL';
export const ADD_ITEM_TO_ORDERGUIDE_EDITOR = 'ADD_ITEM_TO_ORDERGUIDE_EDITOR';
export const UPDATE_ORDERGUIDE_ITEMS = 'UPDATE_ORDERGUIDE_ITEMS';
export const TOGGLE_ORDERGUIDE_EDITOR_MODAL = 'TOGGLE_ORDERGUIDE_EDITOR_MODAL';
export const RECIEVE_SEARCHDATA = 'RECIEVE_SEARCHRESULTS';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const CLEAR_ITEMADDED = 'CLEAR_ITEMADDED';
export const SORT_RESULTS = 'SORT_RESULTS';
export const RESET_CURRENT_CELL = 'RESET_CURRENT_CELL';
export const FILTER_BY_CATEGORY = 'FILTER_BY_CATEGORY';
export const UPDATE_ORDERGUIDE = 'UPDATE_ORDERGUIDE';
export const CHANGE_OG_EDITOR_VIEW = 'CHANGE_OG_EDITOR_VIEW';
export const UPDATE_SELECTED_ORDERGUIDE = 'UPDATE_SELECTED_ORDERGUIDE';

// Orderguide Editor Sidebar
export const REMOVE_ITEM_FROM_ORDERGUIDE_EDITOR =
  'REMOVE_ITEM_FROM_ORDERGUIDE_EDITOR';
export const CLICK_EDIT = 'CLICK_EDIT';
export const SAVE_NAME = 'SAVE_NAME';
export const SEARCH_SIDEBAR = 'SEARCH_SIDEBAR';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const UPDATE_SEARCHTEXT = 'UPDATE_SEARCHTEXT';
export const UPDATE_ORDERGUIDE_DRAG_DROP = 'UPDATE_ORDERGUIDE_DRAG_DROP';

/*
 ** Change Units
 */
export const FETCH_PRODUCT_VARIANTS_REQUEST = 'FETCH_PRODUCT_VARIANTS_REQUEST';
export const FETCH_PRODUCT_VARIANTS_SUCCESS = 'FETCH_PRODUCT_VARIANTS_SUCCESS';
export const FETCH_PRODUCT_VARIANTS_FAILED = 'FETCH_PRODUCT_VARIANTS_FAILED';
export const RECEIVE_PRODUCT_VARIANTS = 'RECEIVE_PRODUCT_VARIANTS';
export const TOGGLE_UNIT = 'TOGGLE_UNIT';
export const TOGGLE_UNIT_DESC = 'TOGGLE_UNIT_DESC';
export const SHOW_ERROR_CHANGE_UNIT = 'SHOW_ERROR_CHANGE_UNIT';
export const REMOVE_ERROR_CHANGE_UNIT = 'REMOVE_ERROR_CHANGE_UNIT';
export const UPDATE_UNIT_DESCRIPTION = 'UPDATE_UNIT_DESCRIPTION';
export const SHOW_MORE_OR_LESS_UNITS = 'SHOW_MORE_OR_LESS_UNITS';
export const CLEAR_DATA_CHANGE_UNIT = 'CLEAR_DATA_CHANGE_UNIT';
export const CHANGE_VIEW_CHANGE_UNIT = 'CHANGE_VIEW_CHANGE_UNIT';
export const RESET_UNITS_CHANGE_UNIT = 'RESET_UNITS_CHANGE_UNIT';
export const CHANGE_UNIT_ORDER_GUIDE_REQUEST =
  'CHANGE_UNIT_ORDER_GUIDE_REQUEST';
export const CHANGE_UNIT_ORDER_GUIDE_SUCCESS =
  'CHANGE_UNIT_ORDER_GUIDE_SUCCESS';
export const CHANGE_UNIT_ORDER_GUIDE_FAILED = 'CHANGE_UNIT_ORDER_GUIDE_FAILED';
export const CHANGE_UNIT_ORDER_PRODUCT_REQUEST =
  'CHANGE_UNIT_ORDER_PRODUCT_REQUEST';
export const CHANGE_UNIT_ORDER_PRODUCT_SUCCESS =
  'CHANGE_UNIT_ORDER_PRODUCT_SUCCESS';
export const CHANGE_UNIT_ORDER_PRODUCT_FAILED =
  'CHANGE_UNIT_ORDER_PRODUCT_FAILED';
export const CREATE_VARIANT_REQUEST = 'CREATE_VARIANT_REQUEST';
export const CREATE_VARIANT_SUCCESS = 'CREATE_VARIANT_SUCCESS';
export const CREATE_VARIANT_FAILED = 'CREATE_VARIANT_FAILED';

/*
 ** Change Unit BG
 */
export const INIT_VALUES_CHANGE_UNIT = 'INIT_VALUES_CHANGE_UNIT';
export const RECEIVE_VARIANTS_CHANGE_UNIT = 'RECEIVE_VARIANTS_CHANGE_UNIT';
export const SHOW_LOADER_CHANGE_UNIT = 'SHOW_LOADER_CHANGE_UNIT';
export const INIT_ALL_VARIANTS_LISTS_CHANGE_UNIT =
  'INIT_ALL_VARIANTS_LISTS_CHANGE_UNIT';
export const CHANGE_VARIANTS_SELECTED_CHANGE_UNIT =
  'CHANGE_VARIANTS_SELECTED_CHANGE_UNIT';
export const CHANGE_CHECKOUT_VARIANTS_CHANGE_UNIT =
  'CHANGE_CHECKOUT_VARIANTS_CHANGE_UNIT';
export const SHOW_MODAL_CONFIRM_PROMPT_CHANGE_UNIT =
  'SHOW_MODAL_CONFIRM_PROMPT_CHANGE_UNIT';
export const FETCH_ORDERGUIDE_PRODUCT_VARIANTS_REQUEST =
  'FETCH_ORDERGUIDE_PRODUCT_VARIANTS_REQUEST';
export const FETCH_ORDERGUIDE_PRODUCT_VARIANTS_SUCCESS =
  'FETCH_ORDERGUIDE_PRODUCT_VARIANTS_SUCCESS';
export const FETCH_ORDERGUIDE_PRODUCT_VARIANTS_FAILED =
  'FETCH_ORDERGUIDE_PRODUCT_VARIANTS_FAILED';

/*
 ** Orders
 */
export const FETCH_CART_ORDERS = 'FETCH_CART_ORDERS';
export const FETCH_ORDERS_TO_PAY_REQUEST = 'FETCH_ORDERS_TO_PAY_REQUEST';
export const PREPROCESS_ORDERS = 'PREPROCESS_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const RECEIVE_PENDING_ORDERS = 'RECEIVE_PENDING_ORDERS';
export const RECEIVE_COMPLETED_ORDERS = 'RECEIVE_COMPLETED_ORDERS';
export const RECEIVE_COMPLETED_ORDERS_TODAY = 'RECEIVE_COMPLETED_ORDERS_TODAY';
export const RECEIVE_SINGLE_ORDER = 'RECEIVE_SINGLE_ORDER';
export const RECEIVE_INVOICED_ORDERS = 'RECEIVE_INVOICED_ORDERS';
export const IS_PAYING_INVOICE = 'IS_PAYING_INVOICE';
export const SHOW_LOADER_ORDERS = 'SHOW_LOADER_ORDERS';
export const SAVE_NOTE_CHECKOUT = 'SAVE_NOTE_CHECKOUT';
export const SAVE_DELIVERY_DAY = 'SAVE_DELIVERY_DAY';
export const TOGGLE_COLLAPSE_CHECKOUT = 'TOGGLE_COLLAPSE_CHECKOUT';
export const COLLAPSE_ORDER = 'COLLAPSE_ORDER';
export const CANCEL_BALANCE_PAYMENT = 'CANCEL_BALANCE_PAYMENT';
export const INCREASE_QUANTITY_CART = 'INCREASE_QUANTITY_CART';
export const DECREASE_QUANTITY_CART = 'DECREASE_QUANTITY_CART';
export const CUSTOM_QUANTITY_CART = 'CUSTOM_QUANTITY_CART';
export const ADD_PRODUCT_TO_CART_ERROR = 'ADD_PRODUCT_TO_CART_ERROR';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const DELETE_ORDER_PRODUCT_REQUEST = 'DELETE_ORDER_PRODUCT_REQUEST';
export const DELETE_ORDER_PRODUCT_SUCCESS = 'DELETE_ORDER_PRODUCT_SUCCESS';
export const DELETE_ORDER_PRODUCT_FAILED = 'DELETE_ORDER_PRODUCT_FAILED';
export const ADD_NEW_PRODUCT_CART = 'ADD_NEW_PRODUCT_CART';
export const ADD_URLSAFE_TO_CART_ITEM = 'ADD_URLSAFE_TO_CART_ITEM';
export const CHANGE_QUANTITY_CART_WITH_ID = 'CHANGE_QUANTITY_CART_WITH_ID';
export const CHANGE_QUANTITY_CART_NO_ID = 'CHANGE_QUANTITY_CART_NO_ID';
export const EDIT_ORDER_PRODUCT_COMPLETE = 'EDIT_ORDER_PRODUCT_COMPLETE';
export const TOGGLE_EDIT_MODE_CART = 'TOGGLE_EDIT_MODE_CART';
export const SAVE_NOTE_CART = 'SAVE_NOTE_CART';
export const CHANGE_UNIT_ORDER_PRODUCT = 'CHANGE_UNIT_ORDER_PRODUCT';
export const REMOVE_ORDER_FROM_CART = 'REMOVE_ORDER_FROM_CART';
export const SHOW_ORDERS_TOTAL_CHECK = 'SHOW_ORDERS_TOTAL_CHECK';
export const CHANGE_SELECTED_DELIVERY_DAY_OPTION_INDEX =
  'CHANGE_SELECTED_DELIVERY_DAY_OPTION_INDEX';
export const SHOW_PRE_ORDER_RESTRICTION = 'SHOW_PRE_ORDER_RESTRICTION';
export const CHANGE_ORDER_POSITION = 'CHANGE_ORDER_POSITION';
export const FETCH_ORDERS_COMPLETED_TODAY_REQUEST =
  'FETCH_ORDERS_COMPLETED_TODAY_REQUEST';
export const FETCH_ORDERS_COMPLETED_TODAY_SUCCESS =
  'FETCH_ORDERS_COMPLETED_TODAY_SUCCESS';
export const FETCH_ORDERS_COMPLETED_TODAY_FAILED =
  'FETCH_ORDERS_COMPLETED_TODAY_FAILED';
export const FETCH_ORDERS_FOR_DELIVERY_TODAY_REQUEST =
  'FETCH_ORDERS_FOR_DELIVERY_TODAY_REQUEST';
export const FETCH_ORDERS_FOR_DELIVERY_TODAY_SUCCESS =
  'FETCH_ORDERS_FOR_DELIVERY_TODAY_SUCCESS';
export const FETCH_ORDERS_FOR_DELIVERY_TODAY_FAILED =
  'FETCH_ORDERS_FOR_DELIVERY_TODAY_FAILED';
export const FETCH_PENDING_ORDERS_REQUEST = 'FETCH_PENDING_ORDERS_REQUEST';
export const FETCH_PENDING_ORDERS_SUCCESS = 'FETCH_PENDING_ORDERS_SUCCESS';
export const FETCH_PENDING_ORDERS_FAILED = 'FETCH_PENDING_ORDERS_FAILED';
export const FETCH_CART_ORDERS_REQUEST = 'FETCH_CART_ORDERS_REQUEST';
export const FETCH_CART_ORDERS_SUCCESS = 'FETCH_CART_ORDERS_SUCCESS';
export const FETCH_CART_ORDERS_FAILED = 'FETCH_CART_ORDERS_FAILED';
export const FETCH_ORDERS_WITH_PARAMS_REQUEST =
  'FETCH_ORDERS_WITH_PARAMS_REQUEST';
export const FETCH_ORDERS_WITH_PARAMS_SUCCESS =
  'FETCH_ORDERS_WITH_PARAMS_SUCCESS';
export const FETCH_ORDERS_WITH_PARAMS_FAILED =
  'FETCH_ORDERS_WITH_PARAMS_FAILED';
export const TOGGLE_IS_CHECKOUT_ORDER = 'TOGGLE_IS_CHECKOUT_ORDER';
export const UPDATE_ORDER_DELIVERY_REQUESTS_REQUEST =
  'UPDATE_ORDER_DELIVERY_REQUESTS_REQUEST';
export const UPDATE_ORDER_DELIVERY_REQUESTS_SUCCESS =
  'UPDATE_ORDER_DELIVERY_REQUESTS_SUCCESS';
export const UPDATE_ORDER_DELIVERY_REQUESTS_FAILED =
  'UPDATE_ORDER_DELIVERY_REQUESTS_FAILED';
export const UPDATE_ORDER_DELIVERY_REQUESTS_STATE_ONLY =
  'UPDATE_ORDER_DELIVERY_REQUESTS_STATE_ONLY';
export const FETCH_SINGLE_ORDER_REQUEST = 'FETCH_SINGLE_ORDER_REQUEST';
export const FETCH_SINGLE_ORDER_SUCCESS = 'FETCH_SINGLE_ORDER_SUCCESS';
export const FETCH_SINGLE_ORDER_FAILED = 'FETCH_SINGLE_ORDER_FAILED';
export const UPDATE_DELIVERY_DAY_REQUEST = 'UPDATE_DELIVERY_DAY_REQUEST';
export const UPDATE_DELIVERY_DAY_SUCCESS = 'UPDATE_DELIVERY_DAY_SUCCESS';
export const UPDATE_DELIVERY_DAY_FAILED = 'UPDATE_DELIVERY_DAY_FAILED';
export const UPDATE_DELIVERY_TIME_WINDOW_REQUEST =
  'UPDATE_DELIVERY_TIME_WINDOW_REQUEST';
export const UPDATE_DELIVERY_TIME_WINDOW_SUCCESS =
  'UPDATE_DELIVERY_TIME_WINDOW_SUCCESS';
export const UPDATE_DELIVERY_TIME_WINDOW_FAILED =
  'UPDATE_DELIVERY_TIME_WINDOW_FAILED';
export const UPDATE_DELIVERY_TIME_WINDOW = 'UPDATE_DELIVERY_TIME_WINDOW';
export const RESET_ORDER_REQUEST = 'RESET_ORDER_REQUEST';
export const RESET_ORDER_SUCCESS = 'RESET_ORDER_SUCCESS';
export const RESET_ORDER_FAILED = 'RESET_ORDER_FAILED';
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILED = 'CANCEL_ORDER_FAILED';
export const REVERSE_ORDER_REQUEST = 'REVERSE_ORDER_REQUEST';
export const REVERSE_ORDER_SUCCESS = 'REVERSE_ORDER_SUCCESS';
export const REVERSE_ORDER_FAILED = 'REVERSE_ORDER_FAILED';
export const TOGGLE_CART_MANAGERS_MODAL = 'TOGGLE_CART_MANAGERS_MODAL';
export const REORDER_REQUEST = 'REORDER_REQUEST';
export const REORDER_SUCCESS = 'REORDER_SUCCESS';
export const REORDER_FAILED = 'REORDER_FAILED';
export const UPDATE_ORIGINAL_QUANTITY = 'UPDATE_ORIGINAL_QUANTITY';
/*
 ** Order History
 */
export const FETCH_ORDER_HISTORY_REQUEST = 'FETCH_ORDER_HISTORY_REQUEST';
export const FETCH_ORDER_HISTORY_SUCCESS = 'FETCH_ORDER_HISTORY_SUCCESS';
export const FETCH_ORDER_HISTORY_FAILED = 'FETCH_ORDER_HISTORY_FAILED';
export const SHOW_LOADER_ORDER_HISTORY = 'SHOW_LOADER_ORDER_HISTORY';
export const RECEIVE_ORDER_HISTORY = 'RECEIVE_ORDER_HISTORY';
export const RECEIVE_SUPPLIER_FILTERS = 'RECEIVE_SUPPLIER_FILTERS';
export const TOGGLE_ORDER_DETAIL_MODAL = 'TOGGLE_ORDER_DETAIL_MODAL';
export const CHANGE_SUPPLIER_FILTERS = 'CHANGE_SUPPLIER_FILTERS';
export const CHANGE_DATE_FILTERS = 'CHANGE_DATE_FILTERS';
export const CHANGE_CURRENT_PAGE_FILTER = 'CHANGE_CURRENT_PAGE_FILTER';
export const CHANGE_CUSTOM_DATE_FILTER = 'CHANGE_CUSTOM_DATE_FILTER';
export const CLEAR_ORDER_FILTERS = 'CLEAR_ORDER_FILTERS';
export const FETCH_SORT_MY_ORDERS_REQUEST = 'FETCH_SORT_MY_ORDERS_REQUEST';
export const CHANGE_SORT_BY_FILTER = 'CHANGE_SORT_BY_FILTER';
export const FETCH_SORT_MY_ORDERS_SUCCESS = 'FETCH_SORT_MY_ORDERS_SUCCESS';
export const FETCH_SORT_MY_ORDERS_FAILED = 'FETCH_SORT_MY_ORDERS_FAILED';
export const FETCH_FILTER_MY_ORDERS_REQUEST = 'FETCH_FILTER_MY_ORDERS_REQUEST';
export const FETCH_FILTER_MY_ORDERS_SUCCESS = 'FETCH_FILTER_MY_ORDERS_SUCCESS';
export const FETCH_FILTER_MY_ORDERS_FAILED = 'FETCH_FILTER_MY_ORDERS_FAILED';
export const UPDATE_MY_ORDERS_POST_RATING = 'UPDATE_MY_ORDERS_POST_RATING';

/*
 ** Checkout
 */
export const CLEAR_DATA_CHECKOUT = 'CLEAR_DATA_CHECKOUT';
export const SHOW_LOADER_CHECKOUT = 'SHOW_LOADER_CHECKOUT';
export const SHOW_LOADER_CHECKOUT_SIDEBAR = 'SHOW_LOADER_CHECKOUT_SIDEBAR';
export const CHANGE_CHECKOUT_VIEW = 'CHANGE_CHECKOUT_VIEW';
export const CHANGE_ORDER_DELIVERY_REQUESTS_VIEW =
  'CHANGE_ORDER_DELIVERY_REQUESTS_VIEW';
export const CHANGE_CHOSEN_LOCATION = 'CHANGE_CHOSEN_LOCATION';
export const SHOW_ERROR_CHECKOUT = 'SHOW_ERROR_CHECKOUT';
export const REMOVE_ERROR_CHECKOUT = 'REMOVE_ERROR_CHECKOUT';
export const TOGGLE_CHANGE_UNIT_MODAL_CHECKOUT =
  'TOGGLE_CHANGE_UNIT_MODAL_CHECKOUT';
export const TOGGLE_PENDING_ORDER_STATE = 'TOGGLE_PENDING_ORDER_STATE';
export const CHANGE_BUYER_PHONE_NUMBER = 'CHANGE_BUYER_PHONE_NUMBER';
export const CHECK_STEP1_COMPLETE_CHECKOUT = 'CHECK_STEP1_COMPLETE_CHECKOUT';
export const DELIVERY_INFO_AFTER_INITIAL_LOAD =
  'DELIVERY_INFO_AFTER_INITIAL_LOAD';
export const TOGGLE_CANCEL_ORDER_MODAL = 'TOGGLE_CANCEL_ORDER_MODAL';
export const SHOW_LOADER_CANCEL_ORDER_MODAL = 'SHOW_LOADER_CANCEL_ORDER_MODAL';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILED = 'PLACE_ORDER_FAILED';
export const CHECKOUT_VALIDATE_BEFORE_PLACING_ORDER =
  'CHECKOUT_VALIDATE_BEFORE_PLACING_ORDER';
export const CHANGE_CHECKOUT_PAYMENT_METHOD = 'CHANGE_CHECKOUT_PAYMENT_METHOD';
export const CHECKOUT_PRECHECK_REQUEST = 'CHECKOUT_PRECHECK_REQUEST';
export const CHECKOUT_PRECHECK_SUCCESS = 'CHECKOUT_PRECHECK_SUCCESS';
export const CHECKOUT_PRECHECK_FAILED = 'CHECKOUT_PRECHECK_FAILED';
export const CHECKOUT_UPDATE_REQUEST = 'CHECKOUT_UPDATE_REQUEST';
export const CHECKOUT_UPDATE_SUCCESS = 'CHECKOUT_UPDATE_SUCCESS';
export const CHECKOUT_UPDATE_FAILED = 'CHECKOUT_UPDATE_FAILED';
export const UPDATE_ADDRESS_CHECKOUT_REQUEST =
  'UPDATE_ADDRESS_CHECKOUT_REQUEST';
export const UPDATE_ADDRESS_CHECKOUT_SUCCESS =
  'UPDATE_ADDRESS_CHECKOUT_SUCCESS';
export const UPDATE_ADDRESS_CHECKOUT_FAILED = 'UPDATE_ADDRESS_CHECKOUT_FAILED';
export const ADD_NEW_ADDRESS_CHECKOUT_REQUEST =
  'ADD_NEW_ADDRESS_CHECKOUT_REQUEST';
export const ADD_NEW_ADDRESS_CHECKOUT_SUCCESS =
  'ADD_NEW_ADDRESS_CHECKOUT_SUCCESS';
export const ADD_NEW_ADDRESS_CHECKOUT_FAILED =
  'ADD_NEW_ADDRESS_CHECKOUT_FAILED';
export const CHECK_CART_AND_CHECKOUT_QUANTITY_MISMATCH =
  'CHECK_CART_AND_CHECKOUT_QUANTITY_MISMATCH';
export const CHECK_CART_AND_CHECKOUT_TOTALS_MISMATCH =
  'CHECK_CART_AND_CHECKOUT_TOTALS_MISMATCH';
export const CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_REQUEST =
  'CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_REQUEST';
export const CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_SUCCESS =
  'CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_SUCCESS';
export const CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_FAILED =
  'CHECKOUT_SAVE_CHANGES_ADD_ON_ORDER_FAILED';
export const CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_REQUEST =
  'CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_REQUEST';
export const CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_SUCCESS =
  'CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_SUCCESS';
export const CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_FAILED =
  'CHECKOUT_CANCEL_CHANGES_ADD_ON_ORDER_FAILED';
export const SHOW_ORDER_IS_ALREADY_CHECKED_OUT_MODAL =
  'SHOW_ORDER_IS_ALREADY_CHECKED_OUT_MODAL';
export const HIDE_ORDER_IS_ALREADY_CHECKED_OUT_MODAL =
  'HIDE_ORDER_IS_ALREADY_CHECKED_OUT_MODAL';

/*
 ** Order Desk
 */
export const SHOW_LOADING_ORDER_DESK = 'SHOW_LOADING_ORDER_DESK';

/*
 ** Settings
 */
export const SHOW_LOADER_SPECIFIC_SETTINGS = 'SHOW_LOADER_SPECIFIC_SETTINGS';
export const SHOW_LOADER_MODAL_SETTINGS = 'SHOW_LOADER_MODAL_SETTINGS';
export const SHOW_LOADER_PAYMENTS_TABS = 'SHOW_LOADER_PAYMENTS_TABS';
export const TOGGLE_EDIT_BUYER_MODAL = 'TOGGLE_EDIT_BUYER_MODAL';
export const TOGGLE_ACCOUNT_SETTINGS_MODAL = 'TOGGLE_ACCOUNT_SETTINGS_MODAL';
export const TOGGLE_LOCATIONS_MODAL = 'TOGGLE_LOCATIONS_MODAL';
export const UPDATE_LOCATIONS_MODAL_INDEX = 'UPDATE_LOCATIONS_MODAL_INDEX';
export const TOGGLE_MEMBERS_MODAL = 'TOGGLE_MEMBERS_MODAL';
export const UPDATE_MEMBERS_MODAL_INDEX = 'UPDATE_MEMBERS_MODAL_INDEX';
export const TOGGLE_DELETE_MEMBER_MODAL = 'TOGGLE_DELETE_MEMBER_MODAL';
export const STORE_PREVIOUS_PATH_SETTINGS = 'STORE_PREVIOUS_PATH_SETTINGS';
export const TOGGLE_INVOICE_PAYMENT_MODAL = 'TOGGLE_INVOICE_PAYMENT_MODAL';
export const OPEN_STRIPE_MODAL = 'OPEN_STRIPE_MODAL';

/*
 ** Order Invoices
 */
export const FETCH_VENDOR_PAYMENT_METHODS_PENDING =
  'FETCH_VENDOR_PAYMENT_METHODS_PENDING';
export const FETCH_VENDOR_PAYMENT_METHODS_FULFILLED =
  'FETCH_VENDOR_PAYMENT_METHODS_FULFILLED';
export const FETCH_VENDOR_PAYMENT_METHODS_REJECTED =
  'FETCH_VENDOR_PAYMENT_METHODS_REJECTED';
export const FETCH_ORDER_INVOICES = 'FETCH_ORDER_INVOICES';
export const RECEIVE_ORDER_INVOICES_FULFILLED =
  'RECEIVE_ORDER_INVOICES_FULFILLED';
export const RECEIVE_ORDER_INVOICES_PENDING = 'RECEIVE_ORDER_INVOICES_PENDING';
export const RECEIVE_ORDER_INVOICES_REJECTED =
  'RECEIVE_ORDER_INVOICES_REJECTED';
export const RECEIVE_ORDER_SYNC_DETAILS =
  'RECEIVE_ORDER_SYNC_DETAILS';
export const MARKING_ORDER_SYNC_IN_PROGRESS =
  'MARKING_ORDER_SYNC_IN_PROGRESS';
export const PAY_INVOICES_PENDING = 'PAY_INVOICES_PENDING';
export const PAY_INVOICES_FULFILLED = 'PAY_INVOICES_FULFILLED';
export const PAY_INVOICES_REJECTED = 'PAY_INVOICES_REJECTED';
export const MARKING_AS_PAID_PENDING = 'MARKING_AS_PAID_PENDING';
export const MARKING_AS_PAID_FULFILLED = 'MARKING_AS_PAID_FULFILLED';
export const SET_ORDER_INVOICES_SORT = 'SET_ORDER_INVOICES_SORT';
export const SET_ORDER_INVOICES_FILTER = 'SET_ORDER_INVOICES_FILTER';
export const UPDATE_ORDER_INVOICE_DUE_DATE_FILTER =
  'UPDATE_ORDER_INVOICE_DUE_DATE_FILTER';
export const UPDATE_ORDER_INVOICE_DELIVERY_DATE_FILTER =
  'UPDATE_ORDER_INVOICE_DELIVERY_DATE_FILTER';
export const RESET_ORDER_INVOICES_STATE = 'RESET_ORDER_INVOICES_STATE';
export const SELECT_ORDER_INVOICE_TYPE = 'SELECT_ORDER_INVOICE_TYPE';
export const SELECT_ORDER_INVOICES_DATE_RANGES =
  'SELECT_ORDER_INVOICES_DATE_RANGES';
export const SHOW_LOADER_ORDER_INVOICES = 'SHOW_LOADER_ORDER_INVOICES';
export const ADD_SELECTED_ORDER_INVOICE_ID = 'ADD_SELECTED_ORDER_INVOICE_ID';
export const REMOVE_SELECTED_ORDER_INVOICE_ID =
  'REMOVE_SELECTED_ORDER_INVOICE_ID';
export const CLEAR_SELECTED_ORDER_INVOICES = 'CLEAR_SELECTED_ORDER_INVOICES';
export const DOWNLOAD_STATEMENT_REQUEST = 'DOWNLOAD_STATEMENT_REQUEST';
export const DOWNLOAD_STATEMENT_SUCCESS = 'DOWNLOAD_STATEMENT_SUCCESS';
export const DOWNLOAD_STATEMENT_FAILED = 'DOWNLOAD_STATEMENT_FAILED';
export const SHOW_INVOICE_IMAGES_MODAL = 'SHOW_INVOICE_IMAGES_MODAL';

/*
 ** Vendors
 */
export const FETCH_VENDORS = 'FETCH_VENDORS';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const RECEIVE_VENDORS = 'RECEIVE_VENDORS';
export const RECEIVE_SINGLE_VENDOR = 'RECEIVE_SINGLE_VENDOR';
export const POST_REPORT_INCORRENT_ITEM = 'POST_REPORT_INCORRENT_ITEM';
export const TOGGLE_PRODUCT_DETAIL_MODAL = 'TOGGLE_PRODUCT_DETAIL_MODAL';

/*
 ** Search
 */
export const SHOW_SEARCH_LOADER = 'SHOW_SEARCH_LOADER';
export const CHANGE_GLOBAL_SEARCH_TEXT = 'CHANGE_GLOBAL_SEARCH_TEXT';
export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export const RECEIVE_SEARCH_VENDOR_FILTERS = 'RECEIVE_SEARCH_VENDOR_FILTERS';
export const RECEIVE_SEARCH_ENABLED_VENDOR_FILTERS =
  'RECEIVE_SEARCH_ENABLED_VENDOR_FILTERS';
export const RECEIVE_SEARCH_CATEGORY_FILTERS =
  'RECEIVE_SEARCH_CATEGORY_FILTERS';
export const RECEIVE_SEARCH_ENABLED_CATEGORY_FILTERS =
  'RECEIVE_SEARCH_ENABLED_CATEGORY_FILTERS';
export const CLEAR_SEARCH_ENABLED_FILTERS = 'CLEAR_SEARCH_ENABLED_FILTERS';
export const FETCH_GLOBAL_SEARCH_REQUESTED = 'FETCH_GLOBAL_SEARCH_REQUESTED';
export const FETCH_GLOBAL_SEARCH_SUCCEEDED = 'FETCH_GLOBAL_SEARCH_SUCCEEDED';
export const FETCH_GLOBAL_SEARCH_FAILED = 'FETCH_GLOBAL_SEARCH_FAILED';
export const FETCH_SEARCH_PRODUCT_RESULTS_REQUEST =
  'FETCH_SEARCH_PRODUCT_RESULTS_REQUEST';
export const FETCH_SEARCH_PRODUCT_RESULTS_SUCCESS =
  'FETCH_SEARCH_PRODUCT_RESULTS_SUCCESS';
export const FETCH_SEARCH_PRODUCT_RESULTS_FAILED =
  'FETCH_SEARCH_PRODUCT_RESULTS_FAILED';
export const FETCH_SEARCH_PRODUCT_RESULTS_IN_PROGRESS =
  'FETCH_SEARCH_PRODUCT_RESULTS_IN_PROGRESS';
export const FETCH_SEARCH_SUGGESTIONS_REQUEST =
  'FETCH_SEARCH_SUGGESTIONS_REQUEST';
export const FETCH_SEARCH_SUGGESTIONS_SUCCESS =
  'FETCH_SEARCH_SUGGESTIONS_SUCCESS';
export const FETCH_SEARCH_SUGGESTIONS_FAILED =
  'FETCH_SEARCH_SUGGESTIONS_FAILED';
export const SELECT_OUT_OF_STOCK_INCLUDED_FILTER =
  'SELECT_OUT_OF_STOCK_INCLUDED_FILTER';
export const UNSELECT_OUT_OF_STOCK_INCLUDED_FILTER =
  'UNSELECT_OUT_OF_STOCK_INCLUDED_FILTER';
export const SELECT_ORGANIC_FILTER = 'SELECT_ORGANIC_FILTER';
export const UNSELECT_ORGANIC_FILTER = 'UNSELECT_ORGANIC_FILTER';
export const SELECT_SPECIAL_PROMOTIONS_FILTER =
  'SELECT_SPECIAL_PROMOTIONS_FILTER';
export const UNSELECT_SPECIAL_PROMOTIONS_FILTER =
  'UNSELECT_SPECIAL_PROMOTIONS_FILTER';
export const SELECT_VENDOR_FILTER = 'SELECT_VENDOR_FILTER';
export const UNSELECT_VENDOR_FILTER = 'UNSELECT_VENDOR_FILTER';
export const CLEAR_SELECTED_VENDOR_FILTERS = 'CLEAR_SELECTED_VENDOR_FILTERS';
export const SELECT_CATEGORY_FILTER = 'SELECT_CATEGORY_FILTER';
export const UNSELECT_CATEGORY_FILTER = 'UNSELECT_CATEGORY_FILTER';
export const CLEAR_SELECTED_CATEGORY_FILTERS =
  'CLEAR_SELECTED_CATEGORY_FILTERS';
export const FETCH_RELATED_PRODUCTS = 'FETCH_RELATED_PRODUCTS';
export const RECEIVE_RELATED_PRODUCTS_RESULTS =
  'RECEIVE_RELATED_PRODUCTS_RESULTS';
export const CHANGE_SEARCH_CELL_INDEX = 'CHANGE_SEARCH_CELL_INDEX';
export const SELECT_TOP_LEVEL_CATEGORY = 'SELECT_TOP_LEVEL_CATEGORY';
export const FETCH_SEARCH_SUGGESTIONS_FOR_BYOS_REQUEST =
  'FETCH_SEARCH_SUGGESTIONS_FOR_BYOS_REQUEST';
export const FETCH_SEARCH_SUGGESTIONS_FOR_BYOS_SUCCESS =
  'FETCH_SEARCH_SUGGESTIONS_FOR_BYOS_SUCCESS';

export const FETCH_SEARCH_SUGGESTIONS_FOR_MP_AS_BYOS_REQUEST =
  'FETCH_SEARCH_SUGGESTIONS_FOR_MP_AS_BYOS_REQUEST';
export const FETCH_SEARCH_SUGGESTIONS_FOR_MP_AS_BYOS_SUCCESS =
  'FETCH_SEARCH_SUGGESTIONS_FOR_MP_AS_BYOS_SUCCESS';
/*
 ** Analytics
 */
export const ANALYTICS_SIGNIN_SCREEN_LOADED = 'V3 - signinScreenLoaded';
export const ANALYTICS_SIGNUP_SCREEN_LOADED = 'V3 - signupScreenLoaded';
export const ANALYTICS_SIGNEDUP = 'V3 - signedup';
export const ANALYTICS_SIGNEDUP_CONFIRMED = 'V3 - signedupConfirmed';
export const ANALYTICS_BUYER_CREATED = 'V3 - buyerCreated';
export const ANALYTICS_ITEM_ADDED_TO_CART = 'V3 - cartItemAdded';
export const ANALYTICS_CHECKOUT_DELIVERY_SCREEN_LOADED =
  'V3 - checkOutDeliveryScreenLoaded';
export const ANALYTICS_CHECKOUT_PAYMENT_SCREEN_LOADED =
  'V3 - checkOutPaymentScreenLoaded';
export const ANALYTICS_CHECKOUT_CONFIRM_SCREEN_LOADED =
  'V3 - checkOutConfirmScreenLoaded';
export const ANALYTICS_EXISTING_BUYERS_ENTRY_TO_ADD_SUPPLIER_FLOW =
  'V3 - existingBuyersEntrytoAddSupplierFlow';
export const ANALYTICS_ONBOARDING_SUPPLIER_LIST_SCREEN_LOADED =
  'V3 - onboardingSupplierListScreenLoaded';
export const ANALYTICS_ONBOARDING_SUPPLIER_CONFIRMATION_SCREEN_LOADED =
  'V3 - onboardingSupplierConfirmationScreenLoaded';
export const ANALYTICS_ADD_NEW_BYOS_SUPPLIERS = 'V3 - addNewBYOSSuppliers';
export const ANALYTICS_INBOX_SCREEN_LOADED = 'V3 - inboxScreenLoaded';
export const ANALYTICS_ORDER_DESK_SCREEN_LOADED = 'V3 - orderDeskScreenLoaded';
export const ANALYTICS_MY_ORDERS_SCREEN_LOADED = 'V3 - myOrdersScreenLoaded';
export const ANALYTICS_ORDER_GUIDE_SCREEN_LOADED =
  'V3 - orderGuideScreenLoaded';
export const ANALYTICS_MARKET_SCREEN_LOADED = 'V3 - marketScreenLoaded';
export const ANALYTICS_ORDER_GUIDE_ITEM_ADDED = 'V3 - orderGuideItemAdded';

// OLD EVENTS
export const ANALYTICS_REQUEST_AN_ITEM = 'requestAnItem'; // Segment Migration
export const ANALYTICS_CHANGE_NOTES = 'V2 - Change Notes';
export const ANALYTICS_REORDER = 'reOrder'; // Segment Migration
export const ANALYTICS_REMOVE_ITEM_FROM_CART = 'V2 - Remove Item From Cart';
export const ANALYTICS_REMOVE_VENDOR_FROM_CART = 'V2 - Remove Vendor From Cart';
export const ANALYTICS_CLOSE_CART = 'V2 - Close Cart';
export const ANALYTICS_CHANGE_ORDER_INSTRUCTIONS =
  'V2 - Change Order Instructions';
export const ANALYTICS_CHANGE_DELIVERY_DAY = 'V2 - Change Delivery Day';
export const ANALYTICS_CHANGE_DELIVERY_TIME_WINDOW =
  'V2 - Change Delivery Time Window';
export const ANALYTICS_DELETE_ORDER = 'V2 - Delete Order';
export const ANALYTICS_REVERSE_ORDER = 'V2 - Reverse Order';
export const ANALYTICS_ORDER_DETAILS_LOADED = 'orderDetailsScreenLoaded'; // Segment Migration
export const ANALYTICS_DOWNLOAD_INVOICE = 'V2 - Download Invoice';
export const ANALYTICS_MAKE_PAYMENT = 'V2 - Make Payment';
export const ANALYTICS_DOWNLOAD_STATEMENT = 'V2 - Download Statement';
export const ANALYTICS_FILTER_PAYMENT_HISTORY = 'V2 - Filter Payment History';
export const ANALYTICS_CHANGE_UNIT_ORDERGUIDE_POPUP =
  'V2 - Change Unit in Order Guide';
export const ANALYTICS_CHANGE_UNIT_ORDERGUIDE_CONFIRMED =
  'V2 - Change Unit in Order Guide Confirmed';
export const ANALYTICS_ADD_ORDERGUIDE = 'V2 - Add OrderGuide';
export const ANALYTICS_REMOVE_ORDERGUIDE = 'V2 - Remove OrderGuide';
export const ANALYTICS_SORT_ORDERGUIDE = 'V2 - Sort OrderGuide';
export const ANALYTICS_SHARE = 'V2 - Share';
export const ANALYTICS_VIEW_PAGE = 'V2 - View Page';
export const ANALYTICS_VIEW_PAGE_SEGMENT = 'pageView'; // Segment Migration
export const ANALYTICS_PRODUCT_DETAIL_LOADED = 'productDetailViewOM'; // Segment Migration
export const ANALYTICS_SINGLE_ORDER_PAY_NOW = 'V2 - Single Order Pay Now';
export const ANALYTICS_VIEW_SUGGESTIONS = 'viewSuggestions'; // Segment Migration
export const ANALYTICS_VIEW_SUPPLIER_LIST = 'supplierList'; // Segment Migration
export const ANALYTICS_VIEW_SUPPLIER_INFO1 = 'addSupplier'; // Segment Migration
export const ANALYTICS_VIEW_SUPPLIER_INFO2 = 'addSupplierDeliveryInfo'; // Segment Migration
export const ANALYTICS_VIEW_SUPPLIER_CONFIRMATION = 'addedSupplierConfirmation'; // Segment Migration
export const ANALYTICS_VIEW_SUPPLIER_ADDED = 'supplierAdded'; // Segment Migration
export const ANALYTICS_APP_STORE_CLICKED = 'appStoreClicked'; // Segment Migration
export const ANALYTICS_GOOGLE_PLAY_CLICKED = 'googlePlayClicked'; // Segment Migration
export const SHOW_ADD_SUPPLIER_MODAL = 'SHOW_ADD_SUPPLIER_MODAL';
export const SHOW_ADD_SUPPLIERS_MODAL = 'SHOW_ADD_SUPPLIERS_MODAL';

/*
 ** orderguide
 */
export const LOADING_ORDERGUIDE = 'LOADING_ORDERGUIDE';
export const FETCH_SINGLE_VENDOR_ORDERGUIDE = 'FETCH_SINGLE_VENDOR_ORDERGUIDE';
export const REMOVE_ITEM_FROM_ORDERGUIDE_REQUEST =
  'REMOVE_ITEM_FROM_ORDERGUIDE_REQUEST';
export const DELETE_ORDERGUIDE_REQUEST = 'DELETE_ORDERGUIDE_REQUEST';
export const RECEIVE_ORDERGUIDES = 'RECEIVE_ORDERGUIDES';
export const TOGGLE_CHANGE_UNIT_OG_MODAL = 'TOGGLE_CHANGE_UNIT_OG_MODAL';
export const REPLACE_ORDERGUIDE = 'REPLACE_ORDERGUIDE';
export const REMOVE_ITEM_FROM_ORDERGUIDE = 'REMOVE_ITEM_FROM_ORDERGUIDE';
export const DELETE_ORDERGUIDE = 'DELETE_ORDERGUIDE';
export const EDIT_ORDERGUIDE_AFTER_DND_REQUEST =
  'EDIT_ORDERGUIDE_AFTER_DND_REQUEST';
export const CHANGE_ORDERGUIDE_SEARCH_TEXT = 'CHANGE_ORDERGUIDE_SEARCH_TEXT';
export const CREATE_ORDERGUIDE_REQUEST = 'CREATE_ORDERGUIDE_REQUEST';
export const ADD_ITEM_TO_ORDERGUIDE = 'ADD_ITEM_TO_ORDERGUIDE';
export const ADD_ITEM_TO_ORDERGUIDE_REQUEST = 'ADD_ITEM_TO_ORDERGUIDE_REQUEST';
export const RECEIVE_SUBSTITUTIONS_FOR_PRODUCT =
  'RECEIVE_SUBSTITUTIONS_FOR_PRODUCT';

/*
 ** single order guide
 */
export const LOADING_SINGLE_ORDERGUIDE = 'LOADING_SINGLE_ORDERGUIDE';
export const FETCH_MYITEMS_REQUEST_IN_PROGRESS =
  'FETCH_MYITEMS_REQUEST_IN_PROGRESS';
export const RECEIVE_ORDERGUIDE_LISTS = 'RECEIVE_ORDERGUIDE_LISTS';
export const SAVE_ORDERGUIDE_ITEM = 'SAVE_ORDERGUIDE_ITEM';
export const RECEIVE_ORDERGUIDE_ITEMS = 'RECEIVE_ORDERGUIDE_ITEMS';
export const FETCH_ORDERGUIDES_REQUEST = 'FETCH_ORDERGUIDES_REQUEST';
export const FETCH_ORDERGUIDES_SUCCESS = 'FETCH_ORDERGUIDES_SUCCESS';
export const FETCH_ORDERGUIDES_FAILED = 'FETCH_ORDERGUIDES_FAILED';
export const FETCH_SINGLE_ORDERGUIDE_ITEMS_REQUEST =
  'FETCH_SINGLE_ORDERGUIDE_ITEMS_REQUEST';
export const FETCH_SINGLE_ORDERGUIDE_ITEMS_SUCCESS =
  'FETCH_SINGLE_ORDERGUIDE_ITEMS_SUCCESS';
export const FETCH_SINGLE_ORDERGUIDE_ITEMS_FAILED =
  'FETCH_SINGLE_ORDERGUIDE_ITEMS_FAILED';
export const RECEIVE_ORDERGUIDE_CATEGORY_FILTERS =
  'RECEIVE_ORDERGUIDE_CATEGORY_FILTERS';
export const RECEIVE_ORDERGUIDE_ENABLED_CATEGORY_FILTERS =
  'RECEIVE_ORDERGUIDE_ENABLED_CATEGORY_FILTERS';
export const CHANGE_ORDERGUIDE_SELECTED_VENDOR_FILTERS =
  'CHANGE_ORDERGUIDE_SELECTED_VENDOR_FILTERS';
export const SELECT_ORDERGUIDE_VENDOR_FILTER =
  'SELECT_ORDERGUIDE_VENDOR_FILTER';
export const UNSELECT_ORDERGUIDE_VENDOR_FILTER =
  'UNSELECT_ORDERGUIDE_VENDOR_FILTER';
export const CLEAR_SELECTED_ORDERGUIDE_VENDOR_FILTERS =
  'CLEAR_SELECTED_ORDERGUIDE_VENDOR_FILTERS';
export const SELECT_ORDERGUIDE_CATEGORY_FILTER =
  'SELECT_ORDERGUIDE_CATEGORY_FILTER';
export const UNSELECT_ORDERGUIDE_CATEGORY_FILTER =
  'UNSELECT_ORDERGUIDE_CATEGORY_FILTER';
export const CLEAR_SELECTED_ORDERGUIDE_CATEGORY_FILTERS =
  'CLEAR_SELECTED_ORDERGUIDE_CATEGORY_FILTERS';
export const ADD_ORDERGUIDE_ITEM_REQUEST = 'ADD_ORDERGUIDE_ITEM_REQUEST';
export const ADD_ORDERGUIDE_ITEM_SUCCESS = 'ADD_ORDERGUIDE_ITEM_SUCCESS';
export const ADD_ORDERGUIDE_ITEM_FAILED = 'ADD_ORDERGUIDE_ITEM_FAILED';
export const REMOVE_ORDERGUIDE_ITEM_REQUEST = 'REMOVE_ORDERGUIDE_ITEM_REQUEST';
export const REMOVE_ORDERGUIDE_ITEM_SUCCESS = 'REMOVE_ORDERGUIDE_ITEM_SUCCESS';
export const REMOVE_ORDERGUIDE_ITEM_FAILED = 'REMOVE_ORDERGUIDE_ITEM_FAILED';
export const REMOVE_ORDERGUIDE_ITEM = 'REMOVE_ORDERGUIDE_ITEM';
export const REMOVE_ORDERGUIDE_REQUEST = 'REMOVE_ORDERGUIDE_REQUEST';
export const REMOVE_ORDERGUIDE_SUCCESS = 'REMOVE_ORDERGUIDE_SUCCESS';
export const REMOVE_ORDERGUIDE_FAILED = 'REMOVE_ORDERGUIDE_FAILED';
export const REMOVE_ORDERGUIDE = 'REMOVE_ORDERGUIDE';
export const ADD_ORDERGUIDE_REQUEST = 'ADD_ORDERGUIDE_REQUEST';
export const ADD_ORDERGUIDE_SUCCESS = 'ADD_ORDERGUIDE_SUCCESS';
export const ADD_ORDERGUIDE_FAILED = 'ADD_ORDERGUIDE_FAILED';
export const UPDATE_ORDERGUIDE_REQUEST = 'UPDATE_ORDERGUIDE_REQUEST';
export const UPDATE_ORDERGUIDE_SUCCESS = 'UPDATE_ORDERGUIDE_SUCCESS';
export const UPDATE_ORDERGUIDE_FAILED = 'UPDATE_ORDERGUIDE_FAILED';
export const UPDATE_ORDERGUIDE_POSITION_REQUEST =
  'UPDATE_ORDERGUIDE_POSITION_REQUEST';
export const UPDATE_ORDERGUIDE_POSITION_SUCCESS =
  'UPDATE_ORDERGUIDE_POSITION_SUCCESS';
export const UPDATE_ORDERGUIDE_POSITION_FAILED =
  'UPDATE_ORDERGUIDE_POSITION_FAILED';

/*
 ** Cart
 */
export const SHOW_CART = 'SHOW_CART';
export const SHOW_CART_LOADER = 'SHOW_CART_LOADER';
export const SHOW_CART_ITEM_NOTIFICATION = 'SHOW_CART_ITEM_NOTIFICATION';
export const DISMISS_CART_ITEM_NOTIFICATION = 'DISMISS_CART_ITEM_NOTIFICATION';

/*
 ** Modals
 */
export const TOGGLE_BUYER_SUSPENDED_MODAL = 'TOGGLE_BUYER_SUSPENDED_MODAL';
export const TOGGLE_BUYER_CREDIT_CARD_MODAL = 'TOGGLE_BUYER_CREDIT_CARD_MODAL';

/*
 ** Config
 */
export const FETCH_API_CONFIG_REQUEST = 'FETCH_API_CONFIG_REQUEST';
export const FETCH_API_CONFIG_SUCCESS = 'FETCH_API_CONFIG_SUCCESS';
export const FETCH_API_CONFIG_FAILED = 'FETCH_API_CONFIG_FAILED';
export const RECEIVE_CONFIG_PARAMS = 'RECEIVE_CONFIG_PARAMS';

/*
 ** Vendors BYOS
 */
export const SHOW_CREATE_VENDOR_BYOS_LOADER = 'SHOW_CREATE_VENDOR_BYOS_LOADER';
export const CREATE_VENDOR_BYOS_SUCCESS = 'CREATE_VENDOR_BYOS_SUCCESS';
export const CREATE_VENDOR_BYOS_ERROR = 'CREATE_VENDOR_BYOS_ERROR';
export const RECEIVE_VENDOR_BYOS = 'RECEIVE_VENDOR_BYOS';
export const SHOW_UPDATE_VENDOR_BYOS_LOADER = 'SHOW_UPDATE_VENDOR_BYOS_LOADER';
export const UPDATE_VENDOR_BYOS_SUCCESS = 'UPDATE_VENDOR_BYOS_SUCCESS';
export const UPDATE_VENDOR_BYOS_ERROR = 'UPDATE_VENDOR_BYOS_ERROR';

/*
 ** Get all Vendors
 */
export const SHOW_ALL_SUGGESTED_VENDORS_LOADER =
  'SHOW_ALL_SUGGESTED_VENDORS_LOADER';
export const GET_ALL_SUGGESTED_VENDORS_SUCCESS =
  'GET_ALL_SUGGESTED_VENDORS_SUCCESS';
export const GET_ALL_SUGGESTED_VENDORS_ERROR =
  'GET_ALL_SUGGESTED_VENDORS_ERROR';
export const RECEIVE_ALL_SUGGESTED_VENDORS = 'RECEIVE_ALL_SUGGESTED_VENDORS';

/*
 ** Create Custom Product
 */
export const SHOW_CUSTOM_PRODUCT_LOADER = 'SHOW_CUSTOM_PRODUCT_LOADER';
export const CREATE_CUSTOM_PRODUCT_SUCCESS = 'CREATE_CUSTOM_PRODUCT_SUCCESS';
export const CREATE_CUSTOM_PRODUCT_ERROR = 'CREATE_CUSTOM_PRODUCT_ERROR';
export const RECEIVE_CUSTOM_PRODUCT = 'RECEIVE_CUSTOM_PRODUCT';
export const UPDATE_CUSTOM_PRODUCT_SUCCESS = 'UPDATE_CUSTOM_PRODUCT_SUCCESS';
export const UPDATE_CUSTOM_PRODUCT_ERROR = 'UPDATE_CUSTOM_PRODUCT_ERROR';
export const RECEIVE_UPDATED_CUSTOM_PRODUCT = 'RECEIVE_UPDATED_CUSTOM_PRODUCT';
export const DELETE_CUSTOM_PRODUCT_SUCCESS = 'DELETE_CUSTOM_PRODUCT_SUCCESS';
export const DELETE_CUSTOM_PRODUCT_ERROR = 'DELETE_CUSTOM_PRODUCT_ERROR';
export const RECEIVE_DELETED_CUSTOM_PRODUCT = 'RECEIVE_DELETED_CUSTOM_PRODUCT';

/*
 ** Upload Buyer Invoice
 */
export const SHOW_BUYER_INVOICE_UPLOAD_LOADER =
  'SHOW_BUYER_INVOICE_UPLOAD_LOADER';
export const UPLOAD_BUYER_INVOICE_SUCCESS = 'UPLOAD_BUYER_INVOICE_SUCCESS';
export const UPLOAD_BUYER_INVOICE_ERROR = 'UPLOAD_BUYER_INVOICE_ERROR';
export const RECEIVE_UPLOADED_BUYER_INVOICE = 'RECEIVE_UPLOADED_BUYER_INVOICE';

/**
 * AppFeature
 */
export const UPDATE_FEATURE = 'UPDATE_FEATURE';

/*
 ** Custom Items
 */

export const SHOW_ORDER_CUSTOM_ITEM_LOADER = 'SHOW_ORDER_CUSTOM_ITEM_LOADER';
export const TOGGLE_ADD_CUSTOM_ITEM_MODAL = 'TOGGLE_ADD_CUSTOM_ITEM_MODAL';
export const FETCH_ORDER_CUSTOM_ITEMS_REQUEST =
  'FETCH_ORDER_CUSTOM_ITEMS_REQUEST';
export const FETCH_ORDER_CUSTOM_ITEMS_SUCCESS =
  'FETCH_ORDER_CUSTOM_ITEMS_SUCCESS';
export const FETCH_ORDER_CUSTOM_ITEMS_REQUEST_FAILED =
  'FETCH_ORDER_CUSTOM_ITEMS_REQUEST_FAILED';
export const ADD_CUSTOM_ITEM = 'ADD_CUSTOM_ITEM';
export const SET_ORDER_CUSTOM_ITEM_WITH_INDEX =
  'SET_ORDER_CUSTOM_ITEM_WITH_INDEX';
export const DELETE_ORDER_CUSTOM_ITEM_REQUEST_FAILED =
  'DELETE_ORDER_CUSTOM_ITEM_REQUEST_FAILED';
export const DELETE_ORDER_CUSTOM_ITEM_REQUEST_SUCCESS =
  'DELETE_ORDER_CUSTOM_ITEM_REQUEST_SUCCESS';
export const DELETE_ORDER_CUSTOM_ITEM_REQUEST =
  'DELETE_ORDER_CUSTOM_ITEM_REQUEST';
export const UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST_FAILED =
  'UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST_FAILED';
export const UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST_SUCCESS =
  'UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST_SUCCESS';
export const UPDATE_ORDER_CUSTOM_ITEM_QUANTITY =
  'UPDATE_ORDER_CUSTOM_ITEM_QUANTITY';
export const UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST =
  'UPDATE_ORDER_CUSTOM_ITEM_QUANTITY_REQUEST';


export const LOAD_SUPPLIERS = 'LOAD_SUPPLIERS';
