import { ARRoutePaths } from '@v2/constants/EPaths';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@notch-ordering/ui-components';
import { useMakeInvoiceEditableMutation } from '@/ar/hooks/mutations/InvoiceMutations';
import { Transaction } from '@/ar/network/AccountsReceivable.network';
import { DraftInvoiceModals, useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { ConfirmModal } from '../../shared/ConfirmModal';
import { useDraftInvoicePreferencesStore } from '@/ar/stores/DraftInvoicePreferences';

export interface EditInvoiceConfirmationModalProps {
    invoice: Transaction,
}

export const EditInvoiceConfirmationModal : React.FC<EditInvoiceConfirmationModalProps> = ({ invoice }) => {
    const { modals, closeModal } = useDraftInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const [doNotShowThisModalAgain, setDoNotShowThisModalAgain] = React.useState(false);
    const { setPreference, draftInvoicePreferences } = useDraftInvoicePreferencesStore();
    const isConfirmDeletionModalOpen = modals.editInvoiceConfirmationModal;
    const navigate = useNavigate();

    const handleCloseModal = () => {
        closeModal(DraftInvoiceModals.EditInvoiceConfirmationModal);
        setDoNotShowThisModalAgain(false);
    };

    const makeInvoiceEditableMutation = useMakeInvoiceEditableMutation({
        onSuccess: async (data) => {
            navigate(`/${ARRoutePaths.AR_DRAFT_INVOICES}/${data.draft_invoice_id}`);
            setPreference('disableEditInvoiceModal', doNotShowThisModalAgain);
            handleCloseModal();
        }
    });

    const handleMakeInvoiceEditable = async () => {
        await makeInvoiceEditableMutation.mutateAsync({
            transactionID: invoice.id,
            supplierID: supplierLoginData?.supplier_id,
        });
    };

    const toggleDisableEditInvoiceModal = () => {
        setDoNotShowThisModalAgain(!doNotShowThisModalAgain);
    };

    if (draftInvoicePreferences.disableEditInvoiceModal && (!isConfirmDeletionModalOpen || !invoice)) return null;

    return <>
        <ConfirmModal isOpen={isConfirmDeletionModalOpen}
            titleSeparatorDesktop
            onConfirm={handleMakeInvoiceEditable}
            isLoading={makeInvoiceEditableMutation.isLoading}
            onClose={handleCloseModal}
            prompt={<div className="flex flex-col gap-6"><div className="mt-6">Editing the invoice will remove it from publication and deactivate the payment link until the invoice is published again.</div>
                <Checkbox label="Do not show me this message again" onChange={toggleDisableEditInvoiceModal} checked={doNotShowThisModalAgain} value={`${doNotShowThisModalAgain}`}/>

            </div>}
            title="Edit invoice "
            confirmLabel="Go to edit"/>
    </>;
};
