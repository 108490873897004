import React, { useState } from 'react';
import { Button, GridColDef, GridRenderCellParams, Popover, toast, Typography } from '@notch-ordering/ui-components';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import SettingIcon from '@icons/setting-icon.svg';
import LinkBrokenIcon from '@icons/link-broken.svg';
import CheckIcon from '@icons/check-icon.svg';
import { IntegrationModals, useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { IntegrationDisconnectConfirmModal } from '@ar/components/Integrations/IntegrationModals/IntegrationDisconnectConfirmModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteIntegrationConfiguration, EConfigurationType } from '@ar/network/Bushwhack.network';
import { FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY } from '@ar/hooks/queries/IntegrationsQuery.hook';
import { trackCurrentPageTitle, TrackingEvents } from '@ar/constants/TrackingConstants';

export interface InvoiceActionButtonColumnProps {
    param: GridRenderCellParams<GridColDef>,
}

export const IntegrationActiveRowMoreButton: React.FC<InvoiceActionButtonColumnProps> = ({ param }) => {
    const { setModal, removeSupplierIntegration } = useIntegrationsContext();
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false);
    const { row } = param;
    const queryClient = useQueryClient();
    const isQBOWorkato = row.type === EConfigurationType.Workato;

    const deleteSupplierIntegrationMutation = useMutation(deleteIntegrationConfiguration, {
        onSuccess: () => {
            removeSupplierIntegration();
            toast.show({
                message: 'Integration disconnected successfully',
                icon: <CheckIcon/>,
            });
            setIsConfirmDeleteModalOpen(false);
            queryClient.invalidateQueries([FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY]);
        },
    });

    const handleDisconnectIntegration = ():void => {
        if (isQBOWorkato) {
            // remove the integration only from ar db
            removeSupplierIntegration();
            toast.show({
                message: 'Integration removed successfully',
                icon: <CheckIcon/>,
            });
            setIsConfirmDeleteModalOpen(false);
        } else {
            deleteSupplierIntegrationMutation.mutate(row.id);
        }
    };

    return <><div className="flex gap-1"
        onClick={(e): void => {
            e.stopPropagation();
            trackCurrentPageTitle(TrackingEvents.moreButtonClicked);
        }}>
        <div>
            <Popover
                className="w-auto"
                button=
                    {<Button
                        variant="TERTIARY_TABLE"
                        className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                        size="SMALL"
                        stopPropagation={false}>
                        <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                            <EllipsisIcon className="h-4 w-4"/>
                        </div>
                    </Button>}
                items={[
                    {
                        label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                            <div className="w-4"><SettingIcon className="w-4 h-4 mt-0.5 "/></div>
                            <div className="flex flex-col truncate">
                                <div>Manage</div>
                            </div>
                        </Typography>,
                        // hide manage if the integration is only defined in the supplier side (no bushwack integration)
                        hidden: !row?.id || isQBOWorkato,
                        className: 'z-50',
                        onClick: (): void => {
                            setModal(row.integrationType as IntegrationModals, true);
                        },
                    },
                    {
                        label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 text-red-400">
                            <div className="w-4"><LinkBrokenIcon className="w-4 h-4 mt-0.5 "/></div>
                            <div className="flex flex-col truncate">
                                <div>Disconnect</div>
                            </div>
                        </Typography>,
                        hidden: !row?.id,
                        className: 'z-50',
                        onClick: (): void => {
                            setIsConfirmDeleteModalOpen(true);
                        },
                    },
                ]}/>

        </div>
    </div>
    {isConfirmDeleteModalOpen && <IntegrationDisconnectConfirmModal onConfirm={handleDisconnectIntegration}
        integrationName={row?.name}
        onClose={(): void => {
            setIsConfirmDeleteModalOpen(false);
        }}
        isOpen={isConfirmDeleteModalOpen}/>}
    </>;
};
