export const TAX_CODE_OTHER_EXEMPT = {
    label: 'No', // "Other Exempt"
    value: '99999',
};

export const TAX_CODE_FULLY_TAXABLE = {
    label: 'Yes', // "Fully Taxable"
    value: '',
};

export const DEFAULT_INPUT_DATE_FORMAT = 'yyyy-MM-dd';

export const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSX";

export enum WeekDays {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday',
}

export const BUSHWHACK_API_KEY = process.env.BUSHWHACK_API_KEY ?? '';
export const BUSHWHACK_API = process.env.BASE_URLS.bushwhackUrl ?? '';
export const RUTTER_PUBLIC_KEY = process.env.RUTTER_PUBLIC_KEY ?? '';
export const RUTTER_DEBUG = process.env.RUTTER_DEBUG ?? false;
export const CORE_API_GOOGLE_CLOUD_BUCKET = process.env.CORE_API_GOOGLE_CLOUD_BUCKET ?? '';

export const SURCHARGES_AND_RESTRICTIONS_LINK = process.env.SURCHARGES_AND_RESTRICTIONS_LINK ?? 'about:blank';

export const AMPLITUDE_PUBLIC_API_KEY = process.env.AMPLITUDE_PUBLIC_API_KEY ?? '';
export const SEND_AMPLITUDE_EVENT = process.env.SEND_AMPLITUDE_EVENT ?? false;

export const GATEWAY_API_HOST = process.env.BASE_URLS.gatewayApi ?? '';

export const enum ActivePaymentMethods {
    stripe = 'stripe',
    adyen = 'adyen',
}
