import React from 'react';
import { Typography } from '@notch-ordering/ui-components';

type Address = {
    line1?: string,
    line2?: string,
    city?: string,
    state?: string,
    postal_code?: string,
};

type CustomerAddressProps = {
    address: Address,
};

export const CustomerAddress: React.FC<CustomerAddressProps> = ({ address }) => {
    const { line1, line2, city, state, postal_code: postalCode } = address;
    const firstLine = [line1, line2].filter(Boolean).join(', ');
    const secondLine = [city, state].filter(Boolean).join(', ') + (postalCode ? ` ${postalCode}` : '');

    if (!firstLine && !secondLine) {
        return <Typography className="m-0 text-gray-600">No address provided</Typography>;
    }

    return (
        <>
            {firstLine && <Typography className="m-0">{firstLine}</Typography>}
            {secondLine && <Typography className="m-0">{secondLine}</Typography>}
        </>
    );
};
