import { toastr } from 'react-redux-toastr';

import * as types from '../constants/ActionTypes';
import * as settingsActions from './settingsActions';
import * as DataAPI from '../api/DataAPI';
import { logException } from '../domains/shared/logger';
import { getErrorMessage } from '../helpers/errorMessages';

function receiveBuyer(buyer) {
  return {
    type: types.RECEIVE_BUYER,
    buyer,
  };
}

function receiveBuyerMembers(buyerMembers) {
  return {
    type: types.RECEIVE_BUYER_MEMBERS,
    buyerMembers,
  };
}

function addNewMemberStateOnly(buyerMember) {
  return {
    type: types.ADD_BUYER_MEMBER,
    buyerMember,
  };
}

export const toggleAddVendors = (vendorsAdded) => {
  return {
    type: types.VENDORS_ADDED,
    payload: vendorsAdded,
  };
};

function updateMemberStateOnly(member, members) {
  const index = members.findIndex(
    (currentMember) => currentMember.urlsafe === member.urlsafe
  );

  // Update role
  if (members[index].roles.length > 0) {
    member.roles = members[index].roles;
    member.roles[0].name = member.roleName;
  }

  if (index === -1) {
    throw new Error(
      'An error occured with updateMemberStateOnly: No buyer member index found.'
    );
  }

  return {
    type: types.UPDATE_BUYER_MEMBER,
    buyerMember: member,
    index,
  };
}

function updateMemberEmailNotificationStateOnly(member, members) {
  const index = members.findIndex(
    (currentMember) => currentMember.urlsafe === member.urlsafe
  );

  if (index === -1) {
    throw new Error(
      'An error occured with updateMemberEmailNotificationStateOnly: No buyer member index found.'
    );
  }

  return {
    type: types.UPDATE_EMAIL_NOTIFICATION_ACCOUNT,
    index,
    receiveNotification: !member.receiveNotification,
  };
}

function deleteMemberStateOnly(memberUrlsafe, members) {
  const index = members.findIndex(
    (currentMember) => currentMember.urlsafe === memberUrlsafe
  );

  if (index === -1) {
    throw new Error(
      'An error occured with deleteMemberStateOnly: No buyer member index found.'
    );
  }

  return {
    type: types.DELETE_BUYER_MEMBER,
    index,
  };
}

function addNewShippingAddressStateOnly(shippingAddress) {
  return {
    type: types.ADD_BUYER_SHIPPING_ADDRESS,
    shippingAddress,
  };
}

function updateShippingAddressStateOnly(shippingAddress, index) {
  return {
    type: types.UPDATE_BUYER_SHIPPING_ADDRESS,
    shippingAddress,
    index,
  };
}

export function selectDefaultBuyer(defaultBuyer) {
  window.localStorage.setItem('chefhero_defb', JSON.stringify(defaultBuyer));

  return {
    type: types.SELECT_DEFAULT_BUYER,
    defaultBuyer,
  };
}

export const loadBuyer = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_BUYER_REQUEST });

      const response = await DataAPI.fetchBuyer();
      dispatch({ type: types.FETCH_BUYER_SUCCESS });

      if (response && response.data) {
        dispatch(receiveBuyer(response.data));

        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in loadBuyer, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.FETCH_BUYER_FAILED });
      dispatch(settingsActions.showLoaderSpecificSettings(false));
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadBuyer');
      console.error(error);
      logException(error);
    }
  };
};

export const updateBuyer = (data) => {
  return async (dispatch, getState) => {
    try {
      const { buyer } = getState().buyerReducer;
      dispatch({ type: types.UPDATE_BUYER_REQUEST });

      const response = await DataAPI.updateBuyer(buyer.urlsafe, data);
      dispatch({ type: types.UPDATE_BUYER_SUCCESS });

      if (response && response.data) {
        dispatch(receiveBuyer(response.data));
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in updateBuyer, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_BUYER_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with updateBuyer');
      console.error(error);
      logException(error);
    }
  };
};

export const loadBuyerMembers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_BUYER_MEMBERS_REQUEST });

      const response = await DataAPI.fetchBuyerMembers();
      dispatch({ type: types.FETCH_BUYER_MEMBERS_SUCCESS });

      if (response && response.data) {
        dispatch(receiveBuyerMembers(response.data.buyerMembers));
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in loadBuyerMembers, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.FETCH_BUYER_MEMBERS_FAILED });
      dispatch(settingsActions.showLoaderSpecificSettings(false));
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadBuyerMembers');
      console.error(error);
      logException(error);
    }
  };
};

export const createMember = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_BUYER_MEMBER_REQUEST });

      const response = await DataAPI.createMember(data);
      dispatch({ type: types.CREATE_BUYER_MEMBER_SUCCESS });

      if (response && response.data) {
        dispatch(addNewMemberStateOnly(response.data));
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in createMember, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.CREATE_BUYER_MEMBER_FAILED });
      dispatch(settingsActions.showLoaderSettingsModal(false));

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with createMember');
      console.error(error);
      logException(error);
    }
  };
};

export const updateMember = (memberUrlsafe, data) => {
  return async (dispatch, getState) => {
    try {
      const { buyerMembers } = getState().buyerReducer;
      dispatch({ type: types.UPDATE_BUYER_MEMBER_REQUEST });
      dispatch(
        updateMemberStateOnly({ ...data, urlsafe: memberUrlsafe }, buyerMembers)
      );

      await DataAPI.updateMember(memberUrlsafe, data);
      dispatch({ type: types.UPDATE_BUYER_MEMBER_SUCCESS });
    } catch (error) {
      dispatch({ type: types.UPDATE_BUYER_MEMBER_FAILED });
      dispatch(settingsActions.showLoaderSettingsModal(false));

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with updateMember');
      console.error(error);
      logException(error);
    }
  };
};

export const updateMemberEmailNotification = (account) => {
  return async (dispatch, getState) => {
    try {
      const { buyerMembers } = getState().buyerReducer;
      dispatch({ type: types.UPDATE_EMAIL_NOTIFICATION_REQUEST });
      const member = buyerMembers.find(
        (member) => member.account.urlsafe === account.urlsafe
      );
      dispatch(updateMemberEmailNotificationStateOnly(member, buyerMembers));

      const data = {
        firstName: account.firstName,
        lastName: account.lastName,
        mobile: account.mobile,
        roleName: member.roles.length > 0 ? member.roles[0].name : '',
        receiveNotification: !member.receiveNotification,
      };

      await DataAPI.partialUpdateMember(member.urlsafe, data);
      dispatch({ type: types.UPDATE_EMAIL_NOTIFICATION_SUCCESS });
    } catch (error) {
      dispatch({ type: types.UPDATE_EMAIL_NOTIFICATION_FAILED });
      dispatch(settingsActions.showLoaderSettingsModal(false));

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with updateMemberEmailNotification');
      console.error(error);
      logException(error);
    }
  };
};

export const deleteMember = (memberUrlsafe) => {
  return async (dispatch, getState) => {
    try {
      const { buyerMembers } = getState().buyerReducer;
      const { account } = getState().accountReducer;

      const memberForAccount = buyerMembers.find(
        (member) => member.account.urlsafe === account.urlsafe
      );
      if (memberUrlsafe === memberForAccount.urlsafe) {
        toastr.error(`Error: Cannot delete your own account.`);
        return;
      }

      const selectedOwner = buyerMembers.filter(
        (member) =>
          memberUrlsafe === member.urlsafe && member.roles[0].name === 'Owner'
      );
      if (account.roles[0].name === 'Manager' && selectedOwner.length > 0) {
        toastr.error(`Error: Unauthorized to delete this account.`);
        return;
      }

      dispatch({ type: types.DELETE_BUYER_MEMBER_REQUEST });
      dispatch(deleteMemberStateOnly(memberUrlsafe, buyerMembers));

      await DataAPI.deleteMember(memberUrlsafe);
      dispatch({ type: types.DELETE_BUYER_MEMBER_SUCCESS });
    } catch (error) {
      dispatch({ type: types.DELETE_BUYER_MEMBER_FAILED });
      dispatch(settingsActions.showLoaderSettingsModal(false));

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with deleteMember');
      console.error(error);
      logException(error);
    }
  };
};

export const validateMemberEmail = (email) => {
  return async (dispatch) => {
    dispatch({ type: types.VALIDATE_EMAIL_REQUEST });

    try {
      const response = await DataAPI.validateAccountEmail(email);

      let isEmailValid = false;

      isEmailValid = response.data.isValid;

      dispatch({ type: types.VALIDATE_EMAIL_SUCCESS, isEmailValid });

      return isEmailValid;
    } catch (error) {
      dispatch({ type: types.VALIDATE_EMAIL_FAILED });

      console.error('An Error occured with validateMemberEmail');
      console.error(error);
    }
  };
};

export const createBuyerShippingAddress = (newShippingAddress) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_BUYER_SHIPPING_ADDRESS_REQUEST });

      const response = await DataAPI.createBuyerShippingAddress(
        newShippingAddress
      );

      dispatch({ type: types.CREATE_BUYER_SHIPPING_ADDRESS_SUCCESS });

      if (response && response.data) {
        dispatch(addNewShippingAddressStateOnly(response.data));
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in createBuyerShippingAddress, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.CREATE_BUYER_SHIPPING_ADDRESS_FAILED });
      dispatch(settingsActions.showLoaderSpecificSettings(false));
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with createBuyerShippingAddress');
      console.error(error);
      logException(error);
    }
  };
};

export const updateBuyerShippingAddress = (
  updateShippingAddressIndex,
  shippingAddressData
) => {
  return async (dispatch, getState) => {
    try {
      const { shippingAddresses } = getState().buyerReducer.buyer;
      const shippingAddressId =
        shippingAddresses[updateShippingAddressIndex].id;

      dispatch({ type: types.UPDATE_BUYER_SHIPPING_ADDRESS_REQUEST });

      const response = await DataAPI.updateBuyerShippingAddress(
        shippingAddressId,
        shippingAddressData
      );

      dispatch({ type: types.UPDATE_BUYER_SHIPPING_ADDRESS_SUCCESS });

      if (response && response.data) {
        dispatch(
          updateShippingAddressStateOnly(
            response.data,
            updateShippingAddressIndex
          )
        );
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in updateBuyerShippingAddress, response is ${response}`
        );
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_BUYER_SHIPPING_ADDRESS_FAILED });
      dispatch(settingsActions.showLoaderSpecificSettings(false));
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with updateBuyerShippingAddress');
      console.error(error);
      logException(error);
    }
  };
};

export const resetPaymentMethodToken = () => {
  return (dispatch) => {
    dispatch({
      type: types.CREATE_PAYMENT_METHOD_TOKEN_SUCCEEDED,
      token: '',
    });
  };
};
