import {LOAD_SUPPLIERS} from "@/constants/ActionTypes";

const initialState = {
    suppliers: []
};

export default function suppliersReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SUPPLIERS:
            return {
                ...state,
                suppliers: action.suppliers
            }

        default:
            return state;
    }
}
