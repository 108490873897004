import { logException } from '../domains/shared/logger';

// Custom Redux-Thunk that catches errors
function createThunkMiddleware(extraArgument) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        const result = action(dispatch, getState, extraArgument);

        // If the function returns a promise (async function usually), then
        // catch the error manually and report it.
            if (result && result.catch && typeof result.catch === 'function') {
          return result.catch((error) => {
            console.error(error);
            logException(error);
          });
        }
        return result;
      }

      return next(action);
    };
}

const enhancedReduxThunk = createThunkMiddleware();
enhancedReduxThunk.withExtraArgument = createThunkMiddleware;

export default enhancedReduxThunk;
