// function to track google analytics events

import { isPlatform } from '@ionic/react';
import { TrackingEvent } from '@v2/constants/Tracking';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics';
import { EIonicPlatforms } from '@/constants/Mobile';

export function sendTrackingEvent(event: TrackingEvent): void {
    try {
        if (isPlatform(EIonicPlatforms.CAPACITOR)) {
            if (process.env.DEPLOY_ENV !== 'production') return;

            FirebaseAnalytics.logEvent(event.action, {
                event_category: event.category,
                event_label: event.label,
                value: event.value,
            });
        } else if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event', event.action, {
                event_category: event.category,
                event_label: event.label,
                value: event.value,
            });
        }
    } catch (e) {
        console.warn(e);
    }
}
