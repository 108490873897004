/* eslint-disable no-unused-vars */
import jwtDecode from 'jwt-decode';
import client from './APIClient';

let store;

export const setStore = (storeInstance) => {
  store = storeInstance;
};

export const getState = () => {
    return store.getState();
}

// Every call needs a buyerKey attached to it.
// We try to get it from the reducer first, but if it's not available,
// we get it the local storage, if not there, then accessToken
export const getBuyerKey = () => {
  const { defaultBuyer = {} } = store.getState().buyerReducer;
  const { org } = store.getState().accountReducer;

  let { buyerKey = '' } = defaultBuyer;

  // If we don't have a default buyerKey from the reducer, we try to get it from:
  // 1) localStorage chefhero_defb key
  // 2) The first buyer available in the reducer's accessToken buyerKey orgs
  // 3) The first buyer available in the window object accessToken buyerKey orgs
  if (!buyerKey) {
    const defaultBuyerLocalStorage =
      window.localStorage.getItem('chefhero_defb');
    if (defaultBuyerLocalStorage) {
      buyerKey = JSON.parse(defaultBuyerLocalStorage).buyerKey;
    } else if (org && org.length > 0) {
      const buyerOrgs = org.filter((o) => o.buyerKey);
      buyerKey = buyerOrgs && buyerOrgs.length > 0 ? buyerOrgs[0].buyerKey : '';
    } else {
      const { org } = jwtDecode(window.__accessToken__);
      const buyerOrgs = org.filter((o) => o.buyerKey);
      buyerKey = buyerOrgs && buyerOrgs.length > 0 ? buyerOrgs[0].buyerKey : '';
    }
  }

  return buyerKey;
};

let {
  baseUrl,
  baseUrlv3,
  chatUrl,
  accessListMPChatUrl,
  bushwhackUrl,
} = process.env.BASE_URLS || {};

/*****************************************/
/** Session Calls                       **/
/*****************************************/
export const createSession = (email, password) => {
  const endpoint = `${baseUrlv3}/sessions/`;

  return client({
    method: 'post',
    url: endpoint,
    data: { email, password },
    isAuthed: false,
    isCloudTrace: false,
  });
};

export const refreshSession = (sessionKey, refreshToken) => {
  const endpoint = `${baseUrlv3}/sessions/refresh/`;

  return client({
    method: 'post',
    url: endpoint,
    data: { sessionKey, refreshToken },
    isAuthed: false,
    isCloudTrace: false,
  });
};

export const destroySession = () => {
  const endpoint = `${baseUrlv3}/sessions/`;

  return client({
    method: 'delete',
    url: endpoint,
    isAuthed: false,
  });
};

export const fetchSession = () => {
  const endpoint = `${baseUrlv3}/sessions/`;

  return client({
    method: 'get',
    url: endpoint,
    isAuthed: true,
  });
};

/*****************************************/
/** Buyer Account/Settings Calls        **/
/*****************************************/
export const fetchAccount = (accountUrlsafe) => {
  const endpoint = `${baseUrlv3}/accounts/${accountUrlsafe}/`;

  return client({
    method: 'get',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
};

export const updateAccount = (accountUrlsafe, data) => {
  // Full account object update
  const endpoint = `${baseUrlv3}/accounts/${accountUrlsafe}/`;

  return client({
    method: 'put',
    url: endpoint,
    data,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
};

export const updateAccountSettings = (accountUrlsafe, data) => {
  // Partial update for account settings page
  const endpoint = `${baseUrlv3}/accounts/${accountUrlsafe}/`;

  return client({
    method: 'patch',
    url: endpoint,
    data,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
};

export const validateAccountEmail = (email) => {
  const endpoint = `${baseUrlv3}/accounts/email/validate/`;

  return client({
    method: 'post',
    url: endpoint,
    data: { email },
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
};

/*****************************************/
/** Payments (Buyer Balance)            **/
/*****************************************/
export const updateCustomer = (defaultSource) => {
  const endpoint = `${baseUrlv3}/payments/customers/`;

  return client(
    {
      method: 'put',
      url: endpoint,
      data: { defaultSource },
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
};

export const addCustomerCard = (tokenID) => {
  const endpoint = `${baseUrlv3}/payments/sources/`;

  return client(
    {
      method: 'post',
      url: endpoint,
      data: { tokenID },
      buyerKey: getBuyerKey(),
      isAuthed: true,
    },
    { retry: 5, retryDelay: 1000 }
  );
};

export const deleteCustomerCard = (sourceID) => {
  const endpoint = `${baseUrlv3}/payments/sources/?sourceID=${sourceID}`;

  return client(
    {
      method: 'delete',
      url: endpoint,
      buyerKey: getBuyerKey(),
      isAuthed: true,
    },
    { retry: 5, retryDelay: 1000 }
  );
};

/*****************************************/
/** Buyer Calls                         **/
/*****************************************/
export function fetchBuyer() {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}`;

  return client(
    {
      method: 'get',
      url: endpoint,
      buyerKey,
      isAuthed: true,
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export function updateBuyer(data) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}`;

  return client({
    method: 'put',
    url: endpoint,
    data,
    buyerKey,
    isAuthed: true,
  });
}

/*****************************************/
/** Buyer Members Calls                 **/
/*****************************************/
export function fetchBuyerMembers() {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/members`;

  return client(
    {
      method: 'get',
      url: endpoint,
      buyerKey,
      isAuthed: true,
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export function fetchBuyerMember(memberUrlsafe) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/members/${memberUrlsafe}`;

  return client(
    {
      method: 'get',
      url: endpoint,
      buyerKey,
      isAuthed: true,
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export function createMember(data) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/members`;

  return client({
    method: 'post',
    url: endpoint,
    data,
    buyerKey,
    isAuthed: true,
  });
}

export function updateMember(memberUrlsafe, data) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/members/${memberUrlsafe}`;

  return client({
    method: 'put',
    url: endpoint,
    data,
    buyerKey,
    isAuthed: true,
  });
}

export function partialUpdateMember(memberUrlsafe, data) {
  // Partial update for buyer member receiveNotification
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/members/${memberUrlsafe}`;

  return client({
    method: 'patch',
    url: endpoint,
    data,
    buyerKey,
    isAuthed: true,
  });
}

export function deleteMember(memberUrlsafe) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/members/${memberUrlsafe}`;

  return client({
    method: 'delete',
    url: endpoint,
    buyerKey,
    isAuthed: true,
  });
}

/*****************************************/
/** Buyer Shipping Addresses Calls      **/
/*****************************************/
export function createBuyerShippingAddress(data) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/shipping_addresses`;

  return client({
    method: 'post',
    url: endpoint,
    data,
    buyerKey,
    isAuthed: true,
  });
}

export function updateBuyerShippingAddress(shippingAddressId, data) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/shipping_addresses/${shippingAddressId}`;

  return client({
    method: 'put',
    url: endpoint,
    data,
    buyerKey,
    isAuthed: true,
  });
}

/*****************************************/
/** Order Calls                         **/
/*****************************************/
export function patchOrder(orderUrlsafe, data, isPendingOrder = false) {
  let endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/`;

  if (isPendingOrder) {
    endpoint = `${endpoint}?update_pending_order=${encodeURIComponent(
      isPendingOrder
    )}`;
  }

  return client(
    {
      method: 'patch',
      url: endpoint,
      data,
      buyerKey: getBuyerKey(),
      isAuthed: true,
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export async function searchOrders({
  deliveryDayStart,
  deliveryDayEnd,
  vendorUrlsafe,
  isInvoiced,
  isInShoppingCart,
  isPending,
  sortBy,
  limit,
  includeAddOnOrders,
  isPaid,
} = {}) {
  const endpoint = `${baseUrlv3}/orders/search/`;

  return client(
    {
      method: 'post',
      url: endpoint,
      data: { ...arguments[0] },
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export async function fetchSingleOrder(order_urlsafe) {
  const endpoint = `${baseUrlv3}/orders/${order_urlsafe}/`;

  return client(
    {
      method: 'get',
      url: endpoint,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export function generateOrderPDF(startDate, endDate, orderIDs, type) {
  const endpoint = `${baseUrlv3}/orders/generate_pdf/`;

  return client(
    {
      method: 'post',
      url: endpoint,
      isAuthed: true,
      data: {
        start_date: startDate,
        end_date: endDate,
        order_ids: orderIDs,
        type: type,
      },
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
}

// Draft invoice call
export function generateDraftOrderPDF(order_urlsafe, invoiceNumber, onSuccess) {
  const endpoint = `${baseUrlv3}/orders/${order_urlsafe}/generate-draft-invoice/`;

  return client({
    method: 'get',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
    responseType: 'blob',
  }).then(({ data }) => {
    const fileURL = window.URL.createObjectURL(
      new Blob([data], { type: 'application/pdf' })
    );
    onSuccess(fileURL);
  });
}

/** Endpoint that will cancel an unconfirmed/unprocessed order
 *
 * @param {string} orderUrlsafe
 */
export function cancelOrder(orderUrlsafe) {
  const endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/`;

  return client({
    method: 'delete',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

/** Endpoint that will reset a shopping cart or reverse a checked-out but unconfirmed/unprocessed order
 *
 * @param {string} orderUrlsafe
 */
export function resetOrder(orderUrlsafe) {
  const endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/reset/`;

  return client({
    method: 'post',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

export function triggerIntegrationEvent(configId, eventName, eventData) {
  const endpoint = `${bushwhackUrl}/buyerConfigurations/${configId}/triggerEvent?buyerUrlsafeKey=${getBuyerKey()}`;
  const data = {
    eventName,
    eventData,
  };

  return client({
    method: 'post',
    url: endpoint,
    data,
    isAuthed: true,
  });
}

/*****************************************/
/** V1 Endpoints                        **/
/*****************************************/

/*
 ** Variant Calls
 */
export function fetchProductVariants(vendorID, productCode) {
  const endpoint = `${baseUrl}/api/v1/store/${vendorID}/product/${productCode}/`;

  return client(
    {
      method: 'get',
      url: endpoint,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
}

/*
 ** Order Calls
 */
export async function fetchCartOrders() {
  const endpoint = `${baseUrlv3}/orders/?is_in_shopping_cart=true&limit=100`;

  return client(
    {
      method: 'get',
      url: endpoint,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export function reorder(orderUrlsafe) {
  const endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/reorder/`;

  return client({
    method: 'post',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

export function orderEdit(orderUrlsafe, data) {
  const endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/rt/`;

  return client({
    method: 'put',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
    data,
  });
}

export function saveChangesToAddOnOrder(
  order_urlsafe,
  data = { items: [], createdBy: '' }
) {
  const endpoint = `${baseUrlv3}/orders/${order_urlsafe}/add-on/`;

  return client({
    method: 'post',
    url: endpoint,
    data,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

/*
 ** OrderProduct or OrderItem Calls
 */
export function editOrderProduct(
  orderUrlsafe,
  orderProductUrlsafe,
  data,
  isPendingOrder = false
) {
  let endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/order_products/${orderProductUrlsafe}/`;

  if (isPendingOrder) {
    endpoint = `${endpoint}?update_pending_order=${encodeURIComponent(
      isPendingOrder
    )}`;
  }

  return client({
    method: 'patch',
    url: endpoint,
    data,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

export function addOrderProduct(orderUrlsafe, data, isPendingOrder = false) {
  const endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/order_products/?update_pending_order=${encodeURIComponent(
    isPendingOrder
  )}`;

  return client({
    method: 'post',
    url: endpoint,
    data,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

export function deleteOrderProduct(
  orderUrlsafe,
  orderProductUrlsafe,
  isPendingOrder = false
) {
  let endpoint = `${baseUrlv3}/orders/${orderUrlsafe}/order_products/${orderProductUrlsafe}/`;

  if (isPendingOrder) {
    endpoint = `${endpoint}?update_pending_order=${encodeURIComponent(
      isPendingOrder
    )}`;
  }

  return client({
    method: 'delete',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

export function removeItemFromOrderGuide(data) {
  const { orderGuideID = 0, productID = 0, vendorID = '' } = data;

  const endpoint = `${baseUrl}/api/v1/orderguide/item/?vendorID=${vendorID}&orderGuideID=${orderGuideID}&dataPricelistID=${productID}`;

  return client({
    method: 'DELETE',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

export function deleteOrderGuide(data) {
  const { orderGuideID = 0, vendorID = '' } = data;

  const endpoint = `${baseUrl}/api/v1/orderguide/?orderGuideID=${orderGuideID}&vendorID=${vendorID}`;

  return client({
    method: 'DELETE',
    url: endpoint,
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

/*
 ** Checkout
 */
export function checkoutOrders({ orderUrlsafes = [], orders = [] } = {}) {
  const endpoint = `${baseUrlv3}/orders/checkout/`;

  return client({
    method: 'post',
    url: endpoint,
    data: { orderUrlsafes, orders },
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

/*
 ** Vendor Calls
 */
export async function fetchVendors(useCache = false, vendorId) {
  // this is temporary as we do want to be able to use cache
  // we need to do some investigation on how do we clear or reset the cache
  // after setting the vendor status from admin3 and do the same
  // when we activate a vendor from the scheduled task
  let endpoint = `${baseUrlv3}/vendors/?useCache=${useCache}`;
  if (vendorId) {
    endpoint = `${baseUrlv3}/vendors/${vendorId}`;
  }

  return client(
    {
      method: 'get',
      url: endpoint,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
}

export async function deleteVendor(vendorUrlsafe) {
  const buyerKey = getBuyerKey();
  const endpoint = `${baseUrlv3}/buyers/${buyerKey}/vendors/${vendorUrlsafe}/`;

  return client(
    {
      method: 'delete',
      url: endpoint,
      isAuthed: true,
      buyerKey,
    },
    { retry: 5, retryDelay: 1000 }
  );
}

/*
 ** API Config
 */
export function fetchAPIConfig() {
  const endpoint = `${baseUrlv3}/config/`;

  return client(
    {
      method: 'get',
      url: endpoint,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
}

/**
 * @deprecated This was part of an old product search flow that is no longer accessible
 */
export function reportIncorrectItem(data) {
  const endpoint = `${baseUrl}/api/v1/product/incorrect/`;

  return client({
    method: 'post',
    url: endpoint,
    data: querystring.stringify(data),
    isAuthed: true,
    buyerKey: getBuyerKey(),
  });
}

/*
 ** Search Calls
 */
// CHECK FOR DEPRECATION
export const fetchRelatedProductsSearch = (data) => {
  const endpoint = `${baseUrl}/api/v1/search/`;

  return client(
    {
      method: 'post',
      url: endpoint,
      data: data,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
};

export const fetchSearchProductResults = (data) => {
  const {
    searchTerm,
    buyerID,
    vendorID,
    categories,
    page,
    isOutOfStockIncluded,
    isOrganicSelected,
    isSpecialPromotionsSelected,
  } = data;

  // When searchTerm is empty, indicates a market request with response order alphabetically
  const endpoint =
    searchTerm === ''
      ? `${baseUrl}/api/v1/market/`
      : `${baseUrl}/api/v1/search/`;
  const postData = {
    keywords: encodeURIComponent(searchTerm),
    buyer_id: parseInt(buyerID),
    vendor_ids: vendorID,
    categories: categories,
    metadata_fields: ['vendorID', 'categories'],
    page: page,
    include_out_of_stock:
      isOutOfStockIncluded === undefined ? false : isOutOfStockIncluded,
    organic_only: isOrganicSelected === undefined ? false : isOrganicSelected,
    featured_only:
      isSpecialPromotionsSelected === undefined
        ? false
        : isSpecialPromotionsSelected,
  };
  return client(
    {
      method: 'post',
      url: endpoint,
      data: postData,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
};

export const fetchSearchSuggestions = (searchText, limit) => {
  const endpoint = `${baseUrl}/api/v1/search/generic-item/?search=${encodeURIComponent(
    searchText
  )}&limit=${limit}`;

  return client(
    {
      method: 'get',
      url: endpoint,
      isAuthed: true,
      buyerKey: getBuyerKey(),
    },
    { retry: 5, retryDelay: 1000 }
  );
};

export const resendSignupActivationEmail = (
  email,
  firstName,
  lastName,
  fromSubdomain = ''
) => {
  const endpoint = `${baseUrlv3}/accounts/send-activation-email/`;

  return client({
    method: 'post',
    url: endpoint,
    data: { email, firstName, lastName, fromSubdomain },
    isCloudTrace: true,
  });
};

export const verifySignupAccountCode = (email, code) => {
  const endpoint = `${baseUrlv3}/accounts/verify-code/`;

  return client({
    method: 'post',
    url: endpoint,
    data: { email, code },
    isCloudTrace: true,
  });
};

export const createSelfOnboardBuyerAccount = (email, code, data) => {
  const endpoint = `${baseUrlv3}/accounts/create-self-onboard-buyer/`;

  return client({
    method: 'post',
    url: endpoint,
    data: { email, code, ...data },
    isSelfOnBoardUser: true,
    isCloudTrace: true,
  });
};

export const fetchTwilioChatToken = (identity) => {
  const endpoint = `${chatUrl}/token`;

  return client({
    method: 'post',
    url: endpoint,
    data: { identity, device: 'webapp' },
    isCloudTrace: true,
  });
};

export const createTwilioChatChannel = async ({
  isMarket,
  orderUrlsafe,
  orderNumber,
  orderStatus,
  orderPlacedDay,
  orderDeliveryDay,
  orderEmail,
  orderPhone,
  orderPOSendBy,
  vendorUrlsafe,
  buyerUrlsafe,
  buyerName,
  buyerAccounts,
  timezone,
  isEnableConnect,
  vendorName,
}) => {
  const endpoint = `${chatUrl}/v3/channel/${orderUrlsafe}`;

  return client({
    method: 'post',
    url: endpoint,
    data: {
      is_market: isMarket,
      order_id: orderUrlsafe,
      order_number: orderNumber,
      order_status: orderStatus,
      order_place_at: orderPlacedDay,
      order_delivery_at: orderDeliveryDay,
      sales_rep_email: orderEmail,
      sales_rep_phone: orderPhone,
      notify_vendor_by: orderPOSendBy,
      vendor_id: vendorUrlsafe,
      buyer_id: buyerUrlsafe,
      buyer_name: buyerName,
      buyer_accounts: buyerAccounts,
      timezone,
      is_enable_connect: isEnableConnect,
      vendor_name: vendorName,
    },
    isAuthed: true,
    isCloudTrace: true,
  });
};

export const createTwilioGeneralChatChannel = async ({
  customerId,
  isMarket,
  orderUrlsafe,
  orderNumber,
  orderStatus,
  orderPlacedDay,
  orderDeliveryDay,
  orderEmail,
  orderPhone,
  orderPOSendBy,
  vendorUrlsafe,
  primaryVendorId,
  buyerUrlsafe,
  buyerName,
  buyerAccounts,
  timezone,
  isEnableConnect,
  vendorName,
}) => {
  const endpoint = `${chatUrl}/v3/channel/${customerId}`;

  return client({
    method: 'post',
    url: endpoint,
    data: {
      is_market: isMarket,
      order_id: orderUrlsafe,
      order_number: orderNumber,
      order_status: orderStatus,
      order_place_at: orderPlacedDay,
      order_delivery_at: orderDeliveryDay,
      sales_rep_email: orderEmail,
      sales_rep_phone: orderPhone,
      notify_vendor_by: orderPOSendBy,
      vendor_id: vendorUrlsafe,
      primary_vendor_id: primaryVendorId,
      buyer_id: buyerUrlsafe,
      buyer_name: buyerName,
      buyer_accounts: buyerAccounts,
      timezone,
      is_enable_connect: isEnableConnect,
      vendor_name: vendorName,
    },
    isAuthed: true,
    isCloudTrace: true,
  });
};

export const forgotPassword = (email) => {
  const endpoint = `${baseUrlv3}/accounts/forgot/`;

  return client({
    method: 'post',
    url: endpoint,
    isAuthed: false,
    data: { email },
  });
};

export const resetPassword = ({
  email,
  password1,
  password2,
  passwordResetCode,
}) => {
  const endpoint = `${baseUrlv3}/accounts/reset/`;

  return client({
    method: 'post',
    url: endpoint,
    isAuthed: false,
    data: {
      password_1: password1,
      password_2: password2,
      reset_email: email,
      password_reset_code: passwordResetCode,
    },
  });
};

export const downloadAccessListForMPChatSupplier = async () => {
  return client({
    method: 'get',
    url: accessListMPChatUrl,
    isAuthed: false,
  });
};

export const addImages = async ({orderId, images}) => {
  const endpoint = `${baseUrlv3}/orders/${orderId}/images?buyerKey=${getBuyerKey()}`;
  const formData = new FormData();
  for (const image of images) {
    formData.append('images', image);
  }
  return client({
    method: 'post',
    url: endpoint,
    data: formData,
    isAuthed: true,
  });
};

export const removeImage = async (orderId, imageKey) => {
  const endpoint = `${baseUrlv3}/orders/${orderId}/images/${imageKey}?buyerKey=${getBuyerKey()}`;
  return client({
    method: 'delete',
    url: endpoint,
    isAuthed: true,
  });
};

export const sendDeleteAccountEmail = (email, firstName, lastName) => {
  const endpoint = `${baseUrlv3}/accounts/send-delete-account-email/`;

  return client({
    method: 'post',
    url: endpoint,
    data: {
      email,
      firstName,
      lastName,
    },
    buyerKey: getBuyerKey(),
    isAuthed: true,
  });
};

export const getMinVersion = async () => {
  const endpoint = `${baseUrlv3}/config/min-version`;
  return client({
    method: 'get',
    url: endpoint,
  });
};
