/**
 * Encapsulates network related utility functions.
 *
 */

/**
 *
 * Encodes query params.
 *
 * @param params : query params
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const encodeQueryParams = (params: any):string => {
    const ret = [];
    Object.keys(params).forEach((key) => {
        if (params[key] !== undefined && params[key] !== null) {
            ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
        }
    });
    return ret.join('&');
};

/**
 *
 * Removes empty values from params object.
 *
 * @param obj : params object
 */

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const removeEmptyValuesFromParamsObject = (obj: any):any => {
    Object.keys(obj).forEach((key) => {
        if (obj[key] === undefined || obj[key] === '') {
            delete obj[key];
        }
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
            delete obj[key];
        }
    });
    return obj;
};
