import {
  ADD_CHAT_MESSAGES,
  ADD_CHAT_MESSAGES_SUCCESS,
  ADD_CHAT_MESSAGES_FAILED,
  APPEND_CHAT_MESSAGES,
  APPEND_CHAT_MESSAGES_SUCCESS,
  APPEND_CHAT_MESSAGES_FAILED,
  LOAD_MORE_CHAT_MESSAGES,
  LOAD_MORE_CHAT_MESSAGES_SUCCESS,
  LOAD_MORE_CHAT_MESSAGES_FAILED,
  REMOVE_CHAT_MESSAGES,
} from '../actions/chatMessagesActions';

export const initialState = {
  /** Below is the dynamic structure of each message on runtime */

  // [sid]: {
  //   messages: [],
  //   messageAttributes: {
  //     vendorName: '',
  //     orderNumber: '',
  //     orderStatus: '',
  //     page: 0,
  //   }
  // },
  isLoading: false,
  isRefreshing: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CHAT_MESSAGES:
    case APPEND_CHAT_MESSAGES:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_MORE_CHAT_MESSAGES:
      return {
        ...state,
        isRefreshing: true,
      };

    case ADD_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        [action.sid]: {
          ...state[action.sid],
          messages: [...action.messages],
          messageAttributes: { ...action.messageAttributes },
        },
        isLoading: false,
      };

    case APPEND_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        [action.sid]: {
          ...state[action.sid],
          messages: [...state[action.sid].messages, ...action.messages],
        },
        isLoading: false,
      };

    case LOAD_MORE_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        [action.sid]: {
          ...state[action.sid],
          messages: [...state[action.sid].messages, ...action.messages],
          messageAttributes: {
            ...state[action.sid].messageAttributes,
            page: action.page || state[action.sid].messageAttributes.page,
          },
        },
        isRefreshing: false,
      };

    case REMOVE_CHAT_MESSAGES:
      const updatedState = { ...state };
      if (updatedState[action.sid]) delete updatedState[action.sid];

      return {
        ...updatedState,
      };

    case ADD_CHAT_MESSAGES_FAILED:
    case APPEND_CHAT_MESSAGES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case LOAD_MORE_CHAT_MESSAGES_FAILED:
      return {
        ...state,
        isRefreshing: false,
      };

    default:
      return state;
  }
};
