import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getLinkedSupplierCatalogs, SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { useHomeStore } from '@v2/stores/HomeStore';
import useBuyerHook from './useBuyer.hook';

export const FETCH_SUPPLIER_CATALOGS_QUERY = 'FETCH_SUPPLIER_CATALOGS';

export const useLinkedSupplierCatalogs = (hideOffline = true): {
    fetchQuery: (config) => UseQueryResult,
    supplierCatalogs: SupplierCatalogData[],
} => {
    const { buyer } = useBuyerHook();
    const { supplierCatalogs, setSupplierCatalogs } = useHomeStore();

    const fetchQuery = (config): UseQueryResult => useQuery([FETCH_SUPPLIER_CATALOGS_QUERY, buyer.urlsafe], () => getLinkedSupplierCatalogs(buyer.urlsafe, hideOffline), {
        enabled: !!buyer.urlsafe,
        onSuccess: ({ supplierCatalogs: suppliers }) => {
            setSupplierCatalogs(suppliers);
        },
        ...config
    });

    return {
        fetchQuery,
        supplierCatalogs
    };
};
