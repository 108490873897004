import { toastr } from "react-redux-toastr";
import { replace } from "react-router-redux";

import * as types from "../constants/ActionTypes";
import Utils from "../utils";
import * as DataAPI from "../api/DataAPI";
import { logException } from "../domains/shared/logger";
import i18n from "@v2/i18n";

export function showSearchLoader(isSearchLoading) {
  return {
    type: types.SHOW_SEARCH_LOADER,
    isSearchLoading,
  };
}

export function changeGlobalSearchText(globalSearchText) {
  // Replace all occurrences of '-' character to be a space.
  // This is for display purposes.
  globalSearchText = globalSearchText.replace(/-/g, ' ');

  return {
    type: types.CHANGE_GLOBAL_SEARCH_TEXT,
    globalSearchText,
  };
}

export function fetchSearchRequestInProgress(isSearchRequestInProgress) {
  return {
    type: types.FETCH_SEARCH_PRODUCT_RESULTS_IN_PROGRESS,
    isSearchRequestInProgress,
  };
}

export function receiveSearchResults(apiSearchResults, page = 0, searchData) {
  // Create and add a unique product code and vendor key identification to product object to group by
  const searchResultWithProductAndVendor = apiSearchResults.items.map(
    (productItem) => ({
      ...productItem,
      productAndVendor: productItem.vendorKey.concat(productItem.productCode),
    })
  );

  // Group searchResults into an Array of search results group by productAndVendor unique ID, maintaining order.
  const resultsByProductAndVendor = Utils.groupByKeepOrder(
    searchResultWithProductAndVendor,
    'productAndVendor'
  );

  const globalSearchResults = resultsByProductAndVendor.map(
    (singleProductAndVendorResult) => {
      // Group each individual productAndVendor search results by productCode, maintaining order.
      const resultsByProductCode = Utils.groupByKeepOrder(
        singleProductAndVendorResult,
        'productCode'
      );
      // Sorting each individual productAndVendor search results by price per standard unit to ensure the cheapest results are at the top
      // If price per standard unit is not provided, we want to push those variants to the bottom of the list (and not sort them within themselves)
      resultsByProductCode[0].sort((variantA, variantB) => {
        if (variantA.pricePerStandardUnit && variantB.pricePerStandardUnit) {
          return variantA.pricePerStandardUnit - variantB.pricePerStandardUnit;
        } else if (
          variantA.pricePerStandardUnit &&
          !variantB.pricePerStandardUnit
        ) {
          return -1;
        } else if (
          !variantA.pricePerStandardUnit &&
          variantB.pricePerStandardUnit
        ) {
          return 1;
        } else {
          return 0;
        }
      });

      const vendorName = singleProductAndVendorResult[0]
        ? singleProductAndVendorResult[0].vendorName
        : '';
      const productAndVendor = singleProductAndVendorResult[0]
        ? singleProductAndVendorResult[0].productAndVendor
        : '';

      return {
        vendorName,
        productAndVendor,
        results: resultsByProductCode,
      };
    }
  );

  // we want to use the search data in order to determine
  // if it's a case of pagination or new filters
  // setting the page to undefined will make it easier
  // as none of the filters will have to change
  const searchDataToState = { ...searchData, page: undefined };
  return {
    type: types.RECEIVE_SEARCH_RESULTS,
    globalSearchResults,
    totalProductCount: apiSearchResults.aggregations.total_product_count.value,
    page,
    searchData: searchDataToState,
  };
}

export function resetPage() {
  return {
    type: 'RESET_PAGE',
  };
}

export function receiveSearchVendorFilters(vendors) {
  return {
    type: types.RECEIVE_SEARCH_VENDOR_FILTERS,
    vendors,
  };
}

export function receiveSearchEnabledVendorFilters(vendors) {
  return {
    type: types.RECEIVE_SEARCH_ENABLED_VENDOR_FILTERS,
    enabledVendors: vendors,
  };
}

export function receiveSearchCategoryFilters(categories) {
  return {
    type: types.RECEIVE_SEARCH_CATEGORY_FILTERS,
    categories,
  };
}

export function receiveSearchEnabledCategoryFilters(categories) {
  return {
    type: types.RECEIVE_SEARCH_ENABLED_CATEGORY_FILTERS,
    enabledCategories: categories,
  };
}

export function clearSearchEnabledFilters() {
  return {
    type: types.CLEAR_SEARCH_ENABLED_FILTERS,
  };
}

// Filter Actions
export function changeOutOfStockIncludedFilter(filters) {
  return (dispatch) => {
    dispatch({
      type: filters.isOutOfStockIncluded
        ? types.UNSELECT_OUT_OF_STOCK_INCLUDED_FILTER
        : types.SELECT_OUT_OF_STOCK_INCLUDED_FILTER,
    });

    // Using regex to change just the search query in the URL for organic ('&o=')
    dispatch(
      replace(
        location.search.includes('&oos=')
          ? `/search/${location.search.replace(
              /&oos=\w+/,
              `&oos=${encodeURIComponent(!filters.isOutOfStockIncluded)}`
            )}`
          : `/search/${location.search}&oos=${encodeURIComponent(
              !filters.isOutOfStockIncluded
            )}`
      )
    );
    dispatch(fetchSearchProductResults());
  };
}

export function clearOutOfStockIncludedFilter(isMakingServerCall = true) {
  return (dispatch) => {
    dispatch({ type: types.UNSELECT_OUT_OF_STOCK_INCLUDED_FILTER });

    // Using regex to change just the search query in the URL for organic ('&o=')
    dispatch(
      replace(
        location.search.includes('&oos=')
          ? `/search/${location.search.replace(
              /&oos=\w+/,
              `&oos=${encodeURIComponent(false)}`
            )}`
          : `/search/${location.search}&oos=${encodeURIComponent(false)}`
      )
    );
    if (isMakingServerCall) {
      dispatch(fetchSearchProductResults());
    }
  };
}

export function changeOrganicFilter(filters) {
  return (dispatch) => {
    dispatch({
      type: filters.isOrganicSelected
        ? types.UNSELECT_ORGANIC_FILTER
        : types.SELECT_ORGANIC_FILTER,
    });

    // Using regex to change just the search query in the URL for organic ('&o=')
    dispatch(
      replace(
        location.search.includes('&o=')
          ? `/search/${location.search.replace(
              /&o=\w+/,
              `&o=${encodeURIComponent(!filters.isOrganicSelected)}`
            )}`
          : `/search/${location.search}&o=${encodeURIComponent(
              !filters.isOrganicSelected
            )}`
      )
    );
    dispatch(fetchSearchProductResults());
  };
}

export function clearOrganicFilter(isMakingServerCall = true) {
  return (dispatch) => {
    dispatch({ type: types.UNSELECT_ORGANIC_FILTER });

    // Using regex to change just the search query in the URL for organic ('&o=')
    dispatch(
      replace(
        location.search.includes('&o=')
          ? `/search/${location.search.replace(
              /&o=\w+/,
              `&o=${encodeURIComponent(false)}`
            )}`
          : `/search/${location.search}&o=${encodeURIComponent(false)}`
      )
    );
    if (isMakingServerCall) {
      dispatch(fetchSearchProductResults());
    }
  };
}

export function changeSpecialPromotionsFilter(filters) {
  return (dispatch) => {
    dispatch({
      type: filters.isSpecialPromotionsSelected
        ? types.UNSELECT_SPECIAL_PROMOTIONS_FILTER
        : types.SELECT_SPECIAL_PROMOTIONS_FILTER,
    });

    // Using regex to change just the search query for special promotions ('fo' since it's featuredOnly on the backend)
    dispatch(
      replace(
        location.search.includes('fo=')
          ? `/search/${location.search.replace(
              /fo=\w+/,
              `fo=${encodeURIComponent(!filters.isSpecialPromotionsSelected)}`
            )}`
          : `/search/${location.search}&fo=${encodeURIComponent(
              !filters.isSpecialPromotionsSelected
            )}`
      )
    );
    dispatch(fetchSearchProductResults());
  };
}

export function clearSpecialPromotionsFilter(isMakingServerCall = true) {
  return (dispatch) => {
    dispatch({ type: types.UNSELECT_SPECIAL_PROMOTIONS_FILTER });

    // Using regex to change just the search query for special promotions ('fo' since it's featuredOnly on the backend)
    dispatch(
      replace(
        location.search.includes('&fo=')
          ? `/search/${location.search.replace(
              /&fo=\w+/,
              `&fo=${encodeURIComponent(false)}`
            )}`
          : `/search/${location.search}&fo=${encodeURIComponent(false)}`
      )
    );
    if (isMakingServerCall) {
      dispatch(fetchSearchProductResults());
    }
  };
}

// This function is simply clears the filters, it does not require any URL changes or additional search fetches
export function clearOnUnmount() {
  return (dispatch) => {
    dispatch({ type: types.UNSELECT_OUT_OF_STOCK_INCLUDED_FILTER });
    dispatch({ type: types.UNSELECT_ORGANIC_FILTER });
    dispatch({ type: types.UNSELECT_SPECIAL_PROMOTIONS_FILTER });
  };
}

export function changeVendorFilters(vendorID, vendorName, selectedVendors) {
  return (dispatch) => {
    if (vendorName === 'Show All') {
      // if show all, set selectedVendors array
      // to empty again and unselect all other filters
      dispatch(clearSelectedVendorFilters());
    } else {
      let index = -1;
      const isSelected = selectedVendors.some((vendor, i) => {
        index = i;
        return vendor.vendorID === vendorID;
      });
      // Adding or removing vendor from selectedVendors depending on if selected
      dispatch(
        isSelected
          ? unselectVendorFilter(index)
          : selectVendorFilter(vendorID, vendorName)
      );
    }
    dispatch(fetchSearchProductResults());
  };
}

export function changeCategoryFilters(category, selectedCategories) {
  return (dispatch) => {
    if (category === 'Show All') {
      // if show all, set selectedCategories array
      // to empty again and unselect all other filters
      dispatch(clearSelectedCategoryFilters());
    } else {
      let index = -1;
      const isSelected = selectedCategories.some((c, i) => {
        index = i;
        return category === c;
      });

      // Adding or removing category from selectedCategories depending on if selected
      dispatch(
        isSelected
          ? unselectCategoryFilter(index)
          : selectCategoryFilter(category)
      );
    }
    dispatch(fetchSearchProductResults());
  };
}

export function selectVendorFilter(vendorID, vendorName) {
  const newVendor = [{ vendorID, vendorName }];
  return {
    type: types.SELECT_VENDOR_FILTER,
    newVendor,
  };
}

export function unselectVendorFilter(index) {
  return {
    type: types.UNSELECT_VENDOR_FILTER,
    index,
  };
}

export function clearSelectedVendorFilters() {
  return {
    type: types.CLEAR_SELECTED_VENDOR_FILTERS,
  };
}

export function selectCategoryFilter(category) {
  return {
    type: types.SELECT_CATEGORY_FILTER,
    newCategory: [category],
  };
}

export function unselectCategoryFilter(index) {
  return {
    type: types.UNSELECT_CATEGORY_FILTER,
    index,
  };
}

export function clearSelectedCategoryFilters() {
  return {
    type: types.CLEAR_SELECTED_CATEGORY_FILTERS,
  };
}

export function selectTopLevelCategory(category) {
  return {
    type: types.SELECT_TOP_LEVEL_CATEGORY,
    selectedTopLevelCategory: category,
  };
}

export function receiveRelatedProductsResults(
  relatedProductsResults,
  currentVariant
) {
  // the vendorname and the value is an array of all the search result items
  const relatedProducts = [];

  for (const vendorName in relatedProductsResults) {
    const vendorProducts = relatedProductsResults[vendorName];
    for (const product of vendorProducts) {
      // Filter out the current ProductDetail product itself
      // from the search results
      const anyVariant = product[0];
      if (anyVariant.productCode !== currentVariant.productCode) {
        relatedProducts.push(product);
      }
    }
  }

  return {
    type: types.RECEIVE_RELATED_PRODUCTS_RESULTS,
    relatedProducts,
  };
}

export const changeSearchCellIndex = (currentCellIndex) => {
  return {
    type: types.CHANGE_SEARCH_CELL_INDEX,
    currentCellIndex,
  };
};

export const searchMarketByCategory = (searchText, buyerID, category = {}) => {
  return (dispatch, getState) => {
    try {
      const { filters } = getState().searchReducer;
      const selectedCategories = !_.isEmpty(category)
        ? category.categories
        : [];
      const selectedCategoryName = !_.isEmpty(category) ? category.name : '';

      // Don't bother searching for an empty buyerID
      if (!buyerID) {
        return;
      }

      // Change the route
      dispatch(
        replace(
          `/search/?s=${encodeURIComponent(searchText)}` +
            `&bid=${buyerID}` +
            `&c=${encodeURIComponent(selectedCategoryName)}` +
            `&oos=${encodeURIComponent(filters.isOutOfStockIncluded)}` +
            `&o=${encodeURIComponent(filters.isOrganicSelected)}` +
            `&fo=${encodeURIComponent(filters.isSpecialPromotionsSelected)}`
        )
      );

      // Clear selected categories and save top level category selected
      clearSelectedCategoryFilters();
      dispatch(selectTopLevelCategory(selectedCategoryName));

      // Clear selected vendors
      clearSelectedVendorFilters();
    } catch (error) {
      console.error('An Error occurred in searchMarketByCategory');
      console.error(error);
      logException(error);
    }
  };
};

export const fetchRelatedProductsSearch = (data) => {
  const { currentVariant } = data;

  return async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_RELATED_PRODUCTS });

      const response = await DataAPI.fetchRelatedProductsSearch(data);
      dispatch(receiveRelatedProductsResults(response.data, currentVariant));
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with fetchRelatedProductsSearch');
      console.error(error);
      logException(error);
    }
  };
};

export const fetchSearchProductResults = (page = 0) => {
  const thunk = async (dispatch, getState) => {
    try {
      const {
        globalSearchText,
        selectedCategories,
        selectedVendors,
        selectedTopLevelCategory,
        topLevelCategories,
        filters,
      } = getState().searchReducer;

      const { buyer } = getState().buyerReducer;

      // Get current top level categories based on the new selected top level category
      const categories = selectedTopLevelCategory
        ? (
            topLevelCategories.find(
              (c) => selectedTopLevelCategory === c.name
            ) || {}
          ).categories || []
        : [];

      // Concatenate level 1 and 2 selected categories
      const allSelectedCategories =
        selectedCategories.length > 0 ? selectedCategories : categories;

      const data = {
        buyerID: buyer.id,
        searchTerm: globalSearchText,
        vendorID: selectedVendors.map((vendor) => vendor.vendorID),
        categories: allSelectedCategories,
        page: page ? page : 0,
        isOutOfStockIncluded: filters.isOutOfStockIncluded,
        isOrganicSelected: filters.isOrganicSelected,
        isSpecialPromotionsSelected: filters.isSpecialPromotionsSelected,
      };

      dispatch({ type: types.FETCH_SEARCH_PRODUCT_RESULTS_REQUEST });
      dispatch(fetchSearchRequestInProgress(true));
      const response = await DataAPI.fetchSearchProductResults(data);
      if (!_.isEmpty(response.data) && response.data.items) {
        dispatch(receiveSearchResults(response.data, page, data));

        if (page === 0) {
          // When both vendor and category filters are set to "Show All" after a specific
          // vendor filter was selected, the full list of category filters will be out of date
          // this condition will check if the "Show All" selections have a larger list of categories
          // than what is save in the reducer it will update both the filters.categories and
          // clear any enabled categories (filters.enabledCategories)
          if (filters.categories.length < response.data.categories.length) {
            dispatch(receiveSearchCategoryFilters(response.data.categories));
            dispatch(clearSearchEnabledFilters());
          } else {
            dispatch(
              receiveSearchEnabledCategoryFilters(response.data.categories)
            );
          }

          dispatch(receiveSearchEnabledVendorFilters(response.data.vendorID));
          dispatch(fetchSearchRequestInProgress(false));
        }

        dispatch({ type: types.FETCH_SEARCH_PRODUCT_RESULTS_SUCCESS });
      } else {
        throw new Error(
          `Error, response from server has issues in fetchSearchProductResults, response is ${response}`
        );
      }
    } catch (error) {
      dispatch(fetchSearchRequestInProgress(false));
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      dispatch({ type: types.FETCH_SEARCH_PRODUCT_RESULTS_FAILED });
      console.error('An Error occured with fetchSearchProductResults');
      console.error(error);
      logException(error);
    }
  };

  // Allow debouncing using redux-debounced middleware addon
  thunk.meta = {
    debounce: {
      time: 100,
      key: 'fetch-search-product-results-action',
    },
  };
  return thunk;
};

export const fetchSearchSuggestions = (searchText, limit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_SEARCH_SUGGESTIONS_REQUEST });

      const response = await DataAPI.fetchSearchSuggestions(searchText, limit);

      if (response && response.data) {
        dispatch({ type: types.FETCH_SEARCH_SUGGESTIONS_SUCCESS });
        return response.data;
      } else {
        throw new Error(
          `Error, response from server has issues in fetchSearchSuggestions, response is ${response}`
        );
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.errorMessage ??
        i18n.t('Errors.detectedConnectionProblem');
      toastr.error(`${i18n.t('Common.error')} ${errorMessage}`);
      console.error('An Error occured with fetchSearchSuggestions');
      console.error(error);
      logException(error);

      // Fix PLAT-406: Network error on search suggestions causing unhandled exceptions and breaking rendering
      return {};
    }
  };
};

