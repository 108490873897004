import * as types from '../constants/ActionTypes';
import * as routerActions from './routerActions';
import * as modalsActions from './modalsActions';
import {sendTrackingEvent} from "@v2/utils/Tracking";
import {TrackingEvents} from "@v2/constants/Tracking";

export function showCartLoader(isCartLoading) {
  return {
    type: types.SHOW_CART_LOADER,
    isCartLoading,
  };
}

export function toggleCartManagersModal(isShowCartManagersModal) {
  return {
    type: types.TOGGLE_CART_MANAGERS_MODAL,
    isShowCartManagersModal: !isShowCartManagersModal,
  };
}

export function setCartManagersModalVisibility(isVisible) {
  return {
    type: types.TOGGLE_CART_MANAGERS_MODAL,
    isShowCartManagersModal: isVisible,
  };
}

export function showCart(isCartOpen) {
  if(isCartOpen){
    sendTrackingEvent(TrackingEvents.viewCart);
  }
  return (dispatch) => {
    dispatch({
      type: types.SHOW_CART,
      isCartOpen,
    });
  };
}

export function proceedToCheckout(orderId) {
  return (dispatch, getState) => {
    const { buyer } = getState().buyerReducer;

    // If the buyer is suspended then show modal
    // Otherwise, route them to checkout page
    if (buyer.isSuspended) {
      dispatch(modalsActions.toggleBuyerSuspendedModal());
    } else {
      dispatch(routerActions.changeRoute(`/checkout/${orderId}`));
    }
  };
}

export function showCartItemNotification(item) {
  return (dispatch, getState) => {
    const { isCartOpen } = getState().cartReducer;
    const currentPage = window.location.pathname;
    const isOnCheckoutPage = new RegExp('/checkout/').test(currentPage);

    if (!isCartOpen && !isOnCheckoutPage) {
      dispatch({
        type: types.SHOW_CART_ITEM_NOTIFICATION,
        payload: item,
      });
    }
  };
}

export function dismissCartItemNotification() {
  return {
    type: types.DISMISS_CART_ITEM_NOTIFICATION,
  };
}

export function toggleAddCustomItemModal(
  isOpen = false,
  supplier = '',
  urlsafe = ''
) {
  return {
    type: types.TOGGLE_ADD_CUSTOM_ITEM_MODAL,
    payload: {
      isOpen,
      supplier,
      urlsafe,
    },
  };
}
