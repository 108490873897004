/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { getRemoteConfig } from '@/domains/shared/feature-flag';
import useBuyerHook from './useBuyer.hook';

const useFirebaseRemoteConfig = (): {
    isEnabled: (key: EFirebaseParams) => Promise<boolean>,
    isEnabledForBuyer: (key: EFirebaseParams) => Promise<boolean>,
    getList:(key: EFirebaseParams) => Promise<Array<string>>,
} => {
    const { buyer } = useBuyerHook();

    async function isEnabled(key: EFirebaseParams, fetch: boolean = false): Promise<boolean> {
        const remoteConfig = await getRemoteConfig(fetch);
        return remoteConfig.getBoolean(key);
    }

    async function getList(key: EFirebaseParams, fetch: boolean = false): Promise<Array<string>> {
        const remoteConfig = await getRemoteConfig(fetch);
        return remoteConfig.getString(key).split(',').map((value) => value.trim());
    }

    async function isEnabledForBuyer(key: EFirebaseParams, fetch: boolean = false): Promise<boolean> {
        const remoteConfig = await getRemoteConfig(fetch);
        const urlsafeKeys = remoteConfig.getString(key).split(',').map((value) => value.trim());
        if (urlsafeKeys.includes(buyer.urlsafe)) {
            return true;
        }

        return false;
    }

    return {
        isEnabled,
        isEnabledForBuyer,
        getList,
    };
};

export default useFirebaseRemoteConfig;
