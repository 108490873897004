/* eslint-disable @typescript-eslint/no-explicit-any */
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import create from 'zustand';
import { persist } from 'zustand/middleware';
/**
 * State used by the home page
 */
type HomeState = {
    supplierCatalogs: SupplierCatalogData[],
    setSupplierCatalogs: (catalogs: SupplierCatalogData[]) => void,
};

export const useHomeStore = create<HomeState>()(
    persist(
        (set): HomeState => ({
            supplierCatalogs: [],
            setSupplierCatalogs: (catalogs: SupplierCatalogData[]): void => set(() => ({ supplierCatalogs: catalogs })),
        }),
        { name: 'home' }
    )
);
