export const DEFAULT_PAYMENT_METHOD_TYPE = 'card';
export const ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const CC_INPUT_MAX_LENGTH = 19;
export const CVC_INPUT_MAX_LENGTH = 4;

/**
 *
 * This function is used to format the credit card expiration date
 *
 * @param string - the credit card number
 */
export const ccExpiresFormat = (string):string => string.replace(/[^0-9]/g, '').replace(/^([2-9])$/g, '0$1').replace(/^(1{1})([3-9]{1})$/g, '0$1/$2').replace(/^0{1,}/g, '0')
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2');

/**
 *
 * This function is used to format the credit card number
 *
 * @param string - string to format
 */
export const cvcFormat = (string):string => string.replace(/[^0-9]/g, '').trim();

/**
 *
 * This function is used to format the credit card number
 *
 * @param string - string to format
 */
export const ccNumberFormat = (string):string => string.replace(/[^0-9]/g, '').replace(/(.{4})/g, '$1 ').trim();
