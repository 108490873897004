const emptyProduct = {
  productDescription: '',
  unitFloat: true,
  productExternalCode: null,
  id: 0,
  organic: false,
  variantCode: '',
  supplier: '',
  local: false,
  price: 0,
  productCode: '',
  urlsafe: '',
  unit: '',
  pricelistName: '',
  category: '',
  variantDescription: '',
  picture: null,
  variantExternalCode: null,
  vendorID: '',
  inStock: true,
  customUnit: true,
  taxable: true,
  vendorName: '',
  preOrder: false,
  byCall: false,
  productName: '',
  unitDescription: null,
  genericItem: { imageURL: '' },
};

const initialState = {
  variants: [],
  orderguide: {},
  originalVariant: emptyProduct,
  openedBy: '',
  variantsSelected: [], // Used by orderguide only, an array of ids
  initialVariantsSelected: [], // will be of of variantsSelected or variantsCheckout format depending on openedBy value
  variantsCheckout: [], // Used by checkout only, an array of objects {id, quantity, orderProductUrlsafe}
  isChangeUnitLoading: false,
  isCloseModalConfirmed: false,
  isModalConfirmPromptShowing: false,
};

export default function changeUnitReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_VALUES_CHANGE_UNIT':
      return Object.assign({}, state, {
        orderguide: action.orderguide,
        originalVariant: action.originalVariant,
        openedBy: action.openedBy,
      });

    case 'INIT_ALL_VARIANTS_LISTS_CHANGE_UNIT':
      return Object.assign({}, state, {
        initialVariantsSelected: action.initialVariantsSelected,
        variantsSelected: action.variantsSelected,
        variantsCheckout: action.variantsCheckout,
      });

    case 'RECEIVE_VARIANTS_CHANGE_UNIT':
      return Object.assign({}, state, {
        variants: action.variants,
      });

    case 'SHOW_LOADER_CHANGE_UNIT':
      return Object.assign({}, state, {
        isChangeUnitLoading: action.isChangeUnitLoading,
      });

    case 'CHANGE_VARIANTS_SELECTED_CHANGE_UNIT':
      return Object.assign({}, state, {
        variantsSelected: action.variantsSelected,
      });

    case 'CHANGE_CHECKOUT_VARIANTS_CHANGE_UNIT':
      return Object.assign({}, state, {
        variantsCheckout: state.variantsCheckout
          .slice(0, action.variantIndex)
          .concat([
            {
              ...state.variantsCheckout[action.variantIndex],
              quantity: action.quantity,
            },
          ])
          .concat(state.variantsCheckout.slice(action.variantIndex + 1)),
      });

    case 'SHOW_MODAL_CONFIRM_PROMPT_CHANGE_UNIT':
      return Object.assign({}, state, {
        isModalConfirmPromptShowing: action.isModalConfirmPromptShowing,
      });

    case 'CLEAR_DATA_CHANGE_UNIT':
      return initialState;

    default:
      return state;
  }
}
