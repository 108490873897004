import '../../../styles/dataPage.scss';
import React, { useMemo } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSupplier, useGetSupplierInfo } from '@ar/hooks/queries/SupplierQueries.hook';
import { Button, Loading, NotchDataGrid, Popover, Separator, Typography } from '@notch-ordering/ui-components';
import { ARRoutePaths } from '@v2/constants/EPaths';
import BackIcon from '@icons/back-icon.svg';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { DO_NOT_CHARGE_ENABLED_STATUSES,
    DontPopoverLabel,
    MANUAL_CHARGE_ENABLED_STATUSES } from '@ar/components/Invoices/InvoiceActionButtonColumn/InvoiceActionButtonColumn';
import { PageTitle } from '@ar/components/PageTitle';
import { useGetBills, useGetTransactionActivity, useGetTransactionItems } from '@ar/hooks/queries/InvoicesQueries.hook';
import { InvoiceStatusBadge } from '@ar/components/Invoices/InvoiceStatusBadge';
import { GetTransactionStatus, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { SectionTitle } from '@ar/components/shared/SectionTitle';
import { EmptyState,
    getInvoiceCreditColumns,
    getInvoiceItemColumns,
    getInvoicePaymentColumns,
    gridSX,
    INVOICE_PAYMENTS_COLUMNS,
    PanelBlock } from '@ar/pages/InvoiceDetailsPage/InvoiceDetailsPageConstants';
import { useGetCustomer, useGetCustomerPaymentMethods } from '@ar/hooks/queries/CustomerQueries.hook';
import { getFlagIconByCurrency } from '@ar/pages/PayoutDetailsPage/PayoutDetailsPageConstants';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceEventItem } from '@ar/components/InvoiceDetails/InvoiceEventItem';
import { useBillStore } from '@ar/stores/BillsStore';
import CardIcon from '@icons/card-icon.svg';
import { getBillStatus } from '@ap/pages/BillsPage/BillsPageConstants';
import { BillManualChargeModal } from '@ap/components/Bills/Modals/BillManualChargeModal';
import { BillDoNotChargeModal } from '@ap/components/Bills/Modals/BillDoNotChargeModal';
import { getGpoId } from '@v2/utils/GPOUtils';
import { PADModal } from '@ap/components/PADModal';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency, formatPhoneNumber } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';
import { InvoiceAttachments } from '@/ar/components/shared/InvoiceAttachments';
import { useSignUrls } from '@/ar/hooks/queries/SignUrlsQuery.hook';

/**
 * The AR Bills detail page
 *
 * @returns JSX Element
 */
export const BillDetailsPage = function BillDetailsPage(): JSX.Element {
    useDocumentTitle('Bill Details - Notch');
    const { supplierLoginData, supplier } = useSupplierStore();
    const { invoiceID } = useParams();
    const { setModal, modals } = useBillStore();
    const { data, isLoading } = useGetBills({
        searchParams: {
            customer_id: supplierLoginData?.customer_id,
            id: invoiceID,
            limit: 1
        }
    });
    const isErrorInvoice = !isLoading && !data?.bills.length;
    const invoice = data?.bills[0] ?? null;
    const supplierToken = supplierLoginData?.api_token;
    const gpoId = getGpoId(supplierLoginData);

    const { data: invoiceItemsResponse, isLoading: isLoadingInvoiceItems } = useGetTransactionItems({
        transactionID: invoice?.id,
        customer_id: supplierLoginData?.customer_id,
    });

    const { data: customerResults } = useGetCustomer(
        { customerID: supplierLoginData?.customer_id,
            token: supplierToken,
            supplierID: supplierLoginData?.supplier_id }
    );

    const { data: invoiceActivityResponse, isLoading: isLoadingActivity } = useGetTransactionActivity({
        supplier_id: supplierLoginData?.supplier_id,
        customer_id: supplierLoginData?.customer_id,
        entity_id: invoice?.id,
        entity_type: 'invoice',
    });

    const { data: supplierInfo, isLoading: isLoadingSupplierInfo } = useGetSupplierInfo({
        supplierID: invoice?.supplier_id,
        customerId: supplierLoginData?.customer_id,
    });

    const { data: signedAttachments } = useSignUrls({
        customerID: supplierLoginData?.customer_id,
        supplierID: supplierLoginData?.supplier_id,
        urls: invoice?.attachments
    });

    const { data: paymentMethods } = useGetCustomerPaymentMethods({ customerID: invoice?.customer_id, supplierID: invoice?.supplier_id, gpoId });
    const events = invoiceActivityResponse?.activities ?? [];
    const hasEvents = !isLoadingActivity && events.length > 0;

    const invoiceItems = invoiceItemsResponse?.items ?? [];
    const credits = invoice?.credits_applied ?? [];
    const hasCredits = !isLoading && credits.length > 0;
    const items = invoiceItems.filter((item) => item.type === 'invoice') ?? [];
    const hasItems = !isLoadingInvoiceItems && items.length > 0;
    const navigate = useNavigate();
    const payments = invoice?.charges ?? [];
    const hasPayments = !isLoading && payments.length > 0;
    const popoverActionItems = useMemo(() => [
        {
            label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                <div className="w-4"><CardIcon className="w-4 h-4 mt-0.5 "/></div>
                <div className="flex flex-col truncate">
                    <div>Pay</div>
                </div>
            </Typography>,
            className: 'z-50',
            hidden: !MANUAL_CHARGE_ENABLED_STATUSES.includes(invoice?.status as TransactionStatus),
            onClick: (): void => {
                setModal('manualCharge', true, invoice);
                sendTrackingEvent(TrackingEvents.chargeInvoiceClicked);
            },
        },
        {
            label: <DontPopoverLabel label={'Dispute'}/>,
            className: 'z-50',
            hidden: !DO_NOT_CHARGE_ENABLED_STATUSES.includes(invoice?.status as TransactionStatus),
            onClick: (): void => {
                setModal('doNotChargeInvoice', true, invoice);
                sendTrackingEvent(TrackingEvents.doNotChargeClicked);
            },
        },
    ], [invoice?.status, supplier]);

    const shouldShowActionButton = useMemo(() => popoverActionItems.some((item) => !item.hidden), [popoverActionItems]);

    useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
        customerId: supplierLoginData?.customer_id
    });
    const [autoPayMethod] = useAutoPayMethod(invoice?.supplier_id, supplierLoginData?.isCustomer, paymentMethods);
    if (isLoading) {
        return <Loading isDark />;
    }

    if (isErrorInvoice) {
        return <>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    onClick={(): void => navigate(`/${ARRoutePaths.AR_BILLS}`)}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
            </section>
            <div className="flex flex-col gap-4 ">
                <PageTitle>Invoice not found</PageTitle>
                <Typography as="div" className="text-gray-600">Apologies, no invoice matching the given criteria could be found in the system.</Typography>
            </div>
        </>;
    }
    const getColumnsVisibilityModel = (): Record<string, boolean> => {
        // if keys are not present in the object by default, all the columns are visible.

        const visibilityModel = {
            [INVOICE_PAYMENTS_COLUMNS.surchargeAmount]: invoice.is_surcharge_enabled || !!invoice.surcharge_amount
        };

        return visibilityModel;
    };
    return (
        <>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    onClick={(): void => navigate(`/${ARRoutePaths.AR_BILLS}`)}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
                <div className="flex gap-3 items-center shrink-0">
                    {MANUAL_CHARGE_ENABLED_STATUSES.includes(invoice?.status as TransactionStatus)
                    && <Button size="SMALL"
                        onClick={(): void => {
                            setModal('manualCharge', true, invoice);
                            sendTrackingEvent(TrackingEvents.chargeInvoiceClicked);
                        }}>
                        Charge
                    </Button>}
                    {shouldShowActionButton && <Popover
                        className="w-60"
                        button={
                            <Button
                                size="ICON_SMALL"
                                className="min-w-min"
                                stopPropagation={false}>
                                <div>
                                    <EllipsisIcon className="h-4 w-4"/>
                                </div>
                            </Button>
                        }
                        items={popoverActionItems}/>}

                </div>
            </section>
            <section className="pb-8">
                <div className="flex gap-3 items-center">
                    <PageTitle>{formatAsCurrency(centsToDollars(invoice?.total))}</PageTitle>
                    <InvoiceStatusBadge status={getBillStatus(invoice?.status as GetTransactionStatus) as TransactionStatus}/>
                </div>
                <Typography className="text-gray-600 mt-1" as="div">Bill {invoice.invoice_number}</Typography>
            </section>
            <SeparatorInternalPageFullWidth className="pb-px"/>
            <section className="flex lg:flex-row flex-col-reverse">
                <div className="overflow-x-auto -ml-10 lg:border-r border-r-gray-200 w-full h-full">
                    <section className="p-10">
                        <SectionTitle className="mb-6">Items</SectionTitle>
                        {isLoadingInvoiceItems && <Loading isDark/>}
                        {!hasItems && <EmptyState text="There are no items in this bill."/>}
                        {hasItems && <NotchDataGrid columns={getInvoiceItemColumns()}
                            autoHeight={true}
                            getRowHeight={() => 'auto'}
                            rowCount={items.length}
                            sx={gridSX}
                            sortingMode="client"
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            rowsPerPageOptions={[50, 100]}
                            hideFooter={true}
                            rows={items}
                            loading={isLoadingInvoiceItems}/>}
                    </section>
                    <Separator/>
                    <section className="p-10">
                        <SectionTitle className="mb-6">Credits</SectionTitle>
                        {isLoading && <Loading isDark/>}
                        {!hasCredits && <EmptyState text="No credits have been applied to this bill yet"/>}
                        {hasCredits && <NotchDataGrid columns={getInvoiceCreditColumns()}
                            autoHeight
                            getRowHeight={() => 'auto'}
                            rowsPerPageOptions={[50, 100]}
                            sx={gridSX}
                            getRowId={(row) => row.external_id ?? uuidv4()}
                            rowCount={credits.length}
                            sortingMode="client"
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            hideFooter={true}
                            rows={credits}
                            loading={isLoading}
                            components={{
                                NoRowsOverlay: () => <Typography className="mt-4 w-full">This bill has no credit applied
                                </Typography>,
                            }}/>}

                    </section>

                    <Separator/>
                    <section className="p-10">
                        <SectionTitle className="mb-6">Payments</SectionTitle>
                        {isLoading && <Loading isDark/>}
                        {!hasPayments && <EmptyState text="No payments have been made towards this bill yet"/>}
                        {hasPayments && <NotchDataGrid columns={getInvoicePaymentColumns()}
                            autoHeight
                            getRowHeight={() => 'auto'}
                            columnVisibilityModel={getColumnsVisibilityModel()}
                            rowsPerPageOptions={[50, 100]}
                            sx={gridSX}
                            getRowId={(row) => row.external_id ?? uuidv4()}
                            rowCount={payments.length}
                            sortingMode="client"
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            hideFooter={true}
                            rows={payments}
                            components={{
                                NoRowsOverlay: () => <Typography className="mt-4 w-full">This bill has no payment</Typography>,
                            }}
                            loading={isLoading}/>}
                    </section>
                    <Separator/>
                    {signedAttachments?.signed_urls?.length > 0 && <><section className="px-10 pt-4 pb-10">
                        <InvoiceAttachments showTitle attachments={signedAttachments?.signed_urls} />
                    </section>
                    <Separator/>
                    </>}

                    <section className="p-10">
                        <SectionTitle className="mb-12">Events</SectionTitle>
                        {isLoadingActivity && <Loading isDark/>}
                        {!hasEvents && <EmptyState text="No events have been recorded for this bill yet."/>}
                        {hasEvents && <div className="flex flex-col gap-5">
                            <>
                                {events.map((event, index) => <InvoiceEventItem isBill={true}
                                    key={event.id}
                                    event={event}
                                    isLast={index === events.length - 1}
                                    isFirst={index === 0}/>)}
                                {events.length === 0 && <Typography className="text-gray-600">No events</Typography>}
                            </>

                        </div>}

                    </section>
                </div>
                <div className="py-10 pl-8 max-w-[272px] w-full" >
                    {isLoadingSupplierInfo && <Loading isDark/> }
                    {!isLoadingSupplierInfo && <div className="flex flex-col gap-8">
                        <PanelBlock title={'Supplier Name'} value={<span className="text-teal-500">{supplierInfo.name}</span>}/>
                        <PanelBlock title={'Contact information'}
                            value={<div className="flex gap-1 flex-col truncate">
                                <div>{supplierInfo.email}</div>
                                {!!supplierInfo.phone && <div>{formatPhoneNumber(supplierInfo.phone)}</div>}
                            </div>}/>
                        <PanelBlock
                            title={'Issue date'}
                            value={invoice.invoice_date ? <div>{formatIsoStringToUtcDate(new Date(invoice.invoice_date).toISOString(), 'MMM d, yyyy')}</div> : '--'}/>
                        <PanelBlock
                            title={'Due date'}
                            value={invoice.due_date ? <div>{formatIsoStringToUtcDate(new Date(invoice.due_date).toISOString(), 'MMM d, yyyy')}</div> : '--'}/>
                        <PanelBlock title={'Currency'}
                            value={ <div className=" flex gap-2 items-center"><span>
                                {getFlagIconByCurrency(supplierInfo?.currency)}
                            </span><span>{supplierInfo?.currency?.toUpperCase()}</span></div>}/>
                        {customerResults?.has_payment_method && autoPayMethod
                        && <PanelBlock title="Payment method" value={<PaymentMethodBadge brand={autoPayMethod.brand} last4={autoPayMethod.last4} autoCollection={false} />}/>
                        }

                    </div>
                    }
                </div>
            </section>
            <BillManualChargeModal/>
            <BillDoNotChargeModal/>
            <PADModal isOpen={modals?.addPaymentMethod}
                handleClose={(): void => {
                    setModal('addPaymentMethod', false, invoice);
                }}
                fromSettingsPage/>
        </>
    );
};
