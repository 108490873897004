import {
  ADD_CHAT_CHANNELS,
  ADD_CHAT_CHANNELS_SUCCESS,
  ADD_CHAT_CHANNELS_FAILED,
  UPDATE_CHAT_CHANNEL,
  UPDATE_CHAT_CHANNEL_SUCCESS,
  UPDATE_CHAT_CHANNEL_FAILED,
  SET_CHAT_CHANNEL_LAST_MESSAGE,
  SET_CHAT_CHANNEL_UNREAD_MESSAGE_COUNT,
  REMOVE_CHAT_CHANNEL,
} from '../actions/chatChannelsListActions';

export const initialState = {
  list: {
    /** Below is the dynamic structure of each channel on runtime */
    // [sid]: {
    //   channel: {},
    //   channelAttributes: {
    //     vendorName: '',
    //     orderNumber: '',
    //     orderStatus: '',
    //     orderDate: '',
    //     deliveryDate: '',
    //     timestamp: '',
    //     lastMessage: '',
    //     lastMessageIndex: 0,
    //     lastConsumedMessageIndex: 0
    //   }
    // },
  },
  isLoading: false,
  unreadMessagesCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CHAT_CHANNELS:
    case UPDATE_CHAT_CHANNEL:
      return {
        ...state,
        isLoading: true,
      };

    case ADD_CHAT_CHANNELS_SUCCESS:
      return {
        ...state,
        list: { ...state.list, ...action.channels },
        isLoading: false,
      };

    case UPDATE_CHAT_CHANNEL_SUCCESS:
      const channelFound = state.list[action.sid];
      if (!channelFound) return { ...state };

      return {
        ...state,
        list: {
          ...state.list,
          [action.sid]: {
            ...state.list[action.sid],
            ...action.updatedChannel.channel,
            channelAttributes: {
              ...state.list[action.sid].channelAttributes,
              ...action.updatedChannel.channelAttributes,
            },
          },
        },
        isLoading: false,
      };

    case SET_CHAT_CHANNEL_LAST_MESSAGE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.sid]: {
            ...state.list[action.sid],
            channelAttributes: state.list[action.sid]
              ? {
                  ...state.list[action.sid].channelAttributes,
                  lastMessage: action.lastMessage,
                }
              : { lastMessage: action.lastMessage },
          },
        },
      };

    case REMOVE_CHAT_CHANNEL:
      const channelList = { ...state.list };
      if (channelList[action.sid]) delete channelList[action.sid];

      return {
        ...state,
        list: { ...channelList },
      };

    case SET_CHAT_CHANNEL_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        unreadMessagesCount: action.unreadMessagesCount,
      };

    case ADD_CHAT_CHANNELS_FAILED:
    case UPDATE_CHAT_CHANNEL_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
