/* eslint-disable @typescript-eslint/no-use-before-define */
import { globalModal, Typography } from '@notch-ordering/ui-components';
import { EOnboardingTasks, OnboardingTasksKeys } from '@v2/components/Home/OnboardingTasks';
import React from 'react';
import create from 'zustand';
import ProtectedImg from '@images/protected.svg';
import { persist } from 'zustand/middleware';
import { t } from '@v2/i18n';

/**
 * Used to track 'Getting Started' tasks' state
 */
type OnboardingState = Partial<Record<EOnboardingTasks, boolean>>;

type BuyerOnboardingStates = {
    completeTask: (buyerUrlsafeKey: string, key: string, showModal?: boolean) => void,
    isTaskComplete: (buyerUrlsafeKey: string, key: string) => boolean,
    areAllComplete: (buyerUrlsafeKey: string) => boolean,
    buyerTasks: { [buyerUrlsafeKey: string]: OnboardingState },
};

const OnboardingCompleteModal = (
    <div className="flex flex-col justify-center items-center px-4">
        <div className="flex flex-col gap-y-0.5 mt-[67px] justify-center items-center max-w-[500px]">
            <ProtectedImg className="flex mb-4 w-[151px] h-[92px]"/>
            <Typography variant="XL" desktopSize="3XL" className="text-center">{t('OnboardingTasks.congratulations')}</Typography>
            <Typography className="text-gray-600 text-center mb-10">{t('OnboardingTasks.completingSteps')}</Typography>
        </div>
    </div>
);

export const useOnboardingStore = create<BuyerOnboardingStates>()(
    persist(
        (set, get) => ({
            buyerTasks: {},
            completeTask: (buyerUrlsafeKey: string, key: EOnboardingTasks, showModal: boolean = true): void => {
                // When everything has been completed already, do nothing
                if (get().areAllComplete(buyerUrlsafeKey)) return;

                // Tasks are left, mark as completed
                set((state) => ({
                    buyerTasks: {
                        ...state.buyerTasks,
                        [buyerUrlsafeKey]: {
                            ...state.buyerTasks[buyerUrlsafeKey],
                            [key]: true
                        }
                    }
                }));
                if (showModal && get().areAllComplete(buyerUrlsafeKey)) {
                    // This was the final task, show the modal
                    globalModal.show({ children: OnboardingCompleteModal });
                }
            },
            isTaskComplete: (buyerUrlsafeKey: string, key: EOnboardingTasks): boolean => get().buyerTasks[buyerUrlsafeKey]?.[key],
            areAllComplete: (buyerUrlsafeKey: string): boolean => {
                const buyerState = get().buyerTasks[buyerUrlsafeKey];

                if (buyerState) {
                    return OnboardingTasksKeys.every((key) => buyerState[key]);
                }

                // Create initial state
                const initialTasksState = {};
                Object.values(EOnboardingTasks).forEach((taskKey) => {
                    initialTasksState[taskKey] = false;
                });
                set((state) => ({
                    buyerTasks: {
                        ...state.buyerTasks,
                        [buyerUrlsafeKey]: initialTasksState
                    }
                }));
                return false;
            }
        }),
        { name: 'onboarding' }
    )
);
