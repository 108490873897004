import {
  SET_SELECTED_CHAT_SUCCESS,
  SET_SELECTED_CHAT,
  SET_SELECTED_CHAT_FAILED,
} from '../actions/chatSelectedActions';

export const initialState = {
  sid: '',
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CHAT:
      return {
        ...state,
        isLoading: true,
      };

    case SET_SELECTED_CHAT_SUCCESS:
      return {
        ...state,
        sid: action.sid,
        isLoading: false,
      };

    case SET_SELECTED_CHAT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
