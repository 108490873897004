export function isInvalidString(value: string): boolean {
    return !value || value.length === 0;
}

export function isInvalidEmail(value: string): boolean {
    return !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
}

export function isInvalidPhoneNumber(value: string): boolean {
    return !(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value));
}
