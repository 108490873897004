import React from 'react';
import { Modal, Separator, Typography } from '@notch-ordering/ui-components';
import { PADButton } from '@ap/components/PADButton';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { ACHButton } from '@ap/components/Bills/ACHButton';
import { FETCH_CUSTOMER_QUERY_KEY, FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY,
    useGetCustomer,
    useGetCustomerPaymentMethods, useGetCustomerSupplierMappings } from '@ar/hooks/queries/CustomerQueries.hook';
import { CreditCardButton } from '@ap/components/CreditCardButton';
import { SIGN_UP_SUPPLIER_ID_KEY } from '@ar/pages/SignUpPage/CustomerSignUpPage';
import { getGpoId } from '@v2/utils/GPOUtils';
import { queryClient } from '@/containers/app/Root';
import { useGetSupplier } from '@/ar/hooks/queries/SupplierQueries.hook';

export interface PADModalProps {
    isOpen?: boolean,
    handleClose?: () => void,
    fromSettingsPage?: boolean,
}

export const PADModal : React.FC<PADModalProps> = ({ isOpen, handleClose, fromSettingsPage } : PADModalProps) => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const [isPADModalOpen, setIsPADModalOpen] = React.useState<boolean>(false);
    const supplierToken = supplierLoginData.api_token;
    const gpoId = getGpoId(supplierLoginData);
    const { data } = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id
    });

    // id is stored in local storage when the customer signs up if not then use the first supplier id from the supplier mappings list (first check if we are a supplier and a customer)
    const supplierID = supplierLoginData.supplier_id ?? window.localStorage.getItem(SIGN_UP_SUPPLIER_ID_KEY) ?? data?.suppliers[0]?.id;
    useGetSupplier({
        supplierID,
        customerId: supplierLoginData?.customer_id,
        gpoId
    });
    const { data: customerResults, isLoading } = useGetCustomer({ customerID: supplierLoginData?.customer_id,
        token: supplierToken,
        supplierID,
        ...(supplierLoginData.gpo && { gpoId }) });
    const { data: paymentMethodsResponse, refetch: refetchPaymentMethods } = useGetCustomerPaymentMethods({
        customerID: supplierLoginData?.customer_id,
        supplierID
    });
    const hasNoPaymentMethods = paymentMethodsResponse?.payment_methods?.length === 0;

    const onClose = () => {
        setIsPADModalOpen(false);
        handleClose();
    };
    const onSuccessfulSetup = async () => {
        setIsPADModalOpen(false);
        await queryClient.invalidateQueries([FETCH_CUSTOMER_QUERY_KEY]);
        await queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
        await refetchPaymentMethods();
        onClose();
    };

    React.useEffect(() => {
        if ((fromSettingsPage && isOpen) || (!fromSettingsPage && hasNoPaymentMethods && !isLoading)) {
            setIsPADModalOpen(true);
        }
    }, [fromSettingsPage, isOpen, hasNoPaymentMethods, isLoading]);
    const isUSSupplier = supplier?.billing_address?.country?.toLowerCase() === 'us';
    const hasNoPaymentMethodsEnabled = !supplier?.allow_customer_pay_with_bank_debits && !supplier?.allow_customer_pay_with_cards;
    const showCreditCardButton = !!supplier?.allow_customer_pay_with_cards;
    // only show PADModal when there are no payment methods or customer is adding a new payment method
    if (!isOpen && !hasNoPaymentMethods) {
        return null;
    }
    return <Modal
        isOpen={isPADModalOpen}
        close={onClose}
        onClose={onClose}
        titleSeparatorDesktop={true}
        desktopModalWidth="lg:w-[460px]"
        title={<div className="flex flex-col gap-2">
            <Typography variant="2XL" className="font-bold m-0">Setup your payment method</Typography>
            <Typography className="text-gray-600 m-0"> {fromSettingsPage ? 'Please take a moment to set up your payment method.' : 'Please take a moment to set up your payment method before proceeding.'} </Typography>
        </div>}>
        <div className="flex flex-col gap-8 px-8 my-8">
            <div className="flex flex-col gap-2 items-center ">
                {showCreditCardButton && <CreditCardButton />}
                {!!supplier?.allow_customer_pay_with_bank_debits && <>
                    {isUSSupplier && <ACHButton customerID={supplierLoginData?.customer_id}
                        supplierID={supplierID}
                        email={supplierLoginData.email}
                        name={customerResults?.name ?? ''}
                        onSuccessfulSetup={async () => {
                            if (onSuccessfulSetup) {
                                await onSuccessfulSetup();
                            }
                        }}/>
                    }
                    <PADButton customerID={supplierLoginData?.customer_id}
                        supplierID={supplierID}
                        email={supplierLoginData.email}
                        name={customerResults?.name ?? ''}
                        onSuccessfulSetup={async () => {
                            if (onSuccessfulSetup) {
                                await onSuccessfulSetup();
                            }
                        }}/>
                </>}
                {hasNoPaymentMethodsEnabled
                    && <Typography className="text-gray-600">Payment methods are not yet enabled for the current supplier.
                        Please contact <a target="_blank"
                        rel="noreferrer"
                        className="text-plum-300 hover:text-plum-400"
                        href="mailto:support@notch.financial?subject=Customer%20payment%20methods%20not%20enabled">customer
                            support</a> to enable them.</Typography>}
            </div>
        </div>
        <Separator className=""/>
        <div className="text-center px-8 pt-6">
            <Typography variant="BASE" className="text-gray-600 mb-0">Setting up your payment method pre-authorizes it
                for use.</Typography>
        </div>
    </Modal>;
};
