import { createSelector } from 'reselect';
import * as checkoutSelectors from './checkoutSelectors';
import { ADDITIONAL_FEE_TYPES } from '../constants/AdditionalFeeTypes';

const getOrders = (state) => state.ordersReducer.orders;
const getVendors = (state) => state.vendorsReducer.vendors;
const getOrder = (state, props) => props.order;
const getIsEditingSingleOrder = (state, props) => props.isEditingSingleOrder;
const getCurrentlyEditingorderUrlsafe = (state, props) =>
  props.currentlyEditingOrderUrlsafe;

export const getEditOrderProductLoading = (state) =>
  state.ordersReducer.editOrderProductLoading;

// Selector that filter orders with no items
export const filterOrdersWithItems = createSelector([getOrders], (orders) => {
  let ordersWithItems = [];

  for (let order of orders) {
    if (order.items && order.items.length > 0) {
      ordersWithItems.push(order);
    }
  }

  return ordersWithItems;
});

// Get a single order's grand total amount with Tax and fees
export const getSingleOrderGrandTotal = createSelector(
  [getOrder, getVendors],
  (order, vendors) => {
    const orderTotal = order.items.reduce((prev, item) => {
      const taxPercentage = item.taxPercentage;

      const orderItemTotal = item.price * item.quantity;
      const orderItemTotalTaxAmount =
        item.isTaxable && taxPercentage
          ? item.price * item.quantity * taxPercentage
          : 0;

      return prev + (orderItemTotal + orderItemTotalTaxAmount);
    }, 0);

    let otherFees = 0;
    if (order.additionalFees) {
      otherFees = order.additionalFees.reduce((prev, fee) => {
        return (
          prev +
          (fee.displayName.toLowerCase() !== 'delivery fee' &&
          fee.feeType.toLowerCase() !== ADDITIONAL_FEE_TYPES.DELIVERY
            ? fee.amount + fee.tax
            : 0)
        );
      }, 0);
    }

    const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);
    const isBelowMinimumOrder = checkoutSelectors.checkIfBelowMinimumOrder({
      order,
      vendor,
    });
    const shouldAdditionalFees =
      isBelowMinimumOrder && order.isCheckout && order.items.length > 0;
    // get additional fees:
    // subtract the original subtotal of the sum of all of the original prices
    const items = order.originalOrderItems || order.items;
    const prevItemsTotal = items.reduce(
      (sum, item) => sum + (item.priceTotal || 0),
      0
    );
    const additionalFees = order.grandSubtotal - prevItemsTotal;
    const itemsTotalTax = order.items.reduce(
      (sum, item) => sum + (item.taxAmount || 0),
      0
    );
    let additionalFeeTaxRate = 0;
    if (additionalFees > 0) {
      additionalFeeTaxRate = 1 + (order.tax - itemsTotalTax) / additionalFees;
    }
    /*
    If the order is below the minimum order amount, has items and is to be checked out,
    then add all the additional fees
    */
    return shouldAdditionalFees
      ? orderTotal + otherFees + additionalFees * additionalFeeTaxRate
      : orderTotal + otherFees;
  }
);

// get the amount of credit that was used in a single order
export const getCreditUsedInOrder = createSelector(
  [getOrders, getIsEditingSingleOrder, getCurrentlyEditingorderUrlsafe],
  (orders, isEditingSingleOrder, currentlyEditingOrderUrlsafe) => {
    let creditUsed = 0;

    if (!isEditingSingleOrder) {
      return creditUsed;
    }

    // Get the current order
    const currentOrder = orders.find(
      (order) =>
        !order.isBYOS &&
        order.isCheckout &&
        order.urlsafe === currentlyEditingOrderUrlsafe
    );
    if (currentOrder) {
      // after getting the current order, we want to sum all of the payments that were made using ACCOUNT_CREDIT
      creditUsed = currentOrder.orderPayments
        .filter(({ paymentType }) => paymentType === 'ACCOUNT_CREDIT')
        .reduce((tempSum, payment) => {
          return tempSum + payment.amount;
        }, 0);
    }

    return creditUsed;
  }
);

export const getOrderByUrlSafe =
  (orderUrlSafe) =>
  ({ ordersReducer: { orders } }) =>
    orders.find((order) => order.urlsafe === orderUrlSafe);
