export const CategoryTypes = [
  {
    name: 'Produce',
    categories: ['Flowers', 'Fruits', 'Herbs', 'Vegetables'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Fruits-Vegetables.png',
  },
  {
    name: 'Grocery & Pantry',
    categories: [
      'Condiments',
      'Frozen',
      'Grocery',
      'Nuts & Seeds',
      'Oils & Shortenings',
      'Spreads & Dips',
    ],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Pantry-Non-Perishable.png',
  },
  {
    name: 'Dairy, Eggs & Alternatives',
    categories: [
      'Cheese',
      'Cream',
      'Dairy Alternatives',
      'Eggs',
      'Ice Cream',
      'Milk',
      'Milk Powder',
      'Milkshake',
      'Yogurt',
    ],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Dairy-Eggs-Alts.png',
  },
  {
    name: 'Baked Goods & Sweets',
    categories: ['Bakery', 'Baking Products'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Baked-Goods-Sweets.png',
  },
  {
    name: 'Meat & Poultry',
    categories: [
      'Beef',
      'Chicken',
      'Deli Meats',
      'Duck',
      'Goat',
      'Lamb',
      'Pork',
      'Sausage',
      'Turkey',
      'Veal',
    ],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Meat-Poultry.png',
  },
  {
    name: 'Beverages',
    categories: ['Beverages'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Drinks-Beverages.png',
  },
  {
    name: 'Seafood',
    categories: ['Fish', 'Seafood'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Seafood-Shelfish.png',
  },
  {
    name: 'Takeout Supplies',
    categories: ['Takeout Supplies'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Takeout-Supplies.png',
  },
  {
    name: 'Restaurant Supplies',
    categories: ['Kitchen Supplies', 'Restaurant Supplies'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Restaurant-Supplies.png',
  },
  {
    name: 'Cleaning Supplies',
    categories: ['Cleaning Products'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Cleaning-Products.png',
  },
  {
    name: 'Seasonal',
    categories: ['Seasonal Items'],
    image:
      'https://storage.googleapis.com/chefhero-storage/static/images/Seasonal-Specials.png',
  },
];
