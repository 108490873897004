import update from 'immutability-helper';

const initialState = {
  name: '',
  orderGuideID: '',
  editMode: false,
  searchText: '',
  itemAdded: { orderGuideID: 0, index: -1 },
  orderGuideModified: false,
  enableDragDrop: true,
  orderGuideItems: [],
};

export default function orderguideEditorSidebar(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_INITIAL':
      return Object.assign({}, state, {
        name: action.name,
        orderGuideID: action.orderGuideID,
        orderGuideItems: action.selectedProducts,
      });

    case 'ADD_ITEM_TO_ORDERGUIDE_EDITOR':
      return Object.assign({}, state, {
        orderGuideItems: state.orderGuideItems.concat(action.item),

        // It's always the last index, so the index is the length of the array
        itemAdded: {
          orderGuideID: action.orderGuideID,
          index: state.orderGuideItems.length,
        },
        orderGuideModified: action.orderGuideModified,
      });

    case 'REMOVE_ITEM_FROM_ORDERGUIDE_EDITOR':
      return {
        ...state,
        orderGuideItems: state.orderGuideItems
          .map((el, indexOG) => {
            if (indexOG === action.itemIndex) {
              if (el.buyerProducts.length === 1) {
                return;
              }
              return {
                ...el,
                buyerProducts: el.buyerProducts.filter((bp, indexBP) => {
                  if (indexBP !== action.productIndex) {
                    return bp;
                  }
                }),
              };
            }
            return el;
          })
          .filter((el) => el),
      };

    case 'CLICK_EDIT':
      return Object.assign({}, state, {
        editMode: !state.editMode,
      });

    case 'SAVE_NAME':
      return Object.assign({}, state, {
        name: action.name,
        editMode: false,
        orderGuideModified: action.orderGuideModified,
      });

    case 'SEARCH_SIDEBAR':
      return Object.assign({}, state, {
        enableDragDrop: action.enableDragDrop,
        searchText: action.text.toLowerCase(),
      });

    // Upate the order guide, after adding a custom product
    case 'UPDATE_ORDERGUIDE':
      return Object.assign({}, state, {
        orderGuideItems: state.orderGuideItems.concat(action.product),
        name: state.name === 'untitled' ? action.name : state.name,
        orderGuideID: action.orderGuideID,

        // It's always the last index, so the index is the length of the array
        itemAdded: {
          orderGuideID: action.orderGuideID,
          index: state.orderGuideItems.length,
        },
        orderGuideModified: action.orderGuideModified,
      });

    case 'UPDATE_ORDERGUIDE_ITEMS':
      return Object.assign({}, state, {
        orderGuideItems: action.orderGuideItems,
      });

    // Update the orderguide after dragging and dropping
    case 'UPDATE_ORDERGUIDE_DRAG_DROP':
      return update(state, {
        orderGuideItems: {
          $splice: [
            [action.dragIndex, 1],
            [action.hoverIndex, 0, action.dragCard],
          ],
        },
        orderGuideModified: { $set: action.orderGuideModified },
      });

    case 'UPDATE_SELECTED_ORDERGUIDE':
      return Object.assign({}, state, {
        orderGuideID: action.selectedOrderGuide.id,
      });

    case 'CLEAR_ITEMADDED':
      return Object.assign({}, state, {
        itemAdded: { orderGuideID: 0, index: -1 },
      });

    case 'CLEAR_DATA':
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}
