import { call, put, fork, takeLatest, delay } from 'redux-saga/effects';

import * as DataAPI from '../api/DataAPI';
import * as searchActions from '../actions/searchActions';
import { logException } from '../domains/shared/logger';

// worker Saga: will be fired on FETCH_GLOBAL_SEARCH_REQUESTED actions
export function* fetchGlobalSearch(action) {
  try {
    const {
      searchText,
      buyerID,
      selectedTopLevelCategories,
      selectedCategories,
      selectedVendors,
      isOutOfStockIncluded,
      isOrganicSelected,
      isSpecialPromotionsSelected,
    } = action.payload;
    let response = {};

    // Show Loader
    yield put(searchActions.showSearchLoader(true));

    // Clear top level categories if they exist and all enabled filters
    if (selectedTopLevelCategories.length > 0) {
      yield put(searchActions.clearSelectedCategoryFilters());
    }
    yield put(searchActions.clearSearchEnabledFilters());

    // delay 100ms to avoid duplicate requests
    yield delay(100);

    const data = {
      buyerID: Number(buyerID),
      searchTerm: searchText,
      vendorID: selectedVendors
        ? selectedVendors.map((vendor) => vendor.vendorID)
        : [],
      categories:
        selectedCategories.length > 0
          ? selectedCategories
          : selectedTopLevelCategories,
      page: 0,
      isOutOfStockIncluded,
      isOrganicSelected,
      isSpecialPromotionsSelected,
    };

    // Request to get search results for keyword or filters
    response = yield call(DataAPI.fetchSearchProductResults, data);

    const searchResults = response.data;

    if (!_.isEmpty(searchResults) && searchResults.items) {
      // Request was successful
      yield put({
        type: 'FETCH_GLOBAL_SEARCH_SUCCEEDED',
        message: searchResults,
      });

      // Sort and load global search results into state
      yield put(searchActions.receiveSearchResults(searchResults, 0, data));

      // Retrive category filters from global search results
      yield put(
        searchActions.receiveSearchCategoryFilters(searchResults.categories)
      );

      // Retrieve vendor filters from global search results that should be enabled
      yield put(
        searchActions.receiveSearchEnabledVendorFilters(searchResults.vendorID)
      );

      // Remove Loader
      yield put(searchActions.showSearchLoader(false));
    }
  } catch (error) {
    yield put({ type: 'FETCH_GLOBAL_SEARCH_FAILED', message: error });

    // Remove Loader
    yield put(searchActions.showSearchLoader(false));

    console.error('An Error occured with fetchGlobalSearch');
    console.error(error);
    logException(error);
  }
}

/*
  Does not allow concurrent fetches of user. If "FETCH_GLOBAL_SEARCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* watchFetch() {
  yield takeLatest('FETCH_GLOBAL_SEARCH_REQUESTED', fetchGlobalSearch);
}

/**
 * Search Sagas
 */
export default function* search() {
  yield fork(watchFetch);
}
