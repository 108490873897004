import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';

import * as types from '../constants/ActionTypes';
import { orderInvoicesRangeTypes } from '../constants/EnumTypes';
import { OFFLINE_PAYMENT_METHODS } from '../constants/OfflinePaymentMethodTypes';
import { ADDITIONAL_FEE_TYPES } from '../constants/AdditionalFeeTypes';
import * as analyticsActions from './analyticsActions';
import * as DataAPI from '../api/DataAPI';
import Utils from '../utils';
import { logException } from '../domains/shared/logger';
import { logError } from '@v2/utils/logError';

const INVOICES_PAGE = 20;

const apiNamePerColName = {
  'Order date': 'order_date',
  'Order number': 'order_number',
  //TODO: not yet active but it'll be in a future
  // 'Delivery date': 'delivery_date',
  'Supplier name': 'vendor_name',
  Amount: 'amount_due',
  'Due date': 'due_date',
};

export const showInvoiceImagesModal = (
  open,
  { invoiceId, invoiceNumber, images, vendorName } = {
    invoiceId: '',
    invoiceNumber: '',
    images: [],
    vendorName: '',
  }
) => {
  return {
    type: types.SHOW_INVOICE_IMAGES_MODAL,
    payload: {
      open,
      invoiceId,
      invoiceNumber,
      vendorName,
      images,
    },
  };
};

export function selectOrderInvoiceType(orderInvoicesRangeTypeSelected) {
  return {
    type: types.SELECT_ORDER_INVOICE_TYPE,
    orderInvoicesRangeTypeSelected,
  };
}

export function selectOrderInvoicesDateRange(
  orderInvoicesRangeTypeOptions = {}
) {
  return (dispatch, getState) => {
    const {
      orderInvoicesRangeTypeSelected: orderInvoicesRangeTypeSelectedReducer,
      invoicesStartDate,
      invoicesEndDate,
    } = getState().orderInvoicesReducer;

    let {
      orderInvoicesRangeTypeSelected = orderInvoicesRangeTypeSelectedReducer, // Default value
      startDate = invoicesStartDate,
      endDate = invoicesEndDate,
    } = orderInvoicesRangeTypeOptions;

    switch (orderInvoicesRangeTypeSelected) {
      case orderInvoicesRangeTypes.THIS_MONTH:
        startDate = Utils.formatDate(moment().startOf('month'));
        endDate = Utils.formatDate(moment().endOf('month'));
        break;

      case orderInvoicesRangeTypes.LAST_MONTH:
        startDate = Utils.formatDate(
          moment().subtract(1, 'months').startOf('month')
        );
        endDate = Utils.formatDate(
          moment().subtract(1, 'months').endOf('month')
        );
        break;

      case orderInvoicesRangeTypes.THREE_MONTHS:
        startDate = Utils.formatDate(
          moment().subtract(2, 'months').startOf('month')
        );
        endDate = Utils.formatDate(moment().endOf('month'));
        break;

      case orderInvoicesRangeTypes.ALL:
        startDate = '';
        endDate = '';
        break;
    }

    dispatch({
      type: types.SELECT_ORDER_INVOICES_DATE_RANGES,
      invoicesStartDate: startDate,
      invoicesEndDate: endDate,
    });
  };
}

export function showLoaderOrderInvoices(isOrderInvoicesLoading) {
  return {
    type: types.SHOW_LOADER_ORDER_INVOICES,
    isOrderInvoicesLoading,
  };
}

export function addSelectedOrderInvoice(IDToAdd) {
  return {
    type: types.ADD_SELECTED_ORDER_INVOICE_ID,
    IDToAdd,
  };
}

export function removeSelectedOrderInvoice(
  IDToRemove,
  selectedOrderInvoiceIDs
) {
  const index = selectedOrderInvoiceIDs.findIndex(
    (selectedOrderInvoiceID) => selectedOrderInvoiceID === IDToRemove
  );

  return {
    type: types.REMOVE_SELECTED_ORDER_INVOICE_ID,
    index,
  };
}

export function clearSelectedOrderInvoiceIDs() {
  return {
    type: types.CLEAR_SELECTED_ORDER_INVOICES,
  };
}

// Interal functions
function receiveOrderInvoices(paidOrderInvoices, unpaidOrderInvoices) {
  const orderInvoicesProcessed = {
    unpaid: unpaidOrderInvoices,
    paid: paidOrderInvoices,
  };

  return {
    type: types.RECEIVE_ORDER_INVOICES_FULFILLED,
    orderInvoices: orderInvoicesProcessed,
  };
}

// Async Thunks
export const loadOrderInvoices = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.FETCH_ORDER_INVOICES });
      dispatch(showLoaderOrderInvoices(true));

      // Need to get the correct statementURLParams first before the call.
      const {
        invoicesStartDate: deliveryDayStart,
        invoicesEndDate: deliveryDayEnd,
      } = getState().orderInvoicesReducer;

      const responses = await Promise.all([
        DataAPI.searchOrders({
          isInvoiced: true,
          limit: 500, // hardcoded for 500 now until PROD-6609 is complete and pagingation is added. Increasing may break
          deliveryDayStart,
          deliveryDayEnd,
          isPaid: true,
        }),
        DataAPI.searchOrders({
          isInvoiced: true,
          limit: 500, // hardcoded for 500 now until PROD-6609 is complete and pagingation is added. Increasing may break
          deliveryDayStart,
          deliveryDayEnd,
          isPaid: false,
        }),
      ]);

      // throw an exception in this function if any responses are not received
      if (responses.every((response) => response) === false) {
        throw new Error(
          'One endpoint failed in loading in Promise.all for buyer'
        );
      }
      const { data: paidOrderInvoices } = responses[0].data;
      const { data: unPaidOrderInvoices } = responses[1].data;

      dispatch(receiveOrderInvoices(paidOrderInvoices, unPaidOrderInvoices));
      dispatch(showLoaderOrderInvoices(false));

      return { unpaid: unPaidOrderInvoices, paid: paidOrderInvoices };
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadOrderInvoices');
      console.error(error);
      logException(error);
    }
  };
};

export const setSort = (sortBy, sortDir) => ({
  type: types.SET_ORDER_INVOICES_SORT,
  payload: { sortBy, sortDir },
});

export const setInvoiceFilter = (filter, value) => ({
  type: types.SET_ORDER_INVOICES_FILTER,
  payload: { filter, value },
});

export const updateDueDates = (startDate, endDate) => ({
  type: types.UPDATE_ORDER_INVOICE_DUE_DATE_FILTER,
  payload: { startDate, endDate },
});

export const updateDeliveryDates = (startDate, endDate) => ({
  type: types.UPDATE_ORDER_INVOICE_DELIVERY_DATE_FILTER,
  payload: { startDate, endDate },
});

export const setIsPaying = (isPaying) => {
  return {
    type: types.IS_PAYING_INVOICE,
    payload: isPaying,
  };
};

export const resetState = () => ({ type: types.RESET_ORDER_INVOICES_STATE });

