import { SET_LOCATION_SWITCHER } from '@/constants/ActionTypes';

const initialState = {
  account: {
    id: '',
    email: '',
    urlsafe: '',
    firstName: '',
    lastName: '',
    mobile: '',
    unsubscribed: false,
    memberID: 0, // This is populated and injected from org information from defaultBuyer
    receive_notification: false,
    roles: [], // roles for given buyer account, from v1 buyer member endpoint
  },
  org: [], // contains roles and permissions for all buyers of this account
  impersonator: {
    id: '',
    email: '',
    urlsafe: '',
    firstName: '',
    lastName: '',
    memberID: 0,
    mobile: '',
    unsubscribed: false,
  },
  isLoadingAppInit: true,
  isOpenLocationSwitcher: false,
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATION_SWITCHER:
      return {
        ...state,
        isOpenLocationSwitcher: action.showLocationSwitcher,
      };

    case 'UPDATE_ACCOUNT_SETTINGS':
      return {
        ...state,
        account: {
          ...state.account,
          firstName: action.firstName,
          lastName: action.lastName,
        },
      };

    case 'GET_SESSION_DATA':
      return {
        ...state,
        account: {
          ...state.account,
          id: action.account.id,
          email: action.account.email,
          urlsafe: action.account.urlsafe,
          firstName: action.account.firstName,
          lastName: action.account.lastName,
          mobile: action.account.mobile,
          unsubscribed: action.account.unsubscribed,
          memberID: action.account.memberID,
          roles: action.account.roles,
        },
        org: action.org,
        impersonator: {
          ...state.impersonator,
          id: action.impersonator ? action.impersonator.id : '',
          email: action.impersonator ? action.impersonator.email : '',
          urlsafe: action.impersonator ? action.impersonator.urlsafe : '',
          firstName: action.impersonator ? action.impersonator.firstName : '',
          lastName: action.impersonator ? action.impersonator.lastName : '',
          mobile: action.impersonator ? action.impersonator.mobile : '',
        },
      };

    case 'IS_LOADING_APP_INIT':
      return {
        ...state,
        isLoadingAppInit: action.isLoadingAppInit,
      };

    default:
      return state;
  }
}
