import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import * as types from '../constants/ActionTypes';
import * as DataAPI from '../api/DataAPI';
import { MY_ORDERS_QUERY_LIMIT } from '../constants/GlobalConstants';
import Utils from '../utils';
import { logException } from '../domains/shared/logger';

export function toggleOrderDetailModal(isOrderDetailModalOpen) {
  return {
    type: types.TOGGLE_ORDER_DETAIL_MODAL,
    isOrderDetailModalOpen,
  };
}

export function showLoaderOrderHistory(isMyOrdersLoading) {
  return {
    type: types.SHOW_LOADER_ORDER_HISTORY,
    isMyOrdersLoading,
  };
}

function receiveMyOrders(myOrders, pagination) {
  return {
    type: types.RECEIVE_ORDER_HISTORY,
    myOrders: myOrders,
    pagination: {
      page:
        pagination.offset === 0
          ? 1
          : pagination.offset / MY_ORDERS_QUERY_LIMIT + 1,
      totalPages: Math.ceil(pagination.totalCount / MY_ORDERS_QUERY_LIMIT),
    },
  };
}

function receiveSupplierFilters(vendors) {
  const supplierOptions = vendors.map((vendor) => vendor.name);
  supplierOptions.unshift('All Suppliers');

  return {
    type: types.RECEIVE_SUPPLIER_FILTERS,
    supplierOptions,
  };
}

export function changeSupplierFilters(suppliers) {
  return {
    type: types.CHANGE_SUPPLIER_FILTERS,
    suppliers,
  };
}

export function changeDateFilters(dates) {
  return {
    type: types.CHANGE_DATE_FILTERS,
    dates,
  };
}

export function changeCurrentPageFilter(currentPage) {
  return {
    type: types.CHANGE_CURRENT_PAGE_FILTER,
    currentPage,
  };
}

export function changeSortByFilter(sortBy) {
  return {
    type: types.CHANGE_SORT_BY_FILTER,
    sortBy,
  };
}
export function changeCustomDateFilter(startDate, endDate) {
  return {
    type: types.CHANGE_CUSTOM_DATE_FILTER,
    startDate: Utils.formatDate(startDate),
    endDate: Utils.formatDate(endDate),
  };
}

export function clearFilters() {
  return {
    type: types.CLEAR_ORDER_FILTERS,
  };
}

export const initOrderHistory = (showLoader = false) => {
  return async (dispatch, getState) => {
    try {
      if (showLoader) {
        dispatch(showLoaderOrderHistory(true));
      }

      const { vendors } = getState().vendorsReducer;
      await dispatch(filterOrders());

      dispatch(receiveSupplierFilters(vendors));
      if (showLoader) {
        dispatch(showLoaderOrderHistory(false));
      }
    } catch (error) {
      console.error(error);
      console.error('error occurred in initOrderHistory');
      logException(error);
    }
  };
};

export const filterOrders = () => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().orderHistoryReducer;
      const { pagination } = getState().orderHistoryReducer;
      const { vendors } = getState().vendorsReducer;

      let startDate = '',
        endDate = '',
        vendorUrlsafe = '';
      dispatch({ type: types.FETCH_FILTER_MY_ORDERS_REQUEST });

      if (filters.dates.length > 0) {
        const dateRange = getDateRange(filters.dates[0], filters);
        startDate = dateRange.startDate;
        endDate = dateRange.endDate;
      }

      if (
        filters.suppliers.length > 0 &&
        !filters.suppliers.includes('All Suppliers')
      ) {
        const vendor = vendors.find(
          (vendor) => vendor.name === filters.suppliers[0]
        );
        vendorUrlsafe = vendor ? vendor.urlsafe : '';
      }
      const offset = (pagination.page - 1) * MY_ORDERS_QUERY_LIMIT;
      const response = await DataAPI.searchOrders({
        deliveryDayStart: startDate,
        deliveryDayEnd: endDate,
        vendorUrlsafe: vendorUrlsafe,
        isInShoppingCart: false,
        limit: MY_ORDERS_QUERY_LIMIT,
        offset,
        ...(filters.sortBy ? { sortBy: filters.sortBy } : {}),
      });

      dispatch({ type: types.FETCH_FILTER_MY_ORDERS_SUCCESS });

      if (response && response.data) {
        const { data: myOrders, ...pagination } = response.data;
        if (myOrders) {
          dispatch(receiveMyOrders(myOrders, pagination));

          return myOrders;
        }
      }
    } catch (error) {
      let errorMessage = '';
      dispatch({ type: types.FETCH_FILTER_MY_ORDERS_FAILED });

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with filterOrders');
      console.error(error);
      logException(error);
    }
  };
};

// helper
function getDateRange(dateType, filters) {
  let startDate = '';
  let endDate = '';

  switch (dateType) {
    case 'This Month':
      // "This Month" filter should only apply to those orders which are delivered in the current month
      // Nothing before or after!!
      startDate = Utils.formatDate(moment().startOf('month'));
      endDate = Utils.formatDate(moment().endOf('month'));
      break;

    case 'Last Month':
      // "Last Month" filter should only apply to those orders which are delivered in the previous month
      // Nothing before or after!!
      startDate = Utils.formatDate(
        moment().subtract(1, 'months').startOf('month')
      );
      endDate = Utils.formatDate(moment().subtract(1, 'months').endOf('month'));
      break;

    case 'Last 3 Months Onwards':
      // "Last 3 Months Onwards" filter will apply to those orders which are delivered in the next month, the current month, and the two months before
      // Nothing before or after!!
      startDate = Utils.formatDate(
        moment().subtract(2, 'months').startOf('month')
      );
      endDate = Utils.formatDate(moment().add(1, 'month').endOf('month'));
      break;

    case 'Last 6 Months Onwards':
      // "Last 6 Months Onwards" filter will apply to those orders which are delivered in the next month, the current month, and the 5 months before
      // Nothing before or after!!
      startDate = Utils.formatDate(
        moment().subtract(5, 'months').startOf('month')
      );
      endDate = Utils.formatDate(moment().add(1, 'month').endOf('month'));
      break;

    case 'Custom Date Range':
      startDate = filters.customDate.startDate;
      endDate = filters.customDate.endDate;
      break;
  }

  return { startDate, endDate };
}
