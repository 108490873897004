import { UPDATE_FEATURE } from '../constants/ActionTypes';

export const initialState = {};

export default function featureReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FEATURE:
      return {...state, [action.state]: action.value };

    default:
      return state;
  }
}
