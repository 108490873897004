import * as types from '../constants/ActionTypes';

export const initialState = {
  isBuyerSuspendedModalOpen: false,
  isBuyerCreditCardModalOpen: false,
  isShowingAddSupplierModal: false,
  addSupplierOpenSource: '',
  isShowingAddSuppliersModal: false,
  isShowingOrderCheckedOutModal: false,
};

export default function modalsReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_BUYER_SUSPENDED_MODAL:
      return {
        ...state,
        isBuyerSuspendedModalOpen: !state.isBuyerSuspendedModalOpen,
      };

    case types.TOGGLE_BUYER_CREDIT_CARD_MODAL:
      return {
        ...state,
        isBuyerCreditCardModalOpen: !state.isBuyerCreditCardModalOpen,
      };

    case types.SHOW_ADD_SUPPLIER_MODAL:
      return {
        ...state,
        isShowingAddSupplierModal: action.show,
        addSupplierOpenSource: action.addSupplierOpenSource,
      };

    case types.SHOW_ADD_SUPPLIERS_MODAL:
      return {
        ...state,
        isShowingAddSuppliersModal: action.show,
      };
    case types.SHOW_ORDER_IS_ALREADY_CHECKED_OUT_MODAL:
      return {
        ...state,
        isShowingOrderCheckedOutModal: true,
      };

    case types.HIDE_ORDER_IS_ALREADY_CHECKED_OUT_MODAL:
      return {
        ...state,
        isShowingOrderCheckedOutModal: false,
      };
    default:
      return state;
  }
}
