/* eslint-disable no-console */
import chalk from 'chalk';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, TFunction } from 'react-i18next';
import * as en from './assets/locales/en.json';
import * as fr from './assets/locales/fr.json';

/**
 * The default namespace for translation keys.
 * We only have one namespace due to the simplicity of the app.
 */
export const tNamespace = 'default';

/**
 * Setup react-i18next localization system with auto-detecting locale
 * and loading translations from http.
 *
 * Override system language with query params.
 * (e.g. append ?lng=fr-CA to the url)
 */
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: process.env.DEPLOY_ENV === 'development',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        resources: {}
    })
    .then(() => {
        console.log(
            chalk.bgCyan.black.bold(
                `===Localization initialized [${i18n.language}]===`
            )
        );
    });
i18n.addResourceBundle('en', tNamespace, en);
i18n.addResourceBundle('fr', tNamespace, fr);

export default i18n;
export const tCommon: TFunction = i18n.getFixedT(null, tNamespace, 'Common');
export const tNavigation: TFunction = i18n.getFixedT(null, tNamespace, 'Navigation');
export const t: TFunction = i18n.getFixedT(null, tNamespace);
