import { combineReducers } from 'redux';

import chatAuthReducer from './chatAuthReducer';
import chatChannelsListReducer from './chatChannelsListReducer';
import chatMessagesReducer from './chatMessagesReducer';
import chatSelectedReducer from './chatSelectedReducer';

export default combineReducers({
  auth: chatAuthReducer,
  channels: chatChannelsListReducer,
  messages: chatMessagesReducer,
  chatSelected: chatSelectedReducer,
});
