/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Checks that a given value is not falsey. Strings are checked for non-empty and non-whitespace.
 *
 * @param value - the value to validate
 * @returns true when the given value is valid
 */
export function isValid(value: any): boolean {
    const valid = value !== null && value !== undefined && !!value;

    switch (typeof value) {
        case 'string':
            return value.trim() !== '' && value.length > 0;
        default:
            return valid;
    }
}
