import {LOAD_SUPPLIERS} from "@/constants/ActionTypes";
import { getLinkedSupplierCatalogs } from "@v2/network/SecretShopAPI";

/**
 * Fetch the user session
 */
export const loadSuppliers = () => {
    return async (dispatch, getState) => {
        const { buyer } = getState().buyerReducer;
        const response  = await getLinkedSupplierCatalogs(buyer?.urlsafe);
        dispatch({
            type: LOAD_SUPPLIERS,
            suppliers: response.supplierCatalogs
        })
    };
};
