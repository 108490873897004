import { toast } from '@notch-ordering/ui-components';
import { parseAxiosError } from '@v2/utils/AxiosUtils';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function networkErrorHandler({ error, title = '', message = 'Error' }): Promise<any> {
    const errorMessage: string = parseAxiosError(error, message);

    toast.show({
        message: `${title}: ${errorMessage}`,
        showClose: true
    });
    return Promise.reject(error);
}
