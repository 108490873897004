/*
** Google Tag Manager for Web Tracking
*/

export function init(): void {
    const tagManagerScript: HTMLScriptElement = document.createElement('script');
    tagManagerScript.async = true;
    tagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_TAG_ID}`;
    document.head.appendChild(tagManagerScript);

    const dataLayerScript: HTMLScriptElement = document.createElement('script');
    dataLayerScript.textContent = `window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${process.env.GOOGLE_TAG_ID}');`;
    document.head.appendChild(dataLayerScript);
}
