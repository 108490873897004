import { createSelector } from 'reselect';

const getAccount = (state) => state.accountReducer.account;
const getImpersonator = (state) => state.accountReducer.impersonator;

export const isAccountOwnerOrManager = createSelector(
  [getAccount],
  (account) =>
    !!account.roles.find((r) => r.name === 'Owner' || r.name === 'Manager')
);

export const isAccountOwner = createSelector(
  [getAccount],
  (account) => !!account.roles.find((r) => r.name === 'Owner')
);

export const isAccountImpersonationEmpty = createSelector(
  [getImpersonator],
  (impersonator) => Object.keys(impersonator).every((key) => !impersonator[key])
);
