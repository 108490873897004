import { getCustomers,
    GetCustomersResponse,
    getCustomer,
    GetCustomerResponse,
    GetPaymentMethodsResponse,
    getPaymentMethods,
    GetCustomerSupplierMappingsParams,
    getCustomerSuppliers,
    GetCustomerQuery,
    getCustomerNotificationRules,
    GetCustomerNotificationsParams,
    GetNotificationsResponse,
    getCustomersWithCustomNotifications,
    CustomersWithCustomNotificationsData, GetCustomerByEmailParams, getCustomerByEmail,
    GetCustomerByCustomerAuthQuery,
    getCustomerByCustomerAuth } from '@ar/network/AccountsReceivable.network';
import { useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';

export const FETCH_CUSTOMERS_QUERY_KEY = 'FETCH_CUSTOMERS_QUERY_KEY';
export const FETCH_CUSTOMER_QUERY_KEY = 'FETCH_CUSTOMER_QUERY_KEY';
export const FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY = 'FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY';
export const FETCH_CUSTOMER_NOTIFICATION_RULES_QUERY_KEY = 'FETCH_CUSTOMER_NOTIFICATION_RULES_QUERY_KEY';

export type TUseGetCustomersQuery = {
    name?: string,
    supplierID: string,
    gpoId?: string,
    limit?: number,
    offset?: number,
    customer_external_id?: string,
};

export type TUseGetCustomersWithNotificationsQuery = {
    supplierID: string,
};

export type TUseGetCustomerPaymentMethodsQuery = {
    customerID: string,
    supplierID: string,
    token?: string,
    gpoId?: string,
    alwaysRefetch?: boolean,
    refetchInterval?: number,
};

export const useGetCustomers = (params: TUseGetCustomersQuery): UseQueryResult<GetCustomersResponse> => useQuery([FETCH_CUSTOMERS_QUERY_KEY, params], async () => getCustomers(params), { enabled: !!params.supplierID });
export const FETCH_CUSTOMERS_INFINITE_QUERY_KEY = 'FETCH_CUSTOMERS_INFINITE_QUERY_KEY';
export const useGetCustomersInfinite = (
    params: TUseGetCustomersQuery,
): UseInfiniteQueryResult<GetCustomersResponse> => useInfiniteQuery(
    [FETCH_CUSTOMERS_INFINITE_QUERY_KEY, params],
    async ({ pageParam = null }) => getCustomers({
        ...params,
        offset: pageParam,
    }),
    {
        getNextPageParam: (lastPage) => {
            const nextPageOffset = lastPage.offset + lastPage.limit;
            return nextPageOffset < lastPage.total ? nextPageOffset : null;
        },
        enabled: !!params.supplierID || !!params.gpoId,
    },
);

export const useGetCustomer = (params: GetCustomerQuery): UseQueryResult<GetCustomerResponse> => useQuery([FETCH_CUSTOMER_QUERY_KEY, params], async () => getCustomer(params), { enabled: (!!params.supplierID || !!params.gpoId) && !!params.customerID });

export const useGetCustomerByCustomerAuth = (params: GetCustomerByCustomerAuthQuery): UseQueryResult<GetCustomerResponse> => useQuery([FETCH_CUSTOMER_QUERY_KEY, params], async () => getCustomerByCustomerAuth(params), { enabled: !!params.customerID });

export const FETCH_CUSTOMER_QUERY_BY_EMAIL_KEY = 'FETCH_CUSTOMER_QUERY_BY_EMAIL_KEY';
export const useGetCustomerByEmail = (params: GetCustomerByEmailParams): UseQueryResult<GetCustomerResponse> => useQuery([FETCH_CUSTOMER_QUERY_BY_EMAIL_KEY, params], async () => getCustomerByEmail(params), { enabled: !!params.supplierID && !!params.email });

export const useGetCustomerNotifications = (params: GetCustomerNotificationsParams): UseQueryResult<GetNotificationsResponse> => useQuery([FETCH_CUSTOMER_NOTIFICATION_RULES_QUERY_KEY, params], async () => getCustomerNotificationRules(params), { enabled: !!params.supplierID && !!params.customerID });

export const useGetCustomerPaymentMethods = ({ customerID, supplierID, token, gpoId, refetchInterval = 0, alwaysRefetch = false }: TUseGetCustomerPaymentMethodsQuery): UseQueryResult<GetPaymentMethodsResponse> => useQuery([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY, customerID, supplierID], async () => getPaymentMethods({
    supplierID,
    customerID,
    token,
    gpoId
}), {
    refetchInterval,
    enabled: !!alwaysRefetch || (!!customerID && (!!supplierID || !!gpoId))
});

export const FETCH_CUSTOMER_SUPPLIER_MAPPINGS_QUERY_KEY = 'FETCH_CUSTOMER_SUPPLIER_MAPPINGS_QUERY_KEY';

export const useGetCustomerSupplierMappings = (params:GetCustomerSupplierMappingsParams) => {
    const { setCustomerSupplierMappings } = useSupplierStore();
    return useQuery(
        [FETCH_CUSTOMER_SUPPLIER_MAPPINGS_QUERY_KEY, params],
        async () => getCustomerSuppliers(params),
        {
            enabled: !!params.customerID,
            onSuccess: async (data) => {
                setCustomerSupplierMappings(data);
            }
        }
    );
};

export const FETCH_CUSTOMERS_WITH_NOTIFICATIONS_QUERY_KEY = 'FETCH_CUSTOMERS_WITH_NOTIFICATIONS_QUERY_KEY';

export const useGetCustomersWithCustomNotifications = (params: TUseGetCustomersWithNotificationsQuery): UseQueryResult<CustomersWithCustomNotificationsData> => useQuery([FETCH_CUSTOMERS_WITH_NOTIFICATIONS_QUERY_KEY, params.supplierID], async () => getCustomersWithCustomNotifications(params), { enabled: !!params.supplierID });
