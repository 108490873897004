import '../../../styles/dataPage.scss';
import React, { useEffect, useState } from 'react';
import { PageTitle } from '@ar/components/PageTitle';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FETCH_SUPPLIER_QUERY_KEY, useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { Button, Input, Loading, Select, toast, Toggle, Typography } from '@notch-ordering/ui-components';
import { InputPhone } from '@ar/components/shared/InputPhone';
import { SettingsFormErrors, SettingsFormState, validateFormState } from '@ar/pages/SettingsPage/SettingsPageConstants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AlertIcon from '@icons/alert-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { isEqual } from 'lodash';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { extractAddressFromPlace } from '@/utils/AddressUtils';
import { updateSupplier } from '../../network/AccountsReceivable.network';
import { InputAddressAutocomplete } from '@/components/shared/InputAddressAutocomplete';

/**
 * The AR Settings page
 *
 * @returns JSX Element
 */
export const SettingsPage = function (): JSX.Element {
    useDocumentTitle('Settings - Notch');
    const { supplierLoginData } = useSupplierStore();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [initialData, setInitialData] = useState<Partial<SettingsFormState>>({});
    const [formState, setFormState] = useState<Partial<SettingsFormState>>({});
    const { data: supplierData, isLoading } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });
    const [addPaymentLinksOnInvoiceNotifications, setAddPaymentLinksOnInvoiceNotifications] = useState<boolean>(false);
    const [errors, setErrors] = useState<SettingsFormErrors>({});
    const queryClient = useQueryClient();
    const updateSupplierMutation = useMutation(updateSupplier);
    const hasDataChanged = (): boolean => !isEqual(initialData, formState);

    const onErrorSavingSupplier = (error) => {
        console.info(error, 'error');
        toast.show({
            message: 'Could not save settings.',
            icon: <AlertIcon className="text-red-300" />,
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const formErrors = validateFormState(formState);
        const isFormValid = Object.keys(formErrors).length === 0;
        setErrors(formErrors);
        if (isFormValid && hasDataChanged()) {
            setIsSaving(true);
            updateSupplierMutation.mutate({
                supplierID: supplierLoginData?.supplier_id,
                body: formState,
            }, {
                onSuccess: () => {
                    toast.show({
                        message: 'Successfully updated settings.',
                        icon: <CheckIcon/>,
                        showClose: false,
                    });
                    setInitialData(formState);
                    queryClient.invalidateQueries([FETCH_SUPPLIER_QUERY_KEY]);
                    setIsSaving(false);
                },
                onError: (error) => {
                    onErrorSavingSupplier(error);
                    setIsSaving(false);
                }
            });
        }
    };

    const updateFormState = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    };

    const handleToggleChange = ():void => {
        setAddPaymentLinksOnInvoiceNotifications(!addPaymentLinksOnInvoiceNotifications);
        updateSupplierMutation.mutate(
            {
                supplierID: supplierLoginData?.supplier_id,
                body: {
                    add_payment_links_on_invoice_notifications: !addPaymentLinksOnInvoiceNotifications,
                },
            },
            {
                onError: (error) => {
                    console.info(error, 'error');
                    toast.show({
                        message: 'Could not save settings.',
                        icon: <AlertIcon className="text-red-300" />,
                    });
                    setAddPaymentLinksOnInvoiceNotifications(!addPaymentLinksOnInvoiceNotifications);
                },
            },
        );
    };

    const handlePlaceSelected = (place: google.maps.places.PlaceResult): void => {
        const address = extractAddressFromPlace(place);
        setFormState((prevState) => ({
            ...prevState,
            ...address,

        }));
    };

    useEffect(() => {
        if (supplierData) {
            const data = {
                name: supplierData?.name,
                email: supplierData?.email,
                phone: supplierData?.phone,
                ...supplierData?.billing_address,
            };
            setFormState(data);
            setInitialData(data);
            setAddPaymentLinksOnInvoiceNotifications(supplierData?.add_payment_links_on_invoice_notifications);
        }
    }, [supplierData]);

    return (
        <>
            <PageTitle>Settings</PageTitle>
            {!isLoading && formState && <><form onSubmit={handleSubmit} className="mt-12 font-body">
                <Typography variant="LG-2" className="font-semibold mb-6">
                    General
                </Typography>
                <div className="flex flex-col gap-5">
                    <div>
                        <Input label="Business Name"
                            className="lg:text-2"
                            variant="SMALL"
                            name="name"
                            isInvalid={!!errors?.name?._errors?.length}
                            value={formState?.name}
                            invalidMessage={'Business name is required'}
                            onChange={updateFormState}
                            required/>
                        <Typography variant="LG-1" className="text-gray-600 mt-1">
                            The customer will see this business name on their bank statements after digitally paying invoices.
                        </Typography>
                    </div>
                    <div className="flex lg:flex-row gap-4 items-start flex-col">
                        <Input
                            type="EMAIL"
                            variant="SMALL"
                            name="email"
                            label="Business email"
                            onChange={updateFormState}
                            value={formState?.email}
                            isInvalid={!!errors?.email?._errors?.length}
                            invalidMessage={'Email is required'}
                            required/>

                        <InputPhone label="Business phone"
                            variant="SMALL"
                            onChange={(e):void => {
                                setFormState({
                                    ...formState,
                                    phone: e.value,
                                });
                            }}
                            invalidMessage={'Enter a valid phone number'}
                            isInvalid={!!errors?.phone?._errors?.length}
                            value={formState?.phone}
                            required
                            name="phone"/>
                    </div>
                    <div className="flex lg:flex-row flex-col gap-4 items-start ">
                        <InputAddressAutocomplete
                            variant="SMALL"
                            className="w-full"
                            isInvalid={!!errors?.line1?._errors?.length}
                            label="Street address"
                            value={formState?.line1}
                            onChange={(e): void => {
                                setFormState({
                                    ...formState,

                                    line1: e.target.value,
                                });
                            }}
                            googleAutocompleteInputProps={{
                                // this will disable the enter key from submitting the form while selecting an address
                                onKeyDown: (e) :void => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                },
                            }}
                            onPlaceSelected={handlePlaceSelected}
                            // isInvalid={formErrors.address}
                            searchTypes={['address']}
                            invalidMessage={'Street address is required'}
                            required/>
                        <Input label="Unit Number"
                            variant="SMALL"
                            name="line2"
                            onChange={updateFormState}
                            value={formState?.line2}/>
                        <Input label="City"
                            variant="SMALL"
                            name="city"
                            isInvalid={!!errors?.city?._errors?.length}
                            required
                            invalidMessage={'City is required'}
                            onChange={updateFormState}
                            value={formState?.city}/>
                    </div>
                    <div className="flex gap-4 items-start lg:flex-row flex-col">
                        <Input label="Province"
                            variant="SMALL"
                            name="state"
                            isInvalid={!!errors?.state?._errors?.length}
                            onChange={updateFormState}
                            invalidMessage={'Province is required'}
                            value={formState?.state}
                            required/>

                        <Select required
                            variant="SMALL"
                            value={formState?.country}
                            label="Country"
                            isInvalid={!!errors?.country?._errors?.length}
                            invalidMessage={'Country is required'}
                            onChange={(e):void => {
                                setFormState({
                                    ...formState,
                                    country: e.target.value,
                                });
                            }
                            }
                            options={[{
                                value: 'ca',
                                label: 'Canada',
                            }, {
                                value: 'us',
                                label: 'United States',
                            }]} />

                        <Input
                            name="postal_code"
                            variant="SMALL"
                            required
                            isInvalid={!!errors?.postal_code?._errors?.length}
                            invalidMessage={'Postal code is required'}
                            onChange={updateFormState}
                            label="Postal Code"
                            value={formState?.postal_code}/>
                    </div>

                </div>
                <div className="flex  mt-6">
                    <Button loading={isSaving}
                        disabled={!hasDataChanged()}
                        variant="SECONDARY"
                        size="SMALL"
                        className="px-3.5 py-1.5">
                        Save
                    </Button>
                </div>
            </form>
            <Typography variant="LG-2" className="font-semibold mt-16 mb-6">
                Payment Links
            </Typography>
            <div className="flex justify-between items-center gap-2">
                <div> <Typography className="font-semibold mb-1">
                    Include payment links
                </Typography>
                <Typography className="text-gray-600 ">
                        When an invoice to be paid has been sent to the customer, they will also receive a link to
                        digitally pay the invoice.
                </Typography></div>

                <div><Toggle
                    isEnabled={addPaymentLinksOnInvoiceNotifications}
                    size="SMALL"
                    onChange={handleToggleChange}/></div>
            </div>

            </>}

            {isLoading && (<Loading isDark/>)}

        </>
    );
};
