// tracking constant events

import { sendTrackingEvent } from '@v2/utils/Tracking';

export const TrackingEventCategory = {
    ocr: 'OCR',
} as const;

export type TrackingEvent = {
    category?: typeof TrackingEventCategory[keyof typeof TrackingEventCategory],
    action: string,
    label?: string,
    value?: number,
};

export const TrackingEventActions = {
    // OCR tracking event actions
    accountingButtonClicked: 'accounting_button_clicked',
    approveButtonClicked: 'approve_button_clicked',
    imsButtonClicked: 'ims_button_clicked',
    saveAnywaysButtonClicked: 'save_anyways_button_clicked',
    // AR tracking event actions
    navigationMenuClicked: 'navigation_menu_clicked',
    signInClicked: 'sign_in_clicked',
    goToNextPageClicked: 'go_to_next_page_clicked',
    goToPreviousPageClicked: 'go_to_previous_page_clicked',
    moreButtonClicked: 'more_button_clicked',
    filterSelected: 'filter_selected',
    enterSearchInvoice: 'enter_search_invoice',
    invoiceSelected: 'invoice_selected',
    chargeInvoiceClicked: 'charge_invoice_clicked',
    sendInvoiceClicked: 'send_invoice_clicked',
    openInvoiceClicked: 'open_invoice_clicked',
    invoicePaidEventSyncManuallyClicked: 'invoice_paid_event_sync_manually_clicked',
    doNotChargeClicked: 'do_not_charge_clicked',
    refundClicked: 'refund_clicked',
    exitActionPanel: 'exit_action_panel',
    enterSearchCustomer: 'enter_search_customer',
    customerRowClicked: 'customer_row_clicked',
    editCustomerClicked: 'edit_customer_clicked',
    payoutRowClicked: 'payout_row_clicked',
    exportListOfPayoutClicked: 'export_list_of_payout_clicked',
    exportPayoutDetailsClicked: 'export_payout_details_clicked',
    refreshInvoiceClicked: 'refresh_invoice_clicked',
} as const;

export const TrackingEvents: Record<keyof typeof TrackingEventActions, TrackingEvent> = {
    // OCR tracking events
    accountingButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.accountingButtonClicked,
    },
    approveButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.approveButtonClicked,
    },
    imsButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.imsButtonClicked,
    },
    saveAnywaysButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.saveAnywaysButtonClicked,
    },
    // AR tracking events
    navigationMenuClicked: {
        action: TrackingEventActions.navigationMenuClicked,
    },
    invoiceSelected: {
        action: TrackingEventActions.invoiceSelected,
    },
    moreButtonClicked: {
        action: TrackingEventActions.moreButtonClicked,
    },
    chargeInvoiceClicked: {
        action: TrackingEventActions.chargeInvoiceClicked,
    },
    sendInvoiceClicked: {
        action: TrackingEventActions.sendInvoiceClicked,
    },
    openInvoiceClicked: {
        action: TrackingEventActions.openInvoiceClicked,
    },
    invoicePaidEventSyncManuallyClicked: {
        action: TrackingEventActions.invoicePaidEventSyncManuallyClicked,
    },
    doNotChargeClicked: {
        action: TrackingEventActions.doNotChargeClicked,
    },
    customerRowClicked: {
        action: TrackingEventActions.customerRowClicked,
    },
    editCustomerClicked: {
        action: TrackingEventActions.editCustomerClicked,
    },
    enterSearchCustomer: {
        action: TrackingEventActions.enterSearchCustomer,
    },
    enterSearchInvoice: {
        action: TrackingEventActions.enterSearchInvoice,
    },
    exitActionPanel: {
        action: TrackingEventActions.exitActionPanel,
    },
    exportListOfPayoutClicked: {
        action: TrackingEventActions.exportListOfPayoutClicked,
    },
    exportPayoutDetailsClicked: {
        action: TrackingEventActions.exportPayoutDetailsClicked,
    },
    filterSelected: {
        action: TrackingEventActions.filterSelected,
    },
    goToNextPageClicked: {
        action: TrackingEventActions.goToNextPageClicked,
    },
    payoutRowClicked: {
        action: TrackingEventActions.payoutRowClicked,
    },
    refundClicked: {
        action: TrackingEventActions.refundClicked,
    },
    signInClicked: {
        action: TrackingEventActions.signInClicked,
    },
    goToPreviousPageClicked: {
        action: TrackingEventActions.goToPreviousPageClicked,
    },
    refreshInvoiceClicked: {
        action: TrackingEventActions.refreshInvoiceClicked,
    }
};

export const trackCurrentPageTitle = (trackEvent: TrackingEvent) => {
    sendTrackingEvent({
        ...trackEvent,
        label: document.title,
    });
};

export const trackFilterSelected = (filterLabel: string) => {
    const pageName = document?.title?.replace(' - Notch', '') ?? '';
    sendTrackingEvent({
        ...TrackingEvents.filterSelected,
        label: `${pageName} - ${filterLabel}`,
    });
};
