const initialState = {
  isOrderGuideLoading: false,
  isMyItemsRequestInProgress: false,
  orderGuides: [],
  orderGuideItems: [],
  selectedVendors: [],
  selectedCategories: [],
  totalProductCount: '0',
  orderGuideSearchText: '',
  isAddItemDialogOpen: false,
  isEditingItem: false,
  buyerProductToEdit: null,
};

export default function singleOrderGuideReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_SINGLE_ORDERGUIDE':
      return Object.assign({}, state, {
        isOrderGuideLoading: action.isOrderGuideLoading,
      });

    case 'FETCH_MYITEMS_REQUEST_IN_PROGRESS':
      return Object.assign({}, state, {
        isMyItemsRequestInProgress: action.isMyItemsRequestInProgress,
      });

    case 'RECEIVE_ORDERGUIDE_LISTS':
      return Object.assign({}, state, {
        orderGuides: action.orderGuides,
      });

    case 'SAVE_ORDERGUIDE_ITEM':
      return Object.assign({}, state, {
        orderGuideItems: [...state.orderGuideItems, action.orderGuideItem],
        totalProductCount: state.totalProductCount + 1,
      });

    case 'RECEIVE_ORDERGUIDE_ITEMS':
      return Object.assign({}, state, {
        orderGuideItems: action.orderGuideItems,
        totalProductCount: action.totalProductCount,
      });

    case 'REMOVE_ORDERGUIDE_ITEM':
      return Object.assign({}, state, {
        orderGuideItems: state.orderGuideItems
          .slice(0, action.itemIndex)
          .concat([
            {
              ...state.orderGuideItems[action.itemIndex],
              buyerProducts: state.orderGuideItems[
                action.itemIndex
              ].buyerProducts
                .slice(0, action.productIndex)
                .concat([
                  {
                    ...state.orderGuideItems[action.itemIndex].buyerProducts[
                      action.productIndex
                    ],
                    buyerProductOrderGuides: state.orderGuideItems[
                      action.itemIndex
                    ].buyerProducts[action.productIndex].buyerProductOrderGuides
                      .slice(0, action.buyerProductOrderGuideIndex)
                      .concat(
                        state.orderGuideItems[action.itemIndex].buyerProducts[
                          action.productIndex
                        ].buyerProductOrderGuides.slice(
                          action.buyerProductOrderGuideIndex + 1
                        )
                      ),
                  },
                ])
                .concat(
                  state.orderGuideItems[action.itemIndex].buyerProducts.slice(
                    action.productIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orderGuideItems.slice(action.itemIndex + 1)),
      });

    case 'REMOVE_ORDERGUIDE':
      return Object.assign({}, state, {
        orderGuides: state.orderGuides
          .slice(0, action.index)
          .concat(state.orderGuides.slice(action.index + 1)),
      });

    case 'RECEIVE_ORDERGUIDE_CATEGORY_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          categories: action.categories,
        },
      });

    case 'RECEIVE_ORDERGUIDE_ENABLED_CATEGORY_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          enabledCategories: action.enabledCategories,
        },
      });

    case 'RECEIVE_ORDERGUIDE_LIST_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          lists: action.lists,
        },
      });

    case 'CHANGE_ORDERGUIDE_SELECTED_VENDOR_FILTERS':
      return Object.assign({}, state, {
        selectedVendors: action.vendors,
      });

    case 'CHANGE_ORDERGUIDE_SELECTED_LISTS_FILTERS':
      return Object.assign({}, state, {
        selectedLists: action.lists,
      });

    case 'SELECT_ORDERGUIDE_VENDOR_FILTER':
      return Object.assign({}, state, {
        selectedVendors: state.selectedVendors.concat(action.newVendor),
      });

    case 'UNSELECT_ORDERGUIDE_VENDOR_FILTER':
      return Object.assign({}, state, {
        selectedVendors: state.selectedVendors
          .slice(0, action.index)
          .concat(state.selectedVendors.slice(action.index + 1)),
      });

    case 'CLEAR_SELECTED_ORDERGUIDE_VENDOR_FILTERS':
      return Object.assign({}, state, {
        selectedVendors: initialState.selectedVendors,
      });

    case 'SELECT_ORDERGUIDE_CATEGORY_FILTER':
      return Object.assign({}, state, {
        selectedCategories: state.selectedCategories.concat(action.newCategory),
      });

    case 'UNSELECT_ORDERGUIDE_CATEGORY_FILTER':
      return Object.assign({}, state, {
        selectedCategories: state.selectedCategories
          .slice(0, action.index)
          .concat(state.selectedCategories.slice(action.index + 1)),
      });

    case 'CLEAR_SELECTED_ORDERGUIDE_CATEGORY_FILTERS':
      return Object.assign({}, state, {
        selectedCategories: initialState.selectedCategories,
      });

    case 'CHANGE_ORDERGUIDE_SEARCH_TEXT':
      return Object.assign({}, state, {
        orderGuideSearchText: action.orderGuideSearchText,
      });

    case 'OPEN_ADD_ITEM':
      return {
        ...state,
        isAddItemDialogOpen: action.open,
        isEditingItem: false,
        buyerProductToEdit: null,
      };

    case 'OPEN_EDIT_ITEM':
      return {
        ...state,
        isAddItemDialogOpen: true,
        isEditingItem: true,
        buyerProductToEdit: action.buyerProductToEdit,
      };

    default:
      return state;
  }
}
