import { batch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { updateChatChannelLastMessage } from './chatChannelsListActions';
import {
  prepareMessages,
  readChannelMessagesPaginator,
} from '../helpers/chatClientHelper';
import {
  CHAT_MESSAGES_HISTORY_LIMIT,
  CHAT_SUPPLIER_CONTACT_TIMELINE_GENERIC_MESSAGE,
} from '../../../constants/GlobalConstants';
import { logException } from '../../../domains/shared/logger';

// #region Action Types
export const ADD_CHAT_MESSAGES = 'ADD CHAT MESSAGES';
export const ADD_CHAT_MESSAGES_SUCCESS = 'ADD CHAT MESSAGES SUCCESS';
export const ADD_CHAT_MESSAGES_FAILED = 'ADD CHAT MESSAGES FAILED';

export const APPEND_CHAT_MESSAGES = 'APPEND CHAT MESSAGES';
export const APPEND_CHAT_MESSAGES_SUCCESS = 'APPEND CHAT MESSAGES SUCCESS';
export const APPEND_CHAT_MESSAGES_FAILED = 'APPEND CHAT MESSAGES FAILED';

export const GET_CHAT_MESSAGES = 'GET CHAT MESSAGES';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET CHAT MESSAGES SUCCESS';
export const GET_CHAT_MESSAGES_FAILED = 'GET CHAT MESSAGES FAILED';

export const LOAD_MORE_CHAT_MESSAGES = 'LOAD MORE CHAT MESSAGES';
export const LOAD_MORE_CHAT_MESSAGES_SUCCESS =
  'LOAD MORE CHAT MESSAGES SUCCESS';
export const LOAD_MORE_CHAT_MESSAGES_FAILED = 'LOAD MORE CHAT MESSAGES FAILED';

export const REMOVE_CHAT_MESSAGES = 'REMOVE CHAT MESSAGES';
// #endregion Action Types

// #region Actions
/**
 * Add Chat messages
 *
 * @param {Object} channel A Chat channel object
 * @param {Array} messages Array of Chat messages to add
 */
export const addChatMessages =
  (channel, messages = [], channelMembers) =>
  async (dispatch, getState) => {
    if (!channel) return;

    try {
      dispatch({ type: ADD_CHAT_MESSAGES });

      // Get all the vendors
      const { vendors = [] } = getState().vendorsReducer;

      // Read metadata from Chat channel and prepare messageAttributes object by taking care of all edge cases.
      const {
        sid,
        state: {
          attributes: {
            order_number: orderNumber,
            order_status: orderStatus,
            vendor_id: vendorUrlSafe,
          },
        },
      } = channel;

      // Match the vendor from users list with Chat vendor info
      const vendor = vendors.find((v) => v.urlsafe === vendorUrlSafe) || {}; // The vendor must always be available in vendors list

      // Create supplier generic response message to shown on top of the each chat (aka Chat System Message)
      const supplierGenericResponseMessage = {
        type: 'text',
        state: {
          sid: sid,
          isSystemMessage: true,
          message: CHAT_SUPPLIER_CONTACT_TIMELINE_GENERIC_MESSAGE,
        },
      };
      messages.unshift(supplierGenericResponseMessage);

      // Prepare all the messages
      const chatMessages = await prepareMessages(messages);
      const messageAttributes = {
        vendorName: vendor.name,
        orderNumber,
        orderStatus,
        channelMembers,
        page: 1,
      };

      dispatch({
        type: ADD_CHAT_MESSAGES_SUCCESS,
        sid,
        messages: chatMessages,
        messageAttributes,
      });

      // Update chat channel last message as last message from chat messages.
      const { state: { body, type } = {} } =
        chatMessages[messages.length - 1] || [];
      if (body || type) dispatch(updateChatChannelLastMessage(sid, body, type));
    } catch (error) {
      handleCatch(ADD_CHAT_MESSAGES_FAILED, 'addChatMessages', error, dispatch);
    }
  };

/**
 * Append Chat messages to channel sid
 *
 * @param {string} sid Channel unique sid
 * @param {Array} messages Array of Chat messages to add
 */
export const appendChatMessages =
  (sid, messages = []) =>
  async (dispatch) => {
    if (!sid || !messages || messages.length === 0) return;

    try {
      dispatch({ type: APPEND_CHAT_MESSAGES });

      const chatMessage = await prepareMessages(messages);
      const {
        state: { body: lastMessageBody = '', type: lastMessageType } = {},
      } = chatMessage[messages.length - 1]; // reads data from last chat message

      batch(() => {
        // Executes both of the dispatch's together and avoids extra render of app
        dispatch({
          type: APPEND_CHAT_MESSAGES_SUCCESS,
          sid,
          messages: chatMessage,
        });
        dispatch(
          updateChatChannelLastMessage(sid, lastMessageBody, lastMessageType)
        );
      });
    } catch (error) {
      handleCatch(
        APPEND_CHAT_MESSAGES_FAILED,
        'appendChatMessages',
        error,
        dispatch
      );
    }
  };

/**
 * Removes Chat message from store
 *
 * @param {string} sid Channel unique sid
 */
export const removeChatMessage = (sid) => (dispatch) => {
  if (!sid) return;

  try {
    dispatch({ type: REMOVE_CHAT_MESSAGES, sid });
  } catch (error) {
    handleCatch(null, 'removeChatMessage', error);
  }
};

/**
 * A method to load previous message(s) in Chat channel
 *
 * By default latest messages of given size (CHAT_MESSAGES_HISTORY_LIMIT) will be retrieved.
 * More messages (backward) can be retrieved using this method.
 *
 * @param {string} sid Channel unique sid
 */
export const loadNextSetOfChatMessages =
  (sid) => async (dispatch, getState) => {
    if (!sid) return;

    try {
      dispatch({ type: LOAD_MORE_CHAT_MESSAGES });

      const { channels, messages } = getState().chat;

      // Return if there are no messages in the Chat list. It most likely that Chat channel
      // is just created and there are no messages in the history to fetch.
      if (messages[sid] && messages[sid].messages.length === 0) {
        dispatch({ type: LOAD_MORE_CHAT_MESSAGES_SUCCESS, sid, messages: [] });
        return;
      }

      const {
        channelAttributes: { lastMessageIndex = 0 },
      } = channels.list[sid]; // Retrieve channel attributes

      // Find current page index
      const currentPageIndex = messages[sid].messageAttributes.page;
      const nextMessageSetStartIndex =
        lastMessageIndex - CHAT_MESSAGES_HISTORY_LIMIT * currentPageIndex;

      // Return if there is a possibility of nextMessageSetStartIndex being -ve. We don't want
      // to create a fetch request with -ve number of records request length.
      if (nextMessageSetStartIndex < 0) {
        dispatch({ type: LOAD_MORE_CHAT_MESSAGES_SUCCESS, sid, messages: [] });
        return;
      }

      const chatMessages = await readChannelMessagesPaginator(
        await channels.list[sid].channel.getMessages(
          CHAT_MESSAGES_HISTORY_LIMIT,
          nextMessageSetStartIndex
        )
      );

      if (chatMessages && chatMessages.length > 0) {
        const preparedChatMessages = await prepareMessages(chatMessages);
        // Updates the messages and page numbers
        dispatch({
          type: LOAD_MORE_CHAT_MESSAGES_SUCCESS,
          sid,
          messages: preparedChatMessages,
          page: currentPageIndex + 1,
        });
      } else {
        dispatch({ type: LOAD_MORE_CHAT_MESSAGES_SUCCESS, sid, messages: [] });
      }
    } catch (error) {
      handleCatch(
        LOAD_MORE_CHAT_MESSAGES_FAILED,
        'loadNextSetOfChatMessages',
        error,
        dispatch
      );
    }
  };
// #endregion Actions

// #region Helper methods
/**
 * Handle Catch method in case Action is failed to execute.
 *
 * @param {string} type Action Type
 * @param {string} methodname Error occured in method name
 * @param {object} error Error object
 * @param {Function} dispatch Redux dispatch function
 */
const handleCatch = (type, methodname, error, dispatch) => {
  if (type) dispatch({ type });

  let errorMessage = '';
  if (!error.response)
    errorMessage = 'Detected a connection problem, please refresh this page';
  else
    errorMessage =
      (((error || {}).response || {}).data || {}).message || 'Please try again';

  toastr.error(`Error: ${errorMessage}`);
  // eslint-disable-next-line no-console
  console.error(`An Error occured with ${methodname} ${error}`);
  logException(error);
};
// #endregion Helper methods
