import {
  SHOW_CART_ITEM_NOTIFICATION,
  DISMISS_CART_ITEM_NOTIFICATION,
  TOGGLE_ADD_CUSTOM_ITEM_MODAL,
  SHOW_CART,
  SHOW_CART_LOADER,
  TOGGLE_CART_MANAGERS_MODAL,
} from '../constants/ActionTypes';

export const initialState = {
  isCartOpen: false,
  isCartLoading: false,
  isShowCartManagersModal: false,
  cartItemNotification: {
    isVisible: false,
    item: {},
  },
  addCustomItemModal: {
    isOpen: false,
    supplier: '',
    urlsafe: '',
  },
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_CART:
      return Object.assign({}, state, {
        isCartOpen: action.isCartOpen,
      });

    case SHOW_CART_LOADER:
      return Object.assign({}, state, {
        isCartLoading: action.isCartLoading,
      });

    case TOGGLE_CART_MANAGERS_MODAL:
      return Object.assign({}, state, {
        isShowCartManagersModal: action.isShowCartManagersModal,
      });

    case SHOW_CART_ITEM_NOTIFICATION:
      const { payload: item } = action;

      return {
        ...state,
        cartItemNotification: {
          isVisible: true,
          item,
        },
      };

    case DISMISS_CART_ITEM_NOTIFICATION:
      const { cartItemNotification } = initialState;

      return {
        ...state,
        cartItemNotification,
      };

    case TOGGLE_ADD_CUSTOM_ITEM_MODAL:
      return Object.assign({}, state, {
        addCustomItemModal: {
          ...action.payload,
        },
      });

    default:
      return state;
  }
}
