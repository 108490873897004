import * as types from '../constants/ActionTypes';

const initialState = {
  buyer: {
    id: '',
    urlsafe: '',
    name: '',
    displayName: '',
    email: '',
    phone: {},
    fax: {},
    billingAddress: {},
    shippingAddresses: [],
    // temporary variable which is used to show the confirmation
    // after the buyer finished the onboarding process
    // this is not coming back from the buyer endpoint
    justAddVenodrs: false,
    paymentMethods: [],
    addPaymentMethodToken: '',
  },
  isFetchingPaymentMethods: false,
  buyerMembers: [],
  defaultBuyer: { buyerKey: '', buyerName: '', permissions: [], roles: [] },
};

export default function buyerReducer(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_BUYER':
      return Object.assign({}, state, {
        buyer: {
          ...action.buyer,
          hasSuppliersSetup:
            action.buyer.hasSuppliersSetup && !state.buyer.justAddVenodrs,
          // paymentMethods: [],
        },
      });

    case 'RECEIVE_BUYER_MEMBERS':
      return Object.assign({}, state, {
        buyerMembers: action.buyerMembers,
      });

    case 'VENDORS_ADDED':
      return Object.assign({}, state, {
        buyer: {
          ...state.buyer,
          justAddVenodrs: action.payload,
        },
      });

    case 'ADD_BUYER_MEMBER':
      return Object.assign({}, state, {
        buyerMembers: [
          ...state.buyerMembers,
          {
            id: action.buyerMember.id,
            urlsafe: action.buyerMember.urlsafe,
            receiveNotification: action.buyerMember.receiveNotification,
            account: action.buyerMember.account,
            roles: action.buyerMember.roles,
          },
        ],
      });

    case 'UPDATE_BUYER_MEMBER':
      return Object.assign({}, state, {
        buyerMembers: state.buyerMembers
          .slice(0, action.index)
          .concat([
            {
              ...state.buyerMembers[action.index],
              account: {
                ...state.buyerMembers[action.index].account,
                firstName: action.buyerMember.firstName,
                lastName: action.buyerMember.lastName,
                mobile: action.buyerMember.mobile,
              },
              roles: action.buyerMember.roles,
              receiveNotification: action.buyerMember.receiveNotification,
            },
          ])
          .concat(state.buyerMembers.slice(action.index + 1)),
      });

    case 'UPDATE_EMAIL_NOTIFICATION_ACCOUNT':
      return Object.assign({}, state, {
        buyerMembers: state.buyerMembers
          .slice(0, action.index)
          .concat([
            {
              ...state.buyerMembers[action.index],
              receiveNotification: action.receiveNotification,
            },
          ])
          .concat(state.buyerMembers.slice(action.index + 1)),
      });

    case 'DELETE_BUYER_MEMBER':
      return Object.assign({}, state, {
        buyerMembers: state.buyerMembers
          .slice(0, action.index)
          .concat(state.buyerMembers.slice(action.index + 1)),
      });

    case 'ADD_BUYER_SHIPPING_ADDRESS':
      return Object.assign({}, state, {
        buyer: {
          ...state.buyer,
          shippingAddresses: [
            ...state.buyer.shippingAddresses,
            {
              id: action.shippingAddress.id,
              name: action.shippingAddress.name,
              line1: action.shippingAddress.line1,
              line2: action.shippingAddress.line2,
              zip: action.shippingAddress.zip,
              city: action.shippingAddress.city,
              state: action.shippingAddress.state,
              country: action.shippingAddress.country,
              geocode: action.shippingAddress.geocode,
              formatted: action.shippingAddress.formatted,
              locationID: action.shippingAddress.locationID,
              driveInstructions: action.shippingAddress.driveInstructions,
              deliveryContactName: action.shippingAddress.deliveryContactName,
              deliveryContactNumber:
                action.shippingAddress.deliveryContactNumber,
            },
          ],
        },
      });

    case 'UPDATE_BUYER_SHIPPING_ADDRESS':
      return Object.assign({}, state, {
        buyer: {
          ...state.buyer,
          shippingAddresses: state.buyer.shippingAddresses
            .slice(0, action.index)
            .concat([
              {
                ...state.buyer.shippingAddresses[action.index],
                id: action.shippingAddress.id,
                name: action.shippingAddress.name,
                line1: action.shippingAddress.line1,
                line2: action.shippingAddress.line2,
                zip: action.shippingAddress.zip,
                city: action.shippingAddress.city,
                state: action.shippingAddress.state,
                country: action.shippingAddress.country,
                geocode: action.shippingAddress.geocode,
                formatted: action.shippingAddress.formatted,
                locationID: action.shippingAddress.locationID,
                driveInstructions: action.shippingAddress.driveInstructions,
                deliveryContactName: action.shippingAddress.deliveryContactName,
                deliveryContactNumber:
                  action.shippingAddress.deliveryContactNumber,
              },
            ])
            .concat(state.buyer.shippingAddresses.slice(action.index + 1)),
        },
      });

    case 'SELECT_DEFAULT_BUYER':
      return {
        ...state,
        defaultBuyer: action.defaultBuyer,
      };
    case types.FETCH_BUYER_PAYMENT_METHODS:
      return {
        ...state,
        isFetchingPaymentMethods: true,
      };

    case types.FETCH_BUYER_PAYMENT_METHODS_SUCCEEDED:
      const {paymentMethods} = action;
      return {
        ...state,
        isFetchingPaymentMethods: false,
        buyer: {
          ...state.buyer,
          paymentMethods,
        },
      };
    case types.REMOVE_BUYER_PAYMENT_METHODS:
      const {paymentMethodId} = action;
      return {
        ...state,
        isFetchingPaymentMethods: false,
        buyer: {
          ...state.buyer,
          paymentMethods: state.buyer.paymentMethods.filter(
            (paymentMethod) => paymentMethod.id !== paymentMethodId
          ),
        },
      };

    case types.SET_BUYER_PAYMENT_METHODS_WITH_INDEX:
      const {paymentMethod, index} = action;
      return {
        ...state,
        buyer: {
          ...state.buyer,
          paymentMethods: [
            ...state.buyer.paymentMethods.slice(0, index),
            paymentMethod,
            ...state.buyer.paymentMethods.slice(index),
          ],
        },
      };
    case types.CREATE_PAYMENT_METHOD_TOKEN_SUCCEEDED:
      const {token} = action;
      return {
        ...state,
        buyer: {
          ...state.buyer,
          addPaymentMethodToken: token,
        },
      };

    case types.FETCH_BUYER_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        isFetchingPaymentMethods: false,
      };

    default:
      return state;
  }
}
