import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Button, Input, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@icons/check-icon.svg';
import { v4 } from 'uuid';
import { DraftInvoiceItem, GetTransactionItem } from '@/ar/network/AccountsReceivable.network';
import { CurrencyInput } from '../shared/InputCurrency';
import { centsToDollars, dollarsToCents } from '@/utils/Formatters';
import { useDraftInvoiceStore, DraftInvoiceModals } from '@/ar/stores/DraftInvoiceStore';
import { queryClient } from '@/containers/app/Root';
import { FETCH_TRANSACTION_ITEMS_QUERY_KEY } from '@/ar/hooks/queries/InvoicesQueries.hook';

export interface DraftInvoiceItemFormModalProps {
    onClose?: () => void,
    onItemSubmit: (item: DraftInvoiceItem, isNewItem: boolean) => void,
}

export const lineItemValidationSchema = z.object({
    description: z.string().nonempty('Product is required'),
    quantity: z.number({ required_error: 'Quantity is required', }),
    price: z.number({ required_error: 'Price is required' }),
    tax_amount: z.number().optional().default(0),
    total: z.number().default(0),
});

type LineItemForm = z.infer<typeof lineItemValidationSchema>;

const DEFAULT_ITEM: LineItemForm = {
    description: '',
    quantity: 0,
    price: 0,
    tax_amount: 0,
    total: 0,
};

export const DraftInvoiceItemFormModal: React.FC<DraftInvoiceItemFormModalProps> = ({ onClose, onItemSubmit }) => {
    const { modals, closeModal } = useDraftInvoiceStore();
    const item = modals?.selectedItem;
    const isNewItem = !item;

    const { handleSubmit, control, reset, setValue, watch, setFocus } = useForm<GetTransactionItem>({
        resolver: zodResolver(lineItemValidationSchema),
        defaultValues: { ...item },
        mode: 'onBlur',
    });
    const { errors } = useFormState({
        control,
    });

    const handleOnClose = () => {
        reset(DEFAULT_ITEM);
        onClose?.();
        closeModal(DraftInvoiceModals.LineItem);
    };

    const handleOnSuccess = () => {
        handleOnClose();
        onClose?.();
        toast.show({
            message: `Item ${isNewItem ? 'added' : 'updated'}`,
            icon: <CheckIcon />,
            showClose: false,
        });
        queryClient.invalidateQueries([FETCH_TRANSACTION_ITEMS_QUERY_KEY]);
    };

    const quantity = watch('quantity');
    const price = watch('price');
    const taxAmount = watch('tax_amount');

    useEffect(() => {
        const total = (quantity * price) + taxAmount;
        setValue('total', total);
    }, [quantity, price, taxAmount]);

    useEffect(() => {
        if (item) {
            reset({ ...item,
                price: centsToDollars(item.price),
                tax_amount: centsToDollars(item.tax_amount), });
        } else {
            reset(DEFAULT_ITEM);
        }
        setTimeout(() => {
            setFocus('description');
        });
    }, [item, setValue]);

    const onSubmit = (data: DraftInvoiceItem) => {
        onItemSubmit({
            ...data,
            id: isNewItem ? v4() : item?.id,
            price: dollarsToCents(data.price),
            quantity: data.quantity,
            tax_amount: dollarsToCents(data.tax_amount),
            total: dollarsToCents(data.total),
        }, isNewItem);
        handleOnSuccess();
    };

    if (!modals[DraftInvoiceModals.LineItem]) return null;

    return <Modal isOpen={modals[DraftInvoiceModals.LineItem]}
        onClose={handleOnClose}
        title={
            <>
                <Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">
                    {isNewItem ? 'Create' : 'Edit'} Item
                </Typography>
                {!isNewItem && <Typography className="m-0 pr-10 mb-1 text-gray-600" variant="BASE">
                    {item.description}
                </Typography>}
            </>

        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[600px]" ><form onSubmit={handleSubmit(onSubmit)}
            className=" pb-0 flex flex-col gap-6 ">
            <div className="px-5 pt-5 flex flex-col gap-6">
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => <Input
                        label="Product"
                        variant="SMALL"
                        isInvalid={!!errors.description}
                        invalidMessage={errors.description?.message}
                        required
                        inputProps={{
                            ...field,
                        }}/>}/>
                <div className="flex items-center gap-4">
                    <Controller
                        name="quantity"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            prefix=""
                            decimalScale={0}
                            allowNegative
                            isInvalid={!!errors.quantity}
                            invalidMessage={errors.quantity?.message}
                            required
                            variant="SMALL"
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            label={'Quantity'}/>}/>
                    <Controller
                        name="price"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            required
                            isInvalid={!!errors.price}
                            invalidMessage={errors.price?.message}
                            variant="SMALL"
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            label={'Price'}/>}/>
                </div>
                <div className="flex items-center gap-4 w-full">

                    <Controller
                        name="tax_amount"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            variant="SMALL"
                            required
                            isInvalid={!!errors.tax_amount}
                            invalidMessage={errors.tax_amount?.message}
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            label={'Tax amount'}/>}/>
                    <Controller
                        name="total"
                        control={control}
                        render={({ field }) => <CurrencyInput
                            variant="SMALL"
                            required
                            allowNegative
                            isInvalid={!!errors.total}
                            invalidMessage={errors.total?.message}
                            onChange={(e):void => {
                                field.onChange(e.floatValue);
                            }}
                            value={field.value}
                            disabled
                            label={'Total'}/>}/>
                </div>
            </div>

            <Separator className="my-4"/>

            <div className="flex items-center gap-4 ml-auto w-full px-5">
                <Button
                    onClick={handleOnClose}
                    variant="TERTIARY_FILLED"
                    size="SMALL"
                    as="div"
                    className="ml-auto"
                    minWidth="w-auto">
                    Cancel
                </Button>
                <Button
                    variant={'SECONDARY'}
                    size="SMALL"
                    minWidth="w-auto"
                    type="submit">
                    {isNewItem ? 'Create' : 'Update'}
                </Button>

            </div>
        </form>
    </Modal>;
};
