const initialState = {
  loadingSpecificSettings: false,
  loadingSettingsModal: false,
  loadingPaymentsTabs: false,
  showEditBuyerModal: false,
  showAccountSettingsModal: false,
  showLocationsModal: false,
  locationsModalIndex: -1,
  showMembersModal: false,
  showInvoicePaymentModal: false,
  membersModalIndex: -1,
  showDeleteMembersModal: false,
  stripePubKey: '',
  overdueTermsUnitPercentage: '',
  overdueTermsDuration: '',
  overdueTermsAPR: '',
  prevPathname: '',
  isStripeModalOpen: false,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_LOADER_SPECIFIC_SETTINGS':
      return Object.assign({}, state, {
        loadingSpecificSettings: action.loadingSpecificSettings,
      });

    case 'SHOW_LOADER_MODAL_SETTINGS':
      return Object.assign({}, state, {
        loadingSettingsModal: action.loadingSettingsModal,
      });

    case 'SHOW_LOADER_PAYMENTS_TABS':
      return Object.assign({}, state, {
        loadingPaymentsTabs: action.loadingPaymentsTabs,
      });

    case 'TOGGLE_EDIT_BUYER_MODAL':
      return Object.assign({}, state, {
        showEditBuyerModal: action.showEditBuyerModal,
      });

    case 'TOGGLE_ACCOUNT_SETTINGS_MODAL':
      return Object.assign({}, state, {
        showAccountSettingsModal: action.showAccountSettingsModal,
      });

    case 'TOGGLE_LOCATIONS_MODAL':
      return Object.assign({}, state, {
        showLocationsModal: action.showLocationsModal,
      });

    case 'UPDATE_LOCATIONS_MODAL_INDEX':
      return Object.assign({}, state, {
        locationsModalIndex: action.locationsModalIndex,
      });

    case 'TOGGLE_MEMBERS_MODAL':
      return Object.assign({}, state, {
        showMembersModal: action.showMembersModal,
      });
    case 'TOGGLE_INVOICE_PAYMENT_MODAL':
      return Object.assign({}, state, {
        showInvoicePaymentModal: action.showInvoicePaymentModal,
      });
    case 'UPDATE_MEMBERS_MODAL_INDEX':
      return Object.assign({}, state, {
        membersModalIndex: action.membersModalIndex,
      });

    case 'TOGGLE_DELETE_MEMBER_MODAL':
      return Object.assign({}, state, {
        showDeleteMembersModal: action.showDeleteMembersModal,
      });

    case 'STORE_PREVIOUS_PATH_SETTINGS':
      return Object.assign({}, state, {
        prevPathname: action.prevPathname,
      });

    case 'RECEIVE_CONFIG_PARAMS':
      return Object.assign({}, state, {
        stripePubKey: action.stripePubKey,
        overdueTermsUnitPercentage: action.overdueTermsUnitPercentage,
        overdueTermsDuration: action.overdueTermsDuration,
        overdueTermsAPR: action.overdueTermsAPR,
      });

    case 'OPEN_STRIPE_MODAL':
      return {
        ...state,
        isStripeModalOpen: action.isStripeModalOpen,
      };

    default:
      return state;
  }
}
