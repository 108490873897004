import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { signUrls, SignUrlsParams, SignUrlsResponse } from '@/ar/network/AccountsReceivable.network';

export const SIGN_URL_QUERY_KEY = 'SIGN_URL_QUERY_KEY';

export const useSignUrls = (params: SignUrlsParams): UseQueryResult<SignUrlsResponse> => useQuery(
    [SIGN_URL_QUERY_KEY, params],
    async () => signUrls(params),
    { enabled: params?.urls?.length > 0 && (!!params?.supplierID || !!params?.customerID) },
);
