import * as types from '../constants/ActionTypes';

export const initialState = {
  orders: [],
  pendingOrders: [],
  completedOrders: [],
  completedOrdersToday: [],
singleOrder: {
    id: 0,
    buyerKey: 0,
    callStatus: '',
    deliveryDay: '',
    addOnOrders: [],
    emailRef: null,
    emailStatus: 'Enqueue',
    errorMessage: '',
    fromProducer: '',
    items: [],
    shippingAddress: {},
    notes: '',
    numberItems: 0,
    placeOrderDate: '',
    receivedByVendor: false,
    sftpStatus: '',
    isInShoppingCart: false,
    smsStatus: '',
    status: 'NEW',
    subtotal: 0,
    total: 0,
    urlsafe: '',
    vendorEmailSend: true,
    vendorID: '',
    vendorName: '',
    deliveryTimeWindow: null,
    creditNotes: [],
    debitNotes: [],
    orderPayments: [],
  },
  invoicedOrders: [],
  loading: false,
  isLoadingSingleOrder: true,
  showOrdersTotal: false,
  totalOrderNumber: 0,
  currentPageOrdersTable: 1,
  editOrderProductLoading: false,
  isFetchingTaxedItem: false,
  isEditingOrderDeliveryDay: false,
  customItems: {
    isLoading: false,
    orders: {},
  },
};

export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_LOADER_ORDERS:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case types.RECEIVE_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };

    case types.RECEIVE_PENDING_ORDERS:
      return Object.assign({}, state, {
        pendingOrders: action.pendingOrders,
      });

    case types.RECEIVE_COMPLETED_ORDERS:
      return Object.assign({}, state, {
        completedOrders: action.completedOrders,
        totalOrderNumber: action.totalOrderNumber,
        currentPageOrdersTable: action.currentPageOrdersTable,
      });

    case types.RECEIVE_COMPLETED_ORDERS_TODAY:
      return Object.assign({}, state, {
        completedOrdersToday: action.completedOrdersToday,
      });

    case types.RECEIVE_INVOICED_ORDERS:
      return Object.assign({}, state, {
        invoicedOrders: action.invoicedOrders,
        totalOrderNumber: action.totalOrderNumber,
        currentPageOrdersTable: action.currentPageOrdersTable,
      });

    case types.RECEIVE_SINGLE_ORDER:
      return Object.assign({}, state, {
        singleOrder: action.singleOrder,
      });

    case types.FETCH_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        isLoadingSingleOrder: true,
      };
    case types.FETCH_SINGLE_ORDER_SUCCESS:
    case types.FETCH_SINGLE_ORDER_FAILED:
      return {
        ...state,
        isLoadingSingleOrder: false,
      };

    case 'FETCHING_TAXED_ITEM':
      return {
        ...state,
        isFetchingTaxedItem: true,
      };

    case types.FETCHING_TAXED_ITEM_SUCCESS:
      return {
        ...state,
        isFetchingTaxedItem: false,
      };

    case types.FETCH_ORDER_CUSTOM_ITEMS_SUCCESS:
      return {
        ...state,
        customItems: {
          orders: { ...state.customItems.orders, ...action.payload },
        },
      };

    // Use switch staement fall through, since they all
    // change the quantity of a particular orderProduct
    // Needs curly braces for scope purposes
    case types.INCREASE_QUANTITY_CART:
    case types.DECREASE_QUANTITY_CART:
    case types.CUSTOM_QUANTITY_CART: {
      const order = state.orders[action.orderIndex];
      const orderProduct =
        state.orders[action.orderIndex].items[action.orderProductIndex];

      // Calculate the total
      const orderSubtotal = order.items.reduce((total, curr) => {
        let quantity = curr.quantity;

        // if it's the current orderProduct being updated, use the new quantity
        if (orderProduct && orderProduct.sourceUrlsafe === curr.sourceUrlsafe) {
          quantity = action.quantity;
        }

        return total + curr.price * quantity;
      }, 0);

      // This should be update to include taxes
      // TODO: find out if this order.credits is deprecated
      const orderTotal = orderSubtotal + order.credits;

      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              subtotal: orderSubtotal,
              total: orderTotal, // This should be update to include taxes
              items: state.orders[action.orderIndex].items
                .slice(0, action.orderProductIndex)
                .concat([
                  {
                    ...state.orders[action.orderIndex].items[
                      action.orderProductIndex
                    ],
                    quantity: action.quantity,
                  },
                ])
                .concat(
                  state.orders[action.orderIndex].items.slice(
                    action.orderProductIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });
    }
    case types.ADD_PRODUCT_TO_CART_ERROR:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              items: action.previousItems,
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });

    // Needs curly braces for scope purposes
    case types.REMOVE_PRODUCT_FROM_CART: {
      const order = state.orders[action.orderIndex];
      const orderProduct =
        state.orders[action.orderIndex].items[action.orderProductIndex];
      if(!orderProduct) {
        return state;
      }
      // Calculate the total
      // Here we just need to remove the orderProducts total (price * quantity) from the current subtotal
      const orderSubtotal =
        order.subtotal - orderProduct.price * orderProduct.quantity;

      const orderTotal = orderSubtotal + order.credits;

      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              subtotal: orderSubtotal,
              total: orderTotal,
              items: state.orders[action.orderIndex].items
                .slice(0, action.orderProductIndex)
                .concat(
                  state.orders[action.orderIndex].items.slice(
                    action.orderProductIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
        editOrderProductLoading: false,
      });
    }

    case types.ADD_NEW_PRODUCT_CART: {
      const order = state.orders[action.orderIndex];
      if (!order) {
        return state;
      }

      // Calculate the total
      // Here we just need to remove the orderProducts total (price * quantity) from the current subtotal
      const orderSubtotal = order.subtotal + action.price * action.quantity;
      const orderTotal = orderSubtotal + (order.credits || 0);

      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              subtotal: orderSubtotal,
              total: orderTotal,
              items: [
                action.newOrderProduct,
                ...state.orders[action.orderIndex].items,
              ],
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });
    }

    case types.REMOVE_ORDER_FROM_CART:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat(state.orders.slice(action.orderIndex + 1)),
      });

    case types.ADD_URLSAFE_TO_CART_ITEM:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              items: state.orders[action.orderIndex].items
                .slice(0, action.orderProductIndex)
                .concat([
                  {
                    ...state.orders[action.orderIndex].items[
                      action.orderProductIndex
                    ],
                    urlsafe: action.urlsafe,
                  },
                ])
                .concat(
                  state.orders[action.orderIndex].items.slice(
                    action.orderProductIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });

    case types.TOGGLE_EDIT_MODE_CART:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              items: state.orders[action.orderIndex].items
                .slice(0, action.orderProductIndex)
                .concat([
                  {
                    ...state.orders[action.orderIndex].items[
                      action.orderProductIndex
                    ],
                    editMode: action.editMode,
                  },
                ])
                .concat(
                  state.orders[action.orderIndex].items.slice(
                    action.orderProductIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });

    case types.SAVE_NOTE_CART:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              items: state.orders[action.orderIndex].items
                .slice(0, action.orderProductIndex)
                .concat([
                  {
                    ...state.orders[action.orderIndex].items[
                      action.orderProductIndex
                    ],
                    notes: action.note,
                    editMode: action.editMode,
                  },
                ])
                .concat(
                  state.orders[action.orderIndex].items.slice(
                    action.orderProductIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });

    case types.SAVE_NOTE_CHECKOUT:
      // We are replacing a single property in one of the order objects in the orders array
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.index)
          .concat([
            {
              ...state.orders[action.index],
              notes: action.notes,
            },
          ])
          .concat(state.orders.slice(action.index + 1)),
      });

    case types.SAVE_DELIVERY_DAY:
      const index = state.orders.findIndex((order) => order.urlsafe === action.orderUrlsafe);
      // We are replacing a single property in one of the order objects in the orders array
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, index)
          .concat([
            {
              ...state.orders[index],
              deliveryDay: action.deliveryDay,
            },
          ])
          .concat(state.orders.slice(index + 1)),
      });

    case types.TOGGLE_COLLAPSE_CHECKOUT:
    case types.COLLAPSE_ORDER:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.index)
          .concat([
            {
              ...state.orders[action.index],
              collapse: action.collapse,
            },
          ])
          .concat(state.orders.slice(action.index + 1)),
      });

    case types.CHANGE_UNIT_ORDER_PRODUCT:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              items: state.orders[action.orderIndex].items
                .slice(0, action.orderProductIndex)
                .concat([
                  {
                    ...state.orders[action.orderIndex].items[
                      action.orderProductIndex
                    ],
                    urlsafe: action.orderProduct_urlsafe,
                    unitName: action.unitName,
                    unitDescription: action.unitDescription,
                    variantCode: action.variantCode,
                    price: action.price,
                  },
                ])
                .concat(
                  state.orders[action.orderIndex].items.slice(
                    action.orderProductIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });

    case types.SHOW_ORDERS_TOTAL_CHECK:
      return Object.assign({}, state, {
        showOrdersTotal: action.showOrdersTotal,
      });

    case types.CHANGE_SELECTED_DELIVERY_DAY_OPTION_INDEX:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.index)
          .concat([
            {
              ...state.orders[action.index],
              selectedDeliveryDayOptionIndex:
                action.selectedDeliveryDayOptionIndex,
            },
          ])
          .concat(state.orders.slice(action.index + 1)),
      });

    case types.UPDATE_DELIVERY_TIME_WINDOW:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.index)
          .concat([
            {
              ...state.orders[action.index],
              deliveryTimeWindow: action.deliveryTimeWindow,
            },
          ])
          .concat(state.orders.slice(action.index + 1)),
      });

    case types.SHOW_PRE_ORDER_RESTRICTION:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.index)
          .concat([
            {
              ...state.orders[action.index],
              preOrderRestricted: action.preOrderRestricted,
            },
          ])
          .concat(state.orders.slice(action.index + 1)),
      });

    case types.CHANGE_ORDER_POSITION:
      return Object.assign({}, state, {
        // push order to the bottom of the cart
        orders: pushItemToTheEnd(state.orders, action.orderIndex),
      });

    case types.TOGGLE_IS_CHECKOUT_ORDER:
      return {
        ...state,
        orders: state.orders
          .slice(0, action.index)
          .concat([
            {
              ...state.orders[action.index],
              isCheckout: action.isCheckout,
            },
          ])
          .concat(state.orders.slice(action.index + 1)),
      };

    case types.TOGGLE_ORDER_DETAIL_MODAL:
      return {
        ...state,
        isShowOrderDetailModal: action.isShowOrderDetailModal,
      };

    case types.UPDATE_ORDER_DELIVERY_REQUESTS_STATE_ONLY:
      return {
        ...state,
        orders: state.orders
          .slice(0, action.index)
          .concat([
            {
              ...state.orders[action.index],
              deliveryRequests: action.specialDeliveryRequests,
            },
          ])
          .concat(state.orders.slice(action.index + 1)),
      };

    case types.UPDATE_ORIGINAL_QUANTITY:
      return Object.assign({}, state, {
        orders: state.orders
          .slice(0, action.orderIndex)
          .concat([
            {
              ...state.orders[action.orderIndex],
              items: state.orders[action.orderIndex].items
                .slice(0, action.orderProductIndex)
                .concat([
                  {
                    ...state.orders[action.orderIndex].items[
                      action.orderProductIndex
                    ],
                    originalQuantity: action.originalQuantity,
                  },
                ])
                .concat(
                  state.orders[action.orderIndex].items.slice(
                    action.orderProductIndex + 1
                  )
                ),
            },
          ])
          .concat(state.orders.slice(action.orderIndex + 1)),
      });
    case types.EDIT_ORDER_PRODUCT_COMPLETE:
    case types.FETCH_CART_ORDERS_SUCCESS:
      return {
        ...state,
        editOrderProductLoading: false,
      };
    case types.CHANGE_QUANTITY_CART_WITH_ID:
      return {
        ...state,
        editOrderProductLoading: true,
      };

    case types.UPDATE_DELIVERY_DAY_REQUEST:
      return {
        ...state,
        isEditingOrderDeliveryDay: true,
      };
    case types.UPDATE_DELIVERY_DAY_SUCCESS:
    case types.UPDATE_DELIVERY_DAY_FAILED:
      return {
        ...state,
        isEditingOrderDeliveryDay: false,
      };
    case types.SHOW_ORDER_CUSTOM_ITEM_LOADER:
      return {
        ...state,
        customItems: {
          ...state.customItems,
          isLoading: action.isLoading,
        },
      };
    case types.ADD_CUSTOM_ITEM:
      const { urlsafe, product } = action.payload;
      return {
        ...state,
        customItems: {
          ...state.customItems,
          orders: {
            ...state.customItems.orders,
            [urlsafe]: [
              ...(state.customItems.orders[urlsafe] ?? []),
              {
                ...product,
              },
            ],
          },
        },
      };
    case types.SET_ORDER_CUSTOM_ITEM_WITH_INDEX:
      return {
        ...state,
        customItems: {
          ...state.customItems,
          orders: {
            ...state.customItems.orders,
            [action.payload.urlsafe]: [
              ...state.customItems.orders[action.payload.urlsafe].slice(
                0,
                action.payload.index
              ),
              action.payload.product,
              ...state.customItems.orders[action.payload.urlsafe].slice(
                action.payload.index
              ),
            ],
          },
        },
      };
    case types.UPDATE_ORDER_CUSTOM_ITEM_QUANTITY:
      const { quantity, productId } = action.payload;
      return {
        ...state,
        customItems: {
          ...state.customItems,
          orders: {
            ...state.customItems.orders,
            [action.payload.urlsafe]: [
              ...state.customItems.orders[action.payload.urlsafe].map((item) =>
                item.id === productId ? { ...item, quantity } : item
              ),
            ],
          },
        },
      };
    case types.DELETE_ORDER_CUSTOM_ITEM_REQUEST:
      return {
        ...state,
        customItems: {
          ...state.customItems,
          orders: {
            ...state.customItems.orders,
            [action.payload.urlsafe]: [
              ...state.customItems.orders[action.payload.urlsafe].filter(
                (item) => item.id !== action.payload.productId
              ),
            ],
          },
        },
      };

    default:
      return state;
  }
}

// Swap Helper
function pushItemToTheEnd(array, firstIndex) {
  // Constant reference - we can still modify the array itself
  return [
    ...array.slice(0, firstIndex),
    ...array.slice(firstIndex + 1, array.length),
    array[firstIndex],
  ];
}
