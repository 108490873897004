/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import useIsMobileWidth from './useIsMobileWidth';

/**
 *
 * @param Component - The component to wrap
 * @description HOC that wraps the component and adds the isMobileWidth flag as a prop, this is intended to be used in class components only
 */
// eslint-disable-next-line react/display-name
const withUseIsMobileWidth = (Component: any) => (props: any) => {
    const isMobileWidth = useIsMobileWidth();

    return <Component isMobileWidth={isMobileWidth} {...props} />;
};

export default withUseIsMobileWidth;
