import { AccountData } from '@v2/types/AccountData';
import { useSelector } from 'react-redux';
import { RootState } from '..';

const useAccount = (): AccountData => {
    const {
        account,
        org = [],
        isOpenLocationSwitcher = false,
    } = useSelector((store: RootState) => store.accountReducer);

    return {
        ...account,
        org,
        isOpenLocationSwitcher,
    };
};

export default useAccount;
