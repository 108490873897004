import { GetCustomerSupplierMappingsOutput } from '@/ar/network/AccountsReceivable.network';
import { SIGN_UP_SUPPLIER_ID_KEY } from '@/ar/pages/SignUpPage/CustomerSignUpPage';
import { SupplierLoginData } from '@/ar/stores/SupplierStore';

/**
 * Retrieves the supplier ID based off supplier login data
 *
 * @param supplierLoginData supplier login data retrieved from supplier store
 * @param supplierMappingData supplier mapping data based off customer
 * @returns Supplier ID
 */

export const getSupplierID = (supplierLoginData: SupplierLoginData, supplierMappingData: GetCustomerSupplierMappingsOutput): string => {
    const supplierID = supplierLoginData.supplier_id ?? window.localStorage.getItem(SIGN_UP_SUPPLIER_ID_KEY) ?? supplierMappingData?.suppliers[0]?.id;
    return supplierID;
};
