import React from 'react';
import { Modal, onMobileSetLarge, toast } from '@notch-ordering/ui-components';
import { AddCreditCardForm } from '@v2/components/Shared/AddCreditCard/AddCreditCardForm';
import { SupplierData } from '@v2/network/CoreAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { fetchBuyer } from '@/api/DataAPI';

export interface AddCreditCardModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    onClose?: () => void,
    onSuccess?: () => void,
    supplier?: SupplierData,
    bills?: InvoiceData[],
}

const AddCreditCardModal : React.FC<AddCreditCardModalProps> = ({
    isOpen,
    setIsOpen,
    onClose,
    onSuccess,
    supplier,
    bills
}) => {
    const onCloseModal = ():void => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };
    const { buyer } = useBuyerHook();
    return <Modal isOpen={
        isOpen
    }
    title="Add payment information"
    description="Hello! Before using the notch platform, we need your credit card for subscription payments after the trial period. Please provide payment information below."
    modalSize={onMobileSetLarge('MEDIUM')}
    close={onCloseModal} >
        <div className="pt-5 lg:mx-8 mx-4"><AddCreditCardForm
            supplier={supplier}
            bills={bills}
            onSuccess={(): void => {
                toast.show({
                    message: 'Credit card added successfully',
                });
                // Immediately set the ID to something to make the modal stop appearing
                buyer.stripeCustomerID = 'stripeID';
                // Do a fetch from be-core to get the actual ID
                // Note: This ID is no longer used by the client for payments after deprecating Balance
                fetchBuyer().then((response) => {
                    buyer.stripeCustomerID = response.data?.stripeCustomerID;
                    if (onSuccess) {
                        onSuccess();
                    }
                    onCloseModal();
                });
            }} /></div>
    </Modal>;
};

export default AddCreditCardModal;
