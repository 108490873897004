import * as types from '../constants/ActionTypes';
import { toastr } from 'react-redux-toastr';

import * as DataAPI from '../api/DataAPI';
import { logException } from '../domains/shared/logger';

export function receiveConfigParams(data) {
  const {
    application,
    orderConfirmCountdown,
    overdueTermsAPR,
    overdueTermsDuration,
    overdueTermsUnitPercentage,
    stripePubKey,
    version,
  } = data;

  return {
    type: types.RECEIVE_CONFIG_PARAMS,
    application,
    orderConfirmCountdown,
    overdueTermsAPR,
    overdueTermsDuration,
    overdueTermsUnitPercentage,
    stripePubKey,
    version,
  };
}

export function loadAPIConfig() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_API_CONFIG_REQUEST });

      const response = await DataAPI.fetchAPIConfig();
      dispatch({ type: types.FETCH_API_CONFIG_SUCCESS });
      dispatch(receiveConfigParams(response.data));

      return response.data;
    } catch (error) {
      dispatch({ type: types.FETCH_API_CONFIG_FAILED });

      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with loadAPIConfig');
      console.error(error);
      logException(error);
    }
  };
}
