import { PLACE_ORDER_FAILED } from '../constants/ActionTypes';

const initialState = {
  loading: false,
  updatingSidebar: false,
  accountPhoneNumber: '',
  chosenLocation: '',
  deliveryInstructions: '',
  deliveryContactName: '',
  deliveryContactNumber: '',
  checkoutView: 'deliveryView',
  orderDeliveryRequestsView: 'ShowDeliveryRequestsView',
  showChangeUnitModal: false,
  changeUnitProduct: {},
  currentOrder_urlSafe: '',
  isPendingOrders: false,
  showCancelOrderModal: false,
  loadingCancelOrderModal: false,
  cancelOrderUrlsafe: '',
  paymentMethod: '',
  error: false,
  errorType: '',
  errorMessage: [], // Array because we pass html through props in jsx
  placedOrders: [],
  orderToPlaceUrlsafe: '',
  isLoadingToken: false,
  checkoutToken: '',
  checkoutId: '',
  isCheckoutTokenError: false,
  credit: { isLoading: true, amount: 0 },
  checkoutDueDate: '',
  checkoutPaymentTerms: 0,
};

export default function checkoutReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case 'CHANGE_CHECKOUT_VIEW':
      return Object.assign({}, state, {
        checkoutView: action.checkoutView,
      });

    case 'CHANGE_ORDER_DELIVERY_REQUESTS_VIEW':
      return Object.assign({}, state, {
        orderDeliveryRequestsView: action.orderDeliveryRequestsView,
      });

    case 'TOGGLE_CHANGE_UNIT_MODAL_CHECKOUT':
      return Object.assign({}, state, {
        showChangeUnitModal: action.showChangeUnitModal,
        changeUnitProduct: action.changeUnitProduct,
        currentOrder_urlSafe: action.currentOrder_urlSafe,
      });

    case 'TOGGLE_PENDING_ORDER_STATE':
      return Object.assign({}, state, {
        isPendingOrders: action.isPendingOrders,
      });

    case 'CHANGE_BUYER_PHONE_NUMBER':
      return Object.assign({}, state, {
        accountPhoneNumber: action.accountPhoneNumber,
      });

    case 'CHANGE_CHOSEN_LOCATION':
      return Object.assign({}, state, {
        chosenLocation: action.chosenLocation,
        deliveryInstructions: action.deliveryInstructions,
        deliveryContactName: action.deliveryContactName,
        deliveryContactNumber: action.deliveryContactNumber,
      });

    case 'TOGGLE_CANCEL_ORDER_MODAL':
      return Object.assign({}, state, {
        showCancelOrderModal: action.showCancelOrderModal,
        cancelOrderUrlsafe: action.cancelOrderUrlsafe,
      });

    case 'SHOW_LOADER_CANCEL_ORDER_MODAL':
      return Object.assign({}, state, {
        loadingCancelOrderModal: action.loadingCancelOrderModal,
      });

    case 'CHANGE_CHECKOUT_PAYMENT_METHOD':
      return Object.assign({}, state, {
        paymentMethod: action.paymentMethod,
      });

    case 'SHOW_LOADER_CHECKOUT':
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case 'CHECKOUT_ORDERS_PLACED':
      return Object.assign({}, state, {
        placedOrders: action.orders,
      });

    case 'SHOW_LOADER_CHECKOUT_SIDEBAR':
      return Object.assign({}, state, {
        updatingSidebar: action.loading,
      });

    case 'SHOW_ERROR_CHECKOUT':
      return Object.assign({}, state, {
        error: action.error,
        errorType: action.errorType,
        errorMessage: action.errorMessage,
        loading: action.loading,
      });

    case 'REMOVE_ERROR_CHECKOUT':
      return Object.assign({}, state, {
        error: action.error,
        errorType: action.errorType,
        errorMessage: action.errorMessage,
      });

    case 'SET_ORDER_TO_PLACE_URLSAFE':
      return {
        ...state,
        orderToPlaceUrlsafe: action.payload,
      };

    case 'CLEAR_DATA_CHECKOUT':
      return initialState;
    case 'CHECKOUT_GET_TOKEN_PENDING':
      return {
        ...state,
        isLoadingToken: true,
        checkoutToken: '',
        checkoutId: '',
        isCheckoutTokenError: false,
        checkoutPaymentTerms: 0,
        checkoutDueDate: '',
      };

    case 'CHECKOUT_GET_TOKEN_FULFILLED':
      return {
        ...state,
        isLoadingToken: false,
        checkoutToken: payload.token,
        checkoutId: payload.id,
        checkoutPaymentTerms: payload.payment_terms,
        checkoutDueDate: payload.due_date,
      };

    case 'CHECKOUT_GET_TOKEN_FAILURE':
      return {
        ...state,
        isLoadingToken: false,
        isCheckoutTokenError: true,
      };

    case 'INIT_CHECKOUT_TOKEN_STATE':
      return {
        ...state,
        isLoadingToken: false,
        isCheckoutTokenError: false,
        checkoutToken: '',
        checkoutId: '',
      };

    // Note: Credit handling disabled since payments now go through
    // core-api accounts payable (stripe).
    /*
    case 'CREDIT_LOAD_PENDING':
      return {
        ...state,
        credit: { isLoading: true, amount: 0 },
    };

    case 'CREDIT_LOAD_FULFILLED':
      return {
        ...state,
        credit: { isLoading: false, amount: action.payload },
    };
    */

    case PLACE_ORDER_FAILED:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
}
