import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { Transaction } from '@/ar/network/AccountsReceivable.network';

export const isSurchargeEnabled = (transactions: Transaction [], flags?: LDFlagSet, isSupplier?: boolean, isGpo?: boolean): boolean => {
    if (isSupplier && !isGpo && !flags.paymentSurcharges) {
        return false;
    }
    // if found at least one transaction with is_surcharge_enabled
    return !!transactions.find((currentTransaction) => currentTransaction.is_surcharge_enabled);
};
