import axios, { AxiosResponse } from 'axios';

import z from 'zod';
import { isValid, parse } from 'date-fns';
import { throwAxiosError } from '@v2/utils/AxiosUtils';
import { BUSHWHACK_API, BUSHWHACK_API_KEY } from '@/utils/constants';
import { GetAttachmentsResponse, getSupplierToken } from './AccountsReceivable.network';
import { IntegrationTypePath } from '../pages/IntegrationsPage/IntegrationUtils';

export interface RutterConfigurationData {
    arApiToken: string,
    syncExistingArStripeCustomers?: boolean,
    syncPaidInvoices?: boolean,
    platform: ERutterPlatform,
    rutterAccessToken: string,
    rutterConnectionID: string,
    rutterPaymentAccountID?: string,
}

export interface QBOConfigurationData {
    arApiToken: string,
    syncExistingArStripeCustomers?: boolean,
    syncPaidInvoices?: boolean,
    auth: QBOAuth,
    platform?: string,
    paymentAccountID?: string,
    initialSyncComplete?: boolean,
    lastSyncCompletedAt?: string,
}
export interface QBOAuth {
    secret: string,
    securityToken: string,
    redirectURI?: string,
    realmID: string,
    authorizationCode: string,
    accessToken: string,
    refreshToken: string,
}

export interface SupplierConfiguration {
    id: string,
    ownerID: string,
    type: EConfigurationType,
    data: QBOConfigurationData | RutterConfigurationData,
    isEnabled: boolean,
    createdAt: string,
    updatedAt: string,
}

export type AccountingAttachment = {
    id: string,
    platform_id: string,
    attached_to_id: string,
    file_name: string,
    file_url: string,
    attached_to_type: string,
    created_at: string,
};

export interface GetSupplierConfigurationResponse {
    results: SupplierConfiguration[],
    hasNextPage: boolean,
    cursor: string | null,
    total: number,
}

export enum EConfigurationType {
    Fidelio = 'fidelio',
    ApRutter = 'apRutter',
    ArRutter = 'arRutter',
    QBO = 'qbo',
    Workato = 'workato',
    Spire = 'spire',
    BigChip = 'big-chip',
    Sage300 = 'sage-300',
}

export enum ERutterPlatform {
    NETSUITE = 'Netsuite',
    QUICKBOOKS = 'Quickbooks',
    XERO = 'Xero'
}

export type RutterConnectionStatus = {
    connectionID: string | null,
    isReady: boolean,
    isDisabled: boolean,
    disabledReason: string | null,
    lastSyncCompletedAt: string,
    platform: string | null,
};

/**
 * See bushwhackUrl-api qbo.routes.ts for schema source of truth.
 */
export type GetOAuthConnectURLResponse = {
    qb_url: string,
};

/**
 * get supplier configuration from bushwhack
 */
export async function getSupplierConfiguration(supplierID: string): Promise<GetSupplierConfigurationResponse> {
    return axios.get(`${BUSHWHACK_API}/configurations?ownerID=${supplierID}&key=${BUSHWHACK_API_KEY}`).then((response) => response.data);
}

export const ConfigurationRecordSchema = z.object({
    id: z.string().uuid(),
    ownerID: z.string().trim().min(1),
    type: z.nativeEnum(EConfigurationType),
    data: z.record(z.string(), z.unknown()),
    isEnabled: z.boolean().default(true),
});

export type ConfigurationRecord = z.infer<typeof ConfigurationRecordSchema>;

export const FidelioConfigurationDataSchema = z.object({
    url: z.string().url(),
    environmentName: z.string().trim().min(1),
    department: z.string().trim().min(1).optional(),
    pollDelaySeconds: z.number().gt(0).default(900), // Poll every 15 min by default
    arApiToken: z.string().optional(),
});

export type FidelioIntegrationConfigurationPayload = z.infer<typeof FidelioConfigurationDataSchema>;

export type UpdateSupplierConfigurationPayload = {
    data: FidelioIntegrationConfigurationPayload,
    isEnabled: boolean,
    configurationID?: string,
    ownerID?: string,
    type?: EConfigurationType,
};

export const SpireConfigurationDataSchema = z.object({
    url: z.string().url(),
    credentials: z.string().trim().min(1),
    nonce: z.string().trim().min(1),
    arApiToken: z.string().min(1),
    companyName: z.string().trim().min(1).optional(),
    spirePaymentMethodID: z.number().int().gt(0).optional(),
    invoiceSyncStartDate: z.string().refine((arg) => isValid(parse(arg, 'yyyy-MM-dd', new Date()))).optional(),
    pollDelaySeconds: z.number().gt(0).default(900), // Poll every 15 min by default
});
export type SpireConfigurationDataDto = z.infer<typeof SpireConfigurationDataSchema>;

export const AuthenticateSpireConfigurationBodySchema = z.object({
    url: z.string().url(),
    username: z.string().trim().min(1),
    password: z.string().trim().min(1),
    arSupplierID: z.string().uuid(),
    arSupplierToken: z.string().uuid(),
});
export type AuthenticateSpireConfigurationBodyDto = z.infer<typeof AuthenticateSpireConfigurationBodySchema>;

export const AuthenticateSage300BodySchema = z.object({
    url: z.string().url(),
    username: z.string().trim().min(1),
    password: z.string().trim().min(1),
    arSupplierID: z.string().uuid(),
    arSupplierToken: z.string().uuid(),
    invoiceSyncStartDate: z.string().refine((arg) => isValid(parse(arg, 'yyyy-MM-dd', new Date()))).optional(),
    refundRevenueAccount: z.string().trim().min(1).optional(),
    refundBankCode: z.string().trim().min(1).optional(),
    isEnabled: z.boolean().optional().default(true),
});
export type AuthenticateSage300BodyDto = z.infer<typeof AuthenticateSage300BodySchema>;

/**
 * update supplier configuration in bushwhack
 */
export async function updateIntegrationConfiguration(params: UpdateIntegrationParams): Promise<SupplierConfiguration> {
    const payload:UpdateIntegrationParams = {
        ...params,
        data: {
            ...params.data,
            arApiToken: await getSupplierToken(params.ownerID),
        },
    };
    return axios.patch(`${BUSHWHACK_API}/configurations/${params.id}?key=${BUSHWHACK_API_KEY}`, payload).then((response) => response.data);
}

export type UpdateIntegrationParams = ConfigurationRecord & {
    data: FidelioIntegrationConfigurationPayload | SpireConfigurationDataDto,
};
/**
 *
 */
export async function createIntegrationConfiguration(params: UpdateIntegrationParams): Promise<SupplierConfiguration> {
    const payload:UpdateIntegrationParams = {
        ...params,
        data: {
            ...params.data,
            arApiToken: await getSupplierToken(params.ownerID),
        },

    };
    return axios.post(`${BUSHWHACK_API}/configurations?key=${BUSHWHACK_API_KEY}`, payload).then((response) => response.data);
}

export async function authenticateSage300Configuration(params: AuthenticateSage300BodyDto): Promise<SupplierConfiguration> {
    return axios.post(`${BUSHWHACK_API}/sage-300/login?key=${BUSHWHACK_API_KEY}`, params).then((response) => response.data);
}

export const ResyncInvoiceRequestBodySchema = z.object({
    supplierID: z.string().uuid(),
    transactionID: z.string().uuid(),
});
export type ResyncInvoiceRequestParamsBodyDto = z.infer<typeof ResyncInvoiceRequestBodySchema>;

/**
 * Resync invoice from Sage 300
 */
export async function resyncSage300Invoice(payload: ResyncInvoiceRequestParamsBodyDto): Promise<void> {
    return axios.post(`${BUSHWHACK_API}/sage-300/resync-transaction?key=${BUSHWHACK_API_KEY}`, payload).then((response) => response.data);
}

/**
 *
 */
export async function deleteIntegrationConfiguration(integrationID:string): Promise<void> {
    return axios.delete(`${BUSHWHACK_API}/configurations/${integrationID}?key=${BUSHWHACK_API_KEY}`).then((response) => response.data);
}

export interface ExchangeRutterPublicTokenBody {
    rutterToken: string,
    ownerID: string,
    arApiToken: string,
    syncExistingArStripeCustomers?: boolean,
}

export interface ExchangeRutterPublicTokenResponse {
    id: string,
    ownerID: string,
    type: string,
    data: {
        platform: string,
        arApiToken: string,
        rutterAccessToken: string,
        rutterConnectionID: string,
        syncExistingArStripeCustomers: boolean,
    },
    isEnabled: boolean,
    createdAt: string,
    updatedAt: string,
}

/**
 * exchange rutter public_token for access_token
 */
export async function exchangeRutterToken(body: ExchangeRutterPublicTokenBody): Promise<ExchangeRutterPublicTokenResponse> {
    return axios.post(`${BUSHWHACK_API}/rutter/ar/exchangeToken`, {
        ...body,
    }).then((response) => response.data);
}

export type AccountType = 'accounts_payable' | 'accounts_receivable' | 'bank' | 'fixed_asset' | 'other_asset' |
'other_current_asset' | 'liability' | 'equity' | 'expense' | 'other_expense' | 'income' | 'other_income' | 'credit_card' |
'cost_of_goods_sold' | 'other_current_liability' | 'long_term_liability' | 'non_posting' | 'unknown';

export type AccountingGlAccountCategory = 'asset' | 'expense' | 'liability' | 'income' | 'nonposting' | 'unknown';

export type AccountingGlAccountStatus = 'active' | 'inactive' | 'pending';

export type AccountingGlAccount = {
    id: string,
    platformID: string,
    accountType: AccountType,
    category: AccountingGlAccountCategory,
    status: AccountingGlAccountStatus,
    balance: number | null,
    currencyCode: string | null,
    name: string | null,
    nominalCode: string | null,
    subsidiaries: Array<{
        id: string,
    }>,
    createdAt: string | null,
    updatedAt: string | null,
};

export interface GetGlAccountsResponse {
    accounts: AccountingGlAccount[],
}

/**
 * get the accounts associated with the connection
 */
export async function getAccountingGlAccounts(supplierID: string, accountingType: IntegrationTypePath, filterByAccountType?: Set<AccountType>): Promise<GetGlAccountsResponse> {
    const data = await axios.get(`${BUSHWHACK_API}/${accountingType}/accounts`, {
        params: {
            ownerID: supplierID,
            key: BUSHWHACK_API_KEY
        }
    }).then((response) => response.data);
    if (filterByAccountType) {
        data.accounts = data.accounts.filter((account) => filterByAccountType.has(account.accountType));
    }

    return data;
}

/**
 * Spire
 */

/**
 *
 */
export async function spireLogin(params: AuthenticateSpireConfigurationBodyDto): Promise<UpdateIntegrationParams> {
    return axios.post(`${BUSHWHACK_API}/spire/login?key=${BUSHWHACK_API_KEY}`, params).then((response) => response.data);
}

export type SpireCompany = {
    name: string,
    description: string,
    url: string,
    isValid: boolean,
};

export type SpireCompaniesResponse = {
    companies: SpireCompany[],
};
/**
 *
 */
export async function getSpireCompanies(supplierID: string): Promise<SpireCompaniesResponse> {
    return axios.get(`${BUSHWHACK_API}/spire/companies`, {
        params: {
            key: BUSHWHACK_API_KEY,
            supplierID,
        },
    }).then((response) => response.data);
}

export type SpirePaymentMethod = {
    id: number,
    description: string,
};

export type SpirePaymentMethodsResponse = {
    paymentMethods: SpirePaymentMethod[],
};
export type SpireCompanyPaymentMethodParams = {
    supplierID: string,
    companyName: string,
    enabled?: boolean,
};
/**
 *
 */
export async function getSpireCompanyPaymentMethods(params:SpireCompanyPaymentMethodParams): Promise<SpirePaymentMethodsResponse> {
    const { supplierID, companyName } = params;
    return axios.get(`${BUSHWHACK_API}/spire/paymentMethods`, {
        params: {
            key: BUSHWHACK_API_KEY,
            supplierID,
            companyName,
        },
    }).then((response) => response.data);
}

export async function getConnectionStatus(configurationID: string, integrationType: IntegrationTypePath): Promise<RutterConnectionStatus> {
    const url: string = `${BUSHWHACK_API}/${integrationType}/ar/connection`;

    return axios.get(url, { params: { configurationID, key: BUSHWHACK_API_KEY } }).then((response) => response.data);
}

export async function connectToQBO(supplierUrlsafeKey: string, arApiToken: string, redirectURI?: string): Promise<GetOAuthConnectURLResponse> {
    const url: string = `${BUSHWHACK_API}/integrations/quickbooks/oauth`;

    const response: AxiosResponse<GetOAuthConnectURLResponse> = await axios.get(url, {
        params: {
            ownerId: supplierUrlsafeKey,
            arApiToken,
            ...(redirectURI && { redirectURI }),
            key: BUSHWHACK_API_KEY
        }
    })
        .catch((e) => throwAxiosError(e));

    return response.data;
}

/**
 * Get attachments manually uploaded in QBO native/Rutter and Rutter invoice PDF link
 */
export async function getInvoiceAttachments(invoiceID: string, configID: string, integrationType: string): Promise<GetAttachmentsResponse> {
    if (!configID || !invoiceID) {
        return {
            attachments: [],
        };
    }

    const response = await axios.get<GetAttachmentsResponse>(`${BUSHWHACK_API}/${integrationType}/ar/invoices/${invoiceID}/attachments`, { params: { configurationID: configID, key: BUSHWHACK_API_KEY, } });
    const attachments = response?.data?.attachments;

    if (integrationType === 'rutter') {
        // There is a bug with Rutter where the file extension isn't returned, so in that case we assume it is a .pdf file
        return {
            ...response.data,
            attachments: attachments.filter((attachment) => attachment.file_name.endsWith('pdf') || !attachment.file_name.includes('.'))
        };
    }

    return response.data;
}

/**
 * Downloads QBO native/Rutter manually attached PDFs.
 */
export async function downloadAttachmentPDF(invoiceID: string, attachment: AccountingAttachment, configID: string, integrationType: EConfigurationType): Promise<void> {
    let bushwhackDownloadAttachmentURL;

    if (integrationType === EConfigurationType.ArRutter) {
        bushwhackDownloadAttachmentURL = `${BUSHWHACK_API}/rutter/ar/invoices/${invoiceID}/attachments/${attachment.id}/file`;
    } else if (integrationType === EConfigurationType.QBO) {
        bushwhackDownloadAttachmentURL = `${BUSHWHACK_API}/integrations/quickbooks/attachment/${attachment.id}/file`;
    } else {
        console.log('Cannot download pdf because integration type is not handled.');
        return;
    }

    const response = await axios.get(
        bushwhackDownloadAttachmentURL,
        { params: { configurationID: configID, key: BUSHWHACK_API_KEY, }, responseType: 'blob' }
    );

    const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

    // Download the file to the computer
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = attachment.file_name;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

/**
 * Downloads QBO native invoice PDF.
 */
export async function getQboInvoicePDF(configurationID: string, invoiceID: string, invoiceNumber: string): Promise<void> {
    const url: string = `${BUSHWHACK_API}/integrations/quickbooks/invoices/${invoiceID}/pdf`;

    const response = await axios.get(url, { params: { configurationID, key: BUSHWHACK_API_KEY }, responseType: 'blob' });

    const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

    // Download the file to the computer
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = invoiceNumber;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
