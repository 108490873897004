/* eslint-disable no-param-reassign */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import * as DataAPI from './DataAPI';
import { logException } from '../domains/shared/logger';
import { isValid } from '@v2/utils/isValid';
import { v4 as uuidv4 } from 'uuid';



let refreshPromise = null;

// Config file.
let _config;

// Check if a valid user session exists with a sessionId and refreshToken
function testIsSessionAvailable(config = {}) {
  const { sessionId, refreshToken, sessionExpiry } = config;

  if (!sessionId || !refreshToken || !sessionExpiry) {
    return false;
  }
  // If session expired, then reload
  if (moment.unix(sessionExpiry - 60).isBefore(moment())) {
    return false;
  }

  return true;
}

// Check if an access token already exists and has not expired
function testIsAccessTokenValid(config) {
  if (!config.accessToken) {
    return false;
  }

  // Wrap in try/catch because jwtDecode throws exception on bad token
  try {
    const { exp } = jwtDecode(config.accessToken);

    if (moment.unix(exp - 60).isBefore(moment())) {
      return false;
    }
  } catch (error) {
    logException(error);
    console.error(error);
    console.error('Error occurred in testIsAccessTokenValid');
    return false;
  }

  return true;
}

// If the session has expired, a new session is requested from the server and a new
// access token is returned.  For multiple request within a promise, only one request
// is made to start a new session and the refreshPromise parameter is checked
// after that to avoid starting another session everytime
const refreshAccessToken = async (config) => {
  try {
    if (!testIsSessionAvailable(config)) {
      // TODO: possible race condition, investigate how to remove reload
      window.location.reload();
      return;
    }

    if (!refreshPromise) {
      refreshPromise = DataAPI.refreshSession(
        config.sessionId,
        config.refreshToken
      );
    }

    const response = await refreshPromise;

    if (response && response.data) {
      config.accessToken = response.data.accessToken;

      // Assign sessionKey, refreshToken and session expirty to localStorage
      // Assign accessToken to global window variable.
      window.localStorage.setItem('chefhero_sk', response.data.urlsafe);
      window.localStorage.setItem('chefhero_rt', response.data.refreshToken);
      window.localStorage.setItem('chefhero_e', response.data.expiresAt);
      window.__accessToken__ = response.data.accessToken;
    }
  } catch (error) {
    // When an error occurs, remove all session info from local storage and redirect to signin
    window.localStorage.removeItem('chefhero_sk');
    window.localStorage.removeItem('chefhero_rt');
    window.localStorage.removeItem('chefhero_e');

    // TODO: possible race condition, investigate how to remove reload
    window.location.reload();

    logException(error);
    throw error;
  } finally {
    refreshPromise = null;
  }
};

// Retrieve the access token
export async function getAccessToken(config) {
  try {
    // If the session exists and hasn't expired, use existing access token
    if (testIsAccessTokenValid(config)) {
      return config.accessToken;
    }

    // If the session exists and has expired, re-generate the access token using
    // the sessionId and refreshToken
    await refreshAccessToken(config);
    return config.accessToken;
  } catch (error) {
    logException(error);
    throw error;
  }
};

export async function getAPIConfig() {
  if (!isValid(_config)) {
    _config = await requestAuthInterceptor();
  }
  
  return _config;
}

// Add access token to all request headers (Authorization Bearer)
const requestAuthInterceptor = async (config) => {
  try {
    const sessionId = window.localStorage.getItem('chefhero_sk');
    const refreshToken = window.localStorage.getItem('chefhero_rt');
    const sessionExpiry = window.localStorage.getItem('chefhero_e');
    const accessToken = window.__accessToken__;

    config = { ...config, sessionId, sessionExpiry, refreshToken, accessToken };

    _config = config;

    if (config.isAuthed && config.headers && config.headers.common) {
      const accessToken = await getAccessToken(config);
      config.headers.common.Authorization = `Bearer ${accessToken}`;
      config.params = {
        buyerKey: config.buyerKey,
        legacyBuyerId: config.legacyBuyerId,
        legacyVendorId: config.legacyVendorId,
      };

      if (config.isCloudTrace) {
        config.headers.common['X-Cloud-Trace-Context'] = uuidv4() + '/0;o=1';
      }
    }

    if (config.isSelfOnBoardUser) {
      config.headers['CH-SELF-ONBOARD-USER-WITH-SESSION'] = 'ChefHero';
    }
  } catch (error) {
    logException(error);
    console.error(error);
    console.error('Error occurred in requestAuthInterceptor');
  } finally {
    // We need to return config for API calls to continue working
    // Just throwing an error will cause all endpoints to fail.
    return config;
  }
};

// Ensure that axios can Retry requests if specified to do so.
// Also enable retryDelay (should be exponential TODO)
const responseRetryInterceptor = (err) => {
  const config = err.config;
  // If config does not exist or the retry option is not set or we are in a test env, reject
  if (!config || !config.retry || window.__TEST__) return Promise.reject(err);

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err);
  }

  // Increase the retry count
  config.__retryCount += 1;

  // Create new promise to handle exponential backoff. formula (2^c - 1 / 2) * 1000(for mS to seconds)
  const backOffDelay = config.retryDelay
    ? (1 / 2) * (Math.pow(2, config.__retryCount) - 1) * 1000
    : 1;

  const backoff = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, backOffDelay);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(() => {
    return axios(config);
  });
};

const client = () => {
  // For testing, we want to mock the API call without interceptors
  // (We will test those separately).
  // Also, creating an instance of axios gives Jest problems running tests.
  if (window.__TEST__) {
    return axios;
  } else {
    const instance = axios.create();
    instance.interceptors.request.use(requestAuthInterceptor);
    instance.interceptors.response.use(undefined, responseRetryInterceptor);
    instance.interceptors.response.use(undefined, async (error) => {
      const { response, config } = error;

      // If we have no response, then request was most likely cancelled.
      if (response === undefined) return Promise.reject(error);

      // if the response has the 401 status code, then refresh the token, it will retry to load the previous request
      if (
        response.status === 401 &&
        !config._retry &&
        config.isAuthed &&
        config.headers &&
        config.headers.Authorization
      ) {
        config._retry = true;
        await refreshAccessToken(config);
        config.headers.Authorization = `Bearer ${config.accessToken}`;
        return instance(config);
      }
      return Promise.reject(error);
    });

    return instance;
  }
};

export default client();
