const initialState = {
  application: '',
  orderConfirmCountdown: 0,
  overdueTermsAPR: '',
  overdueTermsDuration: '',
  overdueTermsUnitPercentage: '',
  stripePubKey: null,
  version: '',
};

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_CONFIG_PARAMS':
      const {
        application,
        orderConfirmCountdown,
        overdueTermsAPR,
        overdueTermsDuration,
        overdueTermsUnitPercentage,
        stripePubKey,
        version,
      } = action;

      return {
        ...state,
        application,
        orderConfirmCountdown,
        overdueTermsAPR,
        overdueTermsDuration,
        overdueTermsUnitPercentage,
        stripePubKey,
        version,
      };

    default:
      return state;
  }
}
