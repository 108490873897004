import {
  GET_CHAT_TOKEN,
  GET_CHAT_TOKEN_SUCCESS,
  GET_CHAT_TOKEN_FAILED,
  REFRESH_CHAT_TOKEN,
  REFRESH_CHAT_TOKEN_SUCCESS,
  REFRESH_CHAT_TOKEN_FAILED,
  UPDATE_CHAT_STATUS,
  SEND_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_SUCCESS,
  SEND_CHAT_MESSAGE_FAILED,
  SHOW_CHAT_UI,
  SET_OPENING_SOURCE,
  SET_MP_CHAT_ACCESS_LIST,
} from '../actions/chatAuthActions';

import {
  CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE,
  CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_SUCCESS,
  CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_FAILED,
} from '../actions/chatSelectedActions';

export const initialState = {
  identity: '',
  name: '',
  displayName: '',
  buyerKey: '',
  accessTokenChat: '',
  chatStatus: '',
  isChatReady: false,
  isLoading: false,
  isRefreshing: false,
  isSendingMessage: false,
  openingSource: 'navigationBar',
  accessListMPChat: [],
  isCreatingChannel: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE:
      return {
        ...state,
        isCreatingChannel: true,
      };

    case CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_SUCCESS:
    case CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_FAILED:
      return {
        ...state,
        isCreatingChannel: false,
      };

    case GET_CHAT_TOKEN:
      return {
        ...state,
        isLoading: true,
      };

    case SHOW_CHAT_UI:
      return {
        ...state,
        isChatReady: true,
      };

    case GET_CHAT_TOKEN_SUCCESS:
      return {
        ...state,
        identity: action.identity,
        name: action.name,
        displayName: action.displayName,
        buyerKey: action.buyerKey,
        accessTokenChat: action.accessTokenChat,
        isLoading: false,
      };

    case UPDATE_CHAT_STATUS:
      return {
        ...state,
        chatStatus: action.chatStatus,
      };

    case REFRESH_CHAT_TOKEN:
      return {
        ...state,
        isRefreshing: true,
      };

    case REFRESH_CHAT_TOKEN_SUCCESS:
      return {
        ...state,
        identity: action.identity,
        accessTokenChat: action.accessTokenChat,
        isRefreshing: false,
      };

    case SEND_CHAT_MESSAGE:
      return {
        ...state,
        isSendingMessage: true,
      };

    case SEND_CHAT_MESSAGE_SUCCESS:
    case SEND_CHAT_MESSAGE_FAILED:
      return {
        ...state,
        isSendingMessage: false,
      };

    case GET_CHAT_TOKEN_FAILED:
    case REFRESH_CHAT_TOKEN_FAILED:
      return {
        ...state,
        isLoading: false,
        isRefreshing: false,
      };

    case SET_OPENING_SOURCE:
      return {
        ...state,
        openingSource: action.openingSource,
      };

    case SET_MP_CHAT_ACCESS_LIST:
      return {
        ...state,
        accessListMPChat: action.accessListMPChat,
      };

    default:
      return state;
  }
};
