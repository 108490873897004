import {
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILED,
  GET_CUSTOM_ITEMS_SUCCESS,
  GET_CUSTOM_ITEMS_FAILED,
} from '../types';
import { IS_LOADING_APP_INIT } from '../../../constants/ActionTypes';

export const initialState = {
  loading: false,
  data: {},
  customItems: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING_APP_INIT:
      return {
        ...state,
        loading: action.isLoadingAppInit,
      };

    case GET_ORDER_DETAILS_START:
      return {
        ...state,
        loading: true,
      };

    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_ORDER_DETAILS_FAILED:
      return {
        ...initialState,
      };

    case GET_CUSTOM_ITEMS_SUCCESS:
      return {
        ...state,
        customItems: action.payload,
      };

    case GET_CUSTOM_ITEMS_FAILED:
      return {
        ...state,
        customItems: [],
      };

    default:
      return state;
  }
};
