import moment from 'moment';
import { batch } from 'react-redux';

import {
  addChatChannels,
  removeChatChannel,
  updateChatChannel,
} from '../actions/chatChannelsListActions';
import {
  addChatMessages,
  appendChatMessages,
  removeChatMessage,
} from '../actions/chatMessagesActions';
import { CHAT_MESSAGES_HISTORY_LIMIT } from '../../../constants/GlobalConstants';
import Utils from '../../../utils';
import { logMessage, logException } from '../../../domains/shared/logger';
import { downloadAccessListForMPChatSupplier } from '../../../api/DataAPI';
import { setMPChatAccessList } from '../actions/chatAuthActions';

/**
 * A method to add a Chat channel and its messages to store
 *
 * @param {Object} channel A Chat channel object
 * @param {Function} dispatch A redux dispatch function
 */
export const addChatChannelWithMessages = async (
  buyerKey,
  channel,
  dispatch
) => {
  try {
  // may throw error for invalid chat channel or expired twilio token
  const channelMembers = await getChannelMembers(channel);

  // may throw error
  const messages = await readChannelMessagesPaginator(
    // may throw error
    await channel.getMessages(CHAT_MESSAGES_HISTORY_LIMIT)
  ); // Reads Chat messages

  const {
    state: { attributes: { buyer_id: buyerKeyFromChat = '' } = {} },
  } = channel;
  if (buyerKeyFromChat && buyerKeyFromChat !== buyerKey) return; // Return if Chat channel is from different buyer

  channel.on('messageAdded', messageAdded(channel.sid, dispatch)); // An event to know when new Chat message is added to the Chat channel

  batch(() => {
    dispatch(addChatChannels([channel]));
    dispatch(addChatMessages(channel, messages, channelMembers));
  });
} catch(err) {
  logException(err);
}
};

/**
 * A method to remoave  Chat channel and its messages from store
 *
 * @param {Object} channel A Chat channel object
 * @param {Function} dispatch A redux dispatch function
 */
export const removeChatChannelWithMessages = async (
  buyerKey,
  channel,
  dispatch
) => {
  const { sid = '' } = channel;

  const {
    state: { attributes: { buyer_id: buyerKeyFromChat = '' } = {} },
  } = channel;
  if (buyerKeyFromChat && buyerKeyFromChat !== buyerKey) return; // Return if Chat channel is from different buyer

  batch(() => {
    dispatch(removeChatChannel(sid));
    dispatch(removeChatMessage(sid));
  });
};

/**
 * A method to update  Chat channel in store
 *
 * @param {Object} channel A Chat channel object
 * @param {Function} dispatch A redux dispatch function
 */
export const updateChatChannelAndUnreadCount = async (
  buyerKey,
  channel,
  dispatch
) => {
  const channelMembers = await getChannelMembers(channel);
  dispatch(updateChatChannel(channel, channelMembers));
};

/**
 * A method to receive Chat messages
 *
 * @param {string} sid Channel unique sid
 * @param {Function} dispatch A redux dispatch function
 */
const messageAdded = (sid, dispatch) => (message) =>
  dispatch(appendChatMessages(sid, [message]));

/**
 * Reads the Chat channel messages (no more than CHAT_MESSAGES_HISTORY_LIMIT)
 *
 * @param {Object} pageinator A Chat channel message object
 */
export const readChannelMessagesPaginator = async (pageinator) => {
  if (pageinator && pageinator.items) {
    return pageinator.items;
  }
};

/**
 * Prepare the Chat messages by reading metadata info when required
 *
 * @param {Array} messages Array of Chat messages
 */
export const prepareMessages = async (messages = []) =>
  Promise.all(
    messages.map(async (message) => {
      if (message.type === 'media') {
        const chatImageUrl = await message.media.getContentUrl();
        const chatMessage = message;

        try {
          // As Twilio Chat media message url is temporary (valid for 300 seconds only)
          // We are here reading the dataUrl of image and storing locally in store.
          const req = await fetch(chatImageUrl);
          const blob = await req.blob();
          const reader = new FileReader();
          chatMessage.chatImageUrl = await new Promise((resolve, reject) => {
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (e) => reject(logException(e));
          });
        } catch (error) {
          logMessage(`Error occurred while retrieving chat image data url`);
          logException(error);
        }

        return chatMessage;
      }
      return message;
    })
  );

/**
 * Sets all messages in Chat channel as read by user.
 *
 * @param {Object} channel A Chat channel
 */
export const setMessagesConsumed = async (channel) => {
  await channel.setAllMessagesConsumed();
};

/**
 * A method to find unread messages
 *
 * @param {Array} channels Array of Chat channels
 */
export const calculateUnreadChatMessages = (channels = {}) => {
  if (Object.keys(channels).length === 0) return 0;

  let unreadMessagesCount = 0;

  // Iterates through each channel and finds how many messages are not yet read by the user
  Object.entries(channels).forEach(([sid, { channel, channelAttributes }]) => {

    if(channelAttributes?.vendorName){
      const {
      state: {
        lastMessage: { index = 0 } = {},
        lastConsumedMessageIndex = 0,
      } = {},
    } = channel || {};
    unreadMessagesCount += index - lastConsumedMessageIndex;}
  });

  return unreadMessagesCount;
};

/**
 * Sort method to arrage Chat channel list
 *
 * @param {Object} a First Chat channel object
 * @param {Object} b Second Chat channel object
 */
export const sortChannelListHandler = (a, b) => {
  const {
    channel: {
      state: { lastMessage: { timestamp: aLastUpdated = '' } = {} } = {},
    } = {},
  } = a[1] || {};
  const {
    channel: {
      state: { lastMessage: { timestamp: bLastUpdated = '' } = {} } = {},
    } = {},
  } = b[1] || {};

  return bLastUpdated - aLastUpdated;
};

/**
 * Sort method to arrage Chat message
 *
 * @param {Object} a First Chat message object
 * @param {Object} b Second Chat message object
 */
export const sortSelectedBodyHandler = (a, b) => {
  const {
    state: { index: aIndex },
  } = a;
  const {
    state: { index: bIndex },
  } = b;

  return aIndex - bIndex;
};

/**
 * Returns DateTime format expected by Chat channel list
 *
 * @param {DateTime} datetime A DateTime object
 */
export const formatDateTimePerChatChannelList = (datetime) => {
  if (!datetime) return '';

  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const engagementDate = Utils.getMomentDateFromJsDate(datetime);

  if (engagementDate.isSame(today, 'day')) return 'Today';
  if (engagementDate.isSame(yesterday, 'day')) return 'Yesterday';
  if (engagementDate.isBefore(today, 'year'))
    return engagementDate.format('MMM D YYYY'); // example: May 19 2019

  return engagementDate.format('MMM D'); // example: May 19
};

/**
 * Returns DateTime format expected by Chat channel Order Status
 *
 * @param {DateTime} datetime A DateTime object
 */
export const formatDateTimePerChatChannelOrderStatus = (datetime) => {
  if (!datetime) return '';

  const today = moment();
  const engagementDate = Utils.getMomentDateFromJsDate(datetime, false);

  if (engagementDate.isSame(today, 'day')) return 'Today';
  if (engagementDate.isBefore(today, 'year'))
    return engagementDate.format('MMM D'); // example: May 19

  return engagementDate.format('MMM D'); // example: May 19
};

/**
 * Returns DateTime format expected by Chat message
 *
 * @param {DateTime} datetime A DateTime object
 */
export const formatDateTimePerChat = (datetime) => {
  if (!datetime) return '';

  const today = moment();
  const engagementDate = Utils.getMomentDateFromJsDate(datetime);

  if (engagementDate.isSame(today, 'day'))
    return engagementDate.format('hh:mm a'); // 02:07 pm
  if (engagementDate.isBefore(today, 'year'))
    return engagementDate.format('MMM D YYYY'); // example: May 19 2019

  return engagementDate.format('MMM D'); // example: May 19
};

export const downloadMPChatAccessList = async (
  dispatch,
  chatAllowAllMarketVendors
) => {
  try {
    if (chatAllowAllMarketVendors) {
      return;
    }

    const response = await downloadAccessListForMPChatSupplier();

    if (response && response.data) {
      const { vendors: vendorUrlsafes } = response.data;
      dispatch(setMPChatAccessList(vendorUrlsafes));
    }
  } catch (error) {
    logException(error);
    return [];
  }
};

export const getChannelMembers = async (channel) => {
  const channelMembers = {};
  try {
    const members = await channel.getUserDescriptors();
    members.items.forEach(({ identity, friendlyName }) => {
      channelMembers[identity] = friendlyName || identity;
    });
    return channelMembers;
  } catch (error) {
    logException(error);
    return channelMembers;
  }
};

/**
 * Sort method to arrage Chat channel member
 *
 * @param {Object} a First Chat channel member
 * @param {Object} b Second Chat channel member
 */
export const sortChannelMembers = (a, b) => {
  const [, name1 = ''] = a;
  const [, name2 = ''] = b;

  const first = name1.toLowerCase();
  const second = name2.toLowerCase();

  if (first < second) {
    return -1;
  }
  if (first > second) {
    return 1;
  }
  return 0;
};
