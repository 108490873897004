import { sendRequest } from '@v2/network/APIClient';
import { IActiveFilters } from '@v2/Pages/Market/MarketConstants';
import { throwAxiosError } from '@v2/utils/AxiosUtils';
import { encodeQueryParams, removeEmptyValuesFromParamsObject } from '@v2/utils/NetworkUtils';
import axios, { AxiosResponse } from 'axios';
import { networkErrorHandler } from '@v2/network/NetworkHelper';
import { TValidateImportInfo } from '@v2/stores/BulkUploadStore';
import { GetOrderGuidesResponse, IFavoriteResponse, IPagination, IProductResponse, OrderGuide } from './types';
import { SupplierData } from './CoreAPI';

const { secretShopUrl } = process.env.BASE_URLS || {};

export type ProductData = {
    name: string,
    description?: string,
    taxCode?: string,
    category: string,
    packages: Array<CatalogPackageData>,
    id?: string,
};

export type CatalogPackageData = {
    id?: string,
    availability?: string,
    description?: string,
    sku: string,
    unit?: string,
    quantity?: number,
    prices?: CatalogPriceData[],
};

export type CatalogPriceData = {
    id?: string,
    pricePerUnit: number,
    priceListId?: string,
    available?: boolean,
};

export type CategoriesData = {
    categories: string[],
};

/**
 * Type referenced from secret-shop-api categories.requests.ts
 */
export type CategoryID = { id: string, name: string };

/**
 * Type referenced from secret-shop-api categories.requests.ts
 */
export type CategoryIDsData = {
    categoryIDs: Array<CategoryID>,
};

export type UnitsData = {
    units: string[],
};

export type SupplierProductsData = {
    results: ProductData[],
};

export type SupplierCatalogData = {
    id: string,
    supplierUrlsafeKey: string,
    canControlSupplierCatalog: boolean,
    productCount: number,
    priceListCount: number,
    priceListID: number,
    supplierName: string,
    isOffline: boolean,
};

export type LinkedSupplierCatalogsData = {
    supplierCatalogs: SupplierCatalogData[],
};

export type SupplierCatalogsResponse = {
    results: SupplierCatalogData[],
} & IPagination;

export type SavedProductData = { buyerUrlsafeKey: string, productID?: string, productIDs?: string[], showErrorToaster?: boolean };
export type SavedProductToOrderGuideData = SavedProductData & { orderGuideID: string };
export type MutateOrderGuideData = { buyerUrlsafeKey: string, name?: string, orderGuideID?: string };

export type UpdateProductData = { data: ProductData, supplierCatalogId: string };
export type UpdateProductPackageData = { pack: CatalogPackageData, productID:string, supplierCatalogID: string };
export type UpdateProductPackagePriceData = { packageID: string, productID:string, supplierCatalogID: string, priceID: string, pricePerUnit: number };

export type DeleteProductPackageData = { pack: CatalogPackageData, productID:string, supplierCatalogID: string };

export type LegacyCustomProduct = {
    id?: string,
    orderUrlsafeKey?: string,
    name: string,
    externalRef: string,
    packaging: string,
    price: number,
    quantity: number,
};

export type LegacyCustomProductsMap = {
    [orderUrlsafeKey: string] : LegacyCustomProduct[],
};

export type CustomProduct = {
    id?: string,
    orderUrlsafeKey: string,
    buyerUrlsafeKey: string,
    productName: string,
    packageSku: string,
    packageDescription: string,
    pricePerUnit: number,
    quantity: number,
};

export type BuyerCatalogSearch = {
    savedProductsOnly?: boolean,
    inStockOnly?: boolean,
    supplierCatalogs?: string[],
    supplierUrlsafeKeys?: string[],
    categories?: string[],
    name?: string,
    packageIDs?: string[],
    orderGuideID?: string,
};

/**
 * Adds a single new product to a supplier's catalog
 *
 * @param data - The product data
 * @param supplierCatalogId - The supplier's ID
 */
export async function createProduct(data: ProductData, supplierCatalogId: string): Promise<ProductData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogId}/products`;
    const config: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return sendRequest(endpoint, config, true, false);
}

/**
 * Updates an existing product in a supplier's catalog
 *
 * @param data - The product data
 * @param supplierCatalogId - The supplier's ID
 */
export async function updateProduct({
    data,
    supplierCatalogId
}: UpdateProductData): Promise<ProductData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogId}/products/${data.id}`;
    const config: RequestInit = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return sendRequest(endpoint, config, true, false);
}

/**
 * Deletes a product from a catalog
 *
 * @param productId - ID of the product to delete
 * @param supplierCatalogId - ID of the catalog to remove the product from
 */
export async function deleteProduct(productId: string, supplierCatalogId: string): Promise<void> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogId}/products/${productId}`;
    return sendRequest(endpoint, { method: 'DELETE' }, true, false);
}

/**
 * Deletes multiple products from a catalog
 *
 * @param productIDs - Array of IDs of the products to delete
 * @param supplierCatalogID - ID of the catalog to remove the products from
 */
export async function deleteProducts(productIDs: string[], supplierCatalogID: string): Promise<void> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/products`;
    const config: RequestInit = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productIDs })
    };
    return sendRequest(endpoint, config, true, false);
}

/**
 * Deletes a package from a product
 *
 * @param pack
 * @param productId - ID of the product to delete
 * @param supplierCatalogId - ID of the catalog to remove the product from
 */
export async function deleteProductPackage({ pack, productID, supplierCatalogID }: DeleteProductPackageData): Promise<void> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/products/${productID}/packages/${pack.id}`;
    const config: RequestInit = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pack)
    };
    return sendRequest(endpoint, config, true, false);
}

/**
 * Deletes a custom product from a catalog.
 *
 * @param buyerUrlsafeKey - ID of the buyer that created the custom product
 * @param customProductID - ID of the product to delete
 */
export async function deleteCustomProduct(buyerUrlsafeKey: string, customProductID: string): Promise<void> {
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/customProducts/${customProductID}`;
    const response: AxiosResponse<void> = await axios.delete(url)
        .catch((e) => throwAxiosError(e, 'Could not delete product'));

    return response.data;
}

/**
 * Update the quantity of a custom product from a catalog.
 *
 * @param buyerUrlsafeKey - ID of the buyer that created the custom product
 * @param customProductID - ID of the product to update
 * @param quantity - Custom product quantity update
 */
export async function updateCustomProduct(buyerUrlsafeKey: string, customProductID: string, quantity: number): Promise<CustomProduct> {
    const body: { quantity: number } = { quantity };
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/customProducts/${customProductID}`;
    const response: AxiosResponse<CustomProduct> = await axios.patch(url, body)
        .catch((e) => throwAxiosError(e, 'Could not update product'));

    return response.data;
}

/**
 * Create a custom product.
 *
 * @param buyerUrlsafeKey - ID of the buyer that created the custom product
 * @param orderUrlsafeKey - ID of order
 * @param data - Custom product data
 */
export async function createCustomProduct(customProduct: LegacyCustomProduct, buyerUrlsafeKey: string, orderUrlsafeKey: string): Promise<LegacyCustomProduct> {
    const data: CustomProduct = {
        orderUrlsafeKey,
        buyerUrlsafeKey,
        productName: customProduct.name,
        packageSku: customProduct.externalRef,
        packageDescription: customProduct.packaging,
        pricePerUnit: customProduct.price,
        quantity: customProduct.quantity
    };

    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/customProducts`;
    const response: AxiosResponse<CustomProduct> = await axios.post(url, data)
        .catch((e) => throwAxiosError(e, 'Could not create product'));

    const product: CustomProduct = response.data;

    const legacyProduct: LegacyCustomProduct = {
        id: product.id,
        name: product.productName,
        externalRef: product.packageSku,
        packaging: product.packageDescription,
        price: product.pricePerUnit,
        quantity: product.quantity,
    };

    return legacyProduct;
}

/**
 * Retrieves the list of custom products for an order.
 *
 * @param buyerUrlsafeKey - ID of the buyer that created the custom product
 * @param orderUrlsafeKey - ID of order
 */
export async function listCustomProducts(buyerUrlsafeKey: string, orderUrlsafeKeys: string[]): Promise<LegacyCustomProduct[]> {
    const data: { orderUrlsafeKeys: string[] } = { orderUrlsafeKeys };
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/customProducts/search`;
    const response: AxiosResponse<{ customProducts: CustomProduct[] }> = await axios.post(url, data)
        .catch((e) => throwAxiosError(e, 'Could not get products'));

    const products: CustomProduct[] = response.data.customProducts;
    const legacyProducts: LegacyCustomProduct[] = [];

    for (let i: number = 0; i < products.length; ++i) {
        const product: CustomProduct = products[i];
        const legacyProduct: LegacyCustomProduct = {
            id: product.id,
            orderUrlsafeKey: product.orderUrlsafeKey,
            name: product.productName,
            externalRef: product.packageSku,
            packaging: product.packageDescription,
            price: product.pricePerUnit,
            quantity: product.quantity,
        };
        legacyProducts.push(legacyProduct);
    }

    return legacyProducts;
}

export async function listCustomProductsMap(buyerUrlsafeKey: string, orderUrlsafeKeys: string[]): Promise<LegacyCustomProductsMap> {
    const legacyProducts: LegacyCustomProduct[] = await listCustomProducts(buyerUrlsafeKey, orderUrlsafeKeys);
    const legacyProductsMap: LegacyCustomProductsMap = {};

    // Create an array for each order key
    for (let i: number = 0; i < orderUrlsafeKeys.length; ++i) {
        legacyProductsMap[orderUrlsafeKeys[i]] = [];
    }

    // Fill up the arrays with custom products
    for (let i: number = 0; i < legacyProducts.length; ++i) {
        const legacyProduct: LegacyCustomProduct = legacyProducts[i];
        legacyProductsMap[legacyProduct.orderUrlsafeKey].push(legacyProduct);
    }

    return legacyProductsMap;
}

/**
 * Search buyer catalog.
 *
 * @param buyerUrlsafeKey - ID of the buyer that created the custom product
 * @param supplierUrlsafeKey - ID of supplier
 * @param packageIDs - ID of packages, optional
 * @param name - Name to search for, optional
 */
export async function searchBuyerCatalog(buyerUrlsafeKey: string, data: BuyerCatalogSearch, limit: number = 20): Promise<IProductResponse> {
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/catalog/search/?limit=${limit}`;
    const response: AxiosResponse<IProductResponse> = await axios.post(url, data)
        .catch((e) => throwAxiosError(e, 'Could not search catalog'));

    return response.data;
}

/**
 * Gets all possible categories for products
 */
export async function getCategories(): Promise<CategoriesData> {
    const endpoint = `${secretShopUrl}/categories`;
    return sendRequest(endpoint, null, true);
}

/**
 * Gets all possible categories for products (name + id)
 */
export async function getCategoryIDs(): Promise<CategoryIDsData> {
    const endpoint = `${secretShopUrl}/categories/id`;
    return sendRequest(endpoint, null, true);
}

/**
 * Gets all possible unit types for products
 */
export async function getUnits(): Promise<UnitsData> {
    const endpoint = `${secretShopUrl}/units`;
    return sendRequest(endpoint, null, true);
}

/**
 * Gets a list of all products in a supplier's catalog
 *
 * @param supplierCatalogId - The supplier's ID
 * @param limit - How many products to receive
 */
export async function getSupplierProducts(supplierCatalogId: string, limit: number): Promise<SupplierProductsData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogId}/products?limit=${limit}`;
    return sendRequest(endpoint, undefined, true, true);
}

/**
 * Gets a list of all products in a supplier's catalog
 *
 * @param supplierCatalogID - The supplier's ID
 * @param productID- the products ID
 */
export async function fetchSupplierCatalogProduct(supplierCatalogID: string, productID: string): Promise<ProductData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/products/${productID}`;
    return sendRequest(endpoint, undefined, true, true);
}

/**
 * Gets a list of all supplier catalogs linked to the buyer
 *
 * @param buyerUrlsafe - ID of the buyer to get all supplier catalogs for
 * @param hideOffline - Whether to hide offline suppliers
 */
export async function getLinkedSupplierCatalogs(buyerUrlsafe: string, hideOffline = true): Promise<LinkedSupplierCatalogsData> {
    const endpoint = `${secretShopUrl}/buyers/${buyerUrlsafe}/supplierCatalogs`;
    const response = await sendRequest(endpoint, undefined, true, true) as LinkedSupplierCatalogsData;
    if (hideOffline) {
        response.supplierCatalogs = response.supplierCatalogs.filter(({ isOffline }) => !isOffline);
    }
    return response;
}

export type SupplierCatalogQueryParams = {
    limit: number,
    supplierUrlsafeKey?: string,
    cursor?: string,
};

/**
 * Gets a list of all available supplier catalogs
 *
 * @param limit - the maximum number of catalogs to receive
 */
export async function getSupplierCatalogs(params: SupplierCatalogQueryParams): Promise<SupplierCatalogsResponse> {
    const queryParams = `?${encodeQueryParams(params)}`;
    const url = `${secretShopUrl}/supplierCatalogs${queryParams}`;
    const response: AxiosResponse<SupplierCatalogsResponse> = await axios.get(url)
        .catch((error) => networkErrorHandler({ error, title: 'Fetch Suppliers', message: 'Error fetching suppliers' }));
    return response.data;
}

/**
 * Updates an existing supplier catalog
 *
 * @param supplierCatalogId - The supplier's ID
 */
export async function updateSupplierCatalog(supplierCatalogId): Promise<SupplierCatalogData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogId}`;
    const config: RequestInit = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            isDiscoverable: true
        })
    };

    return sendRequest(endpoint, config, true, false);
}

/**
 * Gets a list of active supplier catalogs
 *
 * @param limit - the maximum number of catalogs to receive
 */
export async function discoverSupplierCatalogs(buyerUrlsafeKey: string, limit: number = 20, cursor?: string): Promise<SupplierCatalogsResponse> {
    const params = {
        buyerUrlsafeKey,
        limit,
        cursor
    };
    const endpoint = `${secretShopUrl}/discover/supplierCatalogs?${encodeQueryParams(params)}`;
    return sendRequest(endpoint, null, true);
}

/**
 * Update product package
 *
 * @param pack
 * @param productID
 * @param supplierCatalogID
 */
export async function updateProductPackage({ pack, productID, supplierCatalogID }: UpdateProductPackageData): Promise<ProductData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/products/${productID}/packages/${pack.id}`;
    const config: RequestInit = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pack)
    };

    return sendRequest(endpoint, config, true, false);
}

/**
 * Create product package data
 */
export async function createProductPackage({ supplierCatalogID, productID, pack }: UpdateProductPackageData): Promise<UpdateProductPackageData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/products/${productID}/packages`;
    const config: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pack)
    };

    return sendRequest(endpoint, config, true, false);
}

/**
 *
 * Update product package price
 *
 * @param pack
 * @param productID
 * @param supplierCatalogID
 */
export async function updateProductPackagePrice({ packageID, productID, supplierCatalogID, priceID, pricePerUnit }: UpdateProductPackagePriceData): Promise<ProductData> {
    const endpoint = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/products/${productID}/packages/${packageID}/prices/${priceID}`;
    const config: RequestInit = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ pricePerUnit })
    };

    return sendRequest(endpoint, config, true, false);
}

/**
 * Fetch products from supplier catalogs with filters
 *
 * @param buyerId buyer id
 * @param params query params
 */

export enum TFetchProductsType {
    SAVED = 'SAVED',
    ORDER_GUIDE = 'ORDER_GUIDE',
    SEARCH = 'SEARCH',

}

export type TProductFilter = {
    buyerUrlsafeKey: string,
    params: IActiveFilters,
    type:TFetchProductsType,
};

const getFetchProductsEndpoint = (buyerUrlsafeKey: string, type: TFetchProductsType, params: IActiveFilters):string => {
    const { limit, cursor, sort, direction } = params;
    const queryParams = `?${encodeQueryParams({ limit, cursor, sort, direction })}`;
    switch (type) {
        case TFetchProductsType.SAVED:
            return `${secretShopUrl}/buyers/${buyerUrlsafeKey}/savedProducts/search${queryParams}`;
        case TFetchProductsType.ORDER_GUIDE:
            return `${secretShopUrl}/buyers/${buyerUrlsafeKey}/orderGuides/${params.orderGuideID}/products/search${queryParams}`;
        case TFetchProductsType.SEARCH:
        default:
            return `${secretShopUrl}/buyers/${buyerUrlsafeKey}/catalog/search${queryParams}`;
    }
};

export async function fetchProducts({
    buyerUrlsafeKey,
    params,
    type
}:TProductFilter): Promise<IProductResponse> {
    const { limit, cursor, ...payload } = params;
    const endpoint = getFetchProductsEndpoint(buyerUrlsafeKey, type, params);
    const config: RequestInit = {
        method: 'POST',
        body: JSON.stringify(removeEmptyValuesFromParamsObject(payload)),
        headers: {
            'Content-Type': 'application/json'
        },
    };
    return sendRequest(endpoint, config, true);
}

/**
 * Marks a product as a favourite, adding it to all saved products
 *
 * @param buyerId
 * @param productId
 */
export async function addSavedProduct({ buyerUrlsafeKey, productID, productIDs, showErrorToaster }: SavedProductData):Promise<IFavoriteResponse> {
    const endpoint = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/savedProducts`;
    const config: RequestInit = {
        method: 'POST',
        body: JSON.stringify(productIDs?.length > 0 ? { productIDs } : { productID }),
        headers: {
            'Content-Type': 'application/json'
        },
    };
    return sendRequest(endpoint, config, true, showErrorToaster === undefined ? true : showErrorToaster);
}

/**
 * Unmarks a product as a favourite and removes a product from all saved products
 *
 * @param buyerId buyer id
 * @param productId product id
 */
export async function removeFavoriteProduct({ buyerUrlsafeKey, productID }: SavedProductData):Promise<object> {
    const endpoint = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/savedProducts/${productID}`;
    const config: RequestInit = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
    };
    return sendRequest(endpoint, config, true);
}

export async function addSupplierCatalogToBuyer(supplierCatalogID, buyerUrlsafeKey):Promise<SupplierData> {
    const endpoint = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/supplierCatalogs`;
    const config: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ supplierCatalogID }),
        headers: {
            'Content-Type': 'application/json'
        },
    };
    return sendRequest(endpoint, config, true, false);
}

export async function getOrderGuides(buyerId: string): Promise<GetOrderGuidesResponse> {
    const endpoint = `${secretShopUrl}/buyers/${buyerId}/orderGuides`;
    return sendRequest(endpoint, null, true);
}

/**
 *
 * Add Product to Order Guide
 *
 * @param buyerId
 * @param productID
 * @param orderGuideID
 */
export async function addProductToOrderGuide({ buyerUrlsafeKey, productID, productIDs, orderGuideID }: SavedProductToOrderGuideData):Promise<IFavoriteResponse> {
    const endpoint = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/orderGuides/${orderGuideID}/products`;
    const config: RequestInit = {
        method: 'POST',
        body: JSON.stringify(productIDs?.length > 0 ? { productIDs } : { productID }),
        headers: {
            'Content-Type': 'application/json'
        },
    };
    return sendRequest(endpoint, config, true, false);
}

/**
 *
 * Remove Product from Order Guide
 *
 * @param buyerUrlsafeKey buyer urlsafe key
 * @param productID product id
 * @param orderGuideID order guide id
 */
export async function removeProductFromOrderGuide({ buyerUrlsafeKey, productID, orderGuideID }: SavedProductToOrderGuideData):Promise<void> {
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/orderGuides/${orderGuideID}/products/${productID}`;
    const response: AxiosResponse<void> = await axios.delete(url)
        .catch((error) => networkErrorHandler({ error, title: 'Order Guide', message: 'Unable to remove product from order guide' }));
    return response.data;
}

/**
 *
 * Create Order Guide
 *
 * @param buyerUrlsafeKey
 * @param name
 */
export async function createOrderGuide({ buyerUrlsafeKey, name }: MutateOrderGuideData):Promise<OrderGuide> {
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/orderGuides/`;
    const response: AxiosResponse<OrderGuide> = await axios.post(url, { name })
        .catch((error) => networkErrorHandler({ error, title: 'Order Guide', message: 'Unable to create order guide' }));
    return response.data;
}

/**
 *
 * Edit Order Guide
 *
 * @param buyerUrlsafeKey
 * @param name
 * @param orderGuideID
 */
export async function editOrderGuide({ buyerUrlsafeKey, name, orderGuideID }: MutateOrderGuideData):Promise<OrderGuide> {
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/orderGuides/${orderGuideID}`;
    const response: AxiosResponse<OrderGuide> = await axios.patch(url, { name })
        .catch((error) => networkErrorHandler({ error, title: 'Order Guide', message: 'Unable to edit order guide' }));
    return response.data;
}

/**
 *
 * Delete Order Guide
 *
 * @param buyerUrlsafeKey
 * @param orderGuideID
 */
export async function deleteOrderGuide({ buyerUrlsafeKey, orderGuideID }: MutateOrderGuideData):Promise<void> {
    const url = `${secretShopUrl}/buyers/${buyerUrlsafeKey}/orderGuides/${orderGuideID}`;
    const response: AxiosResponse<void> = await axios.delete(url)
        .catch((error) => networkErrorHandler({ error, title: 'Order Guide', message: 'Unable to delete order guide' }));

    return response.data;
}

export type TGetOrderGuideParams = {
    buyerId: string,
    orderGuideID: string,
};

export async function getOrderGuide({
    buyerId,
    orderGuideID,
}:TGetOrderGuideParams): Promise<OrderGuide> {
    const url = `${secretShopUrl}/buyers/${buyerId}/orderGuides/${orderGuideID}`;
    const response: AxiosResponse<OrderGuide> = await axios.get(url)
        .catch((error) => networkErrorHandler({ error, title: 'Order Guide', message: 'Unable to get order guide' }));
    return response.data;
}

// Bulk csv upload
export type IBulkImportRequestPayload = { supplierCatalogID: string, data: FormData };

/**
 *
 *
 * @param supplierCatalogID
 * @param data
 */
export async function bulkImportProductsValidate({
    supplierCatalogID,
    data
}:IBulkImportRequestPayload): Promise<TValidateImportInfo> {
    const url = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/import`;
    const response: AxiosResponse<TValidateImportInfo> = await axios.post(url, data)
        .catch((error) => networkErrorHandler({ error, title: 'Import products', message: 'Unable to import bulk products' }));
    return response.data;
}

export type IBulkImportProductsCommitResponse = { totalProductCount: number, committedProductCount:number, invalidProductCount:number, commitErrors:string[] };

/**
 *
 * Bulk product import endpoint commit products
 *
 * @param supplierCatalogID
 * @param data
 */
export async function bulkImportProductsCommit({
    supplierCatalogID,
    data
}: IBulkImportRequestPayload): Promise<IBulkImportProductsCommitResponse> {
    const url = `${secretShopUrl}/supplierCatalogs/${supplierCatalogID}/import/commit`;
    const response: AxiosResponse<IBulkImportProductsCommitResponse> = await axios.post(url, data)
        .catch((error) => networkErrorHandler({ error, title: 'Commit bulk products', message: 'Unable to commit bulk products' }));
    return response.data;
}

export async function exportCSV({ supplierCatalogID, buyerUrlsafeKey }: { supplierCatalogID: string, buyerUrlsafeKey: string }): Promise<Blob> {
    const response = await axios.get(`${secretShopUrl}/buyers/${buyerUrlsafeKey}/supplierCatalogs/${supplierCatalogID}/products.csv`, {
        responseType: 'blob'
    });
    return response.data;
}
