import '../../../styles/dataPage.scss';
import React, { useEffect, useMemo } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Banner, Button, EBannerType, Input, Loading, NotchDataGrid, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { ARRoutePaths } from '@v2/constants/EPaths';
import BackIcon from '@icons/back-icon.svg';
import SearchIcon from '@icons/search-icon.svg';
import { PageTitle } from '@ar/components/PageTitle';
import { DraftInvoiceStatusBadge } from '@ar/components/Invoices/InvoiceStatusBadge';
import { DraftInvoice,
    OptionalAddress,
    DraftInvoiceItem,
    PatchDraftInvoiceBody,
    PatchDraftInvoiceLineItemData,
    getTransactions,
    Transaction,
    publishDraftInvoice,
    republishDraftInvoice,
    DraftInvoiceStatus } from '@ar/network/AccountsReceivable.network';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { SectionTitle } from '@ar/components/shared/SectionTitle';
import { draftInvoiceValidationSchema, EmptyState, getInvoiceItemColumns, gridSX } from '@ar/pages/DraftInvoiceDetailsPage/DraftInvoiceDetailsPageConstants';
import { useGetCustomer } from '@ar/hooks/queries/CustomerQueries.hook';
import { usePublishOrRepublishDraftInvoiceMutation, useDraftInvoiceUpdateMutation } from '@ar/hooks/mutations/DraftInvoiceMutations';
import { getGpoId } from '@v2/utils/GPOUtils';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { DatePickerInput } from '@v2/components/Shared/DatePickerInput/DatePickerInput';
import AddIcon from '@icons/add-icon.svg';
import { CurrencyInput } from '@v2/components/Shared/InputCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DraftInvoiceActionButton } from '@ar/pages/DraftInvoiceDetailsPage/DraftInvoiceActionButton';
import CheckIcon from '@icons/check-icon.svg';
import { centsToDollars, dollarsToCents, formatAsCurrency } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { CopyButton, CopyButtonFeedback } from '@/ar/components/shared/CopyButton';
import { DraftInvoiceItemFormModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceItemFormModal';
import { DraftInvoiceModals, useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { DraftInvoiceItemDeleteModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceItemDeleteModal';
import { DraftInvoiceDuplicateInvoiceNumberConfirmModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceDuplicateInvoiceNumberConfirmModal';
import { useGetDraftInvoice } from '@/ar/hooks/queries/DraftInvoicesQueries.hook';
import { CustomerOption, CustomerSearch } from './CustomerSearch';
import { CustomerAddress } from './CustomerAddress';
import { useKeyCombination } from '@/ar/hooks/useKeyCombination';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { DraftInvoiceProcessingModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceProcessingModal';
import { InvoiceAttachments } from '@/ar/components/shared/InvoiceAttachments';

type CustomerInfo = {
    name: string,
    address: OptionalAddress,
};

const DEFAULT_CUSTOMER_INFO: CustomerInfo = {
    name: '',
    address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: ''
    }
};

type DraftInvoiceFormData = {
    customer_id?: string | null,
    invoice_number?: string | null,
    invoice_date?: string | null,
    due_date?: string | null,
    invoice_total?: number | null,
    offline_credits_payment?: number | null,
    outstanding_balance?: number | null,
    line_items?: DraftInvoiceItem[],
};

const calculateInvoiceTotalsByItems = (items: DraftInvoiceItem[] = []) => {
    const subtotal = items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const taxAmount = items.reduce((acc, item) => acc + item.tax_amount, 0);
    const total = subtotal + taxAmount;
    return {
        subtotal: centsToDollars(subtotal),
        taxAmount: centsToDollars(taxAmount),
        total: centsToDollars(total)
    };
};

const mapLineItemsToPatchBody = (lineItems: DraftInvoiceItem[]): PatchDraftInvoiceLineItemData[] => {
    if (!lineItems || lineItems.length === 0) return [];

    return lineItems.map((lineItem) => ({
        id: lineItem.id,
        description: lineItem.description,
        price: lineItem.price,
        quantity: lineItem.quantity,
        tax_amount: lineItem.tax_amount,
        total: lineItem.total,
    }));
};

const valueFormatters = {
    invoice_total: dollarsToCents,
    offline_credits_payment: dollarsToCents,
    outstanding_balance: dollarsToCents,
    line_items: mapLineItemsToPatchBody,
};

const mapUpdatedFieldsToPatchBody = (updatedFields: Partial<DraftInvoiceFormData>): Partial<PatchDraftInvoiceBody> => (
    Object.entries(updatedFields).reduce((acc, [invoiceField, value]) => {
        if (value !== undefined) {
            if (valueFormatters[invoiceField]) {
                acc[invoiceField] = valueFormatters[invoiceField](value);
            } else {
                acc[invoiceField] = value;
            }
        }

        return acc;
    }, {})
);

const areLineItemsEqual = (lineItemA: DraftInvoiceItem, lineItemB: DraftInvoiceItem) => {
    const keysToCompare = ['total', 'price', 'quantity', 'tax_amount', 'description', 'id'];

    return keysToCompare.every((key) => lineItemA[key] === lineItemB[key]);
};

const areLineItemsChanged = (originalLineItems: DraftInvoiceItem[], lineItems: DraftInvoiceItem[]): boolean => {
    if (!originalLineItems && !lineItems) return false;

    if (originalLineItems?.length !== lineItems?.length) return true;

    return lineItems?.some((lineItem) => {
        const originalLineItem = originalLineItems.find((item) => item.id === lineItem.id);
        return !areLineItemsEqual(originalLineItem, lineItem);
    }) ?? false;
};

const filterUpdatedFields = (originalInvoice: DraftInvoiceFormData, draftInvoice: DraftInvoiceFormData): Partial<DraftInvoiceFormData> => {
    const keysToCompare = Object.keys(originalInvoice).filter((key) => key !== 'line_items');

    const updatedFields: Partial<DraftInvoiceFormData> = keysToCompare.reduce((acc, key) => {
        if (originalInvoice[key] !== draftInvoice[key]) {
            acc[key] = draftInvoice[key];
        }
        return acc;
    }, {});

    if (originalInvoice?.line_items && areLineItemsChanged(originalInvoice?.line_items, draftInvoice?.line_items)) {
        updatedFields.line_items = draftInvoice.line_items;
    }

    return updatedFields;
};

/**
 * The AR Draft Invoices detail page
 *
 * @returns JSX Element
 */
export const DraftInvoiceDetailsPage = function DraftInvoiceDetailsPage(): JSX.Element {
    useDocumentTitle('Draft Invoice Details - Notch');
    const navigate = useNavigate();
    const flags = useFlags<FeatureFlags>();
    const { supplierLoginData } = useSupplierStore();
    const { openModal } = useDraftInvoiceStore();
    const gpoId = getGpoId(supplierLoginData);
    const { invoiceID } = useParams();
    const [showCustomerSearchInput, setShowCustomerSearchInput] = React.useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = React.useState<CustomerInfo>(DEFAULT_CUSTOMER_INFO);
    const [existingInvoiceId, setExistingInvoiceId] = React.useState<string>(null);
    const draftInvoiceUpdateMutation = useDraftInvoiceUpdateMutation();
    const isSaving = draftInvoiceUpdateMutation.isLoading;
    const lineItemsFeatureFlagEnabled = flags.arInvoiceEditorLineItems;

    const mapInvoiceValuesToForm = (invoice: DraftInvoice): Partial<DraftInvoice> => ({
        customer_id: invoice.customer_id,
        invoice_number: invoice.invoice_number,
        invoice_date: invoice?.invoice_date ? new Date(invoice?.invoice_date).toISOString().split('T')[0] : null,
        due_date: invoice?.due_date ? new Date(invoice?.due_date).toISOString().split('T')[0] : null,
        invoice_total: centsToDollars(invoice?.invoice_total ?? 0),
        offline_credits_payment: centsToDollars(invoice?.offline_credits_payment ?? 0),
        outstanding_balance: centsToDollars(invoice?.outstanding_balance ?? 0),
        line_items: lineItemsFeatureFlagEnabled ? invoice.line_items : [],
        supplier_id: invoice.supplier_id ?? supplierLoginData?.supplier_id,
        external_id: invoice.external_id
    });

    const publishDraftInvoiceMutation = usePublishOrRepublishDraftInvoiceMutation({
        mutationFn: publishDraftInvoice,
        successMessage: 'Invoice published successfully',
    });

    const republishInvoiceMutation = usePublishOrRepublishDraftInvoiceMutation({
        mutationFn: republishDraftInvoice,
        successMessage: 'Invoice republished successfully',
    });

    const isPublishing = publishDraftInvoiceMutation.isLoading || republishInvoiceMutation.isLoading;

    const { data: invoice, isLoading, isError: isErrorInvoice } = useGetDraftInvoice(
        {
            supplierID: supplierLoginData?.supplier_id,
            draftInvoiceID: invoiceID,
        },
        // this means that the data will be cached forever or only loaded once and never refetched
        {
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );

    const canPublishDraftInvoice = invoice?.status === DraftInvoiceStatus.Draft;
    const canSaveDraftInvoice = invoice?.status === DraftInvoiceStatus.Draft;
    const isProcessing = invoice?.status === DraftInvoiceStatus.Processing;
    const isEditingDraftInvoice = invoice?.status === DraftInvoiceStatus.Draft && !!invoice.transaction_id;

    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm<DraftInvoice>({
        mode: 'all',
        resolver: zodResolver(draftInvoiceValidationSchema),
    });

    const { errors, defaultValues } = useFormState({
        control,
    });

    const validateDraftInvoiceForDuplicatedInvoiceNumber = async (invoiceNumber: string): Promise<Transaction | undefined> => {
        const transactionsResponse = await getTransactions({
            invoice_number: invoiceNumber,
            supplier_id: supplierLoginData?.supplier_id,
            gpo_id: gpoId,
            offset: 0,
            limit: 1,
        });

        return transactionsResponse?.transactions?.find((transaction) => transaction.draft_invoice_id !== invoice.id);
    };

    useEffect(() => {
        if (!invoice) return;

        reset(mapInvoiceValuesToForm(invoice));

        if (invoice.customer_id && invoice?.customer?.businessName) {
            setCustomerInfo({
                name: invoice.customer?.businessName ?? '',
                address: {
                    line1: invoice.customer?.line1,
                    line2: invoice.customer?.line2,
                    city: invoice.customer?.city,
                    state: invoice.customer?.state,
                    postal_code: invoice.customer?.postalCode,
                }
            });
            setShowCustomerSearchInput(false);
        } else {
            setCustomerInfo(DEFAULT_CUSTOMER_INFO);
        }

        const checkForDuplicateInvoiceNumber = async () => {
            const existingInvoice = await validateDraftInvoiceForDuplicatedInvoiceNumber(invoice.invoice_number);

            if (existingInvoice) {
                setExistingInvoiceId(existingInvoice.id);
            }
        };

        if (invoice.invoice_number) {
            checkForDuplicateInvoiceNumber();
        }
    }, [invoice]);

    const invoiceNumber = watch('invoice_number');
    const customerId = watch('customer_id');
    const total = watch('invoice_total');
    const paidOffline = watch('offline_credits_payment');
    // these are the line items that are being watched from the form and preloaded from the invoice
    const lineItems = watch('line_items');
    const showWarningBanner = existingInvoiceId && invoice.status === DraftInvoiceStatus.Draft && invoice?.invoice_number.toLowerCase() === invoiceNumber.toLowerCase();

    const { data: customerResults, isFetching: isFetchingCustomer } = useGetCustomer({
        customerID: customerId,
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
        supplierIdFilter: invoice?.supplier_id
    });

    const { subtotal, taxAmount } = useMemo(
        () => calculateInvoiceTotalsByItems(lineItems),
        [lineItems]
    );

    useEffect(() => {
        const outstandingBalance = total - paidOffline;
        setValue('outstanding_balance', outstandingBalance);
    }, [total, paidOffline]);

    const handleLineItemsTotalCalculation = (): void => {
        const newTotal = calculateInvoiceTotalsByItems(lineItems).total;
        setValue('invoice_total', newTotal);
    };

    useEffect(() => {
        if (!lineItems) return;

        if (lineItems.length === 0 && defaultValues?.line_items?.length === 0) {
            setValue('invoice_total', centsToDollars(invoice?.invoice_total));
        } else {
            handleLineItemsTotalCalculation();
        }
    }, [lineItems, defaultValues?.line_items]);

    useEffect(() => {
        if (customerResults?.id) {
            setCustomerInfo({
                name: customerResults.name,
                address: customerResults.address
            });
        }
    }, [customerResults?.id]);

    const handleOnMutateItem = (data: DraftInvoiceItem, isNewItem: boolean): void => {
        if (isNewItem) {
            setValue('line_items', [...lineItems, data]);
        } else {
            const updatedItems = lineItems.map((item) => {
                if (item.id === data.id) {
                    return data;
                }
                return item;
            });
            setValue('line_items', updatedItems);
        }
    };

    const handleOnDeleteItem = (itemId: string) => {
        const updatedItems = lineItems.filter((lineItem) => lineItem.id !== itemId);
        setValue('line_items', updatedItems);
    };

    const handleBackButton = (): void => {
        const { history } = window;

        if (history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/${ARRoutePaths.AR_INVOICES}`);
        }
    };

    const handleEditCustomerClick = (): void => {
        setShowCustomerSearchInput(true);
    };

    const handleCustomerSelect = (field, selectedCustomer: CustomerOption): void => {
        field.onChange(selectedCustomer.id);
        setCustomerInfo({
            name: selectedCustomer.name,
            address: selectedCustomer.address
        });
        setValue('customer_id', selectedCustomer.id);
        setShowCustomerSearchInput(false);
    };

    const showCustomerSearchInputInitially = !customerId && !isFetchingCustomer;
    const hasItems = invoice && lineItems?.length > 0;

    const handleSaveDraftInvoice = async () => {
        // Save draft invoice without validation
        const data: DraftInvoiceFormData = getValues();
        const updatedFields = filterUpdatedFields(defaultValues as DraftInvoiceFormData, data);
        const payload = mapUpdatedFieldsToPatchBody(updatedFields);

        // temporary keeping for debug purposes
        // eslint-disable-next-line no-console
        console.log('Handle save', { data, defaultValues, updatedFields, payload });

        await draftInvoiceUpdateMutation.mutateAsync({
            draftInvoiceID: invoiceID,
            supplierID: supplierLoginData?.supplier_id,
            body: payload,
        }, {
            onSuccess: async () => {
                toast.show({
                    message: 'Draft Invoice saved successfully',
                    icon: <CheckIcon/>,
                });
            },
            onError: () => {
                toast.show({
                    message: 'Failed to save draft invoice',
                });
            }
        });
    };

    const publishInvoice = async () => {
        if (invoice.transaction_id) {
            await republishInvoiceMutation.mutateAsync({
                draftInvoiceID: invoiceID,
                supplierID: supplierLoginData?.supplier_id,
            });
        } else {
            await publishDraftInvoiceMutation.mutateAsync({
                draftInvoiceID: invoiceID,
                supplierID: supplierLoginData?.supplier_id,
            });
        }
    };

    const confirmPublishAndSave = async () => {
        await handleSaveDraftInvoice();
        await publishInvoice();
    };

    const handlePublishAndSave = async () => {
        if (!canPublishDraftInvoice) {
            return;
        }

        const existingInvoice = await validateDraftInvoiceForDuplicatedInvoiceNumber(invoiceNumber);

        if (existingInvoice) {
            openModal(DraftInvoiceModals.DuplicateInvoiceNumberConfirmModal, undefined, existingInvoice);
            return;
        }

        await confirmPublishAndSave();
    };

    const renderWarningBanner = () => (
        <Banner
            className="my-4 mr-8 border border-orange-200 rounded-md"
            alertType={EBannerType.WARNING}
            body={
                <Typography as="div" className="text-gray-600">
                    We&apos;ve noticed that an invoice with{' '}
                    <Link target="_blank" to={`/${ARRoutePaths.AR_INVOICES}/${existingInvoiceId}`} className="text-blue-500">this number</Link>{' '}
                    already exists in our system. You can save & publish this draft to push an update to the existing invoice or archive this draft.
                </Typography>
            }
            isDismissable={false} />
    );

    // Save draft invoice on Ctrl + S
    useKeyCombination(['s'], handleSaveDraftInvoice);
    useKeyCombination(['p'], handleSubmit(handlePublishAndSave));

    if (isLoading) {
        return <Loading isDark />;
    }

    if (isErrorInvoice) {
        return (
            <>
                <section className="flex gap-2 justify-between items-center  mb-6 ">
                    <Button className="flex items-center -ml-3.5 text-gray-600"
                        as="div"
                        size="SMALL"
                        variant="SUBDUED_ICON"
                        onClick={handleBackButton}>
                        <BackIcon className="w-4 h-4 mr-3"/>
                        Back
                    </Button>
                </section>
                <div className="flex flex-col gap-4 ">
                    <PageTitle>Invoice not found</PageTitle>
                    <Typography as="div" className="text-gray-600">Apologies, no invoice matching the given criteria could be found in the system.</Typography>
                </div>
            </>
        );
    }

    return (
        <>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    onClick={handleBackButton}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
                {!isProcessing && <div className="flex gap-3 items-center shrink-0">
                    {canSaveDraftInvoice && !isEditingDraftInvoice && <Button size="SMALL"
                        loading={isSaving}
                        onClick={handleSaveDraftInvoice}>
                                        Save
                    </Button>}
                    {/* This button won't save but publish the existing draft invoice in the DB, this will only appear when an invoice is in draft and linked to a transaction */}
                    {isEditingDraftInvoice && <Button size="SMALL"
                        disabled={isSaving || isPublishing}
                        loading={isPublishing}
                        onClick={publishInvoice}>
                                        Cancel
                    </Button>}
                    {canPublishDraftInvoice && <Button size="SMALL"
                        variant="TERTIARY_DARK"
                        loading={isPublishing}
                        disabled={isPublishing || isSaving}
                        onClick={handleSubmit(handlePublishAndSave)}>
                        Save & {invoice.transaction_id ? 'Republish' : 'Publish'}
                    </Button>}
                    <DraftInvoiceActionButton invoice={invoice} />

                </div>}

            </section>
            <form className="h-full flex flex-col" onSubmit={(e) => e.preventDefault()}>
                {/* <section className="pb-8 flex justify-between"> */}
                <section className="pb-8 flex justify-between flex-grow">
                    <div>
                        <div className="flex gap-3 items-center">
                            <PageTitle>{isProcessing ? 'New draft' : formatAsCurrency(centsToDollars(invoice?.invoice_total))}</PageTitle>
                            <DraftInvoiceStatusBadge status={invoice.status as DraftInvoiceStatus}/>
                        </div>
                        {!!invoice?.invoice_number && <Typography className="text-gray-600 mt-1" as="div"><div className="flex gap-2 item-center">
                            <div>
                                {/* check if the invoice is linked to a transaction and if it's in draft status */}
                                {isEditingDraftInvoice && <span className="text-gray-600">Editing</span> } <span className="text-gray-700">Invoice #{invoice.invoice_number}</span></div>
                            <CopyButton buttonClassName="p-0"
                                textToCopy={invoice.invoice_number}
                                feedbackType={CopyButtonFeedback.TOAST}
                                successMessage="Invoice number copied"
                                errorMessage="Error copying invoice number to clipboard" />
                        </div>
                        </Typography>}
                    </div>

                </section>
                <SeparatorInternalPageFullWidth className="pb-px"/>
                <section className="flex lg:flex-row flex-col h-full relative">
                    <div className="lg:border-r lg:w-7/12 border-r-gray-200 w-full h-full">
                        {isLoading && <Loading isDark/>}
                        {!isLoading && invoice && (<>
                            {showWarningBanner && renderWarningBanner()}
                            <SectionTitle className=" pt-6 mb-6">Details</SectionTitle>
                            <section className="pb-8 pr-8 flex flex-col xl:flex-row gap-6 w-full">

                                <div className="flex flex-col gap-4 customer-details w-full xl:w-7/12 h-full">

                                    <div className="flex flex-col gap-2 items-start w-full">
                                        <Typography className="m-0 h-[23px]">Customer</Typography>
                                        <div className="flex justify-between items-center w-full">
                                            {!customerInfo.name && isFetchingCustomer && <Loading isDark/>}
                                            {(showCustomerSearchInputInitially || showCustomerSearchInput) && (
                                                <Controller
                                                    name="customer_id"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <CustomerSearch autoFocus={showCustomerSearchInput} onSelect={(selectedCustomer) => handleCustomerSelect(field, selectedCustomer)} />
                                                    )}/>
                                            )}
                                            {(customerInfo.name && !showCustomerSearchInput) && <>
                                                <Link className="text-teal-500" to={`/${ARRoutePaths.AR_CUSTOMERS}/${customerId}`}>{customerInfo.name}</Link>
                                                <Button variant="ICON" size="ICON_SMALL" onClick={handleEditCustomerClick}>
                                                    <SearchIcon className="w-4 h-4 text-gray-600" />
                                                </Button>
                                            </>}
                                        </div>
                                        {(!isFetchingCustomer || customerInfo.name) && <div>
                                            <Typography className="mt-1">Address</Typography>
                                            <CustomerAddress address={customerInfo.address}/>
                                        </div>}
                                        {errors.customer_id && <Typography variant="BASE" className="text-red-300 m-0 mt-auto">{errors.customer_id.message}</Typography>}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 w-full xl:w-5/12">
                                    <Controller
                                        name="invoice_number"
                                        control={control}
                                        render={({ field }) => <Input
                                            label="Invoice no."
                                            variant="SMALL"
                                            isInvalid={!!errors.invoice_number}
                                            invalidMessage={errors.invoice_number?.message}
                                            required
                                            inputProps={{
                                                ...field,
                                            }}/>}/>
                                    <div className="flex gap-4 items-center ">
                                        <div className="relative w-full h-full">
                                            <Controller
                                                name="invoice_date"
                                                control={control}
                                                render={({ field }) => <DatePickerInput
                                                    inputFieldProps={{
                                                        isInvalid: !!errors.invoice_date,
                                                        invalidMessage: errors.invoice_date?.message,
                                                        inputClassName: 'pr-0'
                                                    }}
                                                    variant="SMALL"
                                                    required
                                                    label="Invoice Date"
                                                    inputProps={{ readOnly: true }}
                                                    value={field.value}
                                                    onChange={(value): void => {
                                                        const utcDate = new Date(value).toISOString().split('T')[0];
                                                        return field.onChange(utcDate);
                                                    }}
                                                    id="invoice_date"/>}/>
                                        </div>
                                        <div className="relative w-full h-full">
                                            <Controller
                                                name="due_date"
                                                control={control}
                                                render={({ field }) => <DatePickerInput
                                                    variant="SMALL"
                                                    required
                                                    label="Due Date"
                                                    inputProps={{ readOnly: true }}
                                                    inputFieldProps={{
                                                        isInvalid: !!errors.due_date,
                                                        invalidMessage: errors.due_date?.message,
                                                        inputClassName: 'pr-0'
                                                    }}
                                                    value={field.value}
                                                    onChange={(value): void => {
                                                        const utcDate = new Date(value).toISOString().split('T')[0];
                                                        return field.onChange(utcDate);
                                                    }}
                                                    id="due_date"/>}/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>)}
                        {lineItemsFeatureFlagEnabled && <>
                            <section className="py-8">
                                <SectionTitle className="flex items-center gap-2 ">Line items</SectionTitle>

                                {!hasItems && <EmptyState text="There are no items in this invoice."/>}
                                {hasItems && <NotchDataGrid className="overflow-visible"
                                    columns={getInvoiceItemColumns()}
                                    autoHeight={true}
                                    getRowHeight={() => 'auto'}
                                    rowCount={lineItems.length}
                                    sx={gridSX}
                                    sortingMode="client"
                                    onRowClick={({ row }) => openModal(DraftInvoiceModals.LineItem, row)}
                                    disableSelectionOnClick
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableColumnMenu
                                    rowsPerPageOptions={[50, 100]}
                                    hideFooter={true}
                                    rows={lineItems}
                                    loading={isLoading}/>}
                                <button className="bg-transparent mt-4"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        return openModal(DraftInvoiceModals.LineItem);
                                    }}>
                                    <Typography className="flex items-center font-medium cursor-pointer gap-2" >
                                        <AddIcon className="text-teal-500 w-4 h-4" />
                                        <span className="text-teal-500">Add line items</span>
                                    </Typography>
                                </button>
                                {hasItems && (
                                    <div className="flex justify-end mt-6">
                                        <div className="flex flex-col  space-y-3 lg:w-80">
                                            <Separator/>
                                            <div className="flex items-center space-x-4 justify-between">
                                                <Typography className="m-0">Subtotal:</Typography>
                                                <Typography className="m-0 text-right">{formatAsCurrency(subtotal)}</Typography>
                                            </div>
                                            <Separator/>
                                            <div className="flex items-center space-x-4 justify-between">
                                                <Typography className="m-0">Tax Amount:</Typography>
                                                <Typography className="m-0 text-right">{formatAsCurrency(taxAmount)}</Typography>
                                            </div>
                                            <Separator/>
                                        </div>

                                    </div>
                                )}
                            </section>
                            <Separator/>
                        </>}
                        <section>
                            <div className="py-8 pr-8 grid customer-details h-full">
                                <div className="flex flex-col gap-8 h-full">
                                    <div className="flex items-start gap-2">
                                        <Controller
                                            name="invoice_total"
                                            control={control}
                                            render={({ field }) => <CurrencyInput
                                                required
                                                allowNegative
                                                isInvalid={!!errors.invoice_total}
                                                invalidMessage={errors.invoice_total?.message}
                                                variant="SMALL"
                                                disabled={hasItems}
                                                onChange={(e):void => {
                                                    field.onChange(e.floatValue);
                                                }}
                                                value={field.value}
                                                className="lg:flex lg:flex-row lg:justify-between lg:items-center"
                                                fontSize="[&>label]:text-4 [&>label]:lg:text-3 [&>label]:font-semibold font-semibold"

                                                label={'Total'}/>}/>
                                    </div>
                                    <Controller
                                        name="offline_credits_payment"
                                        control={control}
                                        render={({ field }) => <CurrencyInput
                                            required
                                            isInvalid={!!errors.offline_credits_payment}
                                            invalidMessage={errors.offline_credits_payment?.message}
                                            variant="SMALL"
                                            allowNegative
                                            onChange={(e):void => {
                                                field.onChange(e.floatValue);
                                            }}
                                            className="lg:flex lg:flex-row lg:justify-between lg:items-center"
                                            value={field.value}
                                            label={'Offline payments & credits'}/>}/>
                                    {/* TODO: this will be a new field coming from the DB and stored  */}
                                    <Controller
                                        name="outstanding_balance"
                                        control={control}
                                        render={({ field }) => <CurrencyInput
                                            required
                                            disabled={!hasItems}
                                            isInvalid={!!errors.outstanding_balance}
                                            invalidMessage={errors.outstanding_balance?.message}
                                            variant="SMALL"
                                            allowNegative
                                            onChange={(e):void => {
                                                field.onChange(e.floatValue);
                                            }}
                                            value={field.value}
                                            className="lg:flex lg:flex-row lg:justify-between lg:items-center"
                                            label={'Outstanding balance'}/>}/>

                                </div>
                            </div>
                        </section>
                        <InvoiceAttachments attachments={invoice.attachments} showTitle showSeparator/>

                    </div>
                    <div className=" w-full lg:w-5/12">
                        <div className="flex flex-col gap-8 h-full">
                            {!!invoice.invoice_pdf_url && (
                                <iframe
                                    src={`${invoice.invoice_pdf_url}#toolbar=1&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="100%"
                                    title="Invoice PDF"
                                    style={{ border: 'none' }}/>

                            )}
                        </div>
                        {errors.invoice_pdf_url && <Typography className="text-red-500">{errors.invoice_pdf_url.message}</Typography>}
                    </div>
                    {isProcessing && (<DraftInvoiceProcessingModal />)}
                </section>
            </form>

            <DraftInvoiceItemFormModal onItemSubmit={handleOnMutateItem} />
            <DraftInvoiceItemDeleteModal onConfirmDelete={handleOnDeleteItem} />
            <DraftInvoiceDuplicateInvoiceNumberConfirmModal onConfirmSubmit={confirmPublishAndSave} draftInvoiceId={invoice.id}/>
        </>
    );
};
