import { DraftInvoice,
    DraftInvoiceStatus,
    getDraftInvoice,
    GetDraftInvoiceIDParams,
    getDraftInvoices,
    GetDraftInvoicesParams,
    GetDraftInvoicesResponse } from '@ar/network/AccountsReceivable.network';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const FETCH_DRAFT_INVOICES_QUERY_KEY = 'FETCH_DRAFT_INVOICES_QUERY_KEY';

export type TUseGetDraftInvoicesQuery = {
    searchParams: GetDraftInvoicesParams,
};

const DEFAULT_REFETCH_INTERVAL_ON_PROCESSING = 5000;

export const useGetDraftInvoices = ({ searchParams }: TUseGetDraftInvoicesQuery): UseQueryResult<GetDraftInvoicesResponse> => useQuery(
    [FETCH_DRAFT_INVOICES_QUERY_KEY, searchParams],
    async () => getDraftInvoices(searchParams),
    { enabled: !!searchParams.supplier_id,
        refetchInterval: (data) => {
            // If there are processing invoices, refetch every 7 seconds
            const hasProcessingInvoices = data?.invoices?.some((invoice) => invoice.status === DraftInvoiceStatus.Processing) ?? false;
            if (hasProcessingInvoices) {
                return DEFAULT_REFETCH_INTERVAL_ON_PROCESSING;
            }
            return false;
        } }
);

export const DRAFT_INVOICE_QUERY_KEY = 'DRAFT_INVOICE_QUERY_KEY';
export const useGetDraftInvoice = (params: GetDraftInvoiceIDParams, options?: UseQueryOptions<DraftInvoice>): UseQueryResult<DraftInvoice> => useQuery(
    [DRAFT_INVOICE_QUERY_KEY, params],
    async () => getDraftInvoice(params),
    { enabled: !!params.draftInvoiceID && (!!params.supplierID),
        refetchInterval: (data) => {
            // If the invoice is processing, refetch every 7 seconds
            if (data?.status === DraftInvoiceStatus.Processing) {
                return DEFAULT_REFETCH_INTERVAL_ON_PROCESSING;
            }
            return false;
        },

        ...options }
);
