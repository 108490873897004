import React, { useCallback, useContext } from 'react';
import { Loading, NotchDataGrid, Typography } from '@notch-ordering/ui-components';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { useGetTransactions } from '@ar/hooks/queries/InvoicesQueries.hook';
import { GridSortModel } from '@mui/x-data-grid';
import { getColumns } from '@ar/pages/InvoicesPage/InvoicePageConstants';
import { GetTransactionSortBy } from '@ar/network/AccountsReceivable.network';
import { DoNotChargeModal } from '@ar/components/Invoices/Modals/DoNotChargeModal';
import { RefundCustomerModal } from '@ar/components/Invoices/Modals/RefundCustomerModal';
import { ManualChargeModal } from '@ar/components/Invoices/Modals/ManualChargeModal';
import { CreatePaymentMethod } from '@ar/components/shared/modals/CreatePaymentMethod/CreatePaymentMethod';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';

/**
 * The AR Invoices Table
 *
 * @returns JSX Element
 */
export const CustomerInvoices = function (): JSX.Element {
    const { searchParams, updateSearchParams, haveSearchParamsChanged, modals, setModal } = useInvoiceStore();

    const {
        customerID,
        supplierID,
        gpoId,
    } = useContext(CustomerDetailsContext);

    const navigate = useNavigate();

    const { data: transactionsResponse, status, isLoading: isLoadingTransaction } = useGetTransactions({ searchParams: {
        ...searchParams,
        offset: searchParams.page * searchParams.limit,
        supplier_id: supplierID,
        customer_id: customerID,
        gpo_id: gpoId,
    } });
    // push changes
    const isLoading = isLoadingTransaction;
    const invoices = transactionsResponse?.transactions || [];
    const hasResults = invoices.length > 0;
    const successStatus = status === 'success';
    const showResults = successStatus && hasResults;
    const noResults = successStatus && transactionsResponse?.total === 0 && !haveSearchParamsChanged();
    const currentTransaction = modals?.transaction;

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined,
        };
        if (!field || !sort) {
            return;
        }
        const direction = sort === 'asc' ? '' : '-';
        const fieldToSortBy = `${direction}${field}` as GetTransactionSortBy;
        updateSearchParams({
            sort_by: fieldToSortBy,
        });
    }, []);

    const handleCloseCreatePaymentMethod = (): void => {
        setModal('addPaymentMethod', false, currentTransaction);
    };

    return (
        <>
            <div className="mt-2">
                {isLoading && <Loading isDark className="mt-10" />}
                {showResults && <>

                    <NotchDataGrid
                        autoHeight
                        loading={isLoading}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        columns={getColumns()}
                        page={searchParams.page}
                        rowCount={transactionsResponse?.total ?? 0}
                        rowsPerPageOptions={[searchParams.limit]}
                        pageSize={searchParams.limit}
                        paginationMode="server"
                        rowHeight={61}
                        onPageChange={(newPage): void => {
                            updateSearchParams({ page: newPage });
                            const pageChangeEvent = newPage > searchParams.page ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                            sendTrackingEvent(pageChangeEvent);
                        }}
                        rows={invoices}
                        onRowClick={({ row }) => {
                            if (!row.id) return;
                            navigate(`/${ARRoutePaths.AR_INVOICES}/${row.id}`);
                        }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }}/>
                </>
                }
                {noResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">No invoices found for this customer</Typography>
                </div>
                }
            </div>
            <DoNotChargeModal/>
            <RefundCustomerModal/>
            <ManualChargeModal/>
            <CreatePaymentMethod
                isOpen={modals?.addPaymentMethod}
                handleClose={handleCloseCreatePaymentMethod}
                customerID={currentTransaction?.customer_id}
                subTitle={currentTransaction?.business_name}/>
        </>
    );
};
