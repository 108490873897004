import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import cx from 'classnames';

import Utils from '../../../utils';
import { dismissCartItemNotification } from '../../../actions/cartActions';
import { getOrderByUrlSafe } from '../../../selectors/ordersSelectors';

import { DeliveryFeeStatus } from '../cart_orders/delivery-fee-status';

import './styles.scss';
import { Typography } from '@notch-ordering/ui-components';

const CartItemNotification = ({ dismissAfter, item, router, location }) => {
  const dispatch = useDispatch();
  const notificationRef = React.createRef();
  const enclosingOrder = useSelector(getOrderByUrlSafe(item.orderUrlSafe));
  const { items: enclosingOrderItems } = enclosingOrder;
  const allOrderItemsHavePrice = enclosingOrderItems.every(
    (item) => item.price > 0
  );
  const orderTotalWithoutTaxesAndFees = enclosingOrderItems.reduce(
    (total, item) =>
      item.price <= 0 ? total : total + item.price * item.quantity,
    0
  );
  const vendor = useSelector(({ vendorsReducer: { vendors } }) =>
    vendors.find((vendor) => vendor.urlsafe === enclosingOrder.vendorUrlsafe)
  );
  const minimumOrderAmount = Utils.getMinimumOrderAmount(
    enclosingOrder,
    vendor
  );
  const orderQualifiesForFreeDel =
    Utils.hasVendorMinimumOrderAmount(vendor) &&
    orderTotalWithoutTaxesAndFees >= minimumOrderAmount;


  const itemIsDeletedFromCart = item.quantity === 0;

  const removeRouteListener = router.listenBefore((nextLocation) => {
    if (nextLocation.pathname !== location.pathname) {
      dispatch(dismissCartItemNotification());
    }
  });

  useEffect(() => {
    const timerId = window.setTimeout(() => {
      dispatch(dismissCartItemNotification());
    }, dismissAfter);

    const notificationElement = notificationRef.current;
    const activeAnimation = notificationElement.style.animation;
    const animation = `slide-in ${dismissAfter}ms ease 1 normal`;

    // If there's already an active animation, restart animation.
    if (activeAnimation) {
      notificationElement.style.animation = 'none';
      // Trigger DOM reflow
      notificationElement.offsetWidth;
      /**
       * Between the animation removal & re-apply, a DOM reflow needs to be explicitly
       * triggered so that it forces the browser to re-calculate style & layout.
       * If the reflow is not triggered, then both animation removal & re-apply will
       * be executed in a single go, and there will be no difference in the animation state.
       */
    }

    notificationElement.style.animation = animation;

    return () => {
      window.clearTimeout(timerId);
      removeRouteListener();
    };
  }, [item]);

  return (
    <div
      className={cx('cart-item-notification text-1 rounded-xl', {
        'cart-item-notification--deleted': itemIsDeletedFromCart,
      })}
      ref={notificationRef}
    >
      <div className="cart-item-notification__info-container py-4">
        {itemIsDeletedFromCart ? (
         <Typography weight="font-medium" className="m-0 p-0">
            {item.name} has been removed from your cart.
         </Typography>
        ) : (
          <>
            <Typography weight="font-medium" className="m-0 p-0">
              {item.quantity} {item.name} {item.quantity > 1 ? 'are' : 'is'} in
              your cart.
            </Typography>

            {allOrderItemsHavePrice && (
              <DeliveryFeeStatus
                className="cart-item-notification__del-fee-status"
                minimumOrderAmount={Utils.getMinimumOrderAmount(
                  enclosingOrder,
                  vendor
                )}
                orderTotalMinusTaxandFees={orderTotalWithoutTaxesAndFees}
                orderQualifiesForFreeDel={orderQualifiesForFreeDel}
                vendor={vendor}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

CartItemNotification.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    orderUrlSafe: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    genericItem: PropTypes.shape({
      imageURL: PropTypes.string,
    }).isRequired,
  }).isRequired,
  router: PropTypes.shape({
    listenBefore: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

CartItemNotification.defaultProps = {
  dismissAfter: 3000, // defaults to 3 seconds
};

CartItemNotification.propTypes = {
  dismissAfter: PropTypes.number,
};

export default withRouter(CartItemNotification);
