import * as types from '../constants/ActionTypes';

function showErrorStateOnly(errorMessage) {
  return {
    type: types.SHOW_ERROR,
    error: true,
    errorMessage,
  };
}

export function removeError() {
  return {
    type: types.REMOVE_ERROR,
    error: false,
    errorMessage: [],
  };
}

export function showError(errorMessage) {
  return (dispatch) => {
    dispatch(showErrorStateOnly(errorMessage));

    // Remove errors after 6 seconds
    setTimeout(() => {
      dispatch(removeError());
    }, 6000);
  };
}
