import { EPaths, ESettingsPaths } from '@v2/constants/EPaths';
import { Keyboard } from '@capacitor/keyboard';
import React, { FC, useEffect, useMemo } from 'react';
import { isPlatform } from '@ionic/react';
import styles from './IntercomMobile.module.scss';
import { EIonicPlatforms } from '@/constants/Mobile';

type Props = {
    location: {
        pathname: string,
    },
};

const HIDDEN_PATHS = [
    EPaths.SETUP,
    ESettingsPaths.INTEGRATIONS
];

let isKeyboardShowing: boolean = false;

const IntercomMobile: FC<Props> = ({ location }) => {
    // adding this condition since we're only to check the keyboard plugin in native environment
    useEffect(() => {
        if (isPlatform(EIonicPlatforms.CAPACITOR)) {
            Keyboard.addListener('keyboardWillShow', () => {
                isKeyboardShowing = true;
            });

            Keyboard.addListener('keyboardWillHide', () => {
                isKeyboardShowing = false;
            });
        }

        return () => {
            if (isPlatform(EIonicPlatforms.CAPACITOR)) {
                Keyboard.removeAllListeners();
            }
        };
    }, []);

    const hidden = useMemo(() => {
        for (let i = 0; i < HIDDEN_PATHS.length; i++) {
            if (location?.pathname?.includes(HIDDEN_PATHS[i])) {
                return true;
            }
        }

        return false;
    }, [location]);

    return (
        <>
            {((isPlatform(EIonicPlatforms.IOS) || isPlatform(EIonicPlatforms.ANDROID)) && !isPlatform(EIonicPlatforms.TABLET))
                && <div id="intercom-icon-mobile" className={styles.intercomMobile} hidden={hidden || isKeyboardShowing}>
                    <svg className={styles.intercomIconSvg}
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 28 32"
                        width="28"
                        height="32">
                        <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z" />
                    </svg>
                </div>}
        </>);
};

export default IntercomMobile;
