import { CategoryTypes } from '../constants/CategoryTypes';

const initialState = {
  isSearchLoading: false,
  globalSearchText: '',
  globalSearchResults: [],
  totalProductCount: 0,
  relatedProducts: [],
  filters: {
    isOutOfStockIncluded: false,
    isOrganicSelected: false,
    isSpecialPromotionsSelected: false,
    enabledVendors: [],
    vendors: [],
    enabledCategories: [],
    categories: [],
  },
  selectedVendors: [],
  selectedCategories: [],
  isSearchRequestInProgress: false,
  currentCellIndex: -1,
  topLevelCategories: CategoryTypes,
  selectedTopLevelCategory: '',
  page: 0,
  searchData: {},
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_SEARCH_LOADER':
      return Object.assign({}, state, {
        isSearchLoading: action.isSearchLoading,
      });

    case 'CHANGE_GLOBAL_SEARCH_TEXT':
      return Object.assign({}, state, {
        globalSearchText: action.globalSearchText,
      });

    case 'RECEIVE_SEARCH_RESULTS':
      return Object.assign({}, state, {
        globalSearchResults: action.globalSearchResults,
        totalProductCount: action.totalProductCount,
        page: action.page,
        searchData: action.searchData,
      });

    case 'RESET_PAGE':
      return {
        ...state,
        page: 0,
        totalProductCount: 0,
      };

    case 'RECEIVE_RELATED_PRODUCTS_RESULTS':
      return Object.assign({}, state, {
        relatedProducts: action.relatedProducts,
      });

    case 'RECEIVE_SEARCH_VENDOR_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          enabledVendors: state.filters.enabledVendors,
          vendors: action.vendors,
          enabledCategories: state.filters.enabledCategories,
          categories: state.filters.categories,
        },
      });

    case 'RECEIVE_SEARCH_ENABLED_VENDOR_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          enabledVendors: action.enabledVendors,
          vendors: state.filters.vendors,
          enabledCategories: state.filters.enabledCategories,
          categories: state.filters.categories,
        },
      });

    case 'RECEIVE_SEARCH_CATEGORY_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          enabledVendors: state.filters.enabledVendors,
          vendors: state.filters.vendors,
          enabledCategories: state.filters.enabledCategories,
          categories: action.categories,
        },
      });

    case 'RECEIVE_SEARCH_ENABLED_CATEGORY_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          enabledVendors: state.filters.enabledVendors,
          vendors: state.filters.vendors,
          enabledCategories: action.enabledCategories,
          categories: state.filters.categories,
        },
      });

    case 'CLEAR_SEARCH_ENABLED_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          enabledVendors: initialState.filters.enabledVendors,
          enabledCategories: initialState.filters.enabledCategories,
          vendors: state.filters.vendors,
          categories: state.filters.categories,
        },
      });

    case 'FETCH_SEARCH_PRODUCT_RESULTS_IN_PROGRESS':
      return Object.assign({}, state, {
        isSearchRequestInProgress: action.isSearchRequestInProgress,
      });

    case 'SELECT_OUT_OF_STOCK_INCLUDED_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isOutOfStockIncluded: true,
        },
      });

    case 'UNSELECT_OUT_OF_STOCK_INCLUDED_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isOutOfStockIncluded: false,
        },
      });

    case 'SELECT_ORGANIC_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isOrganicSelected: true,
        },
      });

    case 'UNSELECT_ORGANIC_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isOrganicSelected: false,
        },
      });

    case 'SELECT_SPECIAL_PROMOTIONS_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isSpecialPromotionsSelected: true,
        },
      });

    case 'UNSELECT_SPECIAL_PROMOTIONS_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isSpecialPromotionsSelected: false,
        },
      });

    case 'SELECT_VENDOR_FILTER':
      return Object.assign({}, state, {
        selectedVendors: state.selectedVendors.concat(action.newVendor),
      });

    case 'UNSELECT_VENDOR_FILTER':
      return Object.assign({}, state, {
        selectedVendors: state.selectedVendors
          .slice(0, action.index)
          .concat(state.selectedVendors.slice(action.index + 1)),
      });

    case 'CLEAR_SELECTED_VENDOR_FILTERS':
      return Object.assign({}, state, {
        selectedVendors: initialState.selectedVendors,
      });

    case 'SELECT_CATEGORY_FILTER':
      return Object.assign({}, state, {
        selectedCategories: state.selectedCategories.concat(action.newCategory),
      });

    case 'UNSELECT_CATEGORY_FILTER':
      return Object.assign({}, state, {
        selectedCategories: state.selectedCategories
          .slice(0, action.index)
          .concat(state.selectedCategories.slice(action.index + 1)),
      });

    case 'CLEAR_SELECTED_CATEGORY_FILTERS':
      return Object.assign({}, state, {
        selectedCategories: initialState.selectedCategories,
      });

    case 'CHANGE_SEARCH_CELL_INDEX':
      return {
        ...state,
        currentCellIndex: action.currentCellIndex,
      };

    case 'SELECT_TOP_LEVEL_CATEGORY':
      return Object.assign({}, state, {
        selectedTopLevelCategory: action.selectedTopLevelCategory,
      });

    default:
      return state;
  }
}
