import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { toast } from '@notch-ordering/ui-components';
import { tCommon } from '@v2/i18n';
import React from 'react';
import AlertIcon from '@icons/alert-icon.svg';

type TUploadImageActionSheet = {
    handleCamera: () => Promise<void>,
    handlePhotoSelection: () => Promise<void>,
};

enum EActionSheetButtons {
    GALLERY = 0,
    CAMERA = 1,
}

export const uploadImageActionSheet = async ({ handleCamera, handlePhotoSelection }: TUploadImageActionSheet):Promise<void> => {
    try {
        const showActionsResult = await ActionSheet.showActions({
            title: tCommon('Images.photoOptions'),
            message: tCommon('Images.selectAnOption'),
            options: [
                {
                    title: tCommon('Images.chooseFromGallery'),
                },
                {
                    title: tCommon('Images.takePhoto'),
                },
                {
                    title: tCommon('Buttons.cancel'),
                    style: ActionSheetButtonStyle.Cancel
                }
            ],
        });

        if (showActionsResult.index === EActionSheetButtons.CAMERA) {
            await handleCamera();
        } else if (showActionsResult.index === EActionSheetButtons.GALLERY) {
            await handlePhotoSelection();
        }
    } catch (e) {
        toast.show({
            icon: <AlertIcon className="text-red-300"/>,
            message: `${e}`
        });
    }
};
