const initialState = {
  isOrderDeskLoading: false,
};

export default function orderDeskReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_LOADING_ORDER_DESK':
      return {
        ...state,
        isOrderDeskLoading: action.isOrderDeskLoading,
      };

    default:
      return state;
  }
}
