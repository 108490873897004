import React from 'react';
import { PaymentMethods } from '@ar/components/CustomerDetails/PaymentMethods/PaymentMethods';
import { AutoPayAndCardSettings } from '@ar/components/CustomerDetails/AutoPayAndCardSettings/AutoPayAndCardSettings';
import { Separator } from '@notch-ordering/ui-components';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

/**
 * The AR Customer Payments Tab
 *
 * @returns JSX Element
 */
export const CustomerPaymentsTab = function (): JSX.Element {
    useDocumentTitle('Customer Payments - Notch');

    return (
        <>
            <div className="px-10 py-8">
                <PaymentMethods />
            </div>

            <Separator variant="small" />

            <AutoPayAndCardSettings />
        </>
    );
};
