import React, { useState } from 'react';
import { debounce } from 'lodash';
import { Typography, AutocompleteSearch } from '@notch-ordering/ui-components';
import { useGetCustomers } from '@/ar/hooks/queries/CustomerQueries.hook';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { GetCustomerResponse } from '@/ar/network/AccountsReceivable.network';

const formatAddress = (address: GetCustomerResponse['address']): string => {
    const { line1, line2, city, state, postal_code: postalCode } = address;

    return [line1, line2, city, state, postalCode].filter((v) => !!v).join(', ') || '-';
};

export type CustomerOption = {
    name: GetCustomerResponse['name'],
    address: GetCustomerResponse['address'],
    id: GetCustomerResponse['id'],
};

interface CustomerSearchProps {
    onSelect: (option: CustomerOption) => void,
    autoFocus?: boolean,
}

const CustomerSearch: React.FC<CustomerSearchProps> = ({ onSelect, autoFocus }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
    const { supplierLoginData } = useSupplierStore();

    // Debounce the search query
    const debouncedSearch = debounce((query) => {
        setDebouncedSearchQuery(query);
    }, 300);

    // Update the debounced query whenever the search query changes
    React.useEffect(() => {
        debouncedSearch(searchQuery);

        // Cleanup the debounce on unmount
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchQuery]);

    const { data, isLoading } = useGetCustomers({
        supplierID: supplierLoginData?.supplier_id,
        ...debouncedSearchQuery && { name: debouncedSearchQuery },
    });

    const options = data?.customers?.map(
        (customer) => ({
            name: customer.name,
            address: customer.address,
            id: customer.id
        })
    ) || [];

    const optionRenderer = (option: CustomerOption) => {
        const formattedAddress = formatAddress(option.address);

        return (
            <div className="flex flex-col self-start pl-2 w-full">
                <Typography variant="LG-1" className="mb-0">{option.name}</Typography>
                {formattedAddress && (<Typography variant="BASE" className="text-gray-600 mb-0">
                    {formattedAddress}
                </Typography>)}
            </div>
        );
    };

    const handleSearchQueryChange = (value: string) => {
        setSearchQuery(value);
    };

    return (
        <AutocompleteSearch
            searchQuery={searchQuery}
            loading={isLoading}
            loadingText={debouncedSearchQuery ? `Searching customers "${debouncedSearchQuery}" ... ` : 'Loading...'}
            options={options}
            noOptionsText="No customers found"
            customFilterOptions={(x) => x}
            optionRenderer={optionRenderer}
            onInputChanged={handleSearchQueryChange}
            getOptionLabel={(option: CustomerOption) => option.name}
            autoFocus={autoFocus || false}
            openOnFocus={true}
            variant="SMALL"
            onOptionSelect={onSelect}/>
    );
};

export { CustomerSearch };
