import { Badge, GridColDef, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { DraftInvoice, DraftInvoiceStatus } from '@ar/network/AccountsReceivable.network';
import WarningIcon from '@icons/warning-icon.svg';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { DraftInvoiceStatusBadge } from '@/ar/components/Invoices/InvoiceStatusBadge';
import { CopyButton, CopyButtonFeedback } from '@/ar/components/shared/CopyButton';
import { useSupplierStore } from '@/ar/stores/SupplierStore';

export const headerClassName = 'text-gray-600 font-body text-med';
export const cellClassName = 'text-gray-700 font-body text-med';

interface InvoiceRow extends DraftInvoice {
    isHovered: boolean,
}

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
};

export const DRAFT_INVOICE_COLUMNS: Record<string, keyof DraftInvoice | string> = {
    invoiceTotal: 'invoice_total',
    outstandingBalance: 'outstanding_balance',
    invoiceDate: 'invoice_date',
    dueDate: 'due_date',
    invoiceNumber: 'invoice_number',
    customer: 'customer',
    status: 'status'

};

export const MissingInvoiceColumField:React.FC<{ text?: string }> = ({ text = 'Missing' }) => <Badge size="SMALL" variant="ORANGE" className="py-0.5">
    <div className="flex items-center gap-3">
        <WarningIcon className="w- h-4 shrink-0" />
        <Typography variant="BASE" weight="font-medium" className=" m-0">{text}</Typography>
    </div>
</Badge>;

export const DEFAULT_PROCESSING_TEXT = '-';

export const getColumns = (): GridColDef<InvoiceRow>[] => [
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.invoiceNumber,
        headerName: 'Invoice #',
        flex: 1,
        sortable: true,
        minWidth: 140,
        cellClassName: 'overflow-visible', // This must be set for the tooltip to be visible
        renderCell: ({ row }) => {
            if (row.status === DraftInvoiceStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.invoice_number ? <Typography className="truncate">{row.invoice_number} </Typography> : <MissingInvoiceColumField />);
        }
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.status,
        headerName: 'Status',
        minWidth: 120,
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => <DraftInvoiceStatusBadge status={row.status as DraftInvoiceStatus}/>
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.invoiceTotal,
        headerName: 'Total Amount',
        minWidth: 300,
        cellClassName: 'overflow-visible', // This must be set for the popover to be visible
        flex: 1,
        sortable: true,
        renderCell: ({ value, row }) => {
            if (row.status === DraftInvoiceStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (value ? <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography> : <MissingInvoiceColumField />);
        }
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.outstandingBalance,
        headerName: 'Remaining Amount',
        minWidth: 160,
        flex: 1,
        sortable: false,
        renderCell: ({ value, row }) => {
            if (row.status === DraftInvoiceStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (value ? <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography> : <MissingInvoiceColumField />);
        }
    },

    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.invoiceDate,
        headerName: 'Issue date',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => {
            if (row.status === DraftInvoiceStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.invoice_date ? <div>{formatIsoStringToUtcDate(new Date(row.invoice_date).toISOString(), 'MMM d, yyyy')}</div> : <MissingInvoiceColumField />);
        },
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.customer,
        headerName: 'Customer',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => {
            if (row.status === DraftInvoiceStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.customer?.businessName ? <div>{row.customer?.businessName}</div> : <MissingInvoiceColumField text="No match" />);
        },
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.dueDate,
        headerName: 'Due date',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => {
            if (row.status === DraftInvoiceStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.due_date ? <div>{formatIsoStringToUtcDate(new Date(row.due_date).toISOString(), 'MMM d, yyyy')}</div> : <MissingInvoiceColumField />);
        },
    }
];

export const DraftInvoiceEmailButton: React.FC = () => {
    const { supplierLoginData } = useSupplierStore.getState();
    if (!supplierLoginData?.supplier_id) return null;
    const emailToCopy = `${supplierLoginData?.supplier_id}${process.env.DRAFT_INVOICE_EMAIL_ADDRESS}`;

    return (
        <Typography className="text-gray-600 m-0" as="div">
            <div className="flex gap-2 items-center">
                <div className="flex items-center gap-1">
                    <span className="text-gray-600">Email your invoices to </span>
                    <a href={`mailto:${emailToCopy}`}
                        target="_blank"
                        rel="noreferrer"

                        className="text-teal-300">{emailToCopy}</a>
                </div>
                <CopyButton
                    buttonClassName="p-0 bg-gray-25 px-1 py-0.5 rounded-md flex items-center gap-1"
                    buttonText="Copy"
                    iconClassName="m-0"
                    textToCopy={emailToCopy}
                    feedbackType={CopyButtonFeedback.TOOLTIP}
                    successMessage="Email copied to clipboard"
                    errorMessage="Error - Email not copied"/>
            </div>
        </Typography>
    );
};
