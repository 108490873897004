import moment from 'moment';

/*********************************************/
/******* Constants for filters ***************/
/*********************************************/

export const FILTER_TYPE = {
  DUE_DATE: 'dueDate',
  DELIVERY_DATE: 'deliveryDate',
  CUSTOM: 'custom',
  VENDOR: 'vendor',
  PAYMENT_STATUS: 'paymentStatus',
  RECEIVAL_STATUS: 'receivalStatus',
  SEARCH_TERM: 'searchTerm',
};

export const FILTERS = {
  DATE: {
    ALL: '',
    OVERDUE: 'overdue',
    THIS_WEEK: 'this_week',
    NEXT_WEEK: 'next_week',
    THIS_MONTH: 'this_month',
    NEXT_MONTH: 'next_month',
    CUSTOM: 'custom',
  },
  PAYMENT_STATUS: {
    ALL: '',
    UNPAID: 'unpaid',
    PAID: 'paid',
    REFUNDED: 'refunded',
    PROCESSING: 'processing',
  },
  RECEIVAL_STATUS: {
    ALL: '',
    APPROVED: 'confirmed',
    UNCONFIRMED: 'unconfirmed',
    ISSUES_REPORTED: 'issues_reported',
  },
};

export const FILTER_SELECTION = [
  {
    name: FILTER_TYPE.PAYMENT_STATUS,
    label: 'Payment Status',
    dropDownMenuItems: [
      {
        name: 'All payment statuses',
        value: FILTERS.PAYMENT_STATUS.ALL,
      },
      { name: 'Unpaid', value: FILTERS.PAYMENT_STATUS.UNPAID },
      { name: 'Paid', value: FILTERS.PAYMENT_STATUS.PAID },
      { name: 'Refunded', value: FILTERS.PAYMENT_STATUS.REFUNDED },
      { name: 'Processing', value: FILTERS.PAYMENT_STATUS.PROCESSING },
    ],
  },
  {
    name: FILTER_TYPE.RECEIVAL_STATUS,
    label: 'Receival Status',
    dropDownMenuItems: [
      { name: 'All receival statuses', value: FILTERS.RECEIVAL_STATUS.ALL },
      { name: 'Approved', value: FILTERS.RECEIVAL_STATUS.APPROVED },
      { name: 'Unconfirmed', value: FILTERS.RECEIVAL_STATUS.UNCONFIRMED },
      {
        name: 'Issue Reported',
        value: FILTERS.RECEIVAL_STATUS.ISSUES_REPORTED,
      },
    ],
  },
  {
    name: FILTER_TYPE.DUE_DATE,
    label: 'Due Date',
    dropDownMenuItems: [
      { name: 'All due dates', value: FILTERS.DATE.ALL },
      { name: 'Overdue', value: FILTERS.DATE.OVERDUE },
      { name: 'Due this week', value: FILTERS.DATE.THIS_WEEK },
      { name: 'Due next week', value: FILTERS.DATE.NEXT_WEEK },
      { name: 'Due this month', value: FILTERS.DATE.THIS_MONTH },
      { name: 'Due next month', value: FILTERS.DATE.NEXT_MONTH },
      { name: 'Select range...', value: FILTERS.DATE.CUSTOM },
    ],
  },
  {
    name: FILTER_TYPE.DELIVERY_DATE,
    label: 'Delivery Date',
    dropDownMenuItems: [
      { name: 'All delivery dates', value: FILTERS.DATE.ALL },
      { name: 'Delivery this week', value: FILTERS.DATE.THIS_WEEK },
      { name: 'Delivery next week', value: FILTERS.DATE.NEXT_WEEK },
      { name: 'Delivery this month', value: FILTERS.DATE.THIS_MONTH },
      { name: 'Delivery next month', value: FILTERS.DATE.NEXT_MONTH },
      { name: 'Select range...', value: FILTERS.DATE.CUSTOM },
    ],
  },
];

/*********************************************/
/******* Helper functions for Filters ********/
/*********************************************/
export const filterVendors = (vendorsInfo) => {
  const vendors = vendorsInfo
    .map((vendor) => {
      return { name: vendor.name, value: vendor.id };
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  vendors.unshift({ name: 'All suppliers', value: '' });
  return vendors;
};

export const getInvoiceFilterText = (menuItems, value) => {
  const item = menuItems.find((menuItem) => {
    return menuItem.value === value;
  });
  if (item && item.name) {
    return item.name;
  }
  return value;
};

export const getInvoiceFilter = (filter, value) => {
  if (filter === FILTER_TYPE.DUE_DATE && value === FILTERS.DATE.OVERDUE) {
    return {
      dueDate: value,
      paymentStatus: FILTERS.PAYMENT_STATUS.UNPAID,
    };
  }
  return { [filter]: value };
};

export const getStartEndDate = (dateFilter) => {
  const dates = {
    startDate: dateToString(moment()),
    endDate: dateToString(moment()),
  };

  const day = 'day';
  const month = 'month';
  const week = 'week';

  switch (dateFilter) {
    case FILTERS.DATE.OVERDUE:
      dates.startDate = dateToString(moment('0001-01-01'));
      dates.endDate = dateToString(moment().endOf('day').subtract(1, day));
      break;
    case FILTERS.DATE.THIS_MONTH:
      dates.startDate = dateToString(moment().startOf('month'));
      dates.endDate = dateToString(
        moment().add(1, month).startOf('month').subtract(1, day)
      );
      break;
    case FILTERS.DATE.NEXT_MONTH:
      dates.startDate = dateToString(moment().add(1, month).startOf('month'));
      dates.endDate = dateToString(moment().add(1, month).endOf('month'));
      break;
    case FILTERS.DATE.THIS_WEEK:
      dates.startDate = dateToString(moment().startOf('week'));
      dates.endDate = dateToString(
        moment().add(1, week).startOf('week').subtract(1, day)
      );
      break;
    case FILTERS.DATE.NEXT_WEEK:
      dates.startDate = dateToString(moment().add(1, week).startOf('week'));
      dates.endDate = dateToString(moment().add(1, week).endOf('week'));
      break;
    default:
      return { startDate: '', endDate: '' };
  }
  return dates;
};

export const dateToString = (date) => {
  return date.utc().toISOString();
};

export const getFormattedStartEndDateFilter = (startDate, endDate) => {
  return `${`${moment(startDate).format('MMM D')}
          - ${moment(endDate).format('MMM D')}`}`;
};
