import { batch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { addChatChannels } from './chatChannelsListActions';
import { addChatMessages } from './chatMessagesActions';
import { changeRoute } from '../../../actions/routerActions';
import { CHAT_CHANNEL_TEMP_NAME_STARTS_WITH } from '../../../constants/GlobalConstants';
import { logException } from '../../../domains/shared/logger';

// #region Action Types
export const SET_SELECTED_CHAT = 'SET SELECTED CHAT';
export const SET_SELECTED_CHAT_SUCCESS = 'SET SELECTED CHAT SUCCESS';
export const SET_SELECTED_CHAT_FAILED = 'SET SELECTED CHAT FAILED';

export const CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE =
  'CREATE OR SELECT CHAT CHANNEL IN STORE';
export const CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_SUCCESS =
  'CREATE OR SELECT CHAT CHANNEL IN STORE SUCCESS';
export const CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_FAILED =
  'CREATE OR SELECT CHAT CHANNEL IN STORE FAILED';
// #endregion Action Types

// #region Actions
/**
 * Sets the given Chat channel sid as selected.
 *
 * @param {string} sid Channel unique sid
 */
export const setChatSelected =
  (sid, override = false) =>
  (dispatch) => {
    if (!sid && !override) return;

    try {
      batch(() => {
        // Executes both of the dispatch's together and avoids extra render of app
        dispatch({ type: SET_SELECTED_CHAT });
        dispatch({ type: SET_SELECTED_CHAT_SUCCESS, sid });
      });
    } catch (error) {
      handleCatch(SET_SELECTED_CHAT_FAILED, 'setChatSelected', error, dispatch);
    }
  };

export const later = (delay) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

export const createOrSelectGeneralChatChannelInStore =
  ({ customerUrlsafe, vendorUrlsafe, vendorName }) =>
  async (dispatch, getState) => {
    if (!customerUrlsafe || !vendorName) {
      dispatch({ type: CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_FAILED });
      return;
    }

    try {
      dispatch({ type: CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE });
      // Navigate to Inbox view
      dispatch(changeRoute('/inbox/'));

      const { channels } = getState().chat;

      let matchedSid = '';
      // Find the channelID in Chat store
      if (channels && channels.list) {
        Object.entries(channels.list).some(
          ([, { channelAttributes, channel }]) => {
            // Add a condition 'channelAttributes.orderNumber' as sometime on data refresh on staging we loose the order numbers,
            // but same is available in Twilio chat channels. Until we've a functionality to mark chat channels as 'archived' as well as not to
            // show them on the UI, this extra check helps.
            if (
              channelAttributes &&
              channelAttributes.vendorUrlsafe === vendorUrlsafe
            ) {
              matchedSid = channel.sid;
              return true;
            }
            return false;
          }
        );
      }

      if (!matchedSid) {
        // Create a new Chat channel (in local store)
        matchedSid = `${CHAT_CHANNEL_TEMP_NAME_STARTS_WITH}${customerUrlsafe}`;
        const channel = {
          sid: matchedSid,
          state: {
            attributes: {
              channel_id: customerUrlsafe,
              vendor_id: vendorUrlsafe,
              vendor_Name: vendorName,
            },
            lastMessage: {
              timestamp: new Date(),
            },
          },
          setAllMessagesConsumed: () => {},
        };
        batch(() => {
          dispatch(addChatChannels([channel]));
          dispatch(addChatMessages(channel, [], {}));
        });
      }

      batch(() => {
        dispatch({ type: CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_SUCCESS });
        // Set the matched sid as selected sid
        dispatch(setChatSelected(matchedSid));
      });
    } catch (error) {
      handleCatch(
        CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_FAILED,
        'createOrSelectChatChannelInStore',
        error,
        dispatch
      );
    }
  };

/**
 * A method to either create a Chat channel in store (local only) or Select one and switch to (and show inbox view) it if already exists
 *
 * @param {Object} param0 orderNumber, vendorUrlsafe and orderStatus
 */
export const createOrSelectChatChannelInStore =
  ({ order }) =>
  async (dispatch, getState) => {
    const {
      id: orderNumber,
      vendorUrlsafe,
      orderBuyerStatus: orderStatus,
    } = order;

    const { isOrderChat } = getState().feature;

    if (!isOrderChat) {
      dispatch(
        createOrSelectGeneralChatChannelInStore({
          customerUrlsafe: order.customerUrlsafe,
          vendorUrlsafe: order.vendorUrlsafe,
          vendorName: order.vendorName,
        })
      );
      return;
    }

    if (!orderNumber) {
      dispatch({ type: CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_FAILED });
      return;
    }

    try {
      dispatch({ type: CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE });
      // Navigate to Inbox view
      dispatch(changeRoute('/inbox/'));

      const { channels } = getState().chat;

      let matchedSid = '';
      // Find the orderNumber in Chat store
      if (channels && channels.list) {
        Object.entries(channels.list).some(([sid, { channelAttributes }]) => {
          // Add a condition 'channelAttributes.orderNumber' as sometime on data refresh on staging we loose the order numbers,
          // but same is available in Twilio chat channels. Until we've a functionality to mark chat channels as 'archived' as well as not to
          // show them on the UI, this extra check helps.
          if (
            channelAttributes &&
            channelAttributes.orderNumber &&
            channelAttributes.orderNumber.toString() === orderNumber.toString()
          ) {
            matchedSid = sid;
            return true;
          }
          return false;
        });
      }

      if (!matchedSid) {
        // Create a new Chat channel (in local store)
        matchedSid = `${CHAT_CHANNEL_TEMP_NAME_STARTS_WITH}${orderNumber}`;
        const channel = {
          sid: matchedSid,
          state: {
            attributes: {
              vendor_id: vendorUrlsafe,
              order_number: orderNumber,
              order_status: orderStatus,
            },
            lastMessage: {
              timestamp: new Date(),
            },
          },
          setAllMessagesConsumed: () => {},
        };
        batch(() => {
          dispatch(addChatChannels([channel]));
          dispatch(addChatMessages(channel, [], {}));
        });
      }

      batch(() => {
        dispatch({ type: CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_SUCCESS });
        // Set the matched sid as selected sid
        dispatch(setChatSelected(matchedSid));
      });
    } catch (error) {
      handleCatch(
        CREATE_OR_SELECT_CHAT_CHANNEL_IN_STORE_FAILED,
        'createOrSelectChatChannelInStore',
        error,
        dispatch
      );
    }
  };
// #endregion Actions

// #region Helper methods
/**
 * Handle Catch method in case Action is failed to execute.
 *
 * @param {string} type Action Type
 * @param {string} methodname Error occured in method name
 * @param {object} error Error object
 * @param {Function} dispatch Redux dispatch function
 */
const handleCatch = (type, methodname, error, dispatch) => {
  dispatch({ type });

  let errorMessage = '';
  if (!error.response)
    errorMessage = 'Detected a connection problem, please refresh this page';
  else
    errorMessage =
      (((error || {}).response || {}).data || {}).message || 'Please try again';

  toastr.error(`Error: ${errorMessage}`);
  // eslint-disable-next-line no-console
  console.error(`An Error occured with ${methodname} ${error}`);
  logException(error);
};
// #endregion Helper methods
