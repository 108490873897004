import create from 'zustand';
import { GetTransactionItem, Transaction } from '../network/AccountsReceivable.network';

export enum DraftInvoiceModals {
    LineItem = 'lineItemsModal',
    DeleteItem = 'deleteItemModal',
    DuplicateInvoiceNumberConfirmModal = 'duplicateInvoiceNumberConfirmModal',
    EditInvoiceConfirmationModal = 'editInvoiceConfirmationModal',
}

export type DraftInvoiceStoreState = {
    modals: Partial<Record<DraftInvoiceModals, boolean>> & {
        selectedItem?: GetTransactionItem,
        invoice?: Transaction,
    },

};

type DraftInvoiceStoreActions = {
    setModal: (modal: DraftInvoiceModals, show: boolean) => void,
    openModal: (modalType: DraftInvoiceModals, lineItem?: GetTransactionItem, invoice?: Transaction) => void,
    closeModal: (modalType: DraftInvoiceModals) => void, };

export type DraftInvoiceStore = DraftInvoiceStoreState & DraftInvoiceStoreActions;

const DRAFT_INVOICE_STORE_INITIAL_STATE: DraftInvoiceStoreState = {
    modals: {
        selectedItem: null,
        invoice: null,
        [DraftInvoiceModals.LineItem]: false,
        [DraftInvoiceModals.DeleteItem]: false,
        [DraftInvoiceModals.DuplicateInvoiceNumberConfirmModal]: false,
        [DraftInvoiceModals.EditInvoiceConfirmationModal]: false,
    },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/**
 * Defines the draft invoice store with initial state and modal management functions this is intended for draft invoice details page.
 *
 * @param set - Function to update the state.
 * @param getState - Function to get the current state.
 * @returns The draft invoice store with initial state and modal management functions.
 * @property {function(DraftInvoiceModals, boolean): void} setModal - Function to set the visibility of a modal.
 * @param modal - The modal to be shown or hidden.
 * @param show - Boolean indicating whether to show or hide the modal.
 * @property {function(DraftInvoiceModals, GetTransactionItem, Transaction): void} openModal - Function to open a modal and set the selected item.
 * @param modalType - The type of modal to open.
 * @param lineItem - The line item to be selected.
 * @param invoice - The invoice (arTransaction).
 * @property {function(DraftInvoiceModals): void} closeModal - Function to close a modal and clear the selected item.
 * @param modalType - The type of modal to close.
 */

export const draftInvoiceStoreDefinition = (set): DraftInvoiceStore => ({
    ...DRAFT_INVOICE_STORE_INITIAL_STATE,
    setModal: (modal: DraftInvoiceModals, show: boolean) => set((state) => ({
        modals: {
            ...state.modals,
            [modal]: show,
        },
    })),
    openModal: (modalType: DraftInvoiceModals, lineItem?: GetTransactionItem, invoice?: Transaction) => {
        set((state) => ({
            modals: {
                ...state.modals,
                selectedItem: lineItem,
                invoice,
                [modalType]: true,
            },
        }));
    },
    closeModal: (modalType: DraftInvoiceModals) => {
        set((state) => ({
            modals: {
                ...state.modals,
                selectedItem: null,
                invoice: null,
                [modalType]: false,
            },
        }));
    },

});

export const useDraftInvoiceStore = create<DraftInvoiceStore>(draftInvoiceStoreDefinition);
