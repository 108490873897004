import BigNumber from 'bignumber.js';

export function dollarsToCents(dollars: number, round = false): number {
    const cents = dollars * 100;
    if (round) return Math.round(cents);
    return cents;
}

export function centsToDollars(cents: number): number {
    return cents * 0.01;
}

export function getCurrencyFormatFromCents(cents: BigNumber): string {
    if (cents === null) {
        return '$0.00';
    }
    const valueFormatted = cents.dividedBy(100).toFixed(2);
    return `$${valueFormatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function getCurrencyFormat(dollars: BigNumber): string {
    if (dollars === null) {
        return '$0.00';
    }
    const valueFormatted = dollars.toFixed(2);
    return `$${valueFormatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}
