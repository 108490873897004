import create from 'zustand';
import { persist } from 'zustand/middleware';

export const DRAFT_INVOICE_PREFERENCES_STORE_NAME = 'draftInvoicePreferences';

type DraftInvoicePreferences = {
    disableEditInvoiceModal: boolean,
};

export type DraftInvoicePreferencesStoreState = {
    draftInvoicePreferences: DraftInvoicePreferences,
};

type DraftInvoicePreferencesStoreActions = {
    setPreference: (preference: keyof DraftInvoicePreferences, value: boolean) => void,
};

export type DraftInvoicePreferencesStore = DraftInvoicePreferencesStoreState & DraftInvoicePreferencesStoreActions;

const DEFAULT_DRAFT_INVOICE_PREFERENCES: DraftInvoicePreferencesStoreState = {
    draftInvoicePreferences: {
        disableEditInvoiceModal: false,
    },
};

export const draftInvoicePreferencesStore = (set): DraftInvoicePreferencesStore => ({
    ...DEFAULT_DRAFT_INVOICE_PREFERENCES,
    setPreference: (preference: keyof DraftInvoicePreferences, value: boolean) => set((state) => ({
        draftInvoicePreferences: {
            ...state.draftInvoicePreferences,
            [preference]: value,
        },
    })),
});

export const useDraftInvoicePreferencesStore = create<DraftInvoicePreferencesStore>()(
    persist(draftInvoicePreferencesStore, {
        name: DRAFT_INVOICE_PREFERENCES_STORE_NAME,
    })
);
