const initialState = {
  myOrders: [],
  filters: {
    suppliers: [],
    dates: ['Last 6 Months Onwards'],
    customDate: { startDate: null, endDate: null },
    sortBy: '',
  },
  pagination: {
    page: 1,
    totalPages: 0,
  },
  // Based off decisions made on what the filters mean, only "Last 3 Months" and "Last 6 Months" will include future orders
  dateOptions: [
    'This Month',
    'Last Month',
    'Last 3 Months Onwards',
    'Last 6 Months Onwards',
    'Custom Date Range',
  ],
  supplierOptions: [],
  isMyOrdersLoading: false,
  isOrderDetailModalOpen: false,
};

export default function orderHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_LOADER_ORDER_HISTORY':
      return Object.assign({}, state, {
        isMyOrdersLoading: action.isMyOrdersLoading,
      });

    case 'RECEIVE_ORDER_HISTORY':
      return Object.assign({}, state, {
        myOrders: action.myOrders,
        pagination: action.pagination,
      });

    case 'RECEIVE_SUPPLIER_FILTERS':
      return Object.assign({}, state, {
        supplierOptions: action.supplierOptions,
      });

    case 'TOGGLE_ORDER_DETAIL_MODAL':
      return {
        ...state,
        isOrderDetailModalOpen: action.isOrderDetailModalOpen,
      };

    case 'CHANGE_SUPPLIER_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          suppliers: action.suppliers,
        },
      });

    case 'CHANGE_DATE_FILTERS':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          dates: action.dates,
        },
      });

    case 'CHANGE_SORT_BY_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          sortBy: action.sortBy,
        },
      });

    case 'CHANGE_CURRENT_PAGE_FILTER':
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          page: action.currentPage,
        },
      });

    case 'CHANGE_CUSTOM_DATE_FILTER':
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          customDate: {
            startDate: action.startDate,
            endDate: action.endDate,
          },
        },
      });

    case 'CLEAR_ORDER_FILTERS':
      return Object.assign({}, state, {
        filters: {
          statuses: initialState.filters.statuses,
          suppliers: initialState.filters.suppliers,
          dates: initialState.filters.dates,
          customDate: initialState.filters.customDate,
          sortBy: initialState.filters.sortBy,
        },
        pagination: initialState.pagination,
      });

    default:
      return state;
  }
}
