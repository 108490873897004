import { Button, GridColDef, GridColumns, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { IntegrationActiveRowMoreButton } from '@ar/components/Integrations/IntegrationsActive/IntegrationActiveRowMoreButton/IntegrationActiveRowMoreButton';
import FidelioLogo from '@ar/assets/images/fidelio-logo.png';
import NetSuiteLogo from '@ar/assets/images/netsuite-logo.svg';
import QuickbooksLogo from '@ar/assets/images/quickbooks-logo.svg';
import XeroLogo from '@ar/assets/images/xero-logo.svg';
import Sage300Logo from '@ar/assets/images/sage-300-logo.png';
import SpireLogo from '@ar/assets/images/spire-logo.svg';
import CsvLogo from '@ar/assets/images/csv-logo.svg';
import PdfLogo from '@ar/assets/images/pdf-logo.svg';
import RefreshIcon from '@icons/refresh-icon.svg';
import cx from 'classnames';
import { mergeClassNames } from '@v2/utils/Helpers';
import CheckIcon from '@icons/check-icon.svg';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { EIntegrationType } from '@/ar/network/AccountsReceivable.network';
import { EConfigurationType } from '@/ar/network/Bushwhack.network';
import Utils from '@/utils';

const headerClassName = 'text-gray-600 font-body text-med';
const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
    sortable: false,
};
export type IntegrationDetails = {
    label: string,
    logo: JSX.Element | null,
};
export const getIntegrationDetails = (integration: string):IntegrationDetails => {
    const integrationName = integration?.toLowerCase();
    switch (integrationName) {
        case EIntegrationType.Fidelio:
            return {
                label: 'Fidelio',
                logo: <img src={FidelioLogo} />,
            };
        case EIntegrationType.NetSuite:
            return {
                label: 'NetSuite',
                logo: <NetSuiteLogo />,
            };
        case EIntegrationType.QBO:
        case EIntegrationType.QBD:
        case 'quickbooks':
        case 'qbo_v2':
            return {
                label: 'Quickbooks Online',
                logo: <QuickbooksLogo/>,
            };
        case EIntegrationType.Xero:
            return {
                label: 'Xero',
                logo: <XeroLogo />,
            };
        case EIntegrationType.Sage300:
            return {
                label: 'Sage 300',
                logo: <img src={Sage300Logo} />,
            };
        case EIntegrationType.Spire:
            return {
                label: 'Spire',
                logo: <SpireLogo/>,
            };
        case EIntegrationType.CsvImport:
            return {
                label: 'CSV Import',
                logo: <CsvLogo/>,
            };
        case EIntegrationType.PdfImport: {
            return {
                label: 'PDF Import',
                logo: <PdfLogo/>,
            };
        }
        default:
            return {
                label: integration,
                logo: null,
            };
    }
};

export const INTEGRATION_COLUMNS = {
    name: 'name',
    isEnabled: 'isEnabled',
    lastSyncedAt: 'last_synced_at',
    createdAt: 'created_at',
    action: 'action',
    paymentAccountID: 'paymentAccountID'

};

export const getColumns = (integrationType: EConfigurationType): GridColumns => [
    {
        ...commonGridDef,
        field: INTEGRATION_COLUMNS.name,
        headerName: 'Name',
        flex: 1,
        renderCell: ({ row }): React.ReactNode => {
            const integration = getIntegrationDetails(row.name);
            return (
                <div className="flex items-center">
                    {!!integration?.logo && <div className="w-[30px]">
                        {integration.logo}
                    </div>}
                    <Typography weight="font-medium" className="ml-4">{integration.label}</Typography>
                </div>
            );
        },
    },
    {
        ...commonGridDef,
        field: INTEGRATION_COLUMNS.isEnabled,
        headerName: 'Status',
        flex: 1,
        renderCell: ({ row }): React.ReactNode => {
            const { isEnabled, isConnected, integrationSyncStatus, type } = row;
            const typeIsQboOrRutter = [EConfigurationType.ArRutter, EConfigurationType.QBO].includes(type);
            const isEnabledAndSynced = isEnabled && (!typeIsQboOrRutter || integrationSyncStatus);
            const isEnabledAndSyncing = (isEnabled && isConnected) && (typeIsQboOrRutter && integrationSyncStatus === false);
            const isDisabled = !isEnabled;
            return (
                <div className="flex items-center gap-2">
                    <div className={mergeClassNames('w-4 h-4 rounded-full flex items-center justify-center', {
                        'bg-green-100': isEnabledAndSynced,
                        'bg-gray-100': isEnabledAndSyncing,
                        'bg-red-100': isDisabled
                    })}>
                        <div className={mergeClassNames('w-2 h-2 rounded-full', {
                            'bg-green-300': isEnabledAndSynced,
                            'bg-gray-500': isEnabledAndSyncing,
                            'bg-red-500': isDisabled

                        })} />
                    </div>
                    {isEnabledAndSynced && <Typography weight="font-medium m-0" className="text-gray-600">{'Connected'}</Typography>}
                    {isEnabledAndSyncing && <div className="flex flex-row justify-center items-center">
                        <Typography weight="font-medium m-0" className="text-gray-600">{'Initial sync in progress'}</Typography>
                        <Button
                            variant="ICON"
                            size="NO_BACKGROUND"
                            className="mt-2"
                            onClick={(): void => { window.location.reload(); }}>
                            <RefreshIcon className={cx('w-4 h-4', { 'animate-spin': false })}/>
                        </Button>
                    </div>}
                    {isDisabled && <Typography weight="font-medium m-0" className="text-red-500">{'Disabled'}</Typography>}
                </div>
            );
        },
    },
    {
        ...commonGridDef,
        field: INTEGRATION_COLUMNS.lastSyncedAt,
        headerName: 'Last sync',
        hide: ![EConfigurationType.ArRutter, EConfigurationType.QBO].includes(integrationType),
        renderCell: ({ row }) => (row?.last_synced_at ? Utils.formatDate(row?.last_synced_at, 'MMM D, YYYY h:mm a') : '--'),
        flex: 1,
    },
    {
        ...commonGridDef,
        field: INTEGRATION_COLUMNS.createdAt,
        headerName: 'Connected on',
        flex: 1,
        renderCell: ({ row }) => (row?.created_at ? <div>{formatIsoStringToUtcDate(new Date(row.created_at).toISOString(), 'MMM d, yyyy')}</div> : '--'),
    },
    {
        ...commonGridDef,
        field: INTEGRATION_COLUMNS.paymentAccountID,
        headerName: 'Setup complete',
        flex: 1,
        hide: ![EConfigurationType.ArRutter, EConfigurationType.QBO].includes(integrationType),
        renderCell: ({ row }) => {
            const typeQboOrRutter = [EConfigurationType.ArRutter, EConfigurationType.QBO].includes(row.type);
            return (<div>
                {(typeQboOrRutter && row.paymentAccountID) && <CheckIcon className="text-green-300 w-4 h-4" />}
                {(typeQboOrRutter && !row.paymentAccountID) && <Typography>-</Typography>}
            </div>);
        },
    },
    {
        ...commonGridDef,
        field: INTEGRATION_COLUMNS.action,
        headerName: '',
        cellClassName: 'overflow-visible', // This must be set for the popover to be visible
        width: 40,
        renderCell: (param) => (param.row.id
            ? <div onClick={(e): void => e.stopPropagation()}>
                <IntegrationActiveRowMoreButton param={param} />
            </div>
            : null
        ),
    },
];
