import { Loading, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { getCurrencyFormatFromCents } from '@v2/utils/CurrencyUtils';
import BigNumber from 'bignumber.js';
import { EstimatedSurcharge, GetPaymentMethodResponse, Transaction } from '@/ar/network/AccountsReceivable.network';

export const getEstimatedSurcharge = (paymentMethod: GetPaymentMethodResponse, transaction: Transaction): EstimatedSurcharge => {
    const targetBrand = paymentMethod?.brand?.toLowerCase();
    const estimatedSurcharges = transaction.estimated_surcharges;
    return estimatedSurcharges?.find((surcharge) => surcharge?.paymentType?.type?.toLowerCase() === targetBrand);
};

export const getTotalEstimatedSurcharge = (paymentMethod: GetPaymentMethodResponse, transactions: Transaction[]): BigNumber => {
    let estimatedSurchargeAmount = new BigNumber(0);
    if (paymentMethod) {
        transactions?.forEach((transaction) => {
            const estimatedSurcharge = getEstimatedSurcharge(paymentMethod, transaction);
            if (estimatedSurcharge) {
                estimatedSurchargeAmount = estimatedSurchargeAmount.plus(estimatedSurcharge.estimatedSurcharge ?? 0);
            }
        });
    }
    return estimatedSurchargeAmount;
};

export const formatSurchargeAmount = (surchargeAmount: BigNumber): string => {
    const positivePrefix = '+';
    const formattedAsCurrency = getCurrencyFormatFromCents(surchargeAmount);
    return surchargeAmount.isGreaterThan(0) ? (positivePrefix + formattedAsCurrency) : formattedAsCurrency;
};

export const isPaymentMethodBrandKnown = (paymentMethod: GetPaymentMethodResponse, transactions: Transaction[]): boolean => {
    const isNotACard = paymentMethod?.type !== 'card';
    return isNotACard || transactions?.some((transaction) => (getEstimatedSurcharge(paymentMethod, transaction)));
};

export const getSurchargeDisplay = (paymentMethod: GetPaymentMethodResponse, transactions: Transaction[]): JSX.Element => {
    if (!isPaymentMethodBrandKnown(paymentMethod, transactions)) {
        return <Loading isDark />;
    }
    return (<Typography variant="BASE" weight="font-regular" className="text-gray-650 m-0 mb-1">
        {formatSurchargeAmount(getTotalEstimatedSurcharge(paymentMethod, transactions))}
    </Typography>);
};
