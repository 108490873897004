export const AnalyticsEventSources = {
  CART: 'cart',
  CHECKOUT: 'checkout',
  ORDER_GUIDE: 'orderGuide',
  ORDER_DETAILS: 'orderDetails',
  MARKET: 'market',
  MY_ORDERS: 'myOrders',
  NAVIGATION_BAR: 'navigationBar',
  PRODUCT_DETAILS: 'productDetails',
};

export const AnalyticsSupplierType = {
  BYOS: 'byos',
  MARKET: 'market',
};

export const AnalyticsItemType = {
  CUSTOM_ITEM: 'customItem',
  INVENTORY_ITEM: 'inInventory',
};

export const AnalyticsAddSupplierSources = {
  MARKET: 'market',
  ORDER_DESK: 'orderDesk',
  ONBOARIDING_ADD_SUPPLIER: 'onboardingAddSupplier',
};
