import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const getIsMobileWidth = (): boolean => window.innerWidth < 1024;

const useIsMobileWidth = (): boolean => {
    const [isMobileWidth, setIsMobileWidth] = useState(getIsMobileWidth());

    useEffect(() => {
        const onResize = (): void => {
            setIsMobileWidth(getIsMobileWidth());
        };

        window.addEventListener('resize', debounce(onResize, 100));

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return isMobileWidth;
};

export default useIsMobileWidth;
