import {
  SEARCH_START,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  SEARCH_CLEAR,
} from '../types';

export const initialState = {
  loading: false,
  results: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_START:
      return {
        ...state,
        loading: true,
      };

    case SEARCH_SUCCESS:
      return {
        loading: false,
        results: action.payload,
      };

    case SEARCH_FAILED:
      return {
        loading: false,
        results: [],
      };

    case SEARCH_CLEAR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
