import { orderInvoicesRangeTypes } from '../constants/EnumTypes';
import * as types from '../constants/ActionTypes';
import {
  FILTER_TYPE,
  FILTERS,
  getInvoiceFilter,
  getStartEndDate,
  getFormattedStartEndDateFilter,
} from '../containers/invoices/InvoiceHeader/constants';

export const initialState = {
  imagesModal: {
    open: false,
    invoiceId: '',
    invoiceNumber: '',
    images: [],
    vendorName: '',
  },
  orderInvoicesRangeTypeSelected: orderInvoicesRangeTypes.ALL,
  invoicesStartDate: '',
  invoicesEndDate: '',
  invoicesDueDateStart: '',
  invoicesDueDateEnd: '',
  invoicesDeliveryDateStart: '',
  invoicesDeliveryDateEnd: '',
  selectedOrderInvoiceIDs: [],
  isOrderInvoicesLoading: false,
  page: 0,
  orderInvoices: [],
  totalResults: 0,
  cancelToken: '',
  sortBy: '',
  sortDir: '',
  displayDateRange: false,
  invoiceFilters: {
    vendor: '',
    paymentStatus: '',
    receivalStatus: '',
    dueDate: '',
    deliveryDate: '',
    searchTerm: '',
  },
  isPayingInvoices: false,
  isPaying: false,
  isMarkingAsPaid: false,
};

export default function orderInvoicesReducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_INVOICE_IMAGES_MODAL:
      const imagesModal = action.payload.open
        ? action.payload
        : initialState.imagesModal;
      return {
        ...state,
        imagesModal,
      };

    case types.RECEIVE_ORDER_INVOICES_PENDING:
      return {
        ...state,
        cancelToken: action.payload,
        isOrderInvoicesLoading: true,
      };

    case types.RECEIVE_ORDER_INVOICES_REJECTED:
      return {
        ...state,
        isOrderInvoicesLoading: false,
      };

    case types.SET_ORDER_INVOICES_SORT:
      const { sortBy, sortDir } = action.payload;
      return {
        ...initialState,
        invoiceFilters: {
          ...state.invoiceFilters,
        },
        sortBy,
        sortDir,
      };

    case types.SET_ORDER_INVOICES_FILTER:
      const { filter, value } = action.payload;
      if (
        filter === FILTER_TYPE.PAYMENT_STATUS &&
        value !== FILTERS.PAYMENT_STATUS.UNPAID &&
        state.invoiceFilters.dueDate === FILTERS.DATE.OVERDUE
      ) {
        return {
          ...initialState,
          invoiceFilters: {
            ...state.invoiceFilters,
            dueDate: '',
            deliveryDate: '',
            ...getInvoiceFilter(filter, value),
          },
        };
      }

      const newState = {
        ...initialState,
        invoicesDueDateStart: state.invoicesDueDateStart,
        invoicesDueDateEnd: state.invoicesDueDateEnd,
        invoicesDeliveryDateStart: state.invoicesDeliveryDateStart,
        invoicesDeliveryDateEnd: state.invoicesDeliveryDateEnd,
        invoiceFilters: {
          ...state.invoiceFilters,
          ...getInvoiceFilter(filter, value),
        },
      };

      if (filter === FILTER_TYPE.DUE_DATE) {
        const { startDate, endDate } = getStartEndDate(value);
        newState.invoicesDueDateStart = startDate;
        newState.invoicesDueDateEnd = endDate;
      } else if (filter === FILTER_TYPE.DELIVERY_DATE) {
        const { startDate, endDate } = getStartEndDate(value);
        newState.invoicesDeliveryDateStart = startDate;
        newState.invoicesDeliveryDateEnd = endDate;
      }
      return newState;

    case types.UPDATE_ORDER_INVOICE_DUE_DATE_FILTER:
      const { startDate: dueDateStart, endDate: dueDateEnd } = action.payload;
      const formattedDueDate = getFormattedStartEndDateFilter(
        dueDateStart,
        dueDateEnd
      );
      return {
        ...initialState,
        invoicesDueDateStart: dueDateStart,
        invoicesDueDateEnd: dueDateEnd,
        displayDateRange: false,
        invoiceFilters: {
          ...state.invoiceFilters,
          dueDate: formattedDueDate,
        },
      };

    case types.UPDATE_ORDER_INVOICE_DELIVERY_DATE_FILTER:
      const { startDate: deliveryDateStart, endDate: deliveryDateEnd } =
        action.payload;
      const formattedDeliveryDate = getFormattedStartEndDateFilter(
        deliveryDateStart,
        deliveryDateEnd
      );
      return {
        ...initialState,
        invoicesDeliveryDateStart: deliveryDateStart,
        invoicesDeliveryDateEnd: deliveryDateEnd,
        displayDateRange: false,
        invoiceFilters: {
          ...state.invoiceFilters,
          deliveryDate: formattedDeliveryDate,
        },
      };

    case types.PAY_INVOICES_PENDING:
      return {
        ...state,
        isPayingInvoices: true,
      };

    case types.PAY_INVOICES_REJECTED:
    case types.PAY_INVOICES_FULFILLED:
      return {
        ...state,
        isPayingInvoices: false,
      };

    case types.MARKING_AS_PAID_PENDING:
      return {
        ...state,
        isMarkingAsPaid: true,
      };

    case types.MARKING_AS_PAID_FULFILLED:
      return {
        ...state,
        isMarkingAsPaid: false,
      };

    case types.RECEIVE_ORDER_INVOICES_FULFILLED:
      let invoices = [...state.orderInvoices, ...action.payload.invoices];
      if (action.payload.offset === 0) {
        invoices = action.payload.invoices;
      }

      return Object.assign({}, state, {
        orderInvoices: invoices,
        totalResults: action.payload.total_count,
        page: action.payload.offset + action.payload.limit,
        cancelToken: '',
        isOrderInvoicesLoading: false,
      });

    case types.RECEIVE_ORDER_SYNC_DETAILS:
      return Object.assign({}, state, {
        orderInvoices: state.orderInvoices.map(
          (invoice) => invoice.order_id === action.payload.orderId ? {...invoice, orderSyncDetails: action.payload.orderSyncDetails} : invoice
        )
      });

    case types.MARKING_ORDER_SYNC_IN_PROGRESS:
      return {
        ...state,
        orderInvoices: state.orderInvoices.map(
          (invoice) => invoice.order_id === action.payload.orderId ? {...invoice, orderSyncDetails: {...invoice.orderSyncDetails, isSyncInProgress: action.payload.syncInProgress ?? true }} : invoice
        ),
      };

    case types.RESET_ORDER_INVOICES_STATE:
      return initialState;

    case 'SELECT_ORDER_INVOICE_TYPE':
      return Object.assign({}, state, {
        orderInvoicesRangeTypeSelected: action.orderInvoicesRangeTypeSelected,
      });

    case 'SHOW_LOADER_ORDER_INVOICES':
      return Object.assign({}, state, {
        isOrderInvoicesLoading: action.isOrderInvoicesLoading,
      });

    case 'ADD_SELECTED_ORDER_INVOICE_ID':
      return {
        ...state,
        selectedOrderInvoiceIDs: state.selectedOrderInvoiceIDs.concat(
          action.IDToAdd
        ),
      };

    case 'REMOVE_SELECTED_ORDER_INVOICE_ID':
      return {
        ...state,
        selectedOrderInvoiceIDs: state.selectedOrderInvoiceIDs
          .slice(0, action.index)
          .concat(state.selectedOrderInvoiceIDs.slice(action.index + 1)),
      };

    case 'CLEAR_SELECTED_ORDER_INVOICES':
      return {
        ...state,
        selectedOrderInvoiceIDs: [],
      };

    case 'SELECT_ORDER_INVOICES_DATE_RANGES':
      return {
        ...state,
        invoicesStartDate: action.invoicesStartDate,
        invoicesEndDate: action.invoicesEndDate,
      };

    case types.IS_PAYING_INVOICE:
      return {
        ...state,
        isPaying: action.payload,
      };

    default:
      return state;
  }
}
