// Helper that tells you which days the vendors is closed in format array of objects.
// Object format is {dayofWeek: 'saturday', dayOfWeekNumber: 6}
export const getVendorClosedDays = (vendor) => {
  const mapDaystoNumbers = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const vendorClosedDays = Object.entries(vendor.region.operationalDays)
    .filter(([_, value]) => !value.isActive)
    .map(([dayOfWeek]) => ({
      dayOfWeek,
      dayOfWeekNumber: mapDaystoNumbers[dayOfWeek],
    }));

  return vendorClosedDays;
};
